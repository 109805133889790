import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as Ark from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { Loader } from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import { useUserState } from 'utils/userStore';
import config from 'config';
import { usePlotDetails } from 'data/plot';
import { PlotNavigation, PlotDetailsNavigation } from 'screens/routes';
import Page from 'components/Page';
import PlotDetailsBreadcrumb from './PlotDetailsBreadcrumb';
import { ConfigurationPanel, ObservationTypesPanel, TracksPanel } from './components';
import styles from './PlotDetailsScreen.module.scss';

const PlotDetailsScreen: React.FC = () => {
    const { t } = useTranslation();
    const { query: { plotId: plotIdParam }, replace: replaceNavigation } = useNavigation();
    const plotId = React.useMemo(() => parseInt(plotIdParam, 10), [plotIdParam]);
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';
    useBindYearNavigation(PlotDetailsNavigation, [plotId]);
    const {
        data: plot,
        isLoading,
        isFetched: hasLoaded
    } = usePlotDetails(plotId, { enabled: Ark.isValidNumber(plotId) });

    React.useEffect(() => {
        if (plot == null && hasLoaded) {
            replaceNavigation(PlotNavigation());
        }
    }, [plot, hasLoaded, replaceNavigation]);

    if (!Ark.isDefined(plot)) return null;

    return (
        <>
            {isLoading && (
                <Loader text="chargement" />
            )}
            <Helmet>
                <title>{t('plot.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {plot.label}
                    </Page.Title>
                    <Page.HeaderActions>
                        <PlotDetailsBreadcrumb label={plot.label} />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <ConfigurationPanel plot={plot} displayActions={displayActions} className="col col-8" />
                    <div className={`col col-4 ${styles['sidePanelContainer']}`}>
                        <ObservationTypesPanel
                            plotId={plot.id}
                            types={plot.observationTypes}
                            displayActions={displayActions}
                            className={`row ${styles['sidePanel']}`}
                        />
                        <TracksPanel tracks={plot.tracks} className={`row ${styles['sidePanel']}`} />
                    </div>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(PlotDetailsScreen, 'PLOT_DETAILS');