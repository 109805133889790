import { isDefined } from '@dateam/ark';
import { GuardFunction, NavigationRoute } from 'shared-utils';
import { firstAuthPromise } from 'utils/auth';
import { getUserState } from 'utils/userStore';

const authGuard = (fallback: NavigationRoute): GuardFunction => async (next, to) => {
    if (to.metadata?.authRequired !== true) return next();

    try {
        await firstAuthPromise;

        const user = getUserState();

        if (isDefined(user) &&
            ['administrator', 'supervisor', 'writer'].indexOf(user.roleId) >= 0) {
            return next();
        }

        return next.redirect(fallback);
    }
    catch {
        // Ignore
    }

    next.redirect(fallback);
};

export default authGuard;