import {
    assert,
    assertIsArray,
    assertIsValidNumber,
    checkType,
    formatDate,
    HttpStatusCode,
    isArray,
    isDefined,
    isObjectLike,
    isString,
    isValidNumber,
    mapEachProperty,
    ServiceError,
    stringFormat,
    Type
} from '@dateam/ark';
import httpService from 'utils/httpService';
import tokenStore from 'utils/tokenStore';
import config from 'config';
import { parse as parseObservation, RecordValue } from 'data/observation';

type CreateInspectionRequest = {
    label: string;
    includeTrackId: number | null;
};

type UpdateInspectionRequest = {
    label: string;
    instruction: string | null;
    startDate: Date | null;
    overlapDays: number | null;
    assigneeUser: number | null;
    writer: number | null;
};

type UpdateInspectionObservationsRequest = {
    observations: number[];
};

type UpdateInspectionPlotsRequest = {
    plots: App.PlotOrder[];
};

type InspectionPlotObsCommentRequest = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
    observationComment: App.InspectionPlotWithRecord['observationComment'];
};

type InspectionValidationRequest = {
    inspectionId: App.Inspection['id'];
    validationDate: Date;
};

export type ObservationRecord = {
    inspectionId: App.InspectionWithRecord['id'];
    plotId: App.InspectionPlotWithRecord['id'];
    observation: App.Api.ObservationRecord;
};

export const get = async (
    year: number,
    id: App.Inspection['id']
): Promise<App.InspectionDetails> => {
    assertIsValidNumber(year, 'year parameter is not valid');
    assertIsValidNumber(id, 'id parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}', config.apiUrl, year, id);

        response = await httpService.get<App.InspectionDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_GET_DETAILS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_GET_DETAILS_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_GET_DETAILS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_GET_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assert(isDefined(response.body) && isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_GET_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
    // return mapInspectionDetails(response.body);
};

export const getByCampaign = async (
    year: number,
    campaignId: App.Campaign['id']
): Promise<App.Inspection[]> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}/inspection', config.apiUrl, year, campaignId);

        response = await httpService.get<App.Inspection[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_GET_BY_CAMPAIGN_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_GET_BY_CAMPAIGN_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_GET_BY_CAMPAIGN_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_GET_BY_CAMPAIGN_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_GET_BY_CAMPAIGN_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getInspectionRecord = async (
    year: number,
    id: App.Inspection['id']
): Promise<App.InspectionWithRecord> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}/record', config.apiUrl, year, id);

        response = await httpService.get<App.Api.InspectionWithRecord>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_RECORD_GET_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_RECORD_GET_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_RECORD_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_RECORD_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assert(isDefined(response.body) && isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_RECORD_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return parseInspectionRecord(response.body);
};

export const getAll = async (year: number): Promise<App.Inspection[]> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection', config.apiUrl, year);

        response = await httpService.get<App.Inspection[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_GET_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getInspectionReports = async (
    year: number,
    id: App.Inspection['id']
): Promise<App.InspectionReport[]> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}/report', config.apiUrl, year, id);

        response = await httpService.get<App.InspectionReport[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_GET_REPORTS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_GET_REPORTS_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_GET_REPORTS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_GET_REPORTS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_GET_REPORTS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getAllInspectionReport = async (
    year: number,
    customerId?: number
): Promise<App.Report.Report[]> => {
    assertIsValidNumber(year, 'year parameter is not valid');
    // assertIsValidNumber(customerId, 'customerId parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat(
            '{0}/{1}/report',
            config.apiUrl,
            year
        );

        const requestData = customerId != null ? { customerId } : {};

        response = await httpService.get<App.Api.Report.Report[]>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling report API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send report request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_GET_REPORT_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_GET_REPORT_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_GET_REPORT_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_GET_REPORT_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assert(isDefined(response.body) && isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_GET_REPORT_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
    return response.body;
};

export const getInspectionReport = async (
    year: number,
    id: App.Inspection['id'],
    reportId: App.InspectionReport['id']
): Promise<App.Report.ReportDetails> => {
    assertIsValidNumber(year, 'year parameter is not valid');
    assertIsValidNumber(id, 'id parameter is not valid');
    assertIsValidNumber(reportId, 'reportId parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat(
            '{0}/{1}/inspection/{2}/report/{3}',
            config.apiUrl,
            year,
            id,
            reportId
        );

        response = await httpService.get<App.Api.Report.ReportDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${getAll.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_GET_REPORT_DETAILS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_GET_REPORT_DETAILS_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_GET_REPORT_DETAILS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_GET_REPORT_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assert(isDefined(response.body) && isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_GET_REPORT_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    const { observatorUser, writerUser, ...othersProps } = response.body;

    return {
        ...othersProps,
        observatorUser: observatorUser?.id ?? null,
        writerUser: writerUser?.id ?? null
    };
};

export const createInspection = async (
    year: number,
    campaignId: App.Campaign['id'],
    request: CreateInspectionRequest
): Promise<number> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(campaignId)) throw new Error('campaignId parameter is not valid');
    if (!isDefined(request)) throw new Error('request parameter is not valid');

    const { label, includeTrackId } = request;
    if (!isString(label) || label.trim().length === 0) throw new ServiceError('INSPECTION_CREATE_INVALID_REQUEST', 'request label is not valid');
    if (!checkType(includeTrackId, Type.Null | Type.Number | Type.Valid)) throw new ServiceError('INSPECTION_CREATE_INVALID_REQUEST', 'request includeTrackId is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}/inspection', config.apiUrl, year, campaignId);
        const requestData = { label, includeTrackId };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling create inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${createInspection.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_CREATE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_CREATE_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_CREATE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_CREATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isValidNumber(response.body), 'invalid inspection id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const saveInspectionRecord = async (records: ObservationRecord[]): Promise<void> => {
    assertIsArray(records, 'saveRecords: records are not valid');

    let response;

    try {
        const requestUrl = stringFormat('{0}/activity/records', config.apiUrl);

        response = await httpService.post(requestUrl, {
            records: records.map(record => ({
                inspectionId: record.inspectionId,
                plotId: record.plotId,
                ...mapEachProperty(record.observation, value => (value instanceof RecordValue ? value.value : value))
            }))
        });
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling activity API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection request (saveInspectionRecord)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('RECORD_SAVE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('RECORD_SAVE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'RECORD_SAVE_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const updateInspection = async (
    year: number,
    id: App.Inspection['id'],
    request: UpdateInspectionRequest
): Promise<void> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!isDefined(request)) throw new Error('request parameter is not valid');

    const {
        label,
        instruction,
        startDate,
        overlapDays,
        assigneeUser,
        writer
    } = request;

    if (!isString(label) || label.trim().length === 0) throw new ServiceError('INSPECTION_UPDATE_INVALID_REQUEST', 'request label is not valid');
    if (!checkType(instruction, Type.Null | Type.String)) throw new ServiceError('INSPECTION_UPDATE_INVALID_REQUEST', 'request instruction is not valid');
    if (!checkType(startDate, Type.Null | Type.Date | Type.Valid)) throw new ServiceError('INSPECTION_UPDATE_INVALID_REQUEST', 'request startDate is not valid');
    if (!checkType(overlapDays, Type.Null | Type.Number | Type.Valid)) throw new ServiceError('INSPECTION_UPDATE_INVALID_REQUEST', 'request overlapDays is not valid');
    if (!checkType(assigneeUser, Type.Null | Type.Number | Type.Valid)) throw new ServiceError('INSPECTION_UPDATE_INVALID_REQUEST', 'request assigneeUser is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}', config.apiUrl, year, id);
        const requestData = {
            label,
            instruction,
            startDate: startDate != null ? formatDate(startDate) : null,
            overlapDays,
            assigneeUser,
            writer
        };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${updateInspection.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_UPDATE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_UPDATE_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_UPDATE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isValidNumber(response.body), 'invalid inspection id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_UPDATE_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteInspection = async (year: number, id: number): Promise<void> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}', config.apiUrl, year, id);

        response = await httpService.delete(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete inspection API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection request (${deleteInspection.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_DELETE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_DELETE_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_DELETE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const updateInspectionObservations = async (
    year: number,
    id: App.Inspection['id'],
    request: UpdateInspectionObservationsRequest
): Promise<void> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!isDefined(request)) throw new Error('request parameter is not valid');

    const { observations } = request;

    if (!isArray(observations) || !observations.every(isValidNumber)) throw new ServiceError('INSPECTION_UPDATE_OBS_INVALID_REQUEST', 'request observations are not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}/observation', config.apiUrl, year, id);
        const requestData = { observations };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update inspection observation API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection observation request (${updateInspectionObservations.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_UPDATE_OBS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_UPDATE_OBS_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_UPDATE_OBS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_UPDATE_OBS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isValidNumber(response.body), 'invalid inspection id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_UPDATE_OBS_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const updateInspectionPlots = async (
    year: number,
    id: App.Inspection['id'],
    request: UpdateInspectionPlotsRequest
): Promise<void> => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!isDefined(request)) throw new Error('request parameter is not valid');

    const { plots } = request;

    if (!isArray(plots) || !plots.every(isObjectLike)) throw new ServiceError('INSPECTION_UPDATE_PLOT_INVALID_REQUEST', 'request plots are not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}/plot', config.apiUrl, year, id);
        const requestData = { plots };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update inspection plot API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send inspection plot request (${updateInspectionPlots.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_UPDATE_PLOT_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_UPDATE_PLOT_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_UPDATE_PLOT_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_UPDATE_PLOT_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isValidNumber(response.body), 'invalid inspection id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_UPDATE_PLOT_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const savePlotObsComment = async (requests: InspectionPlotObsCommentRequest[]): Promise<void> => {
    assertIsArray(requests, 'requests are not valid');

    let response;

    try {
        const year = new Date().getFullYear();
        const requestUrl = stringFormat('{0}/{1}/inspection/plot/obscomment', config.apiUrl, year);
        const requestData = {
            obsComments: requests
        };

        response = await httpService.post<boolean>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection plot obs comment save API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection plot obs comment request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_PLOT_OBS_COMMENT_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_PLOT_OBS_COMMENT_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_PLOT_OBS_COMMENT_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const saveInspectionReport = async (
    year: number,
    inspectionId: App.Inspection['id'],
    report: App.Report.ReportDetails
): Promise<number> => {
    assert(isValidNumber(year), 'year parameter is not valid');
    assert(isValidNumber(inspectionId), 'inspectionId parameter is not valid');
    assert(isDefined(report), 'report parameter is not valid');

    let response;

    try {
        const requestUrl = stringFormat(
            '{0}/{1}/inspection/{2}/report/{3}',
            config.apiUrl,
            year,
            inspectionId,
            report.id
        );
        const requestData = {
            report
        };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection report save API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection report save request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_REPORT_SAVE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_REPORT_SAVE_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_REPORT_SAVE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_REPORT_SAVE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isValidNumber(response.body), 'invalid report id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_REPORT_SAVE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const validateInspections = async (requests: InspectionValidationRequest[]): Promise<void> => {
    assertIsArray(requests, 'requests are not valid');

    let response;

    try {
        const year = new Date().getFullYear();
        const requestUrl = stringFormat('{0}/{1}/inspection/validation', config.apiUrl, year);
        const requestData = {
            validations: requests
        };

        response = await httpService.post<boolean>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection validation API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection validation request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_VALIDATE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_VALIDATE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_VALIDATE_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const refreshReportRecords = async (
    year: number,
    inspectionId: App.Inspection['id'],
    reportId: App.InspectionReport['id']
): Promise<void> => {
    assertIsValidNumber(year, 'year parameter is not valid');
    assertIsValidNumber(inspectionId, 'inspection id parameter is not valid');
    assertIsValidNumber(reportId, 'reportId parameter is not valid');

    let response;

    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}/report/{3}/refreshRecords', config.apiUrl, year, inspectionId, reportId);
        const requestData = {};

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection report API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection report request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_REFRESH_REPORT_RECORDS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_REFRESH_REPORT_RECORDS_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_REFRESH_REPORT_RECORDS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_REFRESH_REPORT_RECORDS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assertIsValidNumber(response.body, 'invalid report id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_REFRESH_REPORT_RECORDS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const sendReport = async (
    year: number,
    inspectionId: App.Inspection['id'],
    reportId: App.InspectionReport['id']
): Promise<void> => {
    assertIsValidNumber(year, 'year parameter is not valid');
    assertIsValidNumber(inspectionId, 'inspection id parameter is not valid');
    assertIsValidNumber(reportId, 'reportId parameter is not valid');

    let response;

    try {
        const requestUrl = stringFormat('{0}/{1}/inspection/{2}/report/{3}/send', config.apiUrl, year, inspectionId, reportId);
        const requestData = {};

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection report API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection report request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('INSPECTION_SEND_REPORT_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('INSPECTION_SEND_REPORT_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('INSPECTION_SEND_REPORT_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'INSPECTION_SEND_REPORT_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assertIsValidNumber(response.body, 'invalid report id from the response');
    }
    catch (err) {
        throw new ServiceError('INSPECTION_SEND_REPORT_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const getExportUrl = (
    year: number,
    id: App.Inspection['id']
): string => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getExportUrl: unable to retrieve token.');

    return stringFormat('{0}/{1}/inspection/{2}/export?access-token={3}', config.apiUrl, year, id, token);
};

export const getReportDownloadUrl = (
    year: number,
    report: App.InspectionReport
): string => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!isDefined(report)) throw new Error('report parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getReportDownloadUrl: unable to retrieve token.');

    return stringFormat('{0}/{1}/{2}?access-token={3}', config.apiUrl, year, report.downloadLink, token);
};

export const getCustomerReportDownloadUrl = (
    year: number,
    customerId?: number
): string => {
    if (!isValidNumber(year)) throw new Error('year parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getReportDownloadUrl: unable to retrieve token.');

    if (customerId != null) {
        return stringFormat('{0}/{1}/report/download?customerId={2}&access-token={3}', config.apiUrl, year, customerId, token);
    }
    return stringFormat('{0}/{1}/report/download?access-token={2}', config.apiUrl, year, token);
};

const parseInspectionRecord = (inspection: App.Api.InspectionWithRecord): App.InspectionWithRecord => {
    const plots = inspection.plots.map(plot => {
        assertIsArray(plot.observations, 'record doesn\'t match expected format (`inspections[].plots[].observations`).');

        return {
            ...plot,
            observations: plot.observations
                .map(parseObservation)
        };
    });

    return {
        ...inspection,
        plots
    };
};
