import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { ChevronRight } from 'react-feather';
import styles from './Breadcrumb.module.scss';

type Props = {
    className?: string;
}

const BreadcrumbSeparator: React.FC<Props> = ({
    className
}: Props) => {
    return (
        <li className={concatClassName(styles['separator'], className)}>
            <ChevronRight />
        </li>
    );
};

BreadcrumbSeparator.propTypes = {
    className: propTypes.string
};

BreadcrumbSeparator.defaultProps = {
    className: undefined
};

export default BreadcrumbSeparator;