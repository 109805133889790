import React from 'react';
import { isValidDate, dateToFormat } from '@dateam/ark';
import { propTypeNullable } from '@dateam/ark-react';

type Props = {
    date: Date | null;
};

const DateColumn: React.FC<Props> = ({
    date
}: Props) => {
    const formattedDate = React.useMemo(() => {
        if (isValidDate(date)) return dateToFormat(date);

        return '-';
    }, [date]);

    return (
        <>
            {formattedDate}
        </>
    );
};

DateColumn.propTypes = {
    date: propTypeNullable(isValidDate)
};

DateColumn.defaultProps = {
    date: undefined
};

export default DateColumn;
