import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from './Sidebar.module.scss';

type Props = {
    className?: string;
};

const SidebarMenu = ({
    className,
    children
}: React.PropsWithChildren<Props>) => (
    <div className={styles['menuContainer']}>
        <ul className={concatClassName(styles['menu'], className)}>
            {children}
        </ul>
    </div>
);

SidebarMenu.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

SidebarMenu.defaultProps = {
    className: undefined,
    children: undefined
};

export default SidebarMenu;
