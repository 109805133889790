import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import * as Ark from '@dateam/ark';
import { Card } from 'shared-ui';
import { InspectionReportDetailsNavigation } from 'screens/routes';
import { useReport } from 'data/inspection';
import { DocumentIcon } from 'components/Icons';
import {
    SidePanelList,
    SidePanelListItemLink,
    SidePanelListItemCol
} from 'components/SidePanelList';
import styles from '../DashboardScreen.module.scss';

type Props = {
    className?: string;
};

const ReportPanel: React.FC<Props> = ({ className }) => {
    // const { t } = useTranslation();
    // const { data: report } = useReport(2, 172);

    // const reports = React.useMemo(() => {
    //     const data: App.Report.ReportDetails[] = [];

    //     if (Ark.isDefined(report)) data.push(report);

    //     const newData = data.filter(item => Ark.isValidDate(item?.updatedOn));

    //     Ark.orderBy(newData, 'creationDate');

    //     return newData;
    // }, [report]);
    // const campaignNav = useYearlyNavigation(UserDetailsNavigation, [value.id]);
    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>
                    Rapports en attente
                </Card.Title>
            </Card.Header>
            <Card.Body className={styles['cardContent']}>
                {/* <SidePanelList>
                    {reports.map(report => (
                        <SidePanelListItemLink key={report.id} to={InspectionReportDetailsNavigation(report.inspectionId, 'report', report.id)}>
                            <SidePanelListItemCol className="col-1" icon={<DocumentIcon />} />
                            <SidePanelListItemCol className="col-5" text={report.customer.label} subtext={report.inspectionLabel} />
                            <SidePanelListItemCol
                                className="col-6"
                                text={t('dashboard.inspection.plot', { count: report.plotCount })}
                                subtext={Ark.dateToFormat(report.creationDate, 'dd/MM HHhmm')}
                            />
                        </SidePanelListItemLink>
                    ))}
                </SidePanelList> */}
            </Card.Body>
        </Card>
    );
};

ReportPanel.propTypes = {
    className: propTypes.string
};

ReportPanel.defaultProps = {
    className: undefined
};

export default ReportPanel;
