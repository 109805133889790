import React from 'react';
import { NavigationRoute, useNavigation, useRouterPath } from 'shared-utils';
import { useYearlyRootNavigation } from 'utils/yearStore';
import Breadcrumb from 'components/Breadcrumb';
import { DashboardNavigation, PlotNavigation } from 'screens/routes';

type Props = {
    label: string;
};

const PlotDetailsBreadcrumb: React.FC<Props> = ({ label }: Props) => {
    const homePath = useRouterPath(DashboardNavigation());
    const [rootNavigations] = React.useState({ plotNav: PlotNavigation });
    const { plotNav } = useYearlyRootNavigation(rootNavigations);
    const plotPath = useRouterPath(plotNav);
    const { push: navigate } = useNavigation();

    const triggerNav = React.useCallback((route: NavigationRoute) => (event: React.MouseEvent<any>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(route);
    }, [navigate]);

    return (
        <Breadcrumb>
            <Breadcrumb.Home
                key="bc-home"
                to={homePath ?? undefined}
                onClick={triggerNav(DashboardNavigation())}
            />
            <Breadcrumb.Item
                key="bc-plot"
                to={plotPath ?? undefined}
                onClick={triggerNav(plotNav)}
            >
                Parcelles
            </Breadcrumb.Item>
            <Breadcrumb.Item key="bc-plot-details">{label}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default PlotDetailsBreadcrumb;
