import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isArray, isDefined } from '@dateam/ark';
import { Button, Card, Form } from 'shared-ui';
import { customerDetailsPropTypes, useCustomerEmails, useUpdateCustomerEmails } from 'data/customer';
import { CrossIcon, PlusIcon } from 'components/Icons';
import ListSection from 'components/ListSection';
import AddContactModal from './AddContactModal';
import styles from '../CustomerDetailsScreen.module.scss';

type Props = {
    customer: App.CustomerDetails;
    displayActions?: boolean;
};

const ContactSection: React.FC<Props> = ({
    customer,
    displayActions
}: Props) => {
    const [showAddContact, setShowAddContact] = React.useState(false);
    const { data: contacts } = useCustomerEmails(customer.id);
    const { mutateAsync: updateCustomerEmails, isLoading: isUpdating } = useUpdateCustomerEmails();
    const [emailType, setEmailType] = React.useState<string | null>(null);
    const contactsState = React.useRef(contacts);

    React.useEffect(() => {
        contactsState.current = contacts;
    }, [contacts]);

    const contactFiltered = React.useMemo(() => {
        if (!Array.isArray(contacts) || contacts.length === 0) return [];
        return contacts.filter(item => item.type === emailType);
    }, [contacts, emailType]);

    const saveContacts = React.useCallback(async (value: App.CustomerEmail[]) => {
        if (!isDefined(customer)) throw new Error('Le vigneron n\'a pas été trouvé.');

        await updateCustomerEmails({
            id: customer.id,
            year: customer.year,
            emails: value
        });
    }, [customer, updateCustomerEmails]);

    const handleSaveInput = React.useCallback((key: string) => async (value: string) => {
        if (!isArray(contactsState.current)) return undefined;

        const newContacts = [...contactsState.current];
        const keyIndex = newContacts.findIndex(contact => contact.email === key && contact.type === emailType);
        const valueIndex = newContacts.findIndex(contact => contact.email === value && contact.type === emailType);

        if (keyIndex >= 0) {
            if (valueIndex >= 0) newContacts.splice(valueIndex, 1);
            newContacts[keyIndex] = {
                email: value,
                type: emailType
            };
        }

        saveContacts(newContacts);

        return value;
    }, [contactsState, saveContacts, emailType]);

    const removeContact = React.useCallback((value: string) => {
        if (!isArray(contactsState.current)) return;

        const newContacts = [...contactsState.current];
        const itemIndex = newContacts.findIndex(item => item.email === value && item.type === emailType);

        if (itemIndex >= 0) newContacts.splice(itemIndex, 1);

        saveContacts(newContacts);
    }, [contactsState, saveContacts, emailType]);

    const handleAddContacts = React.useCallback((value: App.CustomerEmail[]) => {
        if (!isArray(contactsState.current) || value.length === 0) return;

        const newContacts = [...contactsState.current];

        value.forEach(newContact => {
            const itemIndex = newContacts.findIndex(contact => contact.email === newContact.email &&
                contact.type === newContact.type);

            if (itemIndex < 0) {
                newContacts.push(newContact);
            }
        });

        saveContacts(newContacts);
    }, [contactsState, saveContacts]);

    const handleEmailFilterChanged = React.useCallback((emailType: string | null) => {
        setEmailType(emailType);
    }, []);

    return (
        <Card>
            <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                <Card.Title>
                    Liste des destinataires
                </Card.Title>
                {displayActions && (
                    <Button
                        color="primary"
                        startIcon={(<PlusIcon />)}
                        onClick={() => setShowAddContact(true)}
                    >
                        Ajouter des destinataires
                    </Button>
                )}
            </Card.Header>
            <Card.Body className="row">
                <ListSection className="col col-3">
                    <ListSection.Filter>
                        <div className={styles['colHeader']}>Type</div>
                    </ListSection.Filter>
                    <ListSection.Content>
                        <ul className={styles['emailTypeList']}>
                            <li
                                key={0}
                                className={concatClassName(
                                    styles['emailTypeItem'],
                                    emailType === null ? styles['emailTypeItem-selected'] : null
                                )}
                                onClick={() => handleEmailFilterChanged(null)}
                            >
                                Contact
                            </li>
                            <li
                                key={9999}
                                className={concatClassName(
                                    styles['emailTypeItem'],
                                    emailType === 'DRE' ? styles['emailTypeItem-selected'] : null
                                )}
                                onClick={() => handleEmailFilterChanged('DRE')}
                            >
                                Contact DRE
                            </li>
                        </ul>
                    </ListSection.Content>
                </ListSection>
                <ListSection className="col">
                    <ListSection.Filter>
                        <div className={styles['colHeader']}>Emails</div>
                    </ListSection.Filter>
                    <ListSection.Content>
                        <div className={styles['list']}>
                            {contactFiltered?.map(contact => (
                                <div className={styles['listItem']} key={contact.email}>
                                    <div className={styles['input']}>
                                        <Form.Editable.Input
                                            value={contact.email}
                                            onSave={handleSaveInput(contact.email)}
                                        />
                                    </div>
                                    <Button
                                        onClick={() => removeContact(contact.email)}
                                        className={styles['delete']}
                                        endIcon={<CrossIcon />}
                                        size="xs"
                                    >
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </ListSection.Content>
                </ListSection>
            </Card.Body>
            {showAddContact && (
                <AddContactModal
                    defaultType={emailType}
                    onClose={() => setShowAddContact(false)}
                    onCompletion={handleAddContacts}
                />
            )}
        </Card>
    );
};

ContactSection.propTypes = {
    customer: customerDetailsPropTypes.isRequired,
    displayActions: propTypes.bool
};

ContactSection.defaultProps = {
    displayActions: undefined
};

export default ContactSection;
