import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isFunction, isArray, noop, strIncludes } from '@dateam/ark';
import { useDebounce } from '@dateam/ark-react';
import { useVarietals } from 'data/varietal';
import { useTracks } from 'data/track';
import { useAreas } from 'data/area';
import { useBioTypes } from 'data/bio';
import { useCustomers } from 'data/customer';

export type PlotFilters = {
    plot: string;
    track: string;
    varietals: number[];
    areas: number[];
    customer: string;
    bio: number[];
};

type Props = {
    enabled?: boolean;
    onChange?: (filters: PlotFilters) => void;
};

const FilterBar: React.FC<Props> = ({ enabled, onChange }) => {
    const { data: tracks } = useTracks({ enabled });
    const { data: varietals } = useVarietals({ enabled });
    const { data: areas } = useAreas({ enabled });
    const { data: customers } = useCustomers({ enabled });
    const { data: bio } = useBioTypes({ enabled });

    const notifyChange = React.useRef(onChange ?? noop);
    const saveChanges = useDebounce((filter: PlotFilters) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<PlotFilters>({
        plot: '',
        track: '',
        varietals: [],
        areas: [],
        customer: '',
        bio: []
    });

    const handlePlotChange = React.useCallback((newValue: string) => {
        const newFilter: PlotFilters = {
            ...filter,
            plot: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleTrackGetOptions = React.useCallback(async (value: string) => {
        if (!isArray(tracks)) return [];
        if (value.trim().length === 0) return tracks.map(track => track.label);

        return tracks.filter(item => strIncludes(value, item.label, { caseInsensitive: true }))
            .map(track => track.label);
    }, [tracks]);

    const handleTrackChange = React.useCallback((newValue: string) => {
        const newFilter: PlotFilters = {
            ...filter,
            track: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleAreaChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: PlotFilters = {
            ...filter,
            areas: selectedValues
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleVarietalChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: PlotFilters = {
            ...filter,
            varietals: selectedValues
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleCustomerGetOptions = React.useCallback(async (value: string) => {
        if (!isArray(customers)) return [];
        if (value.trim().length === 0) return customers.map(customer => customer.fullName);

        return customers.filter(item => strIncludes(value, item.fullName, { caseInsensitive: true }))
            .map(customer => customer.fullName);
    }, [customers]);

    const handleCustomerChange = React.useCallback((newValue: string) => {
        const newFilter: PlotFilters = {
            ...filter,
            customer: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleBioChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: PlotFilters = {
            ...filter,
            bio: selectedValues
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    return (
        <div className="row">
            <Form.Group controlId="filter-plot" className="col">
                <Form.Label>Parcelle</Form.Label>
                <Form.Input
                    value={filter.plot}
                    autocomplete="off"
                    onChange={handlePlotChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-track" className="col">
                <Form.Label>Circuit</Form.Label>
                <Form.Autocomplete<string>
                    value={filter.track ?? undefined}
                    onGetOptions={handleTrackGetOptions}
                    onChange={handleTrackChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-customer" className="col">
                <Form.Label>Vigneron</Form.Label>
                <Form.Autocomplete<string>
                    value={filter.customer ?? undefined}
                    onGetOptions={handleCustomerGetOptions}
                    onChange={handleCustomerChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-area" className="col">
                <Form.Label>Secteur géographique</Form.Label>
                <Form.Multiselect id="filter-area-select" onChange={handleAreaChange}>
                    {areas?.map((area, index) => (
                        <Form.Select.Option
                            key={index}
                            value={area.id}
                            selectedText={area.label}
                        >
                            {area.label}
                        </Form.Select.Option>
                    ))}
                </Form.Multiselect>
            </Form.Group>
            <Form.Group controlId="filter-varietal" className="col">
                <Form.Label>Cépage</Form.Label>
                <Form.Multiselect id="filter-varietal-select" onChange={handleVarietalChange}>
                    {varietals?.map((varietal, index) => (
                        <Form.Select.Option
                            key={index}
                            value={varietal.id}
                            selectedText={varietal.label}
                        >
                            {varietal.label}
                        </Form.Select.Option>
                    ))}
                </Form.Multiselect>
            </Form.Group>
            <Form.Group controlId="filter-bio" className="col">
                <Form.Label>Type de protection</Form.Label>
                <Form.Multiselect id="filter-bio-select" onChange={handleBioChange}>
                    {bio?.map((bioItem, index) => (
                        <Form.Select.Option
                            key={index}
                            value={bioItem.id}
                            selectedText={bioItem.label}
                        >
                            {bioItem.label}
                        </Form.Select.Option>
                    ))}
                </Form.Multiselect>
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    enabled: propTypes.bool,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    enabled: true,
    onChange: undefined
};

export default FilterBar;
