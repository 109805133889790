import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from './ProgressStep.module.scss';

type Props = {
    className?: string;
    children?: ReactNode;
}

const ProgressStepTitle: React.FC<Props> = ({
    className,
    children
}: Props) => (
    <div className={concatClassName(styles['progressStepTitle'], className)}>
        {children}
    </div>
);

ProgressStepTitle.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

ProgressStepTitle.defaultProps = {
    className: undefined,
    children: undefined
};

export default ProgressStepTitle;