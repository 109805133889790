import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    isArray,
    isDefined,
    isValidNumber,
    pureObjectAssign,
    removeFromCollection,
    ServiceError
} from '@dateam/ark';
import { useDebounce } from '@dateam/ark-react';
import { useNavigation, withRouter } from 'shared-utils';
import Page from 'components/Page';
import {
    Card,
    Table,
    Form,
    TableSelectionItem,
    Tabs,
    TableSelectionChangeEvent,
    Loader,
    Button,
    TableHeaderSelectionChangeEvent,
    ConfirmDialog
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import { useUserState } from 'utils/userStore';
import { TrashIcon } from 'components/Icons';
import { SelectedObservations } from 'components/SelectedObservations';
import { FooterDateInfo } from 'components/FooterDateInfo';
import { errorProvider } from 'components/errorHandler';
import { useCampaignDetails, useDeleteCampaign, useUpdateCampaign } from 'data/campaign';
import { useObservations } from 'data/observation';
import { CampaignDetailsNavigation, CampaignNavigation, CampaignTabNavigation } from 'screens/routes';
import PlannedInspection from './PlannedInspection';
import { ReportSection } from './components';
import CampaignDetailsBreadcrumb from './CampaignDetailsBreadcrumb';
import styles from './CampaignDetailsScreen.module.scss';

type ObservationTableItem = TableSelectionItem<App.Observation>;

const CampaignDetailsScreen: React.FC = () => {
    const {
        query: { campaignId: campaignIdParam, tab: tabParam },
        push: navigate,
        replace: replaceNavigation
    } = useNavigation();
    const [showDeletionCampaign, setShowDeletionCampaign] = React.useState(false);
    const campaignId = React.useMemo(() => parseInt(campaignIdParam, 10), [campaignIdParam]);
    useBindYearNavigation(CampaignDetailsNavigation, [campaignId]);
    const {
        data: campaign,
        isFetched: hasLoaded,
        isLoading: isLoadingCampaign
    } = useCampaignDetails(campaignId, { enabled: isValidNumber(campaignId) });
    const { data: observationData } = useObservations();
    const { mutateAsync: updateCampaign, isLoading: isUpdating } = useUpdateCampaign();
    const { mutateAsync: deleteCampaign, isLoading: isDeleting } = useDeleteCampaign();
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';
    const [selectedObservations, setSelectedObservations] = React.useState<App.CampaignDetails['observations']>([]);

    const campaignState = React.useRef(campaign);

    React.useEffect(() => {
        campaignState.current = campaign;
    }, [campaign]);

    React.useEffect(() => {
        if (campaign == null && hasLoaded) {
            replaceNavigation(CampaignNavigation());
        }
    }, [campaign, hasLoaded, replaceNavigation]);

    React.useEffect(() => {
        if (isDefined(campaign)) {
            const { observations } = campaign;

            setSelectedObservations([...observations]);
        }
        else {
            setSelectedObservations([]);
        }
    }, [campaign, setSelectedObservations]);

    React.useEffect(() => {
        if (tabParam == null && campaignId !== null) {
            replaceNavigation(CampaignTabNavigation(campaignId, 'config'));
        }
    }, [tabParam, campaignId, replaceNavigation]);

    const selectTab = React.useCallback((tab: string) => {
        navigate(CampaignTabNavigation(campaignId, tab));
    }, [campaignId, navigate]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: string) => {
        if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

        try {
            const {
                id,
                label,
                instruction,
                observations,
                includeNextInspection
            } = campaignState.current;

            await updateCampaign({
                id,
                label,
                instruction,
                observations,
                includeNextInspection,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }

        return undefined;
    }, [campaignState, updateCampaign]);

    const handleSaveCheckbox = React.useCallback((key: string) => async (newValue: boolean) => {
        if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

        try {
            const {
                id,
                label,
                instruction,
                observations,
                includeNextInspection
            } = campaignState.current;

            await updateCampaign({
                id,
                label,
                instruction,
                observations,
                includeNextInspection,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }

        return undefined;
    }, [campaignState, updateCampaign]);

    const observationDataTable = React.useMemo<ObservationTableItem[]>(() => {
        if (!isArray(observationData)) return [];

        return observationData.map(obs => pureObjectAssign(obs, {
            selected: selectedObservations.includes(obs.id)
        }));
    }, [observationData, selectedObservations]);

    const saveChanges = useDebounce(campaignState => {
        updateCampaign(campaignState);
    }, 300) as typeof updateCampaign;

    const selectObservation = React.useCallback((event: TableSelectionChangeEvent<ObservationTableItem>) => {
        if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

        const { id: obsId } = event.data;

        setSelectedObservations(currentSelection => {
            if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

            const newSelection = [...currentSelection];

            if (event.selected === true) newSelection.push(obsId);
            else removeFromCollection(newSelection, item => item === obsId);

            const {
                id,
                label,
                instruction,
                includeNextInspection
            } = campaignState.current;

            saveChanges({
                id,
                label,
                instruction,
                includeNextInspection,
                observations: newSelection
            });
            return newSelection;
        });
    }, [campaignState, setSelectedObservations, saveChanges]);

    const removeObservation = React.useCallback((obsId: number) => {
        if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

        setSelectedObservations(currentSelection => {
            if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

            const newSelection = [...currentSelection];

            removeFromCollection(newSelection, item => item === obsId);

            const {
                id,
                label,
                instruction,
                includeNextInspection
            } = campaignState.current;

            saveChanges({
                id,
                label,
                instruction,
                includeNextInspection,
                observations: newSelection
            });
            return newSelection;
        });
    }, [campaignState, setSelectedObservations, saveChanges]);

    const handleCampaignDeletion = React.useCallback(async (shouldDelete: boolean) => {
        setShowDeletionCampaign(false);
        if (shouldDelete !== true) return;

        if (!isDefined(campaignState.current)) throw new Error('La campagne n\'existe pas.');

        await deleteCampaign(campaignState.current.id);

        navigate(CampaignNavigation());
    }, [campaignState, setShowDeletionCampaign, navigate, deleteCampaign]);

    const handleHeaderSelectionChange =
        React.useCallback((event: TableHeaderSelectionChangeEvent<ObservationTableItem>) => {
            if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

            const newSelection = event.selected === true ? event.items.map(item => item.data.id) : [];

            setSelectedObservations(() => {
                if (!isDefined(campaignState.current)) throw new Error('La tournée n\'a pas été trouvée.');

                const {
                    id,
                    label,
                    instruction,
                    includeNextInspection
                } = campaignState.current;

                saveChanges({
                    id,
                    label,
                    instruction,
                    includeNextInspection,
                    observations: newSelection
                });
                return newSelection;
            });
        }, [campaignState, setSelectedObservations, saveChanges]);

    const helmetTitle = React.useMemo(() => {
        switch (tabParam) {
            case 'config':
                return `${config.appName} - Configuration de la tournée`;
            case 'obs':
                return `${config.appName} - Observations de la tournée`;
            case 'report':
                return `${config.appName} - Modèle de rapport de la tournée`;
            default:
                return `${config.appName} - Détail de la tournée`;
        }
    }, [tabParam]);

    const handleDeleteCampaginClick = () => setShowDeletionCampaign(true);

    return (
        <>
            {isLoadingCampaign && (
                <Loader text="chargement" />
            )}
            <Helmet>
                <title>{helmetTitle}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {campaign?.label ?? '\u00a0'} {/* nobreakspace */}
                    </Page.Title>
                    <Page.HeaderActions>
                        <CampaignDetailsBreadcrumb label={campaign?.label ?? ''} />
                        <Tabs
                            selected={tabParam}
                            onChange={selectTab}
                        >
                            <Tabs.Tab tabKey="config" title="Configuration" />
                            <Tabs.Tab tabKey="obs" title="Observations" />
                            <Tabs.Tab tabKey="report" title="Modèle de rapports" />
                        </Tabs>
                    </Page.HeaderActions>
                </Page.Header>
                {tabParam === 'config' && (
                    <Page.Content className="row">
                        <Card className="col col-6">
                            <Card.Header>
                                <Card.Title>
                                    Caractéristiques
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group controlId="campaign-name" className="row">
                                    <Form.Label>Nom de la tournée</Form.Label>
                                    <Form.Editable.Input
                                        value={campaign?.label ?? ''}
                                        maxlength={50}
                                        onSave={handleSaveInput('label')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="campaign-instruction" className="row">
                                    <Form.Label>Consigne</Form.Label>
                                    <Form.Editable.Input
                                        value={campaign?.instruction ?? ''}
                                        multiline
                                        rows={10}
                                        onSave={handleSaveInput('instruction')}
                                        maxlength={1000}
                                    />
                                </Form.Group>
                                <Form.Group controlId="campaign-notice-next-inspection" className="row form-inline">
                                    <Form.Checkbox checked={campaign?.includeNextInspection ?? false} onChange={handleSaveCheckbox('includeNextInspection')} />
                                    <Form.Label>Annoncer la prochaine visite</Form.Label>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer className="row">
                                {campaign != null && (
                                    <Card.Infos>
                                        <FooterDateInfo
                                            creationDate={campaign.creationDate}
                                            updateDate={campaign.updateDate}
                                        />
                                    </Card.Infos>
                                )}
                                {displayActions && (
                                    <Card.Actions>
                                        <Button
                                            color="danger"
                                            variant="outlined"
                                            startIcon={(<TrashIcon />)}
                                            disabled={isUpdating || isDeleting}
                                            pending={isDeleting}
                                            onClick={handleDeleteCampaginClick}
                                        >
                                            Supprimer la tournée
                                        </Button>
                                    </Card.Actions>
                                )}
                            </Card.Footer>
                        </Card>
                        <PlannedInspection
                            displayActions={displayActions}
                            className="col col-6"
                            campaignId={campaignId ?? undefined}
                        />
                    </Page.Content>
                )}
                {tabParam === 'obs' && (
                    <Page.Content className="row">
                        <Card className="col col-8">
                            <Card.Header>
                                <Card.Title>
                                    Sélection des observations
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table<ObservationTableItem>
                                    data={observationDataTable}
                                    selectedField="selected"
                                    onSelectionChange={selectObservation}
                                    onHeaderSelectionChange={handleHeaderSelectionChange}
                                >
                                    <Table.Column accessor="label">
                                        <Table.Column.Header>Nom</Table.Column.Header>
                                    </Table.Column>
                                </Table>
                            </Card.Body>
                        </Card>
                        <Card className="col col-4">
                            <Card.Header>
                                <Card.Title>
                                    Observations selectionnées
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className={styles['cardContent']}>
                                <SelectedObservations
                                    observations={selectedObservations}
                                    onRemoveObservation={removeObservation}
                                />
                            </Card.Body>
                        </Card>
                    </Page.Content>
                )}
                {tabParam === 'report' && (
                    <ReportSection campaignId={campaign?.id} />
                )}
            </Page>
            {showDeletionCampaign && (
                <ConfirmDialog
                    title={`Supprimer la campagne "${campaign?.label}"`}
                    message="Vous êtes sur le point de supprimer cette campagne. Êtes-vous sûr de vouloir continuer ?"
                    ok="Supprimer"
                    onClose={handleCampaignDeletion}
                />
            )}
        </>
    );
};

export default withRouter(CampaignDetailsScreen, 'CAMPAIGN_DETAILS');