import React from 'react';
import { Button } from 'shared-ui';
import { useNavigation } from 'shared-utils';
import { ChevronLeftIcon } from 'components/Icons';
import { InspectionTabNavigation } from 'screens/routes';
import styles from '../ReportSection.module.scss';

const HeaderAction: React.FC = () => {
    const { query: { inspectionId: inspectionIdParam }, push: navigate } = useNavigation();

    const handleBackNavigation = React.useCallback(() => {
        navigate(InspectionTabNavigation(parseInt(inspectionIdParam, 10), 'report'));
    }, [navigate, inspectionIdParam]);

    return (
        <div className={styles['headerActions']}>
            <Button
                startIcon={<ChevronLeftIcon />}
                color="primary"
                variant="outlined"
                onClick={handleBackNavigation}
            >
                Revenir à la liste des rapports
            </Button>
        </div>
    );
};

export default HeaderAction;
