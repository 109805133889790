import React from 'react';
import propTypes from 'prop-types';
import { useDebounce } from '@dateam/ark-react';
import { Form } from 'shared-ui';
import { useSaveObservationComment } from 'data/observation';
import ObservationItem from 'components/Observation/ObservationItem';
import ObservationField from 'components/Observation/ObservationField';
import { plotPropType } from './types';

type Props = {
    inspectionId: App.Inspection['id'];
    plot: App.InspectionPlotWithRecord;
};

const ObservationComment: React.FC<Props> = ({
    inspectionId,
    plot
}) => {
    const { mutateAsync: saveObservationComment } = useSaveObservationComment();
    const plotId = React.useRef(plot?.id);

    const saveChanges = useDebounce(
        (...args: Parameters<typeof saveObservationComment>): ReturnType<typeof saveObservationComment> => {
            const [changes] = args;

            return saveObservationComment(changes);
        },
        300
    );

    React.useEffect(() => {
        if (plotId.current !== plot.id) plotId.current = plot.id;
    }, [plot]);

    const handleCommentChange = React.useCallback((newValue: string) => {
        saveChanges({
            inspectionId,
            plotId: plotId.current,
            observationComment: newValue
        });
    }, [inspectionId, plotId, saveChanges]);

    return (
        <ObservationItem label="Autres remarques">
            <div className="row">
                <ObservationField
                    id="observationComment"
                    className="col col-12"
                >
                    <Form.Input
                        multiline
                        rows={4}
                        value={plot.observationComment ?? ''}
                        onChange={handleCommentChange}
                    />
                </ObservationField>
            </div>
        </ObservationItem>
    );
};

ObservationComment.propTypes = {
    inspectionId: propTypes.number.isRequired,
    plot: plotPropType.isRequired
};

ObservationComment.defaultProps = {

};

export default ObservationComment;
