import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Modal,
    Table,
    Loader,
    TableSelectionChangeEvent,
    TableHeaderSelectionChangeEvent
} from 'shared-ui';
import { usePlotSearch } from 'data/effects';
import {
    ColumnBio,
    ColumnArea,
    ColumnCustomer,
    ColumnName,
    ColumnVarietal,
    ColumnObservationType,
    FilterBar,
    PlotFilters
} from './components';
import styles from './PlotSelector.module.scss';

type Props = {
    className?: string;
    onClose?: () => void;
    onAdd?: (addedPlots: number[]) => void;
};

type PlotRow = App.Plot & {
    selected: boolean;
};
type DataTablePlot = { data: PlotRow; };

const PlotSelector: React.FC<Props> = ({
    className,
    onClose,
    onAdd
}: Props) => {
    const [selection, setSelection] = React.useState<number[]>([]);
    const [filter, setFilter] = React.useState<PlotFilters>({
        plot: '',
        track: '',
        varietals: [],
        areas: [],
        bio: []
    });

    const { data: plots, isLoading: isLoadingPlots } = usePlotSearch({
        plot: filter.plot,
        track: filter.track,
        varietalIds: filter.varietals,
        areaIds: filter.areas,
        customer: '',
        bio: filter.bio
    });

    const plotRows = React.useMemo<PlotRow[]>(() => {
        if (!Array.isArray(plots)) return [];

        return plots.map(plot => ({
            ...plot,
            selected: selection.some(item => item === plot.id)
        }));
    }, [plots, selection]);

    const handleFilterBarChange = (filter: PlotFilters) => {
        setFilter(filter);
    };

    const handleSelectionChange = (event: TableSelectionChangeEvent<PlotRow>) => {
        const newSelection = [...selection];
        const itemIndex = selection.findIndex(stateItem => stateItem === event.data.id);

        if (itemIndex < 0 && event.selected === true) {
            newSelection.push(event.data.id);
        }
        else if (itemIndex >= 0 && event.selected === false) {
            newSelection.splice(itemIndex, 1);
        }

        setSelection(newSelection);
    };

    const handleHeaderSelectionChange =
        React.useCallback((event: TableHeaderSelectionChangeEvent<PlotRow>) => {
            const newSelection = event.selected === true ? event.items.map(item => item.data.id) : [];
            setSelection(newSelection);
        }, [setSelection]);

    const onCloseClick = () => {
        onClose?.();
    };

    const onAddClick = () => {
        onAdd?.(selection);
    };

    return (
        <Modal
            className={concatClassName(styles['modal'], 'modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Ajouter des parcelles
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles['body']}>
                <FilterBar onChange={handleFilterBarChange} />
                <div className={styles['tableSection']}>
                    {isLoadingPlots && (
                        <Loader text="chargement" />
                    )}
                    <Table<PlotRow>
                        data={plotRows}
                        selectedField="selected"
                        onSelectionChange={handleSelectionChange}
                        onHeaderSelectionChange={handleHeaderSelectionChange}
                    >
                        <Table.Column className={styles['tableColumn']}>
                            <Table.Column.Header>Nom</Table.Column.Header>
                            <Table.Column.Cell>
                                {({ data }: DataTablePlot) => (<ColumnName data={data} />)}
                            </Table.Column.Cell>
                        </Table.Column>
                        <Table.Column className={styles['tableColumn']}>
                            <Table.Column.Header>Localisation</Table.Column.Header>
                            <Table.Column.Cell>
                                {({ data }: DataTablePlot) => (<ColumnArea data={data} />)}
                            </Table.Column.Cell>
                        </Table.Column>
                        <Table.Column className={styles['tableColumn']}>
                            <Table.Column.Header>Vigneron</Table.Column.Header>
                            <Table.Column.Cell>
                                {({ data }: DataTablePlot) => (<ColumnCustomer data={data.customer} />)}
                            </Table.Column.Cell>
                        </Table.Column>
                        <Table.Column className={styles['tableColumn']}>
                            <Table.Column.Header>Cépage</Table.Column.Header>
                            <Table.Column.Cell>
                                {({ data }: DataTablePlot) => (<ColumnVarietal data={data.varietal} />)}
                            </Table.Column.Cell>
                        </Table.Column>
                        <Table.Column className={styles['tableColumn']}>
                            <Table.Column.Header>Suivi</Table.Column.Header>
                            <Table.Column.Cell>
                                {({ data: { observationTypes } }: DataTablePlot) => (
                                    <ColumnObservationType data={observationTypes} />
                                )}
                            </Table.Column.Cell>
                        </Table.Column>
                        <Table.Column className={styles['tableColumn']}>
                            <Table.Column.Header>Type de protection</Table.Column.Header>
                            <Table.Column.Cell>
                                {({ data }: DataTablePlot) => (<ColumnBio data={data.bio} />)}
                            </Table.Column.Cell>
                        </Table.Column>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCloseClick}>Annuler</Button>
                <Button color="primary" onClick={onAddClick}>Ajouter les parcelles</Button>
            </Modal.Footer>
        </Modal>
    );
};

PlotSelector.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onAdd: propTypes.func
};

PlotSelector.defaultProps = {
    className: undefined,
    onClose: undefined,
    onAdd: undefined
};

export default PlotSelector;
