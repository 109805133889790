import React from 'react';
import propTypes from 'prop-types';
import { Table } from 'shared-ui';
import { isArray, orderBy } from '@dateam/ark';
import { reportPropTypes } from 'data/inspection';
import ColumnInspection from './ColumnInspection';
import ColumnPlot from './ColumnPlot';
import ColumnDate from './ColumnDate';
import ColumnActions from './ColumnActions';
import styles from '../ReportScreen.module.scss';

type DataTableReport = { data: App.Report.Report; };

type Props = {
    reports?: App.Report.Report[];
};

const ReportList: React.FC<Props> = ({ reports }: Props) => {
    const tableData = React.useMemo(() => {
        if (!isArray(reports)) return [];

        const data = [...reports];

        orderBy(data, 'inspectionDate', { ascending: false });

        return data;
    }, [reports]);

    return (
        <Table<App.Report.Report>
            data={tableData}
            // onRowClick={handleRowClick}
            className={styles['tableRow']}
        >
            <Table.Column className={styles['colName']}>
                <Table.Column.Header>Visite</Table.Column.Header>
                <Table.Column.Cell>
                    {({ data }: DataTableReport) => (<ColumnInspection data={data} />)}
                </Table.Column.Cell>
            </Table.Column>
            <Table.Column className={styles['colInspectionDate']}>
                <Table.Column.Header>Date</Table.Column.Header>
                <Table.Column.Cell>
                    {({ data }: DataTableReport) =>
                        (<ColumnDate date={data.inspectionDate} />)}
                </Table.Column.Cell>
            </Table.Column>
            <Table.Column className={styles['colPlot']}>
                <Table.Column.Header>Parcelle</Table.Column.Header>
                <Table.Column.Cell>
                    {({ data }: DataTableReport) => (<ColumnPlot data={data} />)}
                </Table.Column.Cell>
            </Table.Column>
            <Table.Column className={styles['coleGenerateDate']}>
                <Table.Column.Header>Généré le</Table.Column.Header>
                <Table.Column.Cell>
                    {({ data }: DataTableReport) =>
                        (<ColumnDate date={data.generationDate} />)}
                </Table.Column.Cell>
            </Table.Column>
            <Table.Column className={styles['colSendDate']}>
                <Table.Column.Header>Envoyé le</Table.Column.Header>
                <Table.Column.Cell>
                    {({ data }: DataTableReport) => (<ColumnDate date={data.sendDate} />)}
                </Table.Column.Cell>
            </Table.Column>
            <Table.Column className={styles['colActions']}>
                <Table.Column.Header>Actions</Table.Column.Header>
                <Table.Column.Cell>
                    {(data: DataTableReport) =>
                        (<ColumnActions data={data.data} />)}
                </Table.Column.Cell>
            </Table.Column>
        </Table>
    );
};

ReportList.propTypes = {
    reports: propTypes.arrayOf(reportPropTypes.isRequired)
};

ReportList.defaultProps = {
    reports: undefined
};

export default ReportList;
