import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';
import tokenStore from 'utils/tokenStore';

type UpdatePlotRequest = {
    publicId: string;
    label: string;
    areaId: number | null;
    bioId: number | null;
    varietalId: number | null;
    confusion: boolean | null;
    city: string | null;
    landReference: string | null;
    comment: string | null;
};

export const getAll = (year: number): Ark.CancellablePromise<App.Plot[]> => {
    if (!Ark.isNumber(year)) throw new Error('year parameter is not valid');

    let responsePromise;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot', config.apiUrl, year);

        responsePromise = httpService.get<App.Plot[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling plot API (${errMessage}})`);
    }

    return responsePromise.withPromise(responsePromise.then(response => {
        if (response.status === -1) throw new Error('unable to send plot request (getAll)');
        if (response.status === Ark.HttpStatusCode.Unauthorized) {
            throw new Ark.ServiceError('PLOT_GET_ALL_UNAUTHORIZED');
        }
        if (response.status === Ark.HttpStatusCode.Forbidden) {
            throw new Ark.ServiceError('PLOT_GET_ALL_FORBIDDEN');
        }

        if (Ark.isDefined(response.error)) {
            throw new Ark.ServiceError(
                response.error.code ?? 'PLOT_GET_ALL_ERROR',
                response.error.message ?? undefined
            );
        }

        try {
            Ark.assert(response.jsonContent === true, 'excepted JSON data');
            Ark.assertIsArray(response.body, 'invalid request data');
        }
        catch (err) {
            throw new Ark.ServiceError('PLOT_GET_ALL_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
        }

        return response.body;
    }));
};

export const get = async (year: number, plotId: App.PlotDetails['id']): Promise<App.PlotDetails> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(plotId, 'plotId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot/{2}', config.apiUrl, year, plotId);

        response = await httpService.get<App.PlotDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling plot API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send plot request (${get.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('PLOT_GET_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('PLOT_GET_DETAILS_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('PLOT_GET_DETAILS_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'PLOT_GET_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isPlainObject(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('PLOT_GET_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getCities = async (year: number): Promise<string[]> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot/city', config.apiUrl, year);

        response = await httpService.get<string[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling plot API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send plot request (${get.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('PLOT_GET_CITY_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('PLOT_GET_CITY_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('PLOT_GET_CITY_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'PLOT_GET_CITY_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('PLOT_GET_CITY_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

type CreatePlotRequest = {
    publicId: string;
    customerId: number;
    label: string;
};

export const create = async (year: number, request: CreatePlotRequest): Promise<App.PlotKey> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot', config.apiUrl, year);
        const {
            publicId,
            customerId,
            label
        } = request;
        const requestData = {
            publicId,
            customerId,
            label
        };

        response = await httpService.post<App.PlotKey>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling plot API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send plot request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('PLOT_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('PLOT_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('PLOT_CREATEL_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'PLOT_CREATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isPlainObject(response.body), 'invalid plot key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('PLOT_CREATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const update = async (year: number, plotId: App.PlotIdentity['id'], request: UpdatePlotRequest): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(plotId, 'plotId parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot/{2}', config.apiUrl, year, plotId);
        const {
            publicId,
            label,
            areaId,
            bioId,
            varietalId,
            confusion,
            city,
            landReference,
            comment
        } = request;
        const requestData = {
            publicId,
            label,
            areaId,
            bioId,
            varietalId,
            confusion,
            city,
            landReference,
            comment
        };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling plot API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send plot request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('PLOT_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('PLOT_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('PLOT_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'PLOT_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid plot key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('PLOT_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const updateObservationTypes = async (year: number, plotId: App.PlotIdentity['id'], observationTypes: number[]): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(plotId, 'plotId parameter is not valid');
    Ark.assertIsArray(observationTypes, 'observation types parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot/{2}/observationtypes', config.apiUrl, year, plotId);
        const requestData = { observationTypes };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling plot type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send plot request (${updateObservationTypes.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('PLOT_UPDATE_OBSERVATION_TYPES_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('PLOT_UPDATE_OBSERVATION_TYPES_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('PLOT_UPDATE_OBSERVATION_TYPES_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'PLOT_UPDATE_OBSERVATION_TYPES_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid plot key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('PLOT_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deletePlot = async (year: number, plotId: App.PlotIdentity['id']): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(plotId, 'plotId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/plot/{2}', config.apiUrl, year, plotId);

        response = await httpService.delete(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete plot API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send plot request (${deletePlot.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('PLOT_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('PLOT_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('PLOT_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'PLOT_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid plot key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('PLOT_DELETE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const getExportUrl = (year: number): string => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getExportUrl: unable to retrieve token.');

    return Ark.stringFormat('{0}/{1}/plot/export?access-token={2}', config.apiUrl, year, token);
};