import React from 'react';
import propTypes from 'prop-types';
import { formatDate, isString, isValidDate } from '@dateam/ark';
import { concatClassName, propTypeNullable } from '@dateam/ark-react';
import styles from './FooterDateInfo.module.scss';

type Props = {
    // label?: string;
    creationDate: Date;
    updateDate?: Date | null;
    className?: string;
};

const FooterDateInfo: React.FC<Props> = ({
    creationDate,
    updateDate,
    className
}: Props) => (
    <div className={concatClassName(styles['footerDateInfo'], className)}>
        <div>Création : {formatDate(creationDate)}</div>
        <div>
            Dernière modification : {isValidDate(updateDate) ? formatDate(updateDate) : formatDate(creationDate)}
        </div>
    </div>
);

FooterDateInfo.propTypes = {
    creationDate: propTypes.instanceOf(Date).isRequired,
    updateDate: propTypes.instanceOf(Date),
    className: propTypes.string
};

FooterDateInfo.defaultProps = {
    updateDate: undefined,
    className: undefined
};

export default FooterDateInfo;
