import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const SelectionIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
    </svg>
);

SelectionIcon.propTypes = {
    className: propTypes.string
};

SelectionIcon.defaultProps = {
    className: undefined
};

export default SelectionIcon;
