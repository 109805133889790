import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { isArray, orderBy } from '@dateam/ark';
import { withRouter } from 'shared-utils';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import {
    Card,
    Loader,
    Table
} from 'shared-ui';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import { ObservationTypesTableItem } from 'components/observationTypesTableItem';
import { useObservationTypes } from 'data/observationType';
import { useObservationSearch } from 'data/effects';
import { ObservationNavigation } from 'screens/routes';
import { FilterBar, HeaderAction, ObservationFilters } from './components';
import ObservationBreadcrumb from './ObservationBreadcrumb';

type ObservationTableItem = {
    id: number;
    label: string;
    types: RefLabel[];
};

const ObservationScreen: React.FC = () => {
    useBindYearNavigation(ObservationNavigation, []);
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState<ObservationFilters>({
        label: '',
        types: []
    });
    const {
        data: observations,
        isLoading: isObsLoading,
        isFetched,
        error
    } = useObservationSearch({
        label: filter.label,
        types: filter.types
    });
    const enableUseTypes = isFetched === true && error == null;
    const { data: observationTypes } = useObservationTypes({ enabled: enableUseTypes });

    const tableData = React.useMemo(() => {
        if (!isArray(observations)) return [];

        const data: ObservationTableItem[] = observations.map(obs => ({
            ...obs,
            types: observationTypes?.filter(type => obs.types.includes(type.id))
                .map(item => ({ id: item.id, label: item.label })) ?? []
        }));

        orderBy(data, 'id');

        return data;
    }, [observations, observationTypes]);

    const handleFilterChange = React.useCallback((filter: ObservationFilters) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('observation.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Observations
                    </Page.Title>
                    <Page.HeaderActions>
                        <ObservationBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName('withAction')}>
                            <Card.Title>
                                Liste des observations
                            </Card.Title>
                            <HeaderAction />
                        </Card.Header>
                        <Card.Body className="row">
                            {isObsLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar
                                        onChange={handleFilterChange}
                                        enabled={enableUseTypes}
                                    />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<ObservationTableItem>
                                        data={tableData}
                                    >
                                        <Table.Column accessor="label">
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column>
                                            <Table.Column.Header>Types de suivi</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: any) => (<ObservationTypesTableItem types={data.types} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(ObservationScreen, 'OBSERVATION');