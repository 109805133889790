import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import ObservationTypeDetailsScreen from './ObservationTypeDetailsScreen';

export const ObservationTypeDetailsNavigation = (observationTypeId: number): NavigationRoute =>
    new NavigationRoute(
        ObservationTypeDetailsScreen.key,
        [getYearRouteParam(), observationTypeId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: ObservationTypeDetailsScreen.key,
        path: '/<:observationTypeId>', // Should have matched the pattern <:observationTypeId([\\d]+)> if react-router V6 supported it
        component: ObservationTypeDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;