import React from 'react';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import { UserDetailsNavigation } from 'screens/routes';
import { PlusIcon } from '../../../components/Icons';
import CreateUserDialog from './createUserDialog';
import styles from '../user.module.scss';

const HeaderAction: React.FC = () => {
    const { push: navigate } = useNavigation();
    const [showCreateUser, setShowCreateUser] = React.useState(false);

    const handleAddClick = () => {
        setShowCreateUser(true);
    };

    const handleUserCreateCompletion = (userId: number) => {
        navigate(UserDetailsNavigation(userId));
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={handleAddClick}
            >
                Ajouter un utilisateur
            </Button>
            {showCreateUser && (
                <CreateUserDialog
                    onClose={() => setShowCreateUser(false)}
                    onCompletion={handleUserCreateCompletion}
                />
            )}
        </>
    );
};

export default HeaderAction;
