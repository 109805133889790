import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { isArray, orderBy } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import { ObservationTypeDetailsNavigation, ObservationTypeNavigation } from 'screens/routes';
import { useObservationTypeSearch } from 'data/effects';
import { FilterBar, HeaderAction, ObservationTypeFilters } from './components';
import ObservationTypeBreadcrumb from './ObservationTypeBreadcrumb';
import styles from './ObservationTypeScreen.module.scss';

const ObservationTypeScreen: React.FC = () => {
    useBindYearNavigation(ObservationTypeNavigation, []);
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const [filter, setFilter] = React.useState<ObservationTypeFilters>({ label: '' });
    const { data: observationTypes, isLoading } = useObservationTypeSearch({ label: filter.label });

    const tableData = React.useMemo(() => {
        if (!isArray(observationTypes)) return [];

        const data = [...observationTypes];

        orderBy(data, 'label');

        return data;
    }, [observationTypes]);

    const handleRowClick = React.useCallback((event: TableRowClickEvent<App.ObservationType>) => {
        navigate(ObservationTypeDetailsNavigation(event.data.id));
    }, [navigate]);

    const handleFilterChange = React.useCallback((filter: ObservationTypeFilters) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('observationType.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Types de suivi
                    </Page.Title>
                    <Page.HeaderActions>
                        <ObservationTypeBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName('withAction')}>
                            <Card.Title>
                                Liste des types de suivi
                            </Card.Title>
                            <HeaderAction />
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar onChange={handleFilterChange} />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<App.ObservationType>
                                        data={tableData}
                                        onRowClick={handleRowClick}
                                        className={styles['tableRow']}
                                    >
                                        <Table.Column accessor="label">
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(ObservationTypeScreen, 'OBSTYPE');