import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import CustomerDetailsScreen from './CustomerDetailsScreen';

const customerTabKey = `${CustomerDetailsScreen.key}_TAB`;
const roles = ['administrator', 'supervisor', 'writer'];

export const CustomerDetailsNavigation = (customerId: App.Customer['id']): NavigationRoute =>
    new NavigationRoute(
        CustomerDetailsScreen.key,
        [getYearRouteParam(), customerId]
    );

export const CustomerTabNavigation = (customerId: App.Customer['id'], tab: string): NavigationRoute =>
    new NavigationRoute(
        customerTabKey,
        [getYearRouteParam(), customerId, tab]
    );

const router = new Router();

router
    .add(new RouteData({
        key: CustomerDetailsScreen.key,
        path: '/<:customerId>', // Should have matched the pattern <:customerId(\\d+)> if react-router V6 supported it
        component: CustomerDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }))
    .add(new RouteData({
        key: customerTabKey,
        path: '/<:customerId>/<:tab>', // Should have matched the pattern <:customerId(\\d+)>/<:tab([a-zA-Z]+)> if react-router V6 supported it
        component: CustomerDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }));

export default router;