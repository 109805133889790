import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbItemHome from './BreadcrumbItemHome';
import BreadcrumbSeparator from './BreadcrumbSeparator';
import styles from './Breadcrumb.module.scss';

type Props = {
    className?: string;
    children?: ReactNode;
}

const Breadcrumb: React.FC<Props> = ({
    className,
    children
}: Props) => {
    const itemChildren = React.useMemo(() => {
        const result: React.ReactElement[] = [];

        React.Children.forEach(children, (child, index) => {
            if (!React.isValidElement(child)) return;
            if (index > 0) {
                result.push((<BreadcrumbSeparator key={`bc-separator-${index}`} />));
            }
            result.push(child);
        });

        return result;
    }, [children]);

    return (
        <ul className={concatClassName(styles['breadcrumb'], className)}>
            {itemChildren}
        </ul>
    );
};

Breadcrumb.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

Breadcrumb.defaultProps = {
    className: undefined,
    children: undefined
};

export default Object.assign(Breadcrumb, {
    Item: BreadcrumbItem,
    Home: BreadcrumbItemHome
});
