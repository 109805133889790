import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'shared-utils';
import config from 'config';
import Page from 'components/Page';
import { Card, Loader } from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import { useGetAllReport } from 'data/inspection';
import { ReportNavigation } from 'screens/routes';
import ListSection from 'components/ListSection';
import ReportBreadcrumb from './ReportBreadcrumb';
import { ReportList, FilterBar, ReportFilters } from './component';
import styles from './ReportScreen.module.scss';
import HeaderAction from './HeaderAction';

const ReportScreen: React.FC = () => {
    useBindYearNavigation(ReportNavigation, []);
    const { t } = useTranslation();

    const [filter, setFilter] = React.useState<ReportFilters>({});

    const { data: reports, isLoading, isFetched, error } = useGetAllReport(filter.customer);

    const handleFilterChange = React.useCallback((filter: ReportFilters) => {
        setFilter(filter);
    }, [setFilter]);

    const enableFilters = isFetched === true && error == null;
    const displayActions = !isLoading && reports && reports.length > 0;

    return (
        <>
            <Helmet>
                <title>{t('dashboard.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page className="page-full">
                <Page.Header>
                    <Page.Title>
                        Rapports
                    </Page.Title>
                    <Page.HeaderActions>
                        <ReportBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Liste des rapports envoyés
                            </Card.Title>
                            {displayActions && (<HeaderAction customerId={filter.customer} reports={reports} />)}
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar
                                        onChange={handleFilterChange}
                                        enabled={enableFilters}
                                    />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <ReportList reports={reports} />
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};
export default withRouter(ReportScreen, 'REPORT');
