import React from 'react';
import propTypes from 'prop-types';
import { isString } from '@dateam/ark';
import { Button, Form } from 'shared-ui';
import { reportObservationsPropTypes } from 'data/inspection';
import { EditIcon } from 'components/Icons';
import ReportTable from './ReportTable';
import OverWrittenTableModal from './OverWrittenTableModal';
import styles from '../ReportSection.module.scss';

type Props = {
    data: App.Report.ReportObservation;
    onChange?: (value: App.Report.ReportObservation) => void;
};

const ReportEntry: React.FC<Props> = ({
    data,
    onChange
}) => {
    const [overwrittingTableKey, setOverwrittingTableKey] = React.useState<string | null>(null);
    const showOverwrittenBtnRef = React.useRef<HTMLButtonElement>(null);

    const showPrevious = React.useMemo(() => {
        if (data.id === 2) return false; // N-Tester obs never display previous values.

        return data.records.plots.some(plot => plot.previous.length > 0);
    }, [data]);

    const handleChange = React.useCallback((key: keyof typeof data) => (value: any) => {
        onChange?.({ ...data, [key]: value });
    }, [onChange, data]);

    const handleOverwrittenChange = React.useCallback((value: App.Report.ReportObservation) => {
        onChange?.(value);
    }, [onChange]);

    const handleCloseModal = React.useCallback(() => {
        setOverwrittingTableKey(null);
    }, [setOverwrittingTableKey]);

    return (
        <>
            <div className={styles['observationLabel']}>{data.label}</div>
            <Form.Group controlId="report-summary-supervisor">
                <Form.Label>Message planificateur</Form.Label>
                <Form.Input
                    value={data.modelComment ?? undefined}
                    multiline
                    rows={5}
                    maxlength={2000}
                    onChange={handleChange('modelComment')}
                />
            </Form.Group>
            <Form.Group controlId="report-summary-writer">
                <Form.Label>Message rédacteur</Form.Label>
                <Form.Input
                    value={data.comment ?? undefined}
                    multiline
                    rows={9}
                    maxlength={2000}
                    onChange={handleChange('comment')}
                />
            </Form.Group>
            <div className={styles['tableLabel']}>
                <div className="formLabel">Relevés</div>
                {data.id === 2 && (
                    <Button
                        ref={showOverwrittenBtnRef}
                        onClick={() => setOverwrittingTableKey('values')}
                        startIcon={<EditIcon />}
                        size="xs"
                    />
                )}
            </div>
            <ReportTable
                // key={`${data.id}-values`}
                headers={data.records.headers}
                plots={data.records.plots}
                display="values"
            />
            <Form.Group controlId="report-includeValues" className={`${styles['checkbox']} form-inline`}>
                <Form.Checkbox checked={data.includeValues} onChange={handleChange('includeValues')} />
                <Form.Label>Inclure les données</Form.Label>
            </Form.Group>
            {showPrevious && (
                <>
                    <div className={styles['tableLabel']}>
                        <div className="formLabel">Relevés précédents</div>
                    </div>
                    <ReportTable
                        // key={`${data.id}-previous`}
                        headers={data.records.headers}
                        plots={data.records.plots}
                        display="previous"
                    />
                    <Form.Group controlId="report-includePrevious" className={`${styles['checkbox']} form-inline`}>
                        <Form.Checkbox checked={data.includePrevious} onChange={handleChange('includePrevious')} />
                        <Form.Label>Inclure les données précédentes</Form.Label>
                    </Form.Group>
                </>
            )}
            {isString(overwrittingTableKey) && (
                <OverWrittenTableModal
                    data={data}
                    display={overwrittingTableKey}
                    onClose={handleCloseModal}
                    onSave={handleOverwrittenChange}
                />
            )}
        </>
    );
};

ReportEntry.propTypes = {
    data: reportObservationsPropTypes.isRequired,
    onChange: propTypes.func
};

ReportEntry.defaultProps = {
    onChange: undefined
};

export default ReportEntry;
