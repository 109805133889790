import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Card
} from 'shared-ui';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import { EditIcon } from 'components/Icons';
import SelectedObservationTypes from './SelectedObservationTypes';
import TypeSelector from './TypeSelector';
import styles from '../PlotDetailsScreen.module.scss';

type Props = {
    plotId: App.PlotDetails['id'];
    types: App.PlotDetails['observationTypes'];
    displayActions?: boolean;
    className?: string;
};

const ObservationTypesPanel: React.FC<Props> = ({
    plotId,
    types,
    displayActions,
    className
}) => {
    const [showTypeSelector, setShowTypeSelector] = React.useState(false);

    return (
        <Card className={className}>
            <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                <Card.Title>
                    Types de suivi
                </Card.Title>
                {displayActions && (
                    <Button
                        color="primary"
                        startIcon={(<EditIcon />)}
                        onClick={() => setShowTypeSelector(true)}
                    >
                        Gérer les types de suivi
                    </Button>
                )}
            </Card.Header>
            <Card.Body className={styles['cardContent']}>
                <SelectedObservationTypes types={types} />
            </Card.Body>
            {showTypeSelector && (
                <TypeSelector
                    plotId={plotId}
                    data={types}
                    onClose={() => setShowTypeSelector(false)}
                />
            )}
        </Card>
    );
};

ObservationTypesPanel.propTypes = {
    plotId: propTypes.number.isRequired,
    types: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    className: propTypes.string
};

ObservationTypesPanel.defaultProps = {
    displayActions: false,
    className: undefined
};

export default ObservationTypesPanel;
