import React from 'react';
import LogoImg from '../../../public/logo.svg';
import styles from './LoadingScreen.module.scss';

const LoadingScreen: React.FC = () => {
    return (
        <div className="loading">
            <div className="loading-wrapper">
                <div className="loading-logo">
                    <LogoImg />
                </div>
                connexion de l'utilisateur ...
                <div className={styles['loading-overlay']}>
                    <div className={styles['loading-bar']}></div>
                    <div className={styles['loading-bar']}></div>
                    <div className={styles['loading-bar']}></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;