import React from 'react';
import propTypes from 'prop-types';
import { isFunction } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import ListItemCol from './ListItemCol';

type Props = {
    id: string;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
};

const ListItem: React.FC<Props> = ({
    id,
    className,
    children,
    onClick
}: Props) => {
    const handleOnClick = React.useCallback(() => {
        if (!isFunction(onClick)) return;
        onClick();
    }, [onClick]);

    return (
        <li
            key={id}
            id={id}
            className={concatClassName(className)}
            onClick={handleOnClick}
        >
            {children}
        </li>
    );
};

ListItem.propTypes = {
    id: propTypes.string.isRequired,
    className: propTypes.string,
    children: propTypes.node,
    onClick: propTypes.func
};

ListItem.defaultProps = {
    className: undefined,
    children: undefined,
    onClick: undefined
};

export default Object.assign(ListItem, { Col: ListItemCol });
