import React from 'react';
import { ConfirmDialog } from 'shared-ui';
import { observer as swObserver, SW_INIT, SW_UPDATE } from 'utils/serviceWorkerObserver';

const ServiceWorkerUpdateNotifier: React.FC = () => {
    const serviceWorkerRegistration = React.useRef<ServiceWorkerRegistration | null>(null);
    const [showNewVersion, setShowNewVersion] = React.useState(false);

    React.useEffect(() =>
        swObserver.subscribe((data: any) => {
            serviceWorkerRegistration.current = data.payload;

            if (data.type === SW_INIT) {
                window.setInterval(() => {
                    console.log('SW refresh');
                    if (serviceWorkerRegistration.current === null) return;
                    serviceWorkerRegistration.current.update();
                }, 1000 * 60 * 2);
            }
            if (data.type === SW_UPDATE) {
                if (serviceWorkerRegistration.current === null) return;
                const registrationWaiting = serviceWorkerRegistration.current.waiting;

                if (registrationWaiting) {
                    registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

                    registrationWaiting.addEventListener('statechange', (e: any) => {
                        if (e.target.state === 'activated') {
                            setShowNewVersion(true);
                        }
                    });
                }
            }
        }), []);

    const handleConfirmClose = React.useCallback((isOk: boolean) => {
        if (isOk) {
            window.location.reload();
        }

        setShowNewVersion(false);
    }, []);

    if (showNewVersion) {
        return (
            <ConfirmDialog
                title="Nouvelle version disponible"
                message="Une nouvelle version de l'application est disponible. Voulez-vous recharger la page ?"
                ok="Recharger la page"
                onClose={handleConfirmClose}
            />
        );
    }

    return null;
};

export default ServiceWorkerUpdateNotifier;
