import { Router, RouteData, NavigationRoute } from 'shared-utils';
import UserDetailsScreen from './UserDetailsScreen';

export const UserDetailsNavigation = (userId: number): NavigationRoute =>
    new NavigationRoute(
        UserDetailsScreen.key,
        [userId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: UserDetailsScreen.key,
        path: '/<:userId>', // Should have matched the pattern <:userId([\\d]+)> if react-router V6 supported it
        component: UserDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator']
        }
    }));

export default router;