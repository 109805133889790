import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isDefined, isString, isValidNumber } from '@dateam/ark';
import { useLocale } from 'i18n/i18nLoader';
import { useUsers } from 'data/user';
import { reportDetailsPropTypes } from 'data/inspection';
import List, { ApiRef } from 'components/List';
import styles from '../ReportSection.module.scss';

type Props = {
    data: App.Report.ReportDetails;
    selectedId: App.Report.ReportObservation['id'];
};

const ReportPreview: React.FC<Props> = ({
    data,
    selectedId
}) => {
    const listApiRef = React.useRef<ApiRef>();
    const ressource = useLocale('fr');
    const { data: users } = useUsers();

    const observator = users?.find(user => user.id === data.observatorUser);
    const observatorName = isDefined(observator) ? `${observator?.firstName} ${observator?.lastName}` : '';
    const writer = users?.find(user => user.id === data.writerUser);
    const writerName = isDefined(writer) ? `${writer?.firstName} ${writer?.lastName}` : '';

    const formatDate = React.useCallback((date: Date) => {
        if (ressource === null || ressource.locale.monthLabel.length - 1 < date.getMonth()) return '';
        const day = `0${date.getDate()}`.slice(-2);
        const month = ressource.locale.monthLabel[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }, [ressource]);

    React.useEffect(() => {
        if (!isValidNumber(selectedId)) return;

        if (selectedId === 0) listApiRef.current?.scrollTo('preview-summary', 'top');
        else if (selectedId === 9999) listApiRef.current?.scrollTo('preview-endsection', 'top');
        else listApiRef.current?.scrollTo(`preview-obs-${selectedId}`, 'top');
    }, [listApiRef, selectedId]);

    return (
        <div className={styles['previewContainer']}>
            <List
                className={styles['previewList']}
                apiRef={listApiRef}
                scrollable
            >
                <List.Item
                    id="preview-header"
                    className={styles['previewListItem']}
                >
                    <List.Item.Col>
                        <div className={styles['previewHeader']}>Entête</div>
                        <div className={styles['previewDate']}>Epernay, le {formatDate(new Date())}</div>
                        <div className={styles['previewCustomer']}>{data.customerHeader}</div>
                    </List.Item.Col>
                </List.Item>
                <List.Item
                    id="preview-summary"
                    className={concatClassName(
                        styles['previewListItem'],
                        selectedId === 0 ? styles['selected'] : undefined
                    )}
                >
                    <List.Item.Col>
                        <div className={styles['previewInspectionDate']}>
                            RAPPORT DE VISITE DU :  {formatDate(data.inspectionDate)}
                        </div>
                        <div className={styles['previewInspectionInfos']}>
                            <div>Circuit : {data.inspectionLabel}</div>
                            <div>Visite réalisée par : {observatorName}</div>
                            <div>Message réalisé par : {writerName}</div>
                        </div>
                        {isString(data.modelHeadline) && (
                            <div className={styles['previewListText']}>{data.modelHeadline}</div>
                        )}
                        {isString(data.headline) && (
                            <div className={`${styles['previewListText']} ${styles['previewListBold']}`}>{data.headline}</div>
                        )}
                    </List.Item.Col>
                </List.Item>
                {data.observations.map(obs => (
                    <List.Item
                        key={`preview-obs-${obs.id}`}
                        id={`preview-obs-${obs.id}`}
                        className={concatClassName(
                            styles['previewListItem'],
                            selectedId === obs.id ? styles['selected'] : undefined
                        )}
                    >
                        <List.Item.Col>
                            <div className={styles['previewListLabel']}>{obs.label}</div>
                            {isString(obs.modelComment) && (
                                <div className={styles['previewListText']}>{obs.modelComment}</div>
                            )}
                            {obs.includeValues === true && (
                                <div className={styles['previewListTable']}>Relevés</div>
                            )}
                            {obs.includePrevious === true && (<div className={styles['previewListTable']}>Relevés précédents</div>)}
                            {isString(obs.modelComment) && (
                                <div className={`${styles['previewListText']} ${styles['previewListBold']}`}>{obs.comment}</div>
                            )}
                        </List.Item.Col>
                    </List.Item>
                ))}
                {data.includeNextInspection === true && (
                    <List.Item
                        id="preview-endsection"
                        className={concatClassName(
                            styles['previewListItem'],
                            selectedId === 9999 ? styles['selected'] : undefined
                        )}
                    >
                        <List.Item.Col>
                            {isString(data.modelNextInspection) && (
                                <div className={styles['previewListText']}>{data.modelNextInspection}</div>
                            )}
                        </List.Item.Col>
                    </List.Item>
                )}
            </List>
        </div>
    );
};

ReportPreview.propTypes = {
    data: reportDetailsPropTypes.isRequired,
    selectedId: propTypes.number.isRequired
};

ReportPreview.defaultProps = {

};

export default ReportPreview;
