import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { matchPath } from 'react-router-dom';
import { isDefined, isValidDate, isValidNumber } from '@dateam/ark';
import { useNavigation, withRouter, useRouterRegistration } from 'shared-utils';
import {
    Loader,
    Tabs
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import { useUserState } from 'utils/userStore';
import Page from 'components/Page';
import { useInspection } from 'data/inspection';
import {
    InspectionNavigation,
    InspectionTabNavigation,
    InspectionEditNavigation,
    InspectionEntriesPlotNavigation,
    InspectionReportDetailsNavigation
} from 'screens/routes';
import {
    ConfigurationSection,
    EntrySection,
    ObservationSection,
    PlotSection,
    ReportSection
} from './components';
import { TAB_KEY as ENTRY_TAB_KEY } from './components/entries';
import InspectionDetailsBreadcrumb from './InspectionDetailsBreadcrumb';
import styles from './InspectionEditScreen.module.scss';

const InspectionEditScreen: React.FC = () => {
    const { t } = useTranslation();
    const [router] = useRouterRegistration();
    const {
        query: {
            inspectionId: inspectionIdParam,
            tab: tabParam,
            plotId: plotIdParam,
            reportId: reportIdParam
        },
        push: navigate,
        replace: replaceNavigation,
        location
    } = useNavigation();

    const inspectionId = React.useMemo(() => parseInt(inspectionIdParam, 10), [inspectionIdParam]);
    const currentTab = React.useMemo(() => {
        // If the current tab parameter is not set, check if the current location matches the entry route
        // The disappearance of pattern matching params in react-router v6 forces to have an extra route without a 'tabParam' key and instead an hardcoded 'entry' route
        if (tabParam == null && router != null) {
            const isEntryRoute = matchPath(location.pathname, InspectionEntriesPlotNavigation(inspectionId, parseInt(plotIdParam, 10)).toPath(router) ?? '');
            if (isEntryRoute) {
                return ENTRY_TAB_KEY;
            }

            const isReportDetailsRoute = matchPath(location.pathname, InspectionReportDetailsNavigation(inspectionId, parseInt(reportIdParam, 10)).toPath(router) ?? '');
            if (isReportDetailsRoute) {
                return 'report';
            }
        }

        return tabParam;
    }, [tabParam, location.pathname, inspectionId, plotIdParam, reportIdParam, router]);
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';
    const {
        data: inspection,
        isLoading: isLoadingInspection,
        isFetched: hasLoaded
    } = useInspection(inspectionId);
    useBindYearNavigation(InspectionEditNavigation, [inspectionId]);

    React.useEffect(() => {
        if (currentTab == null && isValidNumber(inspectionId)) {
            replaceNavigation(InspectionTabNavigation(inspectionId, 'config'));
        }
    }, [currentTab, inspectionId, replaceNavigation]);

    React.useEffect(() => {
        if (inspection == null && hasLoaded) {
            replaceNavigation(InspectionNavigation());
        }
    }, [inspection, hasLoaded, replaceNavigation]);

    const selectTab = React.useCallback((tab: string) => {
        navigate(InspectionTabNavigation(inspectionId, tab));
    }, [inspectionId, navigate]);

    if (!isDefined(inspection)) return null;
    
    return (
        <>
            {isLoadingInspection && (
                <Loader text="chargement" />
            )}
            <Helmet>
                <title>{t('inspection.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {inspection.label}
                    </Page.Title>
                    <Page.HeaderActions>
                        <InspectionDetailsBreadcrumb label={inspection.label} />
                        <Tabs
                            selected={currentTab}
                            onChange={selectTab}
                        >
                            <Tabs.Tab tabKey="config" title="Configuration" />
                            <Tabs.Tab tabKey="obs" title="Observations" />
                            <Tabs.Tab tabKey="plot" title="Parcelles" />
                            <Tabs.Tab tabKey="entry" title="Saisies" />
                            <Tabs.Tab tabKey="report" title="Rapports" disable={!isValidDate(inspection.validationDate)} />
                        </Tabs>
                    </Page.HeaderActions>
                </Page.Header>
                {currentTab === 'config' && (
                    <ConfigurationSection
                        key={`inspection-config-${inspection.id}`}
                        inspection={inspection}
                        displayActions={displayActions}
                    />
                )}
                {currentTab === 'obs' && (
                    <ObservationSection key={`inspection-obs-${inspection.id}`} inspection={inspection} />
                )}
                {currentTab === 'plot' && (
                    <PlotSection
                        key={`inspection-plot-${inspection.id}`}
                        inspection={inspection}
                        displayActions={displayActions}
                    />
                )}
                {currentTab === ENTRY_TAB_KEY && (
                    <EntrySection key={`inspection-${ENTRY_TAB_KEY}-${inspection.id}`} inspectionId={inspection.id} />
                )}
                {currentTab === 'report' && (
                    <ReportSection key={`inspection-report-${inspection.id}`} inspection={inspection} />
                )}
            </Page>
        </>
    );
};

export default withRouter(InspectionEditScreen, 'INSPECTION_EDIT');