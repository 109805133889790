import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { isArray, orderBy } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import Page from 'components/Page';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent
} from 'shared-ui';
import { useUserState } from 'utils/userStore';
import { TrackNavigation, TrackDetailsNavigation } from 'screens/routes';
import { useTrackSearch } from 'data/effects';
import { FilterBar, HeaderAction } from './components';
import TrackBreadcrumb from './TrackBreadcrumb';
import styles from './TrackScreen.module.scss';

const TrackScreen: React.FC = () => {
    useBindYearNavigation(TrackNavigation, []);
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState<string>('');
    const { push: navigate } = useNavigation();
    const { data: tracks, isLoading } = useTrackSearch(filter);
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';

    const tableData = React.useMemo(() => {
        if (!isArray(tracks)) return [];

        const data = [...tracks];

        orderBy(tracks, 'label');

        return data;
    }, [tracks]);

    const handleRowClick = React.useCallback((event: TableRowClickEvent<App.Track>) => {
        navigate(TrackDetailsNavigation(event.data.id));
    }, [navigate]);

    const handleFilterChange = React.useCallback((filter: string) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('track.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Circuits
                    </Page.Title>
                    <Page.HeaderActions>
                        <TrackBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                            <Card.Title>
                                Liste des circuits
                            </Card.Title>
                            {displayActions && (<HeaderAction />)}
                        </Card.Header>
                        <Card.Body className="row">
                            <div className={styles['tableSection']}>
                                <FilterBar onChange={handleFilterChange} />
                                {isLoading && (
                                    <Loader text="chargement" />
                                )}
                                <Table<App.Track>
                                    data={tableData}
                                    onRowClick={handleRowClick}
                                    className={styles['tableRow']}
                                >
                                    <Table.Column accessor="label">
                                        <Table.Column.Header>Nom</Table.Column.Header>
                                    </Table.Column>
                                    <Table.Column accessor="plotCount">
                                        <Table.Column.Header>Nombre de parcelles</Table.Column.Header>
                                    </Table.Column>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(TrackScreen, 'TRACK');