import React from 'react';
import propTypes from 'prop-types';
import { isFunction, isValidNumber } from '@dateam/ark';
import { Button, Form } from 'shared-ui';
import { CrossIcon } from 'components/Icons';
import { Plot, plotPropTypes } from './types';
import {
    PlotInfos,
    CustomerInfos,
    PlotExtraInfos
} from './components';
import styles from './PlotList.module.scss';

type Props = {
    data: Plot;
    onRemovePlot?: (plot: any) => void;
    onPositionChange?: (plot: any, position: number) => void;
};

const PlotListItem = ({
    data,
    onRemovePlot,
    onPositionChange
}: Props): React.ReactElement<any, any> | null => {
    const internalData = React.useRef<typeof data>(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    const removePlot = React.useCallback(() => {
        if (isFunction(onRemovePlot)) onRemovePlot(internalData.current);
    }, [internalData, onRemovePlot]);

    const changePosition = React.useCallback((position: number | undefined): void => {
        if (isValidNumber(position)) {
            onPositionChange?.(internalData.current, position);
        }
    }, [internalData, onPositionChange]);

    return (
        <li key={data.id} className={styles['item']}>
            <div className={styles['position']}>
                <Form.Number
                    value={data.position}
                    onChange={changePosition}
                />
            </div>
            <div className={`${styles['plot']} row`}>
                <PlotInfos data={data} className={`${styles['infosCol']} col col-4`}></PlotInfos>
                <CustomerInfos data={data} className={`${styles['customerCol']} col col-3`}></CustomerInfos>
                <PlotExtraInfos data={data} className={`${styles['detailsCol']} col col-5`}></PlotExtraInfos>
            </div>
            <Button onClick={removePlot} className={styles['delete']} endIcon={<CrossIcon />}>
            </Button>
        </li>
    );
};

PlotListItem.propTypes = {
    data: plotPropTypes.isRequired,
    onRemovePlot: propTypes.func,
    onPositionChange: propTypes.func
};

PlotListItem.defaultProps = {
    onRemovePlot: undefined,
    onPositionChange: undefined
};

export default PlotListItem;
