import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isArray, isDefined, pureObjectAssign, removeFromCollection } from '@dateam/ark';
import { useDebounce } from '@dateam/ark-react';
import { Card, Table, TableHeaderSelectionChangeEvent, TableSelectionChangeEvent, TableSelectionItem } from 'shared-ui';
import config from 'config';
import Page from 'components/Page';
import { SelectedObservations } from 'components/SelectedObservations';
import { useObservations } from 'data/observation';
import { useUpdateInspectionObs } from 'data/inspection';
import styles from '../../InspectionEditScreen.module.scss';

type ObservationTableItem = TableSelectionItem<App.Observation>;

type Props = {
    inspection?: Pick<App.InspectionDetails, 'id' | 'observations'>;
};

const ObservationSection: React.FC<Props> = ({ inspection }: Props) => {
    const { t } = useTranslation();
    const { data: observationData } = useObservations();
    const { mutateAsync: updateObs } = useUpdateInspectionObs();

    const [selectedObservations, setSelectedObservations] = React.useState<App.CampaignDetails['observations']>([]);

    React.useEffect(() => {
        if (isDefined(inspection)) {
            const { observations } = inspection;

            setSelectedObservations(observations.map(obs => obs.id));
        }
        else {
            setSelectedObservations([]);
        }
    }, [inspection, setSelectedObservations]);

    const observationDataTable = React.useMemo<ObservationTableItem[]>(() => {
        if (!isArray(observationData)) return [];

        return observationData.map(obs => pureObjectAssign(obs, {
            selected: selectedObservations.includes(obs.id)
        }));
    }, [observationData, selectedObservations]);

    const saveChanges = useDebounce((...args: Parameters<typeof updateObs>): ReturnType<typeof updateObs> => {
        return updateObs(...args);
    }, 300);

    const selectObservation = React.useCallback((event: TableSelectionChangeEvent<ObservationTableItem>) => {
        if (!isDefined(inspection)) throw new Error('La visite n\'a pas été trouvée.');

        const { id: obsId } = event.data;

        setSelectedObservations(currentSelection => {
            const newSelection = [...currentSelection];

            if (event.selected === true) newSelection.push(obsId);
            else removeFromCollection(newSelection, item => item === obsId);

            const { id } = inspection;

            saveChanges({
                id,
                observations: newSelection
            });
            return newSelection;
        });
    }, [inspection, setSelectedObservations, saveChanges]);

    const removeObservation = React.useCallback((obsId: number) => {
        if (!isDefined(inspection)) throw new Error('La visite n\'a pas été trouvée.');

        setSelectedObservations(currentSelection => {
            const newSelection = [...currentSelection];

            removeFromCollection(newSelection, item => item === obsId);

            const { id } = inspection;

            saveChanges({
                id,
                observations: newSelection
            });
            return newSelection;
        });
    }, [inspection, setSelectedObservations, saveChanges]);

    const handleHeaderSelectionChange =
        React.useCallback((event: TableHeaderSelectionChangeEvent<ObservationTableItem>) => {
            if (!isDefined(inspection)) throw new Error('La visite n\'a pas été trouvée.');

            const newSelection = event.selected === true ? event.items.map(item => item.data.id) : [];

            setSelectedObservations(() => {
                const { id } = inspection;

                saveChanges({
                    id,
                    observations: newSelection
                });
                return newSelection;
            });
        }, [inspection, setSelectedObservations, saveChanges]);

    return (
        <>
            <Helmet>
                <title>{t('inspection.details.obs.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page.Content className="row">
                <Card className="col col-8">
                    <Card.Header>
                        <Card.Title>
                            Sélection des observations
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table<ObservationTableItem>
                            data={observationDataTable}
                            selectedField="selected"
                            onSelectionChange={selectObservation}
                            onHeaderSelectionChange={handleHeaderSelectionChange}
                        >
                            <Table.Column accessor="label">
                                <Table.Column.Header>Nom</Table.Column.Header>
                            </Table.Column>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className={`${styles['selectedObservationPanel']} col col-4`}>
                    <Card.Header>
                        <Card.Title>
                            Observations selectionnées
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className={styles['cardContent']}>
                        <SelectedObservations
                            observations={selectedObservations}
                            onRemoveObservation={removeObservation}
                        />
                    </Card.Body>
                </Card>
            </Page.Content>
        </>
    );
};

ObservationSection.propTypes = {
    inspection: propTypes.shape({
        id: propTypes.number.isRequired,
        observations: propTypes.arrayOf(propTypes.shape({
            id: propTypes.number.isRequired,
            label: propTypes.string.isRequired,
            campaignScope: propTypes.bool.isRequired
        }).isRequired).isRequired
    })
};

ObservationSection.defaultProps = {
    inspection: undefined
};

export default ObservationSection;
