import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as Ark from '@dateam/ark';
import { withRouter } from 'shared-utils';
import config from 'config';
import {
    Button,
    Card,
    Form
} from 'shared-ui';
import Page from 'components/Page';
import { ExportIcon, RotateLeftIcon } from 'components/Icons';
import { useCampaigns } from 'data/campaign';
import { useTracks } from 'data/track';
import { useObservations } from 'data/observation';
import { useCustomers } from 'data/customer';
import { useVarietals } from 'data/varietal';
import { useAreas } from 'data/area';
import { useBioTypes } from 'data/bio';
import { useExport } from 'data/export';
import ExportBreadcrumb from './ExportBreadcrumb';
import styles from './ExportScreen.module.scss';

const ExportScreen: React.FC = () => {
    const { t } = useTranslation();
    const { mutateAsync: exportFile, isLoading: isExporting } = useExport();
    const { data: campaigns } = useCampaigns();
    const { data: tracks } = useTracks();
    const { data: observations } = useObservations();
    const { data: customers } = useCustomers();
    const { data: varietals } = useVarietals();
    const { data: areas } = useAreas();
    const { data: bio } = useBioTypes();
    const [filter, setFilter] = React.useState<App.GlobalExportFilters>({
        startDate: null,
        endDate: null,
        campaigns: [],
        tracks: [],
        observations: [],
        customers: [],
        varietals: [],
        areas: [],
        bio: [],
        confusion: null
    });

    const exportClick = React.useCallback(async () => {
        const file = await exportFile(filter);
        if (file == null) return;

        const blobUrl = URL.createObjectURL(file.blob);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = file.filename ?? 'export.xlsx';

        document.body.appendChild(link);

        link.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        }));

        document.body.removeChild(link);
    }, [exportFile, filter]);

    const handleStartDateChange = React.useCallback((value: Date | undefined) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            startDate: Ark.isValidDate(value) ? value : null
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleEndDateChange = React.useCallback((value: Date | undefined) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            endDate: Ark.isValidDate(value) ? value : null
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleCampaignChange = React.useCallback((value: number | null, event: any) => {
        event.preventDefault();
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            campaigns: value != null ? [value] : []
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleTrackChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            tracks: selectedValues
        };

        setFilter(newFilter);
    }, [filter]);

    const handleObservationChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            observations: selectedValues
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleCustomerGetOptions = React.useCallback(async (value: string) => {
        if (!Ark.isArray(customers)) return [];
        if (value.trim().length === 0) return customers.map(customer => customer.fullName);

        return customers.filter(item => item.fullName.toLowerCase().indexOf(value.toLowerCase()) >= 0)
            .map(customer => customer.fullName);
    }, [customers]);

    const handleCustomerChange = React.useCallback((newValue: string) => {
        const value = [];
        const matchCustomer = customers?.find(customer => customer.fullName === newValue);
        if (Ark.isDefined(matchCustomer)) value.push(matchCustomer.id);
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            customers: value
        };

        setFilter(newFilter);
    }, [filter, setFilter, customers]);

    const handleVarietalChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            varietals: selectedValues
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleBioChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            bio: selectedValues
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleAreaChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            areas: selectedValues
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const handleConfusionChange = React.useCallback((value: boolean) => {
        const newFilter: App.GlobalExportFilters = {
            ...filter,
            confusion: value
        };

        setFilter(newFilter);
    }, [filter, setFilter]);

    const resetFilters = React.useCallback(() => {
        setFilter({
            startDate: null,
            endDate: null,
            campaigns: [],
            tracks: [],
            observations: [],
            customers: [],
            varietals: [],
            areas: [],
            bio: [],
            confusion: null
        });
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('export.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Exports
                    </Page.Title>
                    <Page.HeaderActions>
                        <ExportBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Liste des relevés d'observation
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className={styles['filterRow']}>
                                <Form.Group controlId="export-startDate" className="col col-2">
                                    <Form.Label>Date de début</Form.Label>
                                    <Form.Date
                                        value={filter.startDate ?? undefined}
                                        onChange={handleStartDateChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="export-endDate" className="col col-2">
                                    <Form.Label>Date de fin</Form.Label>
                                    <Form.Date
                                        value={filter.endDate ?? undefined}
                                        onChange={handleEndDateChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="filter-campaign" className="col col-8">
                                    <Form.Label>Tournée</Form.Label>
                                    <Form.Select
                                        id="filter-campaign-select"
                                        onChange={handleCampaignChange}
                                        selected={filter.campaigns[0]}
                                    >
                                        <Form.Select.Option value={null} selectedText="Toutes">Toutes</Form.Select.Option>
                                        {campaigns?.map(campaign => (
                                            <Form.Select.Option
                                                key={`campaign-${campaign.id}`}
                                                value={campaign.id}
                                                selectedText={campaign.label}
                                            >
                                                {campaign.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className={styles['filterRow']}>
                                <Form.Group controlId="filter-track" className="col col-5">
                                    <Form.Label>Circuit(s)</Form.Label>
                                    <Form.Multiselect
                                        id="filter-track-select"
                                        onChange={handleTrackChange}
                                        selected={filter.tracks}
                                    >
                                        {tracks?.map(track => (
                                            <Form.Select.Option
                                                key={`track-${track.id}`}
                                                value={track.id}
                                                selectedText={track.label}
                                            >
                                                {track.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Multiselect>
                                </Form.Group>
                                <Form.Group controlId="filter-observation" className="col col-7">
                                    <Form.Label>Observation(s)</Form.Label>
                                    <Form.Multiselect
                                        id="filter-observation-select"
                                        onChange={handleObservationChange}
                                        selected={filter.observations}
                                    >
                                        {observations?.map(observation => (
                                            <Form.Select.Option
                                                key={`observation-${observation.id}`}
                                                value={observation.id}
                                                selectedText={observation.label}
                                            >
                                                {observation.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Multiselect>
                                </Form.Group>
                            </div>
                            <div className={styles['filterRow']}>
                                <Form.Group controlId="filter-customer" className="col col-5">
                                    <Form.Label>Vigneron</Form.Label>
                                    <Form.Autocomplete<string>
                                        value={customers?.find(customer =>
                                            customer.id === filter.customers[0])?.fullName}
                                        onGetOptions={handleCustomerGetOptions}
                                        onChange={handleCustomerChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="filter-varietal" className="col col-7">
                                    <Form.Label>Cépage(s)</Form.Label>
                                    <Form.Multiselect
                                        id="filter-varietal-select"
                                        onChange={handleVarietalChange}
                                        selected={filter.varietals}
                                    >
                                        {varietals?.map(varietal => (
                                            <Form.Select.Option
                                                key={`varietal-${varietal.id}`}
                                                value={varietal.id}
                                                selectedText={varietal.label}
                                            >
                                                {varietal.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Multiselect>
                                </Form.Group>
                            </div>
                            <div className={styles['filterRow']}>
                                <Form.Group controlId="filter-bio" className="col col-5">
                                    <Form.Label>Type de protection</Form.Label>
                                    <Form.Multiselect
                                        id="filter-bio-select"
                                        onChange={handleBioChange}
                                        selected={filter.bio}
                                    >
                                        {bio?.map(bioItem => (
                                            <Form.Select.Option
                                                key={`bio-${bioItem.id}`}
                                                value={bioItem.id}
                                                selectedText={bioItem.label}
                                            >
                                                {bioItem.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Multiselect>
                                </Form.Group>
                                <Form.Group controlId="filter-area" className="col col-7">
                                    <Form.Label>Secteur(s) géographique</Form.Label>
                                    <Form.Multiselect
                                        id="filter-area-select"
                                        onChange={handleAreaChange}
                                        selected={filter.areas}
                                    >
                                        {areas?.map(area => (
                                            <Form.Select.Option
                                                key={`area-${area.id}`}
                                                value={area.id}
                                                selectedText={area.label}
                                            >
                                                {area.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Multiselect>
                                </Form.Group>
                            </div>
                            <div className={styles['filterRow']}>
                                <Form.Group controlId="filter-confusion" className="col col-5">
                                    <Form.Label>Confusion</Form.Label>
                                    <Form.Select
                                        selected={filter.confusion}
                                        onChange={handleConfusionChange}
                                    >
                                        <Form.Select.Option value={null} />
                                        <Form.Select.Option value={true}>Oui</Form.Select.Option>
                                        <Form.Select.Option value={false}>Non</Form.Select.Option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Card.Body>
                        <Card.Footer className="row">
                            <Card.Actions>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    startIcon={(<RotateLeftIcon />)}
                                    onClick={resetFilters}
                                >
                                    Réinitialiser
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={(<ExportIcon />)}
                                    onClick={exportClick}
                                    pending={isExporting}
                                >
                                    Exporter
                                </Button>
                            </Card.Actions>
                        </Card.Footer>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(ExportScreen, 'EXPORT');