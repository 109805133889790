import React from 'react';
import { concatClassName } from '@dateam/ark-react';
import { dateToFormat, isValidDate } from '@dateam/ark';
import { useNavigation } from 'shared-utils';
import { Button, ConfirmDialog } from 'shared-ui';
import { getYearState } from 'utils/yearStore';
import { inspectionRequests } from 'data/requests';
import { useSendInspectionReport } from 'data/inspection';
import { InspectionEditNavigation } from 'screens/inspectionEdit';
import {
    DownloadIcon,
    MailIcon,
    PinIcon
} from 'components/Icons';
import styles from '../ReportScreen.module.scss';

type Props = {
    data: App.Report.Report;
};

const ColumnActions: React.FC<Props> = ({ data }: Props) => {
    const { push: navigate } = useNavigation();
    const { mutateAsync: sendReport, isLoading: isSending } = useSendInspectionReport();
    const [sendState, setSendState] = React.useState<boolean>(false);

    const handleShowInspectionClick = () => {
        navigate(InspectionEditNavigation(data.inspectionId));
    };

    const downloadUrl = React.useMemo(() => {
        const year = getYearState();

        return inspectionRequests.getReportDownloadUrl(year, data);
    }, [data]);

    const handleRequestSendReport = React.useCallback(async () => {
        setSendState(true);
    }, []);

    const handleSendConfirm = React.useCallback(async (isOk: boolean) => {
        setSendState(false);
        if (isOk !== true) return;

        await sendReport({
            inspectionId: data.inspectionId,
            reportId: data.id
        });
    }, [data, sendReport]);

    const isUpdated = isValidDate(data.updatedOn);

    const reportSendDate = data.sendDate ? dateToFormat(data.sendDate) : '';

    return (
        <div className={concatClassName('row', styles['actions'])}>
            <Button
                startIcon={<PinIcon />}
                color="primary"
                size="xs"
                onClick={handleShowInspectionClick}
            >
                Voir la visite
            </Button>
            <Button
                color="primary"
                size="xs"
                type="link"
                startIcon={(<DownloadIcon />)}
                link={downloadUrl}
                target="_blank"
                rel="noreferrer"
                disabled={!isUpdated}
            >
                Télécharger
            </Button>
            <Button
                startIcon={<MailIcon />}
                color="primary"
                size="xs"
                pending={isSending}
                disabled={!isValidDate(data.updatedOn)}
                onClick={handleRequestSendReport}
            >
                Envoyer
            </Button>

            {sendState === true && (
                <ConfirmDialog
                    title="Envoyer le rapport"
                    message={`Le rapport a déjà été envoyé au vigneron le ${reportSendDate}`}
                    ok="Envoyer le rapport"
                    onClose={handleSendConfirm}
                />
            )}
        </div>
    );
};

export default ColumnActions;
