import React from 'react';
import propTypes from 'prop-types';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useCreateBioType } from 'data/bio';
import styles from '../BioTypeScreen.module.scss';

type Props = {
    className?: string;
    onClose?: () => void;
    onCompletion?: (bioTYpeId: number) => void;
};

const CreateBioTypeModal: React.FC<Props> = ({
    className,
    onClose,
    onCompletion
}: Props) => {
    const { mutateAsync: createType, isLoading: isCreating } = useCreateBioType();
    const [labelState, setLabelState] = React.useState<string>('');

    const formIsValid = React.useMemo(() => labelState.trim().length > 0, [labelState]);

    const handleLabelChange = React.useCallback((value: string) => {
        setLabelState(value);
    }, []);

    const handleCloseClick = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleCreateClick = React.useCallback(async () => {
        if (!formIsValid) return;

        const newTypeId = await createType({ label: labelState });

        onCompletion?.(newTypeId);
        onClose?.();
    }, [labelState, createType, formIsValid, onClose, onCompletion]);

    return (
        <Modal
            className={`${styles['modal']} ${className} modal-flex`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Créer un type de protection
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group controlId="bioType-label" className="col">
                        <Form.Label>Nom du type de protection</Form.Label>
                        <Form.Input
                            value={labelState}
                            maxlength={50}
                            autocomplete="off"
                            readOnly={isCreating}
                            onChange={handleLabelChange}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isCreating}
                    onClick={handleCloseClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer le type de protection
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreateBioTypeModal.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

CreateBioTypeModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default CreateBioTypeModal;
