import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const SaveIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
        <polyline points="17 21 17 13 7 13 7 21"></polyline>
        <polyline points="7 3 7 8 15 8"></polyline>
    </svg>
);

SaveIcon.propTypes = {
    className: propTypes.string
};

SaveIcon.defaultProps = {
    className: undefined
};

export default SaveIcon;
