import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isString, isValidDate, isValidNumber } from '@dateam/ark';
import { propTypeNullable } from '@dateam/ark-react';
import { useNavigation, useRouterRegistration } from 'shared-utils';
import { isRefLabelNumber, refLabelNumberPropTypes } from 'utils/propTypes';
import { InspectionEditNavigation } from 'screens/routes';
import styles from '../CampaignDetailsScreen.module.scss';

type Props = {
    value: App.Inspection;
};

const InspectionItem: React.FC<Props> = ({ value }: Props) => {
    const [router] = useRouterRegistration();
    const { push: navigate } = useNavigation();

    const onClick = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;
        if (!isDefined(value)) return;

        event.preventDefault();
        navigate(InspectionEditNavigation(value.id));
    }, [value, navigate]);

    const path = React.useMemo(() => {
        if (!isDefined(router)) return '';
        if (!isDefined(value)) return router.getOtherwisePath();

        return InspectionEditNavigation(value.id).toPath(router);
    }, [value, router]);

    const date = React.useMemo(() => {
        const { startDate } = value;
        if (!isDefined(startDate)) return null;

        let day = startDate.getDate().toString();
        let month = (startDate.getMonth() + 1).toString();

        if (day.length < 2) day = `0${day}`;
        if (month.length < 2) month = `0${month}`;

        return `${day}/${month}`;
    }, [value]);

    return (
        <div>
            <a href={path} onClick={onClick} className={styles['inspection-container']}>
                <div className={styles['inspection']}>
                    <div className={styles['inspection-label']}>{value.label}</div>
                    <div className={styles['inspection-plot-number']}>{value.plotCount} parcelles</div>
                </div>
                {isString(date) ? (
                    <div className={styles['inspection-infos']}>
                        <div className={styles['inspection-date']}>{date}</div>
                        <div className={styles['inspection-state']}>
                            {isValidDate(value.validationDate) ? 'Validée' : 'Planifiée'}
                        </div>
                    </div>
                ) :
                    <div className={styles['inspection-infos']}>-</div>}
                <div className={styles['inspection-assignee']}>
                    {isDefined(value.assigneeUser) ? value.assigneeUser.label : '-'}
                </div>
            </a>
        </div>
    );
};

InspectionItem.propTypes = {
    value: propTypes.shape({
        id: propTypes.number.isRequired,
        campaign: refLabelNumberPropTypes.isRequired,
        label: propTypes.string.isRequired,
        startDate: propTypeNullable(isValidDate),
        overlapDays: propTypeNullable(isValidNumber),
        assigneeUser: propTypeNullable(isRefLabelNumber),
        writerUser: propTypeNullable(isRefLabelNumber),
        syncDate: propTypeNullable(isValidDate),
        firstSyncDate: propTypeNullable(isValidDate),
        observationCount: propTypes.number.isRequired,
        plotCount: propTypes.number.isRequired,
        validatedPlotCount: propTypes.number.isRequired,
        validationDate: propTypeNullable(isValidDate)
    }).isRequired
};

export default InspectionItem;
