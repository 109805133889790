import { isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Mange-Bourgeons
 ** - count: Comptage // /100 ceps
 **     0 <= Value <= 100
 ** - species: Espèce
 **     Liste: ['Noctuelles', 'Boarmies', 'Noctuelles et boarmies']
 */

type CurrentObservation = App.Observation19;
type CurrentApiObservation = App.Api.Observation19;

export const SPECIES_VALUES = [
    'Noctuelles',
    'Boarmies',
    'Noctuelles et boarmies'
];

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count, species } = observation;

    if (ignored === true) return validation;

    if (count != null) {
        if (!isValidNumber(count) || count < 0 || count > 100) {
            validation.count = {
                field: 'count',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (species != null) {
        if (!isString(species)) {
            validation.species = {
                field: 'species',
                type: 'error',
                code: 'format'
            };
        }
        else if (SPECIES_VALUES.indexOf(species) < 0) {
            validation.species = {
                field: 'species',
                type: 'error',
                code: 'unknown'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        count,
        species
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.count = parseObservationField(count);
    parsedObs.species = parseObservationField(species);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        count,
        species
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.count = count !== undefined ? new RecordValue(count) : null;
    mappedObs.species = species !== undefined ? new RecordValue(species) : null;

    return mappedObs;
};