import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { reportObservationsPropTypes } from 'data/inspection';
import styles from '../ReportSection.module.scss';

type Props = {
    data: App.Report.ReportObservation[];
    selected?: number | null;
    includeNextInspection?: boolean;
    className?: string;
    onSelectPlot?: (id: number) => void;
};

const ObservationList: React.FC<Props> = ({
    data,
    selected,
    includeNextInspection,
    className,
    onSelectPlot
}: Props) => {
    const selectPlot = React.useCallback((id: number) => {
        onSelectPlot?.(id);
    }, [onSelectPlot]);

    return (
        <ul className={concatClassName(styles['sectionList'], className)}>
            <li
                key={0}
                className={concatClassName(
                    styles['sectionListItem'],
                    selected === 0 ? styles['sectionListItem-selected'] : null
                )}
                onClick={() => selectPlot(0)}
            >
                Récapitulatif
            </li>
            {data.map(obs => (
                <li
                    key={obs.id}
                    className={concatClassName(
                        styles['sectionListItem'],
                        selected === obs.id ? styles['sectionListItem-selected'] : null
                    )}
                    onClick={() => selectPlot(obs.id)}
                >
                    {obs?.label}
                </li>
            ))}
            {includeNextInspection === true && (
                <li
                    key={9999}
                    className={concatClassName(
                        styles['sectionListItem'],
                        selected === 9999 ? styles['sectionListItem-selected'] : null
                    )}
                    onClick={() => selectPlot(9999)}
                >
                    Fin de rapport
                </li>
            )}
        </ul>
    );
};

ObservationList.propTypes = {
    data: propTypes.arrayOf(reportObservationsPropTypes.isRequired).isRequired,
    selected: propTypes.number,
    includeNextInspection: propTypes.bool,
    className: propTypes.string,
    onSelectPlot: propTypes.func
};

ObservationList.defaultProps = {
    selected: undefined,
    includeNextInspection: false,
    className: undefined,
    onSelectPlot: undefined
};

export default ObservationList;
