import React from 'react';
import propTypes from 'prop-types';
import { useDebounce } from '@dateam/ark-react';
import * as Ark from '@dateam/ark';
import { Form } from 'shared-ui';
import { useCampaigns } from 'data/campaign';
import { useObservators } from 'data/user';

export type InspectionFilter = {
    label: string | undefined;
    campaignIds: number[];
    status: string | undefined;
    observator: number | undefined;
};

type Props = {
    enabled?: boolean;
    onChange?: (filter: InspectionFilter) => void;
}

const FilterBar: React.FC<Props> = ({
    onChange,
    enabled
}) => {
    const { data: campaignsData } = useCampaigns({ enabled });
    const { data: observatorsData } = useObservators({ enabled });

    const inspectionStatus = [
        { key: 'notplanned', label: 'Non planifié' },
        { key: 'planned', label: 'Planifié' },
        { key: 'validated', label: 'Validé' }
    ];

    const notifyChange = React.useRef(onChange ?? Ark.noop);
    const saveChanges = useDebounce((filter: InspectionFilter) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (Ark.isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<InspectionFilter>({
        label: undefined,
        campaignIds: [],
        status: undefined,
        observator: undefined
    });

    const campaigns = React.useMemo(() => {
        if (!Ark.isArray(campaignsData)) return [];

        const data = [...campaignsData];

        Ark.orderBy(data, 'id');

        return data;
    }, [campaignsData]);

    const handleLabelChange = React.useCallback((newValue: string) => {
        setFilter(previousState => {
            const newFilter: InspectionFilter = {
                ...previousState,
                label: newValue
            };

            saveChanges(newFilter);
            return newFilter;
        });
    }, [saveChanges]);

    const handleCampaignChange = React.useCallback((selectedValues: number[]) => {
        setFilter(previousState => {
            const newFilter: InspectionFilter = {
                ...previousState,
                campaignIds: selectedValues
            };

            saveChanges(newFilter);
            return newFilter;
        });
    }, [saveChanges]);

    const handleStatusChange = React.useCallback((value: string) => {
        setFilter(previousState => {
            const newFilter: InspectionFilter = {
                ...previousState,
                status: value
            };

            saveChanges(newFilter);
            return newFilter;
        });
    }, [saveChanges]);

    const handleObservatorChange = React.useCallback((value: number) => {
        setFilter(previousState => {
            const newFilter: InspectionFilter = {
                ...previousState,
                observator: value
            };

            saveChanges(newFilter);
            return newFilter;
        });
    }, [saveChanges]);

    return (
        <div className="row">
            <Form.Group controlId="filter-label" className="col col-3">
                <Form.Label>Nom</Form.Label>
                <Form.Input
                    value={filter.label}
                    autocomplete="off"
                    onChange={handleLabelChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-campaign" className="col col-3">
                <Form.Label>Tournées</Form.Label>
                <Form.Multiselect id="filter-campaign-select" onChange={handleCampaignChange}>
                    {campaigns?.map((campaign, index) => (
                        <Form.Select.Option
                            key={index}
                            value={campaign.id}
                            selectedText={campaign.label}
                        >
                            {campaign.label}
                        </Form.Select.Option>
                    ))}
                </Form.Multiselect>
            </Form.Group>
            <Form.Group controlId="filter-status" className="col col-3">
                <Form.Label>État</Form.Label>
                <Form.Select id="filter-status-select" onChange={handleStatusChange}>
                    <Form.Select.Option value={undefined} selectedText="Tous">Tous</Form.Select.Option>
                    {inspectionStatus?.map((status, index) => (
                        <Form.Select.Option
                            key={index}
                            value={status.key}
                            selectedText={status.label}
                        >
                            {status.label}
                        </Form.Select.Option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="filter-observator" className="col col-3">
                <Form.Label>Observateurs</Form.Label>
                <Form.Select id="filter-observator-select" onChange={handleObservatorChange}>
                    <Form.Select.Option value={undefined} selectedText="Tous">Tous</Form.Select.Option>
                    {observatorsData?.map((observator, index) => (
                        <Form.Select.Option
                            key={index}
                            value={observator.id}
                            selectedText={`${observator.firstName} ${observator.lastName}`}
                        >
                            {`${observator.firstName} ${observator.lastName}`}
                        </Form.Select.Option>
                    ))}
                </Form.Select>
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    enabled: propTypes.bool,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    enabled: true,
    onChange: undefined
};

export default FilterBar;
