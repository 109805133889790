import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const get = async (): Promise<App.Varietal[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/varietal', config.apiUrl);

        response = await httpService.get<App.Varietal[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling varietal API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send varietal request (get)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('VARIETAL_GET_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('VARIETAL_GET_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'VARIETAL_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('VARIETAL_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getTypeDetails = async (id: App.VarietalTypeDetails['id']): Promise<App.VarietalTypeDetails> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/varietal/{1}', config.apiUrl, id);
        console.log(requestUrl);
        response = await httpService.get<App.Api.VarietalTypeDetails>(requestUrl, {});
    }

    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling varietal type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send varietal request (getTypeDetails)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('VARIETAL_GET_TYPE_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('VARIETAL_GET_TYPE_DETAILS_FORBIDDEN');
    }

    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('VARIETAL_GET_TYPE_DETAILS_NOT_FOUND');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'VARIETAL_GET_TYPE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isDefined(response.body) && Ark.isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('VARIETAL_GET_TYPE_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return mapVarietalTypeDetails(response.body);
};

export const createVarietalType = async (request: App.Api.CreateVarietalTypeRequest): Promise<number> => {
    if (!Ark.isDefined(request)) throw new Error('request parameter is not valid');

    const { label, code } = request;
    if (!Ark.isString(code) || code.trim().length === 0) throw new Ark.ServiceError('VARIETAL_TYPE_CREATE_INVALID_REQUEST', 'request code is not valid');
    if (!Ark.isString(label) || label.trim().length === 0) throw new Ark.ServiceError('VARIETAL_TYPE_CREATE_INVALID_REQUEST', 'request label is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/varietal', config.apiUrl);
        const requestData = { label, code };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling create varietal type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${createVarietalType.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('VARIETAL_TYPE_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('VARIETAL_TYPE_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('VARIETAL_TYPE_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'VARIETAL_TYPE_CREATE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid varietal type id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('VARIETAL_TYPE_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const update = async (
    id: App.VarietalTypeDetails['id'],
    request: App.Api.UpdateVarietalTypeRequest
): Promise<void> => {
    Ark.assertIsValidNumber(id, 'id parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/varietal/{1}', config.apiUrl, id);
        const { label, code } = request;
        const requestData = { label, code };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling varietal type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send varietal type request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('VARIETAL_TYPE_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('VARIETAL_TYPE_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('VARIETAL_TYPE_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'VARIETAL_TYPE_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
    }
    catch (err) {
        throw new Ark.ServiceError('VARIETAL_TYPE_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteVarietalType = async (varietalTypeId: App.VarietalTypeDetails['id']): Promise<void> => {
    Ark.assertIsNumber(varietalTypeId, 'varietalTypeId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/varietal/{1}', config.apiUrl, varietalTypeId);

        response = await httpService.delete(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete varietal type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send varietal type request (${deleteVarietalType.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('VARIETAL_TYPE_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('VARIETAL_TYPE_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('VARIETAL_TYPE_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'VARIETAL_TYPE_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }
};

const mapVarietalTypeDetails = ({
    id,
    code,
    label,
    plotCount,
    creationDate,
    updatedDate
}: App.Api.VarietalTypeDetails): App.VarietalTypeDetails => ({
    id,
    code,
    label,
    plotCount,
    creationDate,
    updatedDate
});