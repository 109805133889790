import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import ExportScreen from './ExportScreen';

export const ExportNavigation = (): NavigationRoute => new NavigationRoute(
    ExportScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: ExportScreen.key,
        path: '',
        component: ExportScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;