import React from 'react';
import propTypes from 'prop-types';
import { isArray } from '@dateam/ark';
import { refLabelNumberPropTypes } from 'utils/propTypes';

type Props = {
    data: App.Plot['observationTypes'];
};

const ColumnObservationType: React.FC<Props> = ({ data }: Props) => {
    if (!isArray(data)) return null;

    return (
        <>
            {
                data.map((obs, idx) => (
                    <div key={idx}>
                        {obs.label}
                    </div>
                ))
            }
        </>
    );
};

ColumnObservationType.propTypes = {
    data: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired
};

export default ColumnObservationType;
