import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from '../InspectionScreen.module.scss';

type Props = {
    status: App.InspectionSearchResult['status'];
};

const AssigneeColumn: React.FC<Props> = ({
    status
}: Props) => {
    let statusLabel = '';
    switch (status) {
        case 'notplanned':
            statusLabel = 'Non planifiée';
            break;
        case 'planned':
        case 'downloaded':
        case 'inprogress':
            statusLabel = 'Planifiée';
            break;
        case 'validated':
            statusLabel = 'Validée';
            break;
    }

    return (
        <div
            className={concatClassName(
                styles['statusColumn'],
                status === 'planned' || status === 'downloaded' || status === 'inprogress' ? styles['statusColumn-planned'] : null,
                status === 'validated' ? styles['statusColumn-validated'] : null
            )}
        >
            {statusLabel}
        </div>
    );
};

AssigneeColumn.propTypes = {
    status: propTypes.oneOf(['notplanned', 'planned', 'validated'] as const).isRequired
};

AssigneeColumn.defaultProps = {

};

export default AssigneeColumn;
