import React from 'react';
import { NavigationRoute, useNavigation, useRouterPath } from 'shared-utils';
import Breadcrumb from 'components/Breadcrumb';
import { DashboardNavigation } from 'screens/routes';

type Props = Record<string, never>;

const BioTypeBreadcrumb: React.FC<Props> = (props: Props) => {
    const homePath = useRouterPath(DashboardNavigation());
    const { push: navigate } = useNavigation();

    const triggerNav = React.useCallback((route: NavigationRoute) => (event: React.MouseEvent<any>) => {
        event.preventDefault();
        navigate(route);
    }, [navigate]);

    return (
        <Breadcrumb>
            <Breadcrumb.Home
                key="bc-home"
                to={homePath ?? undefined}
                onClick={triggerNav(DashboardNavigation())}
            />
            <Breadcrumb.Item key="bc-bioType">Type de protection</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default BioTypeBreadcrumb;
