import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'shared-utils';
import { getUserState } from 'utils/userStore';
import { initClient, queryClient } from 'utils/queryClient';
import tokenStore from 'utils/tokenStore';
import { ServiceWorkerUpdateNotifier } from 'components/ServiceWorkerUpdateNotifier';
import { UnauthorizedHandler, unauthorizedProvider } from 'components/unauthorizedHandler';
import { ErrorHandler } from 'components/errorHandler';
import { router } from 'screens';
import { LogOutNavigation } from 'screens/routes';
import LoadingScreen from 'screens/loading';
import useAppInitialize from './useAppInitialize';
import './i18n';
import './App.scss';

initClient();

const App: React.FC = () => {
    const isReady = useAppInitialize();
    const [unauthorizedNavigation] = React.useState(LogOutNavigation());

    React.useEffect(() => {
        if (!isReady) return;

        const user = getUserState();
        if (user == null) {
            unauthorizedProvider.notify(true);
        }
    }, [isReady]);

    if (!isReady) return (<LoadingScreen />);

    return (
        <RouterProvider router={router}>
            <ErrorHandler />
            <UnauthorizedHandler route={unauthorizedNavigation} tokenStore={tokenStore} />
        </RouterProvider>
    );
};

const AppWrapper: React.FC = () => (
    <QueryClientProvider client={queryClient}>
        <HelmetProvider>
            <App />
        </HelmetProvider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        <ServiceWorkerUpdateNotifier />
    </QueryClientProvider>
);

export default AppWrapper;
