import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Ark from '@dateam/ark';
import {
    concatClassName,
    propTypeAllowUndefined,
    propTypeNullable
} from '@dateam/ark-react';
import propTypes from 'prop-types';
import { useNavigation, useRouterPath } from 'shared-utils';
import { InspectionEditNavigation } from 'screens/routes';
import Timeline from './Timeline';
import styles from '../../../DashboardScreen.module.scss';

type SummaryItem = {
    id: number;
    label: string;
    plotCount: number;
    overlapDays: number;
    assigneeUser: string;
    activeStep: string | null;
    history: {
        key: string;
        label: string;
        date?: Date | null;
        subtext?: string;
        isActive: boolean;
    }[];
};

type Props = {
    data: SummaryItem;
    className?: string;
};

const SummaryItem: React.FC<Props> = ({ data, className }) => {
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const path = useRouterPath(InspectionEditNavigation(data.id));

    const activeStep = data.history.find(step => step.key === data.activeStep);

    const onClick = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(InspectionEditNavigation(data.id));
    }, [data, navigate]);

    return (
        <li key={data.id} className={className}>
            <a href={path} onClick={onClick} className={styles['summaryItem']}>
                <div
                    className={concatClassName(
                        styles['inspectionBorder'],
                        data.activeStep != null ? styles[`inspectionBorder-${data.activeStep}`] : null
                    )}
                />
                <div className={`col col-4 ${styles['inspectionInfos']}`}>
                    <div className={styles['inspectionLabel']}>
                        {data.label}
                    </div>
                    <div className={styles['inspectionDetails']}>
                        {t('dashboard.inspection.plot', { count: data.plotCount })}
                        {data.overlapDays > 1 && (
                            <span> - {t('dashboard.inspection.overlapDays', { count: data.overlapDays })}</span>
                        )}
                    </div>
                    <div className={styles['inspectionDetails']}>
                        {data.assigneeUser}
                    </div>
                    <div className={styles['status-mobile']}>
                        {Ark.isDefined(activeStep) && (
                            <div className={concatClassName(styles['inspectionStatus'], styles[`inspectionStatus-${data.activeStep}`])}>
                                {activeStep.label}
                                {Ark.isDefined(activeStep.subtext) && activeStep.subtext.length > 0 && (
                                    <span> ({activeStep.subtext})</span>
                                )}
                                {Ark.isValidDate(activeStep.date) && (
                                    <span> - {Ark.dateToFormat(activeStep.date, 'dd/MM HHhmm')}</span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <Timeline history={data.history} className={concatClassName(styles['status-desktop'], 'col', 'col-8')} />
            </a>
        </li>
    );
};

SummaryItem.propTypes = {
    data: propTypes.shape({
        id: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        plotCount: propTypes.number.isRequired,
        overlapDays: propTypes.number.isRequired,
        assigneeUser: propTypes.string.isRequired,
        activeStep: propTypeNullable(Ark.isString),
        history: propTypes.arrayOf(propTypes.shape({
            key: propTypes.string.isRequired,
            label: propTypes.string.isRequired,
            date: propTypeAllowUndefined(Ark.isValidDate),
            subtext: propTypeAllowUndefined(Ark.isString),
            isActive: propTypes.bool.isRequired
        }).isRequired).isRequired
    }).isRequired,
    className: propTypes.string
};

SummaryItem.defaultProps = {
    className: undefined
};

export default SummaryItem;
