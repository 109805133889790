import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName, useDebounce } from '@dateam/ark-react';
import { Form } from 'shared-ui';

type Props = {
    className?: string;
    onChange?: (filter: string) => void;
};

const FilterBar: React.FC<Props> = ({
    className,
    onChange
}) => {
    const notifyChange = React.useRef(onChange ?? Ark.noop);
    const saveChanges = useDebounce((filter: string) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (Ark.isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<string>('');

    const handleLabeleChange = React.useCallback((newValue: string) => {
        setFilter(newValue);
        saveChanges(newValue);
    }, [saveChanges]);

    return (
        <div className={concatClassName('row', className)}>
            <Form.Group controlId="filter-label" className="col">
                <Form.Label>Nom</Form.Label>
                <Form.Input
                    value={filter}
                    autocomplete="off"
                    onChange={handleLabeleChange}
                />
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    className: propTypes.string,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default FilterBar;
