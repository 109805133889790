import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

type CreateObservationTypeRequest = {
    label: string;
};

type UpdateObservationTypeRequest = {
    label: string;
    comment: string | null;
};

export const getTypes = async (year: number): Promise<App.ObservationType[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observationType', config.apiUrl, year);

        response = await httpService.get<App.ObservationType[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling observation type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send observation request (getTypes)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_GET_TYPE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getTypeDetails = async (
    year: number,
    id: App.ObservationTypeDetails['id']
): Promise<App.ObservationTypeDetails> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observationType/{2}', config.apiUrl, year, id);

        response = await httpService.get<App.ObservationTypeDetails>(requestUrl, {});
    }

    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling observation type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send observation request (getTypeDetails)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_DETAILS_FORBIDDEN');
    }

    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_DETAILS_NOT_FOUND');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_GET_TYPE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isDefined(response.body) && Ark.isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('OBSERVATION_GET_TYPE_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const createObservationType = async (
    year: number,
    request: CreateObservationTypeRequest
): Promise<number> => {
    if (!Ark.isDefined(request)) throw new Error('request parameter is not valid');

    const { label } = request;
    if (!Ark.isString(label) || label.trim().length === 0) throw new Ark.ServiceError('OBSERVATION_TYPE_CREATE_INVALID_REQUEST', 'request label is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observationType', config.apiUrl, year);
        const requestData = { label };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling create observation type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${createObservationType.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_TYPE_CREATE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid observation type id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const update = async (
    year: number,
    id: App.ObservationTypeDetails['id'],
    request: UpdateObservationTypeRequest
): Promise<void> => {
    Ark.assertIsValidNumber(id, 'id parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observationType/{2}', config.apiUrl, year, id);
        const {
            label,
            comment
        } = request;
        const requestData = {
            label,
            comment
        };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling observation type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send observation type request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_TYPE_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
    }
    catch (err) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteObservationType = async (
    year: number,
    observationTypeId: App.TrackDetails['id']
): Promise<void> => {
    Ark.assertIsNumber(observationTypeId, 'observationTypeId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observationType/{2}', config.apiUrl, year, observationTypeId);

        response = await httpService.delete(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete observation type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send observation type request (${deleteObservationType.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_TYPE_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_TYPE_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }
};
