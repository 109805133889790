import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { queryClient } from 'utils/queryClient';
import { useYearState } from 'utils/yearStore';
import {
    OBSERVATION_TYPE_KEY,
    OBSERVATION_TYPE_DETAILS_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    mutateResultKeys,
    defaultActionOptions,
    PLOT_DETAILS_KEY
} from './constants';
import { observationTypeRequests } from './requests';

export const useObservationTypes = (options?: DataOptions): QueryResult<App.ObservationType[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.ObservationType[], ServiceError>({
        ...config,
        queryKey: [year, OBSERVATION_TYPE_KEY],
        queryFn: () => observationTypeRequests.getTypes(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useObservationType = (id: App.ObservationTypeDetails['id'], options?: DataOptions): QueryResult<App.ObservationTypeDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.ObservationTypeDetails, ServiceError>({
        ...config,
        queryKey: [year, OBSERVATION_TYPE_DETAILS_KEY, id],
        queryFn: () => observationTypeRequests.getTypeDetails(year, id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateObservationType = (): MutationResult<number, App.CreateObservationTypeRequest> => {
    const [year] = useYearState();
    const result = useMutation<number, ServiceError, App.CreateObservationTypeRequest>(
        (requestData: App.CreateObservationTypeRequest) =>
            observationTypeRequests.createObservationType(year, { ...requestData }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateObservationType = (): MutationResult<void, App.UpdateObservationTypeRequest> => {
    const result = useMutation<void, ServiceError, App.UpdateObservationTypeRequest>(
        ({ year, ...request }: App.UpdateObservationTypeRequest) => {
            const {
                id,
                ...props
            } = request;

            return observationTypeRequests.update(year, id, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id, year }) => {
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_KEY]);
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};

export const useDeleteObservationType = (): MutationResult<void, App.ObservationTypeDetails['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.ObservationTypeDetails['id']>(
        id => observationTypeRequests.deleteObservationType(year, id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_KEY]);
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, PLOT_DETAILS_KEY]);
            }
        }
    );

    return result;
};
