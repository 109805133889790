import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';
import logger from 'utils/logger';

export const LOGIN_UNAUTHORIZED = 'LOGIN_UNAUTHORIZED';

export const getUser = async (id: App.UserDetails['id']): Promise<App.UserDetails> => {
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/{1}', config.apiUrl, id);

        response = await httpService.get<App.UserDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling user API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send user request (getUser)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_GET_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_GET_DETAILS_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_GET_DETAILS_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_GET_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isDefined(response.body) && Ark.isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_GET_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getUsers = async (): Promise<App.User[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user', config.apiUrl);

        response = await httpService.get<App.User[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling user API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send user request (getUsers)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_GET_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_GET_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getObservators = async (): Promise<App.User[]> => {
    let response;

    try {
        const requestUrl = Ark.stringFormat('{0}/user/observator', config.apiUrl);

        response = await httpService.get<App.User[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling user observator API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send user request (getObservators)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_GET_OBSERVATOR_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_GET_OBSERVATOR_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_GET_OBSERVATOR_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_GET_OBSERVATOR_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getWriters = async (): Promise<App.User[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/writer', config.apiUrl);

        response = await httpService.get<App.User[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling user writer API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send user request (getWriters)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_GET_WRITER_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_GET_WRITER_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_GET_WRITER_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_GET_WRITER_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getTechnicalUsers = async (): Promise<App.User[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/technical', config.apiUrl);

        response = await httpService.get<App.User[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling user technical API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send user request (getTechnicalUsers)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_GET_TECHNICAL_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_GET_TECHNICAL_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_GET_TECHNICAL_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_GET_TECHNICAL_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const login = async (data: App.AuthCredentials): Promise<App.UserInfo> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/auth', config.apiUrl);
        const { username, password } = data;

        response = await httpService.post<App.UserInfo>(requestUrl, {
            username,
            password
        }, {
            passToken: false
        });
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling login API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send login request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError(LOGIN_UNAUTHORIZED);
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'LOGIN_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsDefined(response.body, 'unable to access request data');
        Ark.assert(Ark.isString(response.body.token) && response.body.token.length > 0, 'token is missing from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('LOGIN_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const renewAuth = async (): Promise<App.UserInfo> => {
    let response;

    try {
        const requestUrl = Ark.stringFormat('{0}/auth/renew', config.apiUrl);

        response = await httpService.post<App.UserInfo>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling login API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send login request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('RENEW_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('RENEW_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'RENEW_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsDefined(response.body, 'unable to access request data');
        Ark.assert(Ark.isString(response.body.token) && response.body.token.length > 0, 'token is missing from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('RENEW_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const createUser = async (request: App.CreateUserRequest): Promise<number> => {
    if (!Ark.isDefined(request)) throw new Error('request parameter is not valid');

    const {
        username,
        password,
        roleId,
        firstName,
        lastName
    } = request;
    if (!Ark.isString(username) || username.trim().length === 0) throw new Ark.ServiceError('USER_CREATE_INVALID_REQUEST', 'request username is not valid');
    if (!Ark.isString(password) || password.trim().length === 0) throw new Ark.ServiceError('USER_CREATE_INVALID_REQUEST', 'request password is not valid');
    if (!Ark.isString(roleId) || roleId.trim().length === 0) throw new Ark.ServiceError('USER_CREATE_INVALID_REQUEST', 'request roleId is not valid');
    if (!Ark.isString(firstName) || firstName.trim().length === 0) throw new Ark.ServiceError('USER_CREATE_INVALID_REQUEST', 'request firstName is not valid');
    if (!Ark.isString(lastName) || lastName.trim().length === 0) throw new Ark.ServiceError('USER_CREATE_INVALID_REQUEST', 'request lastName is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user', config.apiUrl);
        const requestData = {
            username,
            password,
            roleId,
            firstName,
            lastName
        };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling create user API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${createUser.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_CREATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid user id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

type UpdateUserRequest = {
    firstName: string;
    lastName: string;
    roleId: string;
    phoneNumber: string | null;
    email: string | null;
    isTrustworthy: boolean;
};

export const updateUser = async (id: App.UserDetails['id'], request: UpdateUserRequest): Promise<number> => {
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!Ark.isDefined(request)) throw new Error('request parameter is not valid');

    const {
        firstName,
        lastName,
        roleId,
        phoneNumber,
        email,
        isTrustworthy
    } = request;
    if (!Ark.isString(firstName) || firstName.trim().length === 0) throw new Ark.ServiceError('USER_UPDATE_INVALID_REQUEST', 'request firstName is not valid');
    if (!Ark.isString(lastName) || lastName.trim().length === 0) throw new Ark.ServiceError('USER_UPDATE_INVALID_REQUEST', 'request lastName is not valid');
    if (!Ark.isString(roleId) || roleId.trim().length === 0) throw new Ark.ServiceError('USER_UPDATE_INVALID_REQUEST', 'request roleId is not valid');
    if (!Ark.checkType(phoneNumber, Ark.Type.Null | Ark.Type.String)) throw new Ark.ServiceError('USER_UPDATE_INVALID_REQUEST', 'request phoneNumber is not valid');
    if (!Ark.checkType(email, Ark.Type.Null | Ark.Type.String)) throw new Ark.ServiceError('USER_UPDATE_INVALID_REQUEST', 'request email is not valid');
    if (!Ark.isBoolean(isTrustworthy)) throw new Ark.ServiceError('USER_UPDATE_INVALID_REQUEST', 'request isTrustworthy is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/{1}', config.apiUrl, id);
        const requestData = {
            firstName,
            lastName,
            roleId,
            phoneNumber,
            email,
            isTrustworthy
        };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update user API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${updateUser.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid user id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_UPDATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const updateUserPassword = async (id: App.UserDetails['id'], password: string): Promise<number> => {
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!Ark.isString(password) || password.trim().length === 0) throw new Error('password parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/{1}/password', config.apiUrl, id);
        const requestData = {
            password
        };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update user password API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${updateUserPassword.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_UPDATE_PASSWORD_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_UPDATE_PASSWORD_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_UPDATE_PASSWORD_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_UPDATE_PASSWORD_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid user id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_UPDATE_PASSWORD_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const deactivateUser = async (id: App.UserDetails['id']): Promise<number> => {
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/{1}/deactivate', config.apiUrl, id);

        response = await httpService.post<number>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling deactivate user password API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${deactivateUser.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_DEACTIVATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_DEACTIVATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_DEACTIVATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_DEACTIVATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid user id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_DEACTIVATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const reactivateUser = async (id: App.UserDetails['id']): Promise<number> => {
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/{1}/reactivate', config.apiUrl, id);

        response = await httpService.post<number>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling reactivate user password API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${reactivateUser.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_REACTIVATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_REACTIVATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_REACTIVATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_REACTIVATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid user id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_REACTIVATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const deleteUser = async (id: App.UserDetails['id']): Promise<number> => {
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/user/{1}', config.apiUrl, id);

        response = await httpService.delete<number>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete user password API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${deleteUser.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('USER_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('USER_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('USER_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'USER_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid user id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('USER_DELETE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};