import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import CustomerScreen from './CustomerScreen';

export const CustomerNavigation = (): NavigationRoute => new NavigationRoute(
    CustomerScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: CustomerScreen.key,
        path: '',
        component: CustomerScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;