import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isDefined } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import {
    Loader,
    Tabs
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import { useUserState } from 'utils/userStore';
import Page from 'components/Page';
import { CustomerNavigation, CustomerDetailsNavigation, CustomerTabNavigation } from 'screens/routes';
import { useCustomerDetails } from 'data/customer';
import { ConfigurationSection, ContactSection } from './components';
import CustomerDetailsBreadcrumb from './CustomerDetailsBreadcrumb';

const CustomerDetailsScreen: React.FC = () => {
    const { t } = useTranslation();
    const {
        query: { customerId: customerIdParam, tab: tabParam },
        push: navigate,
        replace: replaceNavigation
    } = useNavigation();
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';

    const customerId = React.useMemo(
        () => (customerIdParam != null ? parseInt(customerIdParam, 10) : -1),
        [customerIdParam]
    );
    useBindYearNavigation(CustomerDetailsNavigation, [customerId]);
    const {
        data: customer,
        isFetched: hasLoaded,
        isLoading
    } = useCustomerDetails(customerId, { enabled: customerId > 0 });

    React.useEffect(() => {
        if (customer == null && hasLoaded) {
            replaceNavigation(CustomerNavigation());
        }
    }, [customer, hasLoaded, replaceNavigation]);

    React.useEffect(() => {
        if (tabParam == null && customerId !== null) {
            replaceNavigation(CustomerTabNavigation(customerId, 'config'));
        }
    }, [tabParam, customerId, replaceNavigation]);

    const selectTab = React.useCallback((tab: string) => {
        navigate(CustomerTabNavigation(customerId, tab));
    }, [customerId, navigate]);

    if (!isDefined(customer)) return null;

    return (
        <>
            <Helmet>
                <title>{t('customer.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {customer.fullName}
                    </Page.Title>
                    <Page.HeaderActions>
                        <CustomerDetailsBreadcrumb label={customer.fullName} />
                        <Tabs
                            selected={tabParam}
                            onChange={selectTab}
                        >
                            <Tabs.Tab tabKey="config" title="Configuration" />
                            <Tabs.Tab tabKey="contact" title="Contacts" />
                        </Tabs>
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    {isLoading && (
                        <Loader text="chargement" />
                    )}
                    {tabParam === 'config' && (
                        <ConfigurationSection key={`customer-config-${customer.id}`} customer={customer} displayActions={displayActions} />
                    )}
                    {tabParam === 'contact' && (
                        <ContactSection key={`customer-contact-${customer.id}`} customer={customer} displayActions={displayActions} />
                    )}
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(CustomerDetailsScreen, 'CUSTOMER_DETAILS');