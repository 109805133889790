import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from './ProgressStep.module.scss';

type Props = {
    variant?: 'plain' | 'border';
    color?: 'primary' | 'secondary' | 'success';
    className?: string;
    children?: ReactNode;
    disable?: boolean;
}

const VARIANTS: any[] = ['plain', 'border'];
const COLORS: any[] = ['primary', 'secondary', 'success'];

const ProgressStep: React.FC<Props> = ({
    variant,
    color,
    className,
    children,
    disable
}: Props) => (
    <div
        className={concatClassName(
            styles['progressStep'],
            VARIANTS.includes(variant) ? styles[`progressStep-${variant}`] : null,
            COLORS.includes(color) ? styles[`progressStep-${color}`] : null,
            disable ? styles['progressStep-disable'] : null,
            className
        )}
    >
        {children}
    </div>
);

ProgressStep.propTypes = {
    variant: propTypes.oneOf(['plain', 'border']),
    color: propTypes.oneOf(['primary', 'secondary', 'success']),
    className: propTypes.string,
    children: propTypes.node,
    disable: propTypes.bool
};

ProgressStep.defaultProps = {
    variant: undefined,
    color: undefined,
    className: undefined,
    children: undefined,
    disable: undefined
};

export default ProgressStep;