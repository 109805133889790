import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { isFunction } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import styles from './Breadcrumb.module.scss';

type Props = {
    to?: string;
    className?: string;
    children?: ReactNode;
    onClick?: (event: React.MouseEvent<any>) => void;
};

const BreadcrumbItem: React.FC<Props> = ({
    to,
    className,
    children,
    onClick
}: Props) => (
    <li className={concatClassName(styles['item'], className)}>
        {isFunction(onClick) ? (
            <a
                href={to ?? '#'}
                className={styles['itemLink']}
                onClick={onClick}
            >
                {children}
            </a>
        ) : children}
    </li>
);

BreadcrumbItem.propTypes = {
    to: propTypes.string,
    className: propTypes.string,
    children: propTypes.node,
    onClick: propTypes.func
};

BreadcrumbItem.defaultProps = {
    to: undefined,
    className: undefined,
    children: undefined,
    onClick: undefined
};

export default BreadcrumbItem;