import React from 'react';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import { PlusIcon } from '../../../components/Icons';
import CreateCustomerModal from './CreateObservationTypeModal';

const HeaderAction: React.FC = () => {
    const { push: navigate } = useNavigation();
    const [showCreateObservationType, setShowCreateObservationType] = React.useState(false);

    const handleObservationTypeCreateCompletion = (observationTypeId: number) => {
        // navigate(ObservationTypeDetailsNavigation(customerId));
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={() => setShowCreateObservationType(true)}
            >
                Ajouter un type de suivi
            </Button>
            {showCreateObservationType && (
                <CreateCustomerModal
                    onClose={() => setShowCreateObservationType(false)}
                    onCompletion={handleObservationTypeCreateCompletion}
                />
            )}
        </>
    );
};

export default HeaderAction;
