import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import VarietalTypeScreen from './VarietalTypeScreen';

export const VarietalTypeNavigation = (): NavigationRoute => new NavigationRoute(
    VarietalTypeScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: VarietalTypeScreen.key,
        path: '',
        component: VarietalTypeScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;