import React from 'react';
import * as Ark from '@dateam/ark';
import { useCustomers } from 'data/customer';
import {
    QueryResult,
    queryResultKeys
} from '../constants';

export const useCustomerSearch = (filter: string, options?: DataOptions): QueryResult<App.Customer[]> => {
    const { data: customers, ...queryResult } = useCustomers();

    return React.useMemo(() => {
        let filteredData: App.Customer[] = [];

        if (Ark.isArray(customers)) {
            filteredData = [...customers];

            const filterPredicates: ((dataEntry: App.Customer) => boolean)[] = [];

            if (Ark.isString(filter) && filter.trim().length > 0) {
                const customerFilter = filter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.Customer) =>
                    dataEntry.fullName.toUpperCase().indexOf(customerFilter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        Ark.orderBy(filteredData, 'fullName');

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [customers, queryResult, filter]);
};
