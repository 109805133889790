import React from 'react';

type Props = {
    data: App.Report.Report;
};

const ColumnPlot: React.FC<Props> = ({ data }: Props) => (
    <div>
        {data.plotCount}
    </div>
);

export default ColumnPlot;
