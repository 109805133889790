import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { queryClient } from 'utils/queryClient';
import {
    VARIETAL_KEY,
    VARIETAL_TYPE_DETAILS_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    mutateResultKeys,
    defaultActionOptions
} from './constants';
import { varietalRequests } from './requests';

export const useVarietals = (options?: DataOptions): QueryResult<App.Varietal[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Varietal[], ServiceError>({
        ...config,
        queryKey: VARIETAL_KEY,
        queryFn: varietalRequests.get
    });

    return usePick(query as any, queryResultKeys);
};

export const useVarietalType = (id: App.VarietalTypeDetails['id'], options?: DataOptions): QueryResult<App.VarietalTypeDetails> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.VarietalTypeDetails, ServiceError>({
        ...config,
        queryKey: [VARIETAL_TYPE_DETAILS_KEY, id],
        queryFn: () => varietalRequests.getTypeDetails(id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateVarietalType = (): MutationResult<number, App.CreateVarietalTypeRequest> => {
    const result = useMutation<number, ServiceError, App.CreateVarietalTypeRequest>(
        (requestData: App.CreateVarietalTypeRequest) =>
            varietalRequests.createVarietalType({ ...requestData }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([VARIETAL_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateVarietalType = (): MutationResult<void, App.UpdateVarietalTypeRequest> => {
    const result = useMutation<void, ServiceError, App.UpdateVarietalTypeRequest>(
        ({ ...request }: App.UpdateVarietalTypeRequest) => {
            const {
                id,
                ...props
            } = request;

            return varietalRequests.update(id, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([VARIETAL_KEY]);
                queryClient.invalidateQueries([VARIETAL_TYPE_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};

export const useDeleteVarietalType = (): MutationResult<void, App.VarietalTypeDetails['id']> => {
    const result = useMutation<void, ServiceError, App.VarietalTypeDetails['id']>(
        id => varietalRequests.deleteVarietalType(id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([VARIETAL_KEY]);
                queryClient.invalidateQueries([VARIETAL_TYPE_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};