import React from 'react';
import propTypes from 'prop-types';
import { isArray, isFunction, strIncludes } from '@dateam/ark';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useTracks } from 'data/track';
import { useCreateInspection } from 'data/inspection';
import styles from './CreateInspectionDialog.module.scss';

type Props = {
    campaignId: number;
    onClose?: () => void;
    onCompletion?: (campainId: number) => void;
};

type TrackOption = {
    id: number;
    label: string;
};

type TrackState = {
    track?: TrackOption;
    includeTrack: boolean;
};

const CreateInspectionDialog: React.FC<Props> = ({
    campaignId,
    onClose,
    onCompletion
}: Props) => {
    const { data: tracks } = useTracks();
    const { mutateAsync: createInspection, isLoading: isCreating } = useCreateInspection();
    const [labelState, setLabelState] = React.useState<App.CreateInspectionRequest['label']>('');
    const [trackState, setTrackState] = React.useState<TrackState>({ includeTrack: false });

    const formIsValid = React.useMemo(() => labelState.trim().length > 0, [labelState]);

    const handleLabelChange = React.useCallback((value: string) => {
        setLabelState(value);
    }, [setLabelState]);

    const handleCloseClick = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    const handleCreateClick = async () => {
        if (!formIsValid) return;

        const newInspectionId = await createInspection({
            campaignId,
            label: labelState,
            includeTrackId: (trackState.includeTrack && trackState.track?.id) || null // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        });

        if (isFunction(onCompletion)) {
            onCompletion(newInspectionId);
        }
    };

    const handleGetLabelOptions = React.useCallback(async (value: string): Promise<TrackOption[]> => {
        if (!isArray(tracks)) return [];

        return tracks.filter(dataItem => strIncludes(value, dataItem.label, { caseInsensitive: true }));
    }, [tracks]);

    const handleOptionSelected = React.useCallback((option: TrackOption) => {
        setLabelState(option.label);
        setTrackState({
            track: { ...option },
            includeTrack: true
        });
    }, []);

    const handleIncludeTrackChange = React.useCallback((value: boolean) => {
        setTrackState(prevState => ({
            ...prevState,
            includeTrack: value
        }));
    }, []);

    return (
        <Modal
            className={`${styles['modal']} modal-flex`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Créer une visite à la tournée
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group controlId="dialog-inspection-name" className="col">
                        <Form.Label>Nom de la visite</Form.Label>
                        <Form.Autocomplete<TrackOption>
                            maxlength={50}
                            onGetOptions={handleGetLabelOptions}
                            onChange={handleLabelChange}
                            onOptionSelected={handleOptionSelected}
                            onGetLabel={value => value.label}
                        />
                    </Form.Group>
                    {trackState?.track != null && (
                        <Form.Group controlId="include-track" className="form-inline">
                            <Form.Checkbox checked={trackState.includeTrack} onChange={handleIncludeTrackChange} />
                            <Form.Label>Inclure les parcelles du circuit {trackState.track.label}</Form.Label>
                        </Form.Group>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isCreating}
                    onClick={handleCloseClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer la visite
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreateInspectionDialog.propTypes = {
    campaignId: propTypes.number.isRequired,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

CreateInspectionDialog.defaultProps = {
    onClose: undefined,
    onCompletion: undefined
};

export default CreateInspectionDialog;
