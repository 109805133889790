import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { Plot, plotPropTypes } from './types';
import PlotListItem from './PlotListItem';
import styles from './PlotList.module.scss';

type Props<T> = {
    data: T[];
    className?: string;
    onRemovePlot?: (plot: T) => void;
    onPositionChange?: (plot: T, position: number) => void;
};

const PlotList = <T extends Plot>({
    data,
    className,
    onRemovePlot,
    onPositionChange
}: Props<T>): React.ReactElement<any, any> | null => {
    return (
        <ul className={concatClassName(styles['list'], className)}>
            {data.map((plot: T) => (
                <PlotListItem
                    key={plot.id}
                    data={plot}
                    onRemovePlot={onRemovePlot}
                    onPositionChange={onPositionChange}
                />
            ))}
        </ul>
    );
};

PlotList.propTypes = {
    data: propTypes.arrayOf(plotPropTypes.isRequired).isRequired,
    className: propTypes.string,
    onRemovePlot: propTypes.func,
    onPositionChange: propTypes.func
};

PlotList.defaultProps = {
    className: undefined,
    onRemovePlot: undefined,
    onPositionChange: undefined
};

export default PlotList;
