import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isValidDate, ObjectAccessor, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { plotDetailsPropTypes } from 'data/plot';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps } from './types';
import { ObservationErrors, AdventiceObservationField } from './components';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation5';

type Props = ObservationProps<App.Observation5>;

const Observation5: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.field1 === undefined &&
            data.field2 === undefined &&
            data.field3 === undefined &&
            data.field4 === undefined &&
            data.field5 === undefined) {
            return;
        }

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const field1 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.field1 ?? undefined;
    }, [data]);

    const field2 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.field2 ?? undefined;
    }, [data]);

    const field3 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.field3 ?? undefined;
    }, [data]);

    const field4 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.field4 ?? undefined;
    }, [data]);

    const field5 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.field5 ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>{internalData.current.previous.field1?.value ?? '-'}</div>
                    <div className={styles['previous']}>{internalData.current.previous.field2?.value ?? '-'}</div>
                    <div className={styles['previous']}>{internalData.current.previous.field3?.value ?? '-'}</div>
                    <div className={styles['previous']}>{internalData.current.previous.field4?.value ?? '-'}</div>
                    <div className={styles['previous']}>{internalData.current.previous.field5?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation5 = {
            ...internalData.current,
            field1: null,
            field2: null,
            field3: null,
            field4: null,
            field5: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleOptionChange = React.useCallback((label: string, newValue: string | null) => {
        const newData: App.Observation5 = {
            ...internalData.current,
            [label]: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleFocus = React.useCallback((field: ObjectAccessor<Omit<App.Observation5, 'previous'>>) => {
        if (internalData.current[field] !== undefined) return;

        const newData: App.Observation5 = {
            ...internalData.current,
            [field]: null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="Adventices dominantes"
        >
            <div className="row">
                <AdventiceObservationField
                    id={`${observationKey}-field1`}
                    value={field1}
                    onChange={value => handleOptionChange('field1', value)}
                    className={concatClassName(styles[`${observationKey}-field1`], 'col-4')}
                    state={validationErrors['field1']?.type}
                    onFocus={() => handleFocus('field1')}
                    required={!data.ignored}
                    readOnly={data.ignored}
                />
                <AdventiceObservationField
                    id={`${observationKey}-field2`}
                    value={field2}
                    onChange={value => handleOptionChange('field2', value)}
                    className={concatClassName(styles[`${observationKey}-field2`], 'col-4')}
                    state={validationErrors['field2']?.type}
                    readOnly={data.ignored}
                />
                <AdventiceObservationField
                    id={`${observationKey}-field3`}
                    value={field3}
                    onChange={value => handleOptionChange('field3', value)}
                    className={concatClassName(styles[`${observationKey}-field3`], 'col-4')}
                    state={validationErrors['field3']?.type}
                    readOnly={data.ignored}
                />
            </div>
            <div className="row">
                <AdventiceObservationField
                    id={`${observationKey}-field4`}
                    value={field4}
                    onChange={value => handleOptionChange('field4', value)}
                    className={concatClassName(styles[`${observationKey}-field4`], 'col-4')}
                    state={validationErrors['field4']?.type}
                    readOnly={data.ignored}
                />
                <AdventiceObservationField
                    id={`${observationKey}-field5`}
                    value={field5}
                    onChange={value => handleOptionChange('field5', value)}
                    className={concatClassName(styles[`${observationKey}-field5`], 'col-4')}
                    state={validationErrors['field5']?.type}
                    readOnly={data.ignored}
                />
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation5.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotDetailsPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation5.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation5;
