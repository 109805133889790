import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useCreateTrack } from 'data/track';

type Props = {
    className?: string;
    onClose?: () => void;
    onCompletion?: (cuatomerId: number) => void;
};

const CreateTrackModal: React.FC<Props> = ({
    className,
    onClose,
    onCompletion
}: Props) => {
    const { mutateAsync: createTrack, isLoading: isCreating } = useCreateTrack();
    const [labelState, setLabelState] = React.useState<string>('');

    const formIsValid = React.useMemo(() => labelState.trim().length > 0, [labelState]);

    const handleLabelChange = React.useCallback((value: string) => {
        setLabelState(value);
    }, [setLabelState]);

    const handleCloseClick = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleCreateClick = React.useCallback(async () => {
        if (!formIsValid) return;

        const trackKey = await createTrack({ label: labelState });

        onCompletion?.(trackKey.id);
    }, [createTrack, onCompletion, formIsValid, labelState]);

    return (
        <Modal
            className={concatClassName('modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Créer un nouveau circuit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group controlId="track-label" className="col">
                        <Form.Label>Nom du circuit</Form.Label>
                        <Form.Input
                            value={labelState}
                            maxlength={50}
                            autocomplete="off"
                            readOnly={isCreating}
                            onChange={handleLabelChange}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isCreating}
                    onClick={handleCloseClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer le circuit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreateTrackModal.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

CreateTrackModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default CreateTrackModal;
