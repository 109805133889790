import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { mapEachProperty, objectPick, dateToFormat, isValidDate } from '@dateam/ark';
import i18nLoader, { TranslateResource } from './i18nLoader';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: 'fr',
        resources: mapEachProperty(i18nLoader.resources, (resource: TranslateResource) => objectPick(resource, ['translation'])),
        interpolation: {
            escapeValue: false,
            format: (value, format, language) => {
                if (format === 'uppercase') return value.toUpperCase();
                if (isValidDate(value)) return dateToFormat(value, i18nLoader.resources[language ?? ''].locale.dateFormat);

                return value;
            }
        }
    });

export default i18n;