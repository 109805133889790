import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isValidDate } from '@dateam/ark';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import config from 'config';
import { getYearState } from 'utils/yearStore';
import { inspectionRequests } from 'data/requests';
import { inspectionReportPropTypes, useSendInspectionReport } from 'data/inspection';
import { InspectionReportDetailsNavigation } from 'screens/inspectionEdit';
import {
    DownloadIcon,
    EditIcon,
    MailIcon
} from 'components/Icons';
import styles from '../ReportSection.module.scss';

type Props = {
    inspectionId: App.InspectionDetails['id'];
    data: App.InspectionReport;
};

const ActionsColumn: React.FC<Props> = ({
    inspectionId,
    data
}: Props) => {
    const { push: navigate } = useNavigation();
    const { mutateAsync: sendReport, isLoading: isSending } = useSendInspectionReport();

    const handleEditClick = () => {
        navigate(InspectionReportDetailsNavigation(inspectionId, data.id));
    };

    const downloadUrl = React.useMemo(() => {
        const year = getYearState();

        return inspectionRequests.getReportDownloadUrl(year, data);
    }, [data]);

    const send = React.useCallback(async () => {
        await sendReport({
            inspectionId,
            reportId: data.id
        });
    }, [inspectionId, data, sendReport]);

    return (
        <div className={concatClassName('row', styles['actions'])}>
            <Button
                startIcon={<EditIcon />}
                color="primary"
                size="xs"
                onClick={handleEditClick}
            >
                Éditer
            </Button>
            <Button
                color="primary"
                size="xs"
                type="link"
                startIcon={(<DownloadIcon />)}
                link={downloadUrl}
                target="_blank"
                rel="noreferrer"
                disabled={!isValidDate(data.updatedOn)}
            >
                Télécharger
            </Button>
            <Button
                startIcon={<MailIcon />}
                color="primary"
                size="xs"
                pending={isSending}
                disabled={!isValidDate(data.updatedOn)}
                onClick={send}
            >
                Envoyer
            </Button>
        </div>
    );
};

ActionsColumn.propTypes = {
    inspectionId: propTypes.number.isRequired,
    data: inspectionReportPropTypes.isRequired
};

ActionsColumn.defaultProps = {

};

export default ActionsColumn;
