import { isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Araignées rouges
 ** - count: Comptage // /10 feuilles
 **     0 <= Value <= 10
 **     Required
 ** - total: Total /10 feuilles
 **     Required
 */

type CurrentObservation = App.Observation25;
type CurrentApiObservation = App.Api.Observation25;

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count, total } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (count == null || !isValidNumber(count)) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'required'
        };
    }
    else if (count < 0 || count > 10) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'format'
        };
    }

    // The value must be defined
    if (total == null || !isValidNumber(total)) {
        validation.total = {
            field: 'total',
            type: 'error',
            code: 'required'
        };
    }
    else if (total < 0) {
        validation.total = {
            field: 'total',
            type: 'error',
            code: 'format'
        };
    }
    else if (isValidNumber(count) && total < count) {
        validation.total = {
            field: 'total',
            type: 'error',
            code: 'mincount'
        };
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        count,
        total
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.count = parseObservationField(count);
    parsedObs.total = parseObservationField(total);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        count,
        total
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.count = count !== undefined ? new RecordValue(count) : null;
    mappedObs.total = total !== undefined ? new RecordValue(total) : null;

    return mappedObs;
};