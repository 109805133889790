import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { Card } from 'shared-ui';
import { SidePanelList } from 'components/SidePanelList';
import { useObservators } from 'data/user';
import ObservatorItem from './ObservatorItem';
import styles from '../DashboardScreen.module.scss';

type Props = {
    data: number[];
    className?: string;
};

const ObservatorPanel: React.FC<Props> = ({
    data,
    className
}) => {
    const { data: observators } = useObservators();

    const observatorList = React.useMemo(() => {
        if (!Ark.isArray(observators) || !Ark.isArray(data)) return [];

        return observators.filter(observator => data.indexOf(observator.id) >= 0);
    }, [observators, data]);

    return (
        <Card
            className={concatClassName(
                styles['dayObservator'],
                className
            )}
        >
            <Card.Header>
                <Card.Title>
                    Observateurs du jour
                </Card.Title>
            </Card.Header>
            <Card.Body className={styles['cardContent']}>
                <SidePanelList>
                    {observatorList.map(observator => <ObservatorItem key={observator.id} value={observator} />)}
                </SidePanelList>
            </Card.Body>
        </Card>
    );
};

ObservatorPanel.propTypes = {
    data: propTypes.arrayOf(propTypes.number.isRequired).isRequired,
    className: propTypes.string
};

ObservatorPanel.defaultProps = {
    className: undefined
};

export default ObservatorPanel;
