import React from 'react';
import { useMutation } from 'react-query';
import { ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { useYearState } from 'utils/yearStore';
import {
    defaultActionOptions,
    mutateResultKeys,
    MutationResult
} from './constants';
import { exportRequests } from './requests';

export const useExport = (): MutationResult<App.FileData, App.GlobalExportFilters> => {
    const [year] = useYearState();
    const result = useMutation<App.FileData, ServiceError, App.GlobalExportFilters>(
        (request: App.GlobalExportFilters) => exportRequests.exportFile(year, request),
        {
            ...defaultActionOptions
        }
    );

    return result;
};