import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Modal,
    Table,
    TableHeaderSelectionChangeEvent,
    TableSelectionChangeEvent,
    TableSelectionItem
} from 'shared-ui';
import {
    useObservations,
    useUpdateObservationObservationTypes
} from 'data/observation';
import { useObservationTypes } from 'data/observationType';
import styles from './AssociateObservationModal.module.scss';

type ObservationTableItem = TableSelectionItem<App.Observation>;

type TypeObservations = {
    type: App.ObservationType['id'];
    observations: App.Observation['id'][];
};

type Props = {
    className?: string;
    onClose?: () => void;
    onCompletion?: () => void;
};

const AssociateObservationModal: React.FC<Props> = ({
    className,
    onClose,
    onCompletion
}: Props) => {
    const { data: observationTypes } = useObservationTypes();
    const { data: observations } = useObservations();
    const { mutateAsync: updateObsTypes } = useUpdateObservationObservationTypes();
    const [selectedType, setSelectedType] = React.useState<App.ObservationType['id'] | null>(null);
    const [typeObservations, setTypeObservations] = React.useState<TypeObservations[]>([]);

    React.useEffect(() => {
        if (observationTypes == null) return;

        Ark.orderBy(observationTypes, 'id');

        const typeObservations = observationTypes.map(type => ({
            type: type.id,
            observations: type.observations.map(obs => obs.id)
        }));

        setTypeObservations(typeObservations);
    }, [observationTypes, setTypeObservations]);

    const handleCreateClick = React.useCallback(async () => {
        if (observations == null) return;

        const updatedObservations = observations.map(obs => {
            const observationTypes = typeObservations.filter(item => item.observations
                .includes(obs.id))
                .map(item => item.type);

            return {
                id: obs.id,
                observationTypes
            };
        });

        updateObsTypes(updatedObservations);

        onCompletion?.();
        onClose?.();
    }, [observations, typeObservations, onCompletion, onClose, updateObsTypes]);

    const observationDataTable = React.useMemo<ObservationTableItem[]>(() => {
        if (selectedType == null) return [];
        if (!Ark.isArray(observations)) return [];

        return observations.map(obs => Ark.pureObjectAssign(obs, {
            selected: typeObservations.find(type => type.type === selectedType)?.observations.includes(obs.id)
        }));
    }, [selectedType, observations, typeObservations]);

    const selectObservation = React.useCallback((event: TableSelectionChangeEvent<ObservationTableItem>) => {
        if (selectedType == null) return;

        const { id: obsId } = event.data;

        setTypeObservations(currentSelection => {
            const newSelection = [...currentSelection];

            const idx = newSelection.findIndex(item => item.type === selectedType);

            if (event.selected === true) newSelection[idx].observations.push(obsId);
            else Ark.removeFromCollection(newSelection[idx].observations, item => item === obsId);

            return newSelection;
        });
    }, [selectedType, setTypeObservations]);

    const handleHeaderSelectionChange =
        React.useCallback((event: TableHeaderSelectionChangeEvent<ObservationTableItem>) => {
            setTypeObservations(currentSelection => {
                const newSelection = [...currentSelection];

                const idx = currentSelection.findIndex(item => item.type === selectedType);

                if (event.selected === true) newSelection[idx].observations = event.items.map(item => item.data.id);
                else newSelection[idx].observations = [];

                return newSelection;
            });
        }, [selectedType, setTypeObservations]);

    return (
        <Modal
            className={concatClassName('modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Associer les types de suivi
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles['modal']}>
                <div className={styles['modalContent']}>
                    <ul className={styles['typeList']}>
                        {observationTypes?.map(type => (
                            <li
                                key={type.id}
                                className={concatClassName(
                                    styles['typeItem'],
                                    selectedType === type.id ? styles['typeItem-selected'] : null
                                )}
                                onClick={() => setSelectedType(type.id)}
                            >
                                {type.label}
                            </li>
                        ))}
                    </ul>
                    <div className={styles['observations']}>
                        {selectedType != null && (
                            <Table<ObservationTableItem>
                                data={observationDataTable}
                                selectedField="selected"
                                onSelectionChange={selectObservation}
                                onHeaderSelectionChange={handleHeaderSelectionChange}
                            >
                                <Table.Column accessor="label">
                                    <Table.Column.Header>Observations associées</Table.Column.Header>
                                </Table.Column>
                            </Table>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={handleCreateClick}
                >
                    Valider
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AssociateObservationModal.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

AssociateObservationModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default AssociateObservationModal;
