import {
    assert,
    assertIsArray,
    HttpStatusCode,
    isDefined,
    ServiceError,
    stringFormat
} from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const getSummary = async (): Promise<App.ActivitySummary[]> => {
    let response;
    try {
        const requestUrl = stringFormat('{0}/activity/summary', config.apiUrl);

        response = await httpService.get<App.ActivitySummary[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling summary API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send campaign request (getSummary)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('SUMMARY_GET_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('SUMMARY_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'SUMMARY_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('SUMMARY_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getInspectionRemainingReports = async (year: number): Promise<App.InspectionRemainingReport[]> => {
    let response;
    try {
        const requestUrl = stringFormat('{0}/activity/remainingReports/{1}', config.apiUrl, year);

        response = await httpService.get<App.InspectionRemainingReport[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspectiom remaining reports API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send activity request (getInspectionRemainingReports)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('REMAININGREPORT_GET_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('REMAININGREPORT_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'REMAININGREPORT_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('REMAININGREPORT_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};