import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { matchPath } from 'react-router-dom';
import NavigationRoute from '../navigationRoute';
import useNavigation from './useNavigation';
import useHistory from './useNavigationHistory';
import useRouterRegistration from './useRouterRegistration';
export var useNavigationActive = function useNavigationActive() {
  var _useRouterRegistratio = useRouterRegistration(),
    _useRouterRegistratio2 = _slicedToArray(_useRouterRegistratio, 1),
    router = _useRouterRegistratio2[0];
  var _useNavigation = useNavigation(),
    location = _useNavigation.location;
  var _useHistory = useHistory(),
    current = _useHistory.current;
  return React.useCallback(function (route) {
    if (!(route instanceof NavigationRoute)) return false;
    if (current != null) return current.key.indexOf(route.key) === 0;
    if (router != null) {
      var path = route.toPath(router);

      // TODO: Be careful about this change
      return path != null && matchPath(location.pathname, path) != null;
    }
    return false;
  }, [router, current, location]);
};
export default useNavigationActive;