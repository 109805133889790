import React from 'react';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import { PlusIcon } from '../../../components/Icons';
import CreateVarietalTypeModal from './CreateVarietalTypeModal';

const HeaderAction: React.FC = () => {
    const { push: navigate } = useNavigation();
    const [showCreateVarietalType, setShowCreateVarietalType] = React.useState(false);

    const handleObservationTypeCreateCompletion = (observationTypeId: number) => {
        // navigate(ObservationTypeDetailsNavigation(customerId));
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={() => setShowCreateVarietalType(true)}
            >
                Ajouter un cépage
            </Button>
            {showCreateVarietalType && (
                <CreateVarietalTypeModal
                    onClose={() => setShowCreateVarietalType(false)}
                    onCompletion={handleObservationTypeCreateCompletion}
                />
            )}
        </>
    );
};

export default HeaderAction;
