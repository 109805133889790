import React from 'react';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import { CampaignDetailsNavigation } from 'screens/routes';
import CreateCampaignDialog from 'components/CreateCampaignDialog';
import { PlusIcon } from 'components/Icons';
import styles from './campaign.module.scss';

const HeaderAction: React.FC = () => {
    const { push: navigate } = useNavigation();
    const [showCreateCampaign, setShowCreateCampaign] = React.useState(false);

    const handleAddClick = () => {
        setShowCreateCampaign(true);
    };

    const handleCampaignCreateCompletion = (campaignId: number) => {
        navigate(CampaignDetailsNavigation(campaignId));
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={handleAddClick}
            >
                Ajouter une tournée
            </Button>
            {showCreateCampaign && (
                <CreateCampaignDialog
                    onClose={() => setShowCreateCampaign(false)}
                    onCompletion={handleCampaignCreateCompletion}
                />
            )}
        </>
    );
};

export default HeaderAction;
