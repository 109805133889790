import React from 'react';
import * as Ark from '@dateam/ark';
import { useBioTypes } from 'data/bio';
import {
    QueryResult,
    queryResultKeys
} from '../constants';

export type BioTypeSearchFilter = {
    label: string | undefined;
};

export const useBioTypeSearch = (
    filter: BioTypeSearchFilter,
    options?: DataOptions
): QueryResult<App.BioType[]> => {
    const { data: bioTypes, ...queryResult } = useBioTypes();

    return React.useMemo(() => {
        let filteredData: App.BioType[] = [];

        if (Ark.isArray(bioTypes)) {
            filteredData = [...bioTypes];

            const {
                label: userLabelFilter
            } = filter;

            const filterPredicates: ((dataEntry: App.BioType) => boolean)[] = [];

            if (Ark.isString(userLabelFilter) && userLabelFilter.trim().length > 0) {
                const filter = userLabelFilter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.BioType) =>
                    dataEntry.label.toUpperCase().indexOf(filter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        Ark.orderBy(filteredData, 'label');

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [bioTypes, queryResult, filter]);
};
