import React from 'react';
import propTypes from 'prop-types';
import { useDebounce } from '@dateam/ark-react';
import { plotDetailsPropTypes } from 'data/plot';
import { useSaveObservation } from 'data/observation';
import Observation1 from './Observation1';
import Observation2 from './Observation2';
import Observation3 from './Observation3';
import Observation4 from './Observation4';
import Observation5 from './Observation5';
import Observation6 from './Observation6';
import Observation7 from './Observation7';
import Observation8 from './Observation8';
import Observation9 from './Observation9';
import Observation10 from './Observation10';
import Observation11 from './Observation11';
import Observation12 from './Observation12';
import Observation13 from './Observation13';
import Observation14 from './Observation14';
import Observation15 from './Observation15';
import Observation16 from './Observation16';
import Observation17 from './Observation17';
import Observation18 from './Observation18';
import Observation19 from './Observation19';
import Observation20 from './Observation20';
import Observation21 from './Observation21';
import Observation22 from './Observation22';
import Observation23 from './Observation23';
import Observation24 from './Observation24';
import Observation25 from './Observation25';
import Observation26 from './Observation26';
import Observation27 from './Observation27';
import Observation28 from './Observation28';
import Observation29 from './Observation29';
import Observation30 from './Observation30';
import Observation31 from './Observation31';
import Observation32 from './Observation32';
import Observation33 from './Observation33';
import Observation34 from './Observation34';
import Observation35 from './Observation35';
import Observation36 from './Observation36';
import Observation37 from './Observation37';
import Observation38 from './Observation38';
import Observation39 from './Observation39';
import Observation40 from './Observation40';
import Observation41 from './Observation41';
import Observation42 from './Observation42';
import Observation43 from './Observation43';
import Observation44 from './Observation44';
import Observation45 from './Observation45';
import Observation46 from './Observation46';
import Observation47 from './Observation47';
// import styles from './Observation.module.scss';

type Props = {
    inspectionId: App.InspectionWithRecord['id'];
    data: App.ObservationRecord;
    plot: App.InspectionPlotWithRecord;
};

const Observation: React.FC<Props> = ({
    data,
    inspectionId,
    plot
}) => {
    const { mutateAsync: save } = useSaveObservation();
    const plotId = React.useRef(plot?.id);

    const saveChanges = useDebounce((...args: Parameters<typeof save>): ReturnType<typeof save> => {
        const [changes] = args;

        return save(changes);
    }, 300);

    React.useEffect(() => {
        if (plotId.current !== plot.id) plotId.current = plot.id;
    }, [plot]);

    const handleObservationChange = React.useCallback((newData: App.ObservationRecord) => {
        saveChanges({
            inspectionId,
            plotId: plotId.current,
            observation: newData
        });
    }, [saveChanges, inspectionId, plotId]);

    switch (data.id) {
        case 1: return (<Observation1 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 2: return (<Observation2 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 3: return (<Observation3 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 4: return (<Observation4 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 5: return (<Observation5 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 6: return (<Observation6 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 7: return (<Observation7 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 8: return (<Observation8 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 9: return (<Observation9 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 10: return (<Observation10 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 11: return (<Observation11 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 12: return (<Observation12 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 13: return (<Observation13 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 14: return (<Observation14 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 15: return (<Observation15 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 16: return (<Observation16 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 17: return (<Observation17 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 18: return (<Observation18 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 19: return (<Observation19 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 20: return (<Observation20 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 21: return (<Observation21 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 22: return (<Observation22 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 23: return (<Observation23 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 24: return (<Observation24 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 25: return (<Observation25 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 26: return (<Observation26 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 27: return (<Observation27 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 28: return (<Observation28 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 29: return (<Observation29 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 30: return (<Observation30 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 31: return (<Observation31 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 32: return (<Observation32 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 33: return (<Observation33 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 34: return (<Observation34 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 35: return (<Observation35 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 36: return (<Observation36 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 37: return (<Observation37 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 38: return (<Observation38 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 39: return (<Observation39 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 40: return (<Observation40 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 41: return (<Observation41 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 42: return (<Observation42 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 43: return (<Observation43 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 44: return (<Observation44 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 45: return (<Observation45 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 46: return (<Observation46 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
        case 47: return (<Observation47 data={data} plot={plot} onChange={handleObservationChange} ignorable />);
    }

    return null;
};

Observation.propTypes = {
    data: propTypes.any.isRequired,
    inspectionId: propTypes.number.isRequired,
    plot: plotDetailsPropTypes.isRequired
};

Observation.defaultProps = {};

export default Observation;
