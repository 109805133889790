import React from 'react';
import * as Ark from '@dateam/ark';
import { useTracks } from 'data/track';
import {
    QueryResult,
    queryResultKeys
} from '../constants';

export const useTrackSearch = (filter: string, options?: DataOptions): QueryResult<App.Track[]> => {
    const { data: tracks, ...queryResult } = useTracks();

    return React.useMemo(() => {
        let filteredData: App.Track[] = [];

        if (Ark.isArray(tracks)) {
            filteredData = [...tracks];

            const filterPredicates: ((dataEntry: App.Track) => boolean)[] = [];

            if (Ark.isString(filter) && filter.trim().length > 0) {
                const trackFilter = filter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.Track) =>
                    dataEntry.label.toUpperCase().indexOf(trackFilter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        Ark.orderBy(filteredData, 'label');

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [tracks, queryResult, filter]);
};
