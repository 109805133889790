import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import * as Ark from '@dateam/ark';
import { Card } from 'shared-ui';
import { getInspectionStatus } from 'data/inspection';
import {
    ProgressStep,
    ProgressStepBody,
    ProgressStepTitle
} from 'components/ProgressStep';
import styles from '../../../InspectionEditScreen.module.scss';

type Props = {
    data: Omit<App.InspectionDetails, 'observations' | 'plots'>;
    className?: string;
};

const TimelinePanel: React.FC<Props> = ({ data, className }) => {
    const { t } = useTranslation();

    const inspectionStatus = getInspectionStatus(data);
    const noDateSteps = ['planned', 'notplanned'];

    const formatDate = React.useCallback((date: Date | null) => {
        if (Ark.isValidDate(date)) return Ark.dateToFormat(date, 'dd/MM/yyyy HHhmm');

        return '';
    }, []);

    const isValidatedStep = inspectionStatus === 'validated';
    const isInProgressSetp = inspectionStatus === 'inprogress';
    const isDownloadedStep = inspectionStatus === 'downloaded';
    const isPlannedStep = noDateSteps.indexOf(inspectionStatus) >= 0;

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>
                    État
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <ProgressStep
                    color={isValidatedStep ? 'primary' : undefined}
                    disable={!isValidatedStep}
                    variant={isValidatedStep ? 'plain' : 'border'}
                    className={concatClassName(isValidatedStep ? styles['step-focus'] : null)}
                >
                    <ProgressStepTitle>Validée</ProgressStepTitle>
                    {Ark.isValidDate(data.validationDate && (
                        <ProgressStepBody className={styles['stepContent']}>
                            <div className={styles['stepDate']}>{formatDate(data.validationDate)}</div>
                        </ProgressStepBody>
                    ))}
                </ProgressStep>
                <ProgressStep
                    color={isInProgressSetp || isValidatedStep ? 'primary' : undefined}
                    disable={!(isInProgressSetp || isValidatedStep)}
                    variant={isInProgressSetp ? 'plain' : 'border'}
                    className={concatClassName(isInProgressSetp ? styles['step-focus'] : null)}
                >
                    <ProgressStepTitle className={styles['stepTitle']}>En cours</ProgressStepTitle>
                    <ProgressStepBody className={styles['stepContent']}>
                        {data.validatedPlotCount > 0 && (
                            <>
                                <div className={styles['stepDate']}>{formatDate(data.syncDate)}</div>
                                <div className={styles['stepDate']}>{data.validatedPlotCount}/{t('inspection.plot', { count: data.plotCount })}</div>
                            </>
                        )}
                    </ProgressStepBody>
                </ProgressStep>
                <ProgressStep
                    color={isDownloadedStep || isInProgressSetp || isValidatedStep ? 'primary' : undefined}
                    disable={!(isDownloadedStep || isInProgressSetp || isValidatedStep)}
                    variant={isDownloadedStep ? 'plain' : 'border'}
                    className={concatClassName(isDownloadedStep ? styles['step-focus'] : null)}
                >
                    <ProgressStepTitle>Récupérée</ProgressStepTitle>
                    <ProgressStepBody className={styles['stepContent']}>
                        <div className={styles['stepDate']}>{formatDate(data.firstSyncDate)}</div>
                    </ProgressStepBody>
                </ProgressStep>
                <ProgressStep
                    color="primary"
                    variant={isPlannedStep ? 'plain' : 'border'}
                    className={concatClassName(isPlannedStep ? styles['step-focus'] : null)}
                >
                    <ProgressStepTitle>
                        {inspectionStatus === 'notplanned' ? 'Non planifiée' : 'Planifiée'}
                    </ProgressStepTitle>
                </ProgressStep>
            </Card.Body>
        </Card>
    );
};

TimelinePanel.propTypes = {
    className: propTypes.string
};

TimelinePanel.defaultProps = {
    className: undefined
};

export default TimelinePanel;
