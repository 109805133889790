import React from 'react';
import { isValidDate, dateToFormat } from '@dateam/ark';
import { propTypeNullable } from '@dateam/ark-react';

type Props = {
    date: Date | null;
};

const ColumnDate: React.FC<Props> = ({ date }: Props) => {
    const formattedDate = React.useMemo(() => {
        if (isValidDate(date)) return dateToFormat(date);

        return '-';
    }, [date]);

    return (
        <>
            {formattedDate}
        </>
    );
};

ColumnDate.propTypes = {
    date: propTypeNullable(isValidDate)
};

ColumnDate.defaultProps = {
    date: undefined
};

export default ColumnDate;
