import React from 'react';
import * as Ark from '@dateam/ark';
import { useMutation, useQuery } from 'react-query';
import propTypes from 'prop-types';
import { assertIsArray, computeOptions, isBoolean, isString, isValidDate, isValidNumber, ServiceError } from '@dateam/ark';
import { propTypeNullable, usePick } from '@dateam/ark-react';
import { isRefLabelNumber, refLabelNumberPropTypes, refLabelStringPropTypes } from 'utils/propTypes';
import { queryClient } from 'utils/queryClient';
import { useYearState } from 'utils/yearStore';
import { ObservationRecord } from 'data/requests/inspectionRequests';
import { getInspectionRecordsState, setInspectionRecordsState } from 'utils/inspectionRecordsStore';
import { map as mapObservations } from 'data/observation';
import {
    INSPECTION_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    mutateResultKeys,
    INSPECTION_DETAILS_KEY,
    INSPECTION_RECORD_KEY,
    CAMPAIGN_KEY,
    INSPECTION_REPORT_KEY,
    defaultActionOptions,
    SUMMARY_KEY,
    CAMPAIGN_DETAILS_KEY,
    INSPECTION_ALL_REPORT_KEY
} from '../constants';
import { inspectionRequests } from '../requests';
import { resetInspectionRecords } from './helpers';

export * from './helpers';

export const useInspection = (id: number, options?: DataOptions): QueryResult<App.InspectionDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.InspectionDetails, ServiceError>({
        ...config,
        queryKey: [year, INSPECTION_DETAILS_KEY, id],
        queryFn: () => inspectionRequests.get(year, id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useInspectionRecord = (id: number, options?: DataOptions): QueryResult<App.InspectionWithRecord> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.InspectionWithRecord, ServiceError>({
        ...config,
        queryKey: [year, INSPECTION_DETAILS_KEY, id, INSPECTION_RECORD_KEY],
        queryFn: () => inspectionRequests.getInspectionRecord(year, id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useInspections = (options?: DataOptions): QueryResult<App.Inspection[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Inspection[], ServiceError>({
        ...config,
        queryKey: [year, INSPECTION_KEY],
        queryFn: () => inspectionRequests.getAll(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCampaignInspections = (campaignId: number, options?: DataOptions): QueryResult<App.Inspection[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Inspection[], ServiceError>({
        ...config,
        queryKey: [year, CAMPAIGN_KEY, CAMPAIGN_DETAILS_KEY, campaignId, INSPECTION_KEY],
        queryFn: () => inspectionRequests.getByCampaign(year, campaignId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useInspectionReports = (id: App.Inspection['id'], options?: DataOptions): QueryResult<App.InspectionReport[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.InspectionReport[], ServiceError>({
        ...config,
        queryKey: [year, INSPECTION_DETAILS_KEY, id, INSPECTION_REPORT_KEY],
        queryFn: () => inspectionRequests.getInspectionReports(year, id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useReport = (id: App.Inspection['id'], reportId: App.InspectionReport['id'], options?: DataOptions): QueryResult<App.Report.ReportDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Report.ReportDetails, ServiceError>({
        ...config,
        queryKey: [year, INSPECTION_DETAILS_KEY, id, INSPECTION_REPORT_KEY, reportId],
        queryFn: () => inspectionRequests.getInspectionReport(year, id, reportId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useGetAllReport = (customerId?: number, options?: DataOptions): QueryResult<App.Report.Report[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Report.Report[], ServiceError>({
        ...config,
        queryKey: [year, INSPECTION_ALL_REPORT_KEY, customerId],
        queryFn: () => {
            if (customerId == null) return [];
            return inspectionRequests.getAllInspectionReport(year, customerId);
        }
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateInspection = (): MutationResult<number, App.CreateInspectionRequest> => {
    const [year] = useYearState();
    const result = useMutation<number, ServiceError, App.CreateInspectionRequest>(
        ({ campaignId, ...requestData }: App.CreateInspectionRequest) =>
            inspectionRequests.createInspection(year, campaignId, { ...requestData }),
        {
            ...defaultActionOptions,
            onSuccess: (_, { campaignId }) => {
                queryClient.invalidateQueries([year, INSPECTION_KEY]);
                queryClient.invalidateQueries([year, CAMPAIGN_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateInspection = (): MutationResult<void, App.UpdateInspectionRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdateInspectionRequest>(
        (request: App.UpdateInspectionRequest) => {
            const { id, ...props } = request;

            return inspectionRequests.updateInspection(year, id, { ...props });
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([year, INSPECTION_KEY]);
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, CAMPAIGN_KEY]);
                queryClient.invalidateQueries([year, SUMMARY_KEY]);
            }
        }
    );

    return result;
};

export const useDeleteInspection = (): MutationResult<void, number> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, number>(
        id => inspectionRequests.deleteInspection(year, id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, INSPECTION_KEY]);
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, CAMPAIGN_KEY]);
                queryClient.invalidateQueries([year, SUMMARY_KEY]);
            }
        }
    );

    return result;
};

export const useSaveInspectionRecord = (): MutationResult<number> => {
    const [year] = useYearState();
    const result = useMutation<number, ServiceError>(
        async () => {
            const inspectionRecords = getInspectionRecordsState();
            if (inspectionRecords === null) throw new Error('');

            await pushData(inspectionRecords);

            return inspectionRecords.id;
        },
        {
            ...defaultActionOptions,
            onSuccess: id => {
                setInspectionRecordsState(prevState => {
                    if (prevState != null) return resetInspectionRecords(prevState);

                    return prevState;
                });

                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, id, INSPECTION_RECORD_KEY]);
            }
        }
    );

    return result;
};

export const useValidateInspection = (): MutationResult<void, App.InspectionWithRecord['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.InspectionWithRecord['id']>(
        (id: App.InspectionWithRecord['id']) => inspectionRequests.validateInspections([{
            inspectionId: id,
            validationDate: new Date()
        }]),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, SUMMARY_KEY]);
            }
        }
    );

    return result;
};

type SaveReportRequest = {
    inspectionId: App.Inspection['id'];
    report: App.Report.ReportDetails;
};

export const useSaveInspectionReport = (): MutationResult<number, SaveReportRequest> => {
    const [year] = useYearState();
    const result = useMutation<number, ServiceError, SaveReportRequest>(
        async ({ inspectionId, report }: SaveReportRequest) =>
            inspectionRequests.saveInspectionReport(year, inspectionId, report),
        {
            ...defaultActionOptions,
            onSuccess: (_, { inspectionId }) => {
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, inspectionId, INSPECTION_REPORT_KEY]);
            }
        }
    );

    return result;
};

type RefreshReportRecordsRequest = {
    inspectionId: App.Inspection['id'];
    reportId: App.Report.ReportDetails['id'];
};

export const useRefreshReportRecords = (): MutationResult<void, RefreshReportRecordsRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, RefreshReportRecordsRequest>(
        async ({ inspectionId, reportId }: RefreshReportRecordsRequest) =>
            inspectionRequests.refreshReportRecords(year, inspectionId, reportId),
        {
            ...defaultActionOptions,
            onSuccess: (_, { inspectionId }) => {
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, inspectionId, INSPECTION_REPORT_KEY]);
            }
        }
    );

    return result;
};

type SendReportRequest = {
    inspectionId: App.Inspection['id'];
    reportId: App.Report.ReportDetails['id'];
};

export const useSendInspectionReport = (): MutationResult<void, SendReportRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, SendReportRequest>(
        async ({ inspectionId, reportId }: SendReportRequest) =>
            inspectionRequests.sendReport(year, inspectionId, reportId),
        {
            ...defaultActionOptions,
            onSuccess: (_, { inspectionId }) => {
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, inspectionId, INSPECTION_REPORT_KEY]);
                queryClient.invalidateQueries([year, INSPECTION_ALL_REPORT_KEY]);
            }
        }
    );

    return result;
};

const pushData = async (inspectionSync: App.InspectionWithRecordSync): Promise<void> => {
    assertIsArray(inspectionSync.plots, 'activity doesn\'t match expected format (`inspections[].plots`).');

    const observationRecords: ObservationRecord[] = [];
    const obsComments: App.SaveObservationCommentRequest[] = [];

    if (inspectionSync.syncState === 'none') return;

    inspectionSync.plots.forEach(plot => {
        assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');

        if (plot.readyForSync === false) return;

        plot.observations.forEach(observation => {
            if (isValidDate(observation.updatedOn)) {
                observationRecords.push({
                    observation: mapObservations(observation),
                    inspectionId: inspectionSync.id,
                    plotId: plot.id
                });
            }
        });

        obsComments.push({
            inspectionId: inspectionSync.id,
            plotId: plot.id,
            observationComment: plot.observationComment
        });
    });

    if (observationRecords.length > 0) await inspectionRequests.saveInspectionRecord(observationRecords);
    if (obsComments.length > 0) await inspectionRequests.savePlotObsComment(obsComments);
};

export const useUpdateInspectionObs = (): MutationResult<void, App.UpdateInspectionObsRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdateInspectionObsRequest>(
        (request: App.UpdateInspectionObsRequest) => {
            const { id, ...props } = request;

            return inspectionRequests.updateInspectionObservations(year, id, { ...props });
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([year, INSPECTION_KEY]);
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};

export const useUpdateInspectionPlot = (): MutationResult<void, App.UpdateInspectionPlotRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdateInspectionPlotRequest>(
        (request: App.UpdateInspectionPlotRequest) => {
            const { id, ...props } = request;

            return inspectionRequests.updateInspectionPlots(year, id, { ...props });
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([year, INSPECTION_KEY]);
                queryClient.invalidateQueries([year, INSPECTION_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};

export const inspectionPlotPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    year: propTypes.number.isRequired,
    activityId: propTypes.number.isRequired,
    publicId: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    position: propTypes.number.isRequired,
    varietal: propTypeNullable(isRefLabelNumber),
    customer: refLabelNumberPropTypes.isRequired,
    area: propTypeNullable(isRefLabelNumber),
    bio: refLabelNumberPropTypes.isRequired,
    city: propTypes.string.isRequired,
    confusion: propTypeNullable(isBoolean),
    observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    validationDate: propTypeNullable(isValidDate)
});

export const inspectionDetailsPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    campaign: refLabelNumberPropTypes.isRequired,
    label: propTypes.string.isRequired,
    instruction: propTypeNullable(isString),
    startDate: propTypeNullable(isValidDate),
    overlapDays: propTypeNullable(isValidNumber),
    assigneeUser: propTypeNullable(isRefLabelNumber),
    writerUser: propTypeNullable(isRefLabelNumber),
    creationDate: propTypes.instanceOf(Date).isRequired,
    updateDate: propTypeNullable(isValidDate),
    observations: propTypes.arrayOf(propTypes.any.isRequired).isRequired,
    plots: propTypes.arrayOf(inspectionPlotPropTypes),
    firstSyncDate: propTypeNullable(isValidDate),
    syncDate: propTypeNullable(isValidDate),
    observationCount: propTypes.number.isRequired,
    plotCount: propTypes.number.isRequired,
    validatedPlotCount: propTypes.number.isRequired,
    validationDate: propTypeNullable(isValidDate)
});

export const inspectionReportPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    customer: refLabelNumberPropTypes.isRequired,
    plotCount: propTypes.number.isRequired,
    generationDate: propTypeNullable(isValidDate),
    sendDate: propTypeNullable(isValidDate),
    updatedOn: propTypeNullable(isValidDate),
    downloadLink: propTypes.string.isRequired
});

export const reportPlotPropTypes = propTypes.shape({
    id: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    values: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
    previous: propTypes.arrayOf(propTypes.string.isRequired).isRequired
});

export const reportObservationsPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    label: propTypes.string.isRequired,
    position: propTypes.number.isRequired,
    modelComment: propTypeNullable(isString),
    comment: propTypeNullable(isString),
    includeValues: propTypes.bool.isRequired,
    includePrevious: propTypes.bool.isRequired,
    manualEdit: propTypes.bool.isRequired,
    records: propTypes.shape({
        headers: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
        plots: propTypes.arrayOf(reportPlotPropTypes.isRequired).isRequired,
        additionalData: propTypes.arrayOf(propTypes.shape({
            label: propTypes.string.isRequired,
            values: propTypes.arrayOf(propTypes.string.isRequired).isRequired
        }).isRequired).isRequired
    }).isRequired
});

export const reportDetailsPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    inspectionId: propTypes.number.isRequired,
    inspectionLabel: propTypes.string.isRequired,
    inspectionDate: propTypes.instanceOf(Date).isRequired,
    customer: refLabelNumberPropTypes.isRequired,
    customerHeader: propTypes.string.isRequired,
    plotCount: propTypes.number.isRequired,
    modelHeadline: propTypeNullable(isString),
    modelNextInspection: propTypeNullable(isString),
    includeNextInspection: propTypes.bool.isRequired,
    headline: propTypeNullable(isString),
    generationDate: propTypeNullable(isValidDate),
    sendDate: propTypeNullable(isValidDate),
    updatedOn: propTypeNullable(isValidDate),
    observatorUser: propTypeNullable(isValidNumber),
    writerUser: propTypeNullable(isValidNumber),
    observations: propTypes.arrayOf(reportObservationsPropTypes.isRequired).isRequired,
    creationDate: propTypes.instanceOf(Date).isRequired,
    creationUserId: propTypeNullable(isValidNumber),
    updateUserId: propTypeNullable(isValidNumber)
});

export const reportPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    inspectionId: propTypes.number.isRequired,
    inspectionLabel: propTypes.string.isRequired,
    inspectionDate: propTypes.instanceOf(Date).isRequired,
    customer: refLabelNumberPropTypes.isRequired,
    plotCount: propTypes.number.isRequired,
    generationDate: propTypeNullable(isValidDate),
    sendDate: propTypeNullable(isValidDate),
    creationDate: propTypes.instanceOf(Date).isRequired,
    updatedOn: propTypeNullable(isValidDate),
    creationUserId: propTypeNullable(isValidNumber),
    updateUserId: propTypeNullable(isValidNumber),
    downloadLink: propTypes.string.isRequired
});
