import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
    date?: Date;
    children?: ReactNode;
}

const ProgressStepBody: React.FC<Props> = ({
    className,
    date,
    children
}: Props) => (
    <div className={concatClassName('', className)}>
        {children}
    </div>
);

ProgressStepBody.propTypes = {
    date: propTypes.instanceOf(Date),
    className: propTypes.string,
    children: propTypes.node
};

ProgressStepBody.defaultProps = {
    date: undefined,
    className: undefined,
    children: undefined
};

export default ProgressStepBody;