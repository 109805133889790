import React from 'react';
import { plotPropTypes } from './types';

type Props = {
    data: App.Plot;
};

const ColumnName: React.FC<Props> = ({ data }: Props) => (
    <>
        <div className="table-text-secondary">
            {data.publicId}
        </div>
        <div>
            {data.label}
        </div>
    </>
);

ColumnName.propTypes = {
    data: plotPropTypes.isRequired
};

export default ColumnName;
