import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { formatDate, isArray, isValidDate, orderBy } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import Page from 'components/Page';
import {
    Card,
    Table,
    TableRowClickEvent,
    Loader
} from 'shared-ui';
import { useUserState } from 'utils/userStore';
import { useCampaigns } from 'data/campaign';
import { CampaignNavigation, CampaignDetailsNavigation } from 'screens/routes';
import HeaderAction from './HeaderAction';
import CampaignBreadcrumb from './CampaignBreadcrumb';
import styles from './campaign.module.scss';

type CampaignTableItem = App.Campaign;

const CampaignScreen: React.FC = () => {
    useBindYearNavigation(CampaignNavigation, []);
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const { data: campaigns, isLoading: isCampaignLoading } = useCampaigns();
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';
    
    const tableData = React.useMemo(() => {
        if (!isArray(campaigns)) return [];

        const data = [...campaigns];

        orderBy(data, 'creationDate', { ascending: false });

        return data;
    }, [campaigns]);

    const handleRowClick = (event: TableRowClickEvent<App.Campaign>) => {
        navigate(CampaignDetailsNavigation(event.data.id));
    };

    return (
        <>
            <Helmet>
                <title>{t('campaign.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Tournées
                    </Page.Title>
                    <Page.HeaderActions>
                        <CampaignBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        {isCampaignLoading && (
                            <Loader text="chargement" />
                        )}
                        <Card.Header className={concatClassName(displayActions ? 'withAction' : null, styles['header'])}>
                            <Card.Title>
                                Liste des tournées
                            </Card.Title>
                            {displayActions && (<HeaderAction />)}
                        </Card.Header>
                        <Card.Body className="row">
                            <div className={styles['tableSection']}>
                                <Table<CampaignTableItem>
                                    data={tableData}
                                    onRowClick={handleRowClick}
                                    className={styles['tableRow']}
                                >
                                    <Table.Column accessor="label" className={styles['colName']}>
                                        <Table.Column.Header>Nom</Table.Column.Header>
                                    </Table.Column>
                                    <Table.Column accessor="inspectionCount" className={styles['colPlanned']}>
                                        <Table.Column.Header>Visites planifiées</Table.Column.Header>
                                    </Table.Column>
                                    <Table.Column accessor="inspectionValidatedCount" className={styles['colValidated']}>
                                        <Table.Column.Header>Visites validées</Table.Column.Header>
                                    </Table.Column>
                                    <Table.Column className={styles['colCreation']}>
                                        <Table.Column.Header>Date de création</Table.Column.Header>
                                        <Table.Column.Cell>
                                            {(props: any) => (<> {formatDate(props.data.creationDate)} </>)}
                                        </Table.Column.Cell>
                                    </Table.Column>
                                    <Table.Column className={styles['colFirstPlanned']}>
                                        <Table.Column.Header>Première visite planifiée</Table.Column.Header>
                                        <Table.Column.Cell>
                                            {(props: any) => (<> {isValidDate(props.data.firstPlannedDate) ? formatDate(props.data.firstPlannedDate) : '-'} </>)}
                                        </Table.Column.Cell>
                                    </Table.Column>
                                    <Table.Column className={styles['colLastPlanned']}>
                                        <Table.Column.Header>Dernière visite planifiée</Table.Column.Header>
                                        <Table.Column.Cell>
                                            {(props: any) => (<> {isValidDate(props.data.lastPlannedDate) ? formatDate(props.data.lastPlannedDate) : '-'} </>)}
                                        </Table.Column.Cell>
                                    </Table.Column>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(CampaignScreen, 'CAMPAIGN');