import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isArray } from '@dateam/ark';
import { Button } from 'shared-ui';
import { CrossIcon } from 'components/Icons';
import { useObservations } from 'data/observation';
import styles from './SelectedObservations.module.scss';

type Props = {
    observations?: number[];
    className?: string;
    onRemoveObservation?: (id: number) => void;
};

const SelectedObservations: React.FC<Props> = ({
    observations,
    className,
    onRemoveObservation
}: Props) => {
    const { data: observationData } = useObservations();

    const selectedObservations = React.useMemo<RefLabel[]>(() => {
        if (!isArray(observations) || !isArray(observationData)) return [];

        return observationData.filter(obs => observations.includes(obs.id))
            .map(obs => ({ id: obs.id, label: obs.label }));
    }, [observationData, observations]);

    const removeObservation = React.useCallback((id: number) => {
        onRemoveObservation?.(id);
    }, [onRemoveObservation]);

    return (
        <ul className={concatClassName(styles['list'], className)}>
            {selectedObservations?.map(observation => (
                <li key={observation.id} className={styles['item']}>
                    {observation.label}
                    <Button
                        onClick={() => removeObservation(observation.id)}
                        className={styles['delete']}
                        endIcon={<CrossIcon />}
                        size="xs"
                    >
                    </Button>
                </li>
            ))}
        </ul>
    );
};

SelectedObservations.propTypes = {
    observations: propTypes.arrayOf(propTypes.number.isRequired),
    className: propTypes.string,
    onRemoveObservation: propTypes.func
};

SelectedObservations.defaultProps = {
    observations: undefined,
    className: undefined,
    onRemoveObservation: undefined
};

export default SelectedObservations;
