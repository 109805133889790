import React from 'react';
import { propTypeNullable } from '@dateam/ark-react';
import { isBoolean } from '@dateam/ark';

type Props = {
    data: App.Plot['confusion'];
};

const ColumnConfusion: React.FC<Props> = ({ data }: Props) => (
    <div>
        {isBoolean(data) ? (data ? 'Oui' : 'Non') : '-'}
    </div>
);

ColumnConfusion.propTypes = {
    data: propTypeNullable(isBoolean)
};

ColumnConfusion.defaultProps = {
    data: undefined
};

export default ColumnConfusion;
