import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { insertAt, isDefined, orderBy, removeAt, removeFromCollection, ServiceError } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { Button, Card, ConfirmDialog, Form, Loader } from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import { useUserState } from 'utils/userStore';
import config from 'config';
import { TrackNavigation, TrackDetailsNavigation } from 'screens/routes';
import Page from 'components/Page';
import { PlotList } from 'components/PlotList';
import { TrashIcon } from 'components/Icons';
import ListSection from 'components/ListSection';
import { errorProvider } from 'components/errorHandler';
import { useDeleteTrack, useTrackDetails, useUpdateTrack } from 'data/track';
import { TrackDetailsHeader } from './components';
import TrackDetailsBreadcrumb from './TrackDetailsBreadcrumb';
import styles from './TrackDetailsScreen.module.scss';

const TrackDetailsScreen: React.FC = () => {
    const { t } = useTranslation();
    const {
        push: navigate,
        query: { trackId: trackIdParam },
        replace: replaceNavigation
    } = useNavigation();
    const trackId = React.useMemo(() => parseInt(trackIdParam, 10), [trackIdParam]);
    useBindYearNavigation(TrackDetailsNavigation, [trackId]);
    const { data: track, isFetched: hasLoaded, isLoading } = useTrackDetails(trackId);
    const { mutateAsync: updateTrack, isLoading: isUpdating } = useUpdateTrack();
    const { mutateAsync: deleteTrack, isLoading: isDeleting } = useDeleteTrack();
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';
    const [plots, setPlots] = React.useState<App.TrackDetails['plots']>([]);
    const [showDeletionConfirm, setShowDeletionConfirm] = React.useState(false);

    React.useEffect(() => {
        if (track == null && hasLoaded) {
            replaceNavigation(TrackNavigation());
        }
    }, [track, hasLoaded, replaceNavigation]);

    React.useEffect(() => {
        if (isDefined(track)) {
            const plots = [...track.plots];
            orderBy(plots, 'position');
            setPlots(plots);
        }
        else setPlots([]);
    }, [track, setPlots]);

    const saveChanges = React.useCallback((...args: Parameters<typeof updateTrack>): ReturnType<typeof updateTrack> => {
        return updateTrack(...args);
    }, [updateTrack]);

    const addPlot = React.useCallback((plotIds: number[]) => {
        if (!isDefined(track)) return;

        const { id } = track;

        const updatedPlots: App.PlotOrder[] = [...plots.map(({ id, position }) => ({ id, position }))];
        const lastPlot = updatedPlots[updatedPlots.length - 1];
        const lastPosition = (lastPlot?.position ?? 0) + 1;

        const currentPlotIds = updatedPlots.map(plot => plot.id); // TODO: Update with clean Unique filter
        const newPlots = plotIds
            .filter(id => currentPlotIds.indexOf(id) < 0)
            .map((id, idx) => ({ id, position: lastPosition + idx }));

        updatedPlots.push(...newPlots);

        saveChanges({ id, year: track.year, label: track.label, plots: updatedPlots });
    }, [track, plots, saveChanges]);

    const removePlot = React.useCallback((plot: App.InspectionPlot) => {
        if (!isDefined(track)) return;

        const { position: plotPosition } = plot;

        const updatedPlots = [...plots];
        removeAt(updatedPlots, plotPosition - 1);

        updatedPlots.forEach((plot, idx) => {
            if (idx >= plotPosition - 1) plot.position -= 1;
        });

        saveChanges({
            id: track.id,
            year: track.year,
            label: track.label,
            plots: updatedPlots.map(({ id, position }) => ({
                id,
                position
            }))
        });
    }, [track, plots, saveChanges]);

    const handlePositionChange = React.useCallback((plot: ArrayType<App.TrackDetails['plots']>, position: number): void => {
        if (!isDefined(track)) return;

        setPlots(plots => {
            let newPlots = [...plots];

            removeFromCollection(newPlots, item => item.id === plot.id);
            insertAt(newPlots, position - 1, plot);

            newPlots = newPlots.map((plot, idx) => {
                plot.position = idx + 1;

                return plot;
            });
            orderBy(newPlots, 'position');

            saveChanges({
                id: track.id,
                year: track.year,
                label: track.label,
                plots: newPlots
            });

            return newPlots;
        });
    }, [track, setPlots, saveChanges]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: string) => {
        if (!isDefined(track)) throw new Error('Le circuit n\'a pas été trouvé.');

        try {
            const {
                id,
                year,
                label,
                plots
            } = track;

            const mappedPlots = plots.map(plot => ({ id: plot.id, position: plot.position }));

            saveChanges({
                id,
                year,
                label,
                plots: mappedPlots,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }

        return undefined;
    }, [track, saveChanges]);

    const handleTrackDeletion = React.useCallback(async (shouldDelete: boolean) => {
        if (shouldDelete === false) return setShowDeletionConfirm(false);
        if (!isDefined(track)) throw new Error('Le circuit n\'a pas été trouvé.');

        await deleteTrack(track.id);

        setShowDeletionConfirm(false);
        navigate(TrackNavigation());
    }, [track, deleteTrack, navigate, setShowDeletionConfirm]);

    if (!isDefined(track)) return null;

    return (
        <>
            {(isLoading || !isDefined(track)) && (
                <Loader text="chargement" />
            )}
            <Helmet>
                <title>{t('track.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {track?.label}
                    </Page.Title>
                    <Page.HeaderActions>
                        <TrackDetailsBreadcrumb label={track.label} />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                            <Card.Title>
                                Configuration
                            </Card.Title>
                            {displayActions && (
                                <TrackDetailsHeader onPlotAdded={addPlot} />
                            )}
                        </Card.Header>
                        <Card.Body>
                            <ListSection>
                                <ListSection.Filter>
                                    <Form.Group controlId="track-name">
                                        <Form.Label>Nom du circuit</Form.Label>
                                        <Form.Editable.Input maxlength={50} value={track.label} onSave={handleSaveInput('label')} />
                                    </Form.Group>
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <PlotList
                                        data={plots}
                                        onRemovePlot={removePlot}
                                        onPositionChange={handlePositionChange}
                                    />
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                        <Card.Footer>
                            {displayActions && (
                                <Card.Actions>
                                    <Button
                                        color="danger"
                                        variant="outlined"
                                        startIcon={(<TrashIcon />)}
                                        disabled={isUpdating || isDeleting}
                                        pending={isDeleting}
                                        onClick={() => setShowDeletionConfirm(true)}
                                    >
                                        Supprimer le circuit
                                    </Button>
                                    {showDeletionConfirm && (
                                        <ConfirmDialog
                                            title={`Supprimer le circuit "${track.label}"`}
                                            message="Vous êtes sur le point de supprimer ce circuit. Êtes-vous sûr de vouloir continuer ?"
                                            ok="Supprimer"
                                            onClose={handleTrackDeletion}
                                        />
                                    )}
                                </Card.Actions>
                            )}
                        </Card.Footer>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(TrackDetailsScreen, 'TRACK_DETAILS');