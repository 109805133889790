import React, { ReactNode } from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { HomeIcon } from 'components/Icons';
import BreadcrumbItem from './BreadcrumbItem';
import styles from './Breadcrumb.module.scss';

type Props = {
    to?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<any>) => void;
}

const BreadcrumbItemHome: React.FC<Props> = ({
    to,
    className,
    onClick
}: Props) => (
    <BreadcrumbItem
        className={concatClassName(styles['home'], className)}
        to={to}
        onClick={onClick}
    >
        <HomeIcon />
    </BreadcrumbItem>
);

BreadcrumbItemHome.propTypes = {
    to: propTypes.string,
    className: propTypes.string,
    onClick: propTypes.func
};

BreadcrumbItemHome.defaultProps = {
    to: undefined,
    className: undefined,
    onClick: undefined
};

export default BreadcrumbItemHome;