import React from 'react';
import propTypes from 'prop-types';
import { propTypeNullable } from '@dateam/ark-react';
import { isDefined, isValidDate, isValidNumber } from '@dateam/ark';
import { isRefLabelNumber, refLabelNumberPropTypes } from 'utils/propTypes';

type Props = {
    data: App.Inspection;
};

const AssigneeColumn: React.FC<Props> = ({
    data
}: Props) => {
    if (!isDefined(data.assigneeUser)) return null;
    return (<>{data.assigneeUser.label}</>);
};

AssigneeColumn.propTypes = {
    data: propTypes.shape({
        id: propTypes.number.isRequired,
        campaign: refLabelNumberPropTypes.isRequired,
        label: propTypes.string.isRequired,
        startDate: propTypeNullable(isValidDate),
        overlapDays: propTypeNullable(isValidNumber),
        assigneeUser: propTypeNullable(isRefLabelNumber),
        writerUser: propTypeNullable(isRefLabelNumber),
        syncDate: propTypeNullable(isValidDate),
        firstSyncDate: propTypeNullable(isValidDate),
        observationCount: propTypes.number.isRequired,
        plotCount: propTypes.number.isRequired,
        validatedPlotCount: propTypes.number.isRequired,
        validationDate: propTypeNullable(isValidDate)
    }).isRequired
};

AssigneeColumn.defaultProps = {

};

export default AssigneeColumn;
