import React from 'react';
import { useMutation, useQuery } from 'react-query';
import {
    computeOptions,
    hasProperty,
    isValidDate,
    replaceCollectionItem,
    ServiceError,
    sortByProperty
} from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { getInspectionRecordsState, setInspectionRecordsState } from 'utils/inspectionRecordsStore';
import { queryClient } from 'utils/queryClient';
import { useYearState } from 'utils/yearStore';
import {
    OBSERVATION_KEY,
    OBSERVATION_TYPE_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    mutateResultKeys,
    defaultActionOptions,
    OBSERVATION_TYPE_DETAILS_KEY
} from '../constants';
import { observationRequests } from '../requests';
import { computeInspectionRecords } from '../inspection';
import validate from './validator';

export { default as validate } from './validator';
export * from './validator';
export * from './mapper';

// All orders have a 100 digit offset to be able to insert new observations between existing ones
// Please always insert new observations with a {ID}00 digit offset
// AND for the ones inserted in between, always half the offset (ex: 50, 25, ....) to keep room for more inserts
const OBSERVATIONS = [
    { id: 1, label: 'Stade phénologique', order: 100 },
    { id: 2, label: 'N-Tester', order: 200 },
    { id: 3, label: 'Entretien du sol', order: 300 },
    { id: 4, label: '% surface enherbée', order: 400 },
    { id: 5, label: 'Adventices dominantes', order: 500 },
    { id: 6, label: 'Adventices secondaires', order: 600 },
    { id: 7, label: 'Ceps manquants', order: 700 },
    { id: 8, label: 'Entreplants', order: 800 },
    { id: 9, label: 'Accident climatique - gel de printemps', order: 900 },
    { id: 10, label: 'Accident climatique - grêle', order: 1000 },
    { id: 11, label: 'Accident climatique - échaudage', order: 1100 },
    { id: 12, label: 'Chlorose', order: 1200 },
    { id: 13, label: 'Carence Mn', order: 1300 },
    { id: 14, label: 'Carence Mg', order: 1400 },
    { id: 15, label: 'Carence K', order: 1500 },
    { id: 16, label: 'Court Noué', order: 1600 },
    { id: 17, label: 'Enroulement', order: 1700 },
    { id: 18, label: 'Esca / BDA', order: 1800 },
    { id: 44, label: 'Eutypiose', order: 1835 },
    { id: 45, label: 'Excoriose', order: 1865 },
    { id: 19, label: 'Mange-Bourgeons', order: 1900 },
    { id: 20, label: 'Erinose Feuilles', order: 2000 },
    { id: 21, label: 'Erinose Grappes', order: 2100 },
    { id: 22, label: 'Acariose', order: 2200 },
    { id: 23, label: 'Cochenille', order: 2300 },
    { id: 24, label: 'Typhlodromes', order: 2400 },
    { id: 25, label: 'Araignées rouges', order: 2500 },
    { id: 26, label: 'Pyrales feuilles', order: 2600 },
    { id: 27, label: 'Pyrales grappes', order: 2700 },
    { id: 28, label: 'Tordeuses - Confusion', order: 2800 },
    { id: 29, label: 'Tordeuses - Œufs G1', order: 2900 },
    { id: 30, label: 'Tordeuses - Glomérules G1', order: 3000 },
    { id: 31, label: 'Tordeuses - Œufs G2', order: 3100 },
    { id: 32, label: 'Tordeuses - Perforations G2', order: 3200 },
    { id: 33, label: 'Cicadelle Verte', order: 3300 },
    { id: 34, label: 'Cicadelle Flavescence Dorée', order: 3400 },
    { id: 35, label: 'Gribouri / Ecrivain', order: 3500 },
    { id: 36, label: 'Mildiou feuilles', order: 3600 },
    { id: 37, label: 'Mildiou grappes', order: 3700 },
    { id: 46, label: 'Black rot', order: 3750 },
    { id: 38, label: 'Oïdium feuilles', order: 3800 },
    { id: 39, label: 'Oïdium grappes', order: 3900 },
    { id: 40, label: 'Botrytis feuilles', order: 4000 },
    { id: 41, label: 'Botrytis grappes', order: 4100 },
    { id: 42, label: 'Brenner feuilles', order: 4200 },
    { id: 43, label: 'Comptages grappes', order: 4300 },
    { id: 47, label: 'Apex', order: 4700 }
];

export const sortObservations = <T extends { id: number; }[]>(observations: T): T => {
    const sortedArray = [...observations] as T;

    sortByProperty(sortedArray, 'id', (obsA, obsB) => {
        if (obsA === obsB) return 0;
        if (obsA == null) return -1;
        if (obsB == null) return 1;

        const orderA = OBSERVATIONS.find(obs => obs.id === obsA)?.order ?? Infinity;
        const orderB = OBSERVATIONS.find(obs => obs.id === obsB)?.order ?? Infinity;

        return orderA < orderB ? -1 : 1;
    });

    return sortedArray;
};

export const useObservations = (options?: DataOptions): QueryResult<App.Observation[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Observation[], ServiceError>({
        ...config,
        queryKey: [year, OBSERVATION_KEY],
        queryFn: () => observationRequests.get(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useSaveObservation = (): MutationResult<void, App.SaveObservationRequest> => {
    const result = useMutation<void, ServiceError, App.SaveObservationRequest>(
        async ({ plotId, observation }: App.SaveObservationRequest) => {
            try {
                const inspectionRecords = getInspectionRecordsState();

                if (inspectionRecords == null) throw new Error('Observation save: no inspection available, aborted.');
                const inspection = { ...inspectionRecords };

                inspection.plots.forEach(plot => {
                    if (plot.id !== plotId) return;

                    observation.hasChanged = true;
                    // Only N-tester requires to keep a live update date if there is already one
                    if (observation.id === 2 || !isValidDate(observation.updatedOn)) {
                        observation.updatedOn = new Date();
                    }

                    refreshSyncProps(observation, plot);

                    replaceCollectionItem(plot.observations, observation, obs => obs.id === observation.id);
                });

                const computedInspection = computeInspectionRecords(inspection);

                setInspectionRecordsState(computedInspection);
            }
            catch (err) {
                throw new Error('Observation save: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const useSaveObservationComment = (): MutationResult<void, App.SaveObservationCommentRequest> => {
    const result = useMutation<void, ServiceError, App.SaveObservationCommentRequest>(
        async ({ plotId, observationComment }: App.SaveObservationCommentRequest) => {
            try {
                const inspectionRecords = getInspectionRecordsState();

                if (inspectionRecords == null) throw new Error('Observation save: no inspection available, aborted.');
                const inspection = { ...inspectionRecords };

                inspection.plots.forEach(plot => {
                    if (plot.id !== plotId) return;

                    plot.hasChanged = true;
                    plot.observationComment = observationComment;
                });

                const computedInspection = computeInspectionRecords(inspection);

                setInspectionRecordsState(computedInspection);
            }
            catch (err) {
                throw new Error('Observation comment save: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const validateObservation = (observation: App.ObservationRecord, plot: App.InspectionPlotWithRecord): void => {
    if (!hasProperty(observation, 'hasChanged')) observation.hasChanged = false;

    const errors = validate(observation, plot);

    observation.isValid = Object.values(errors).filter(error => error.type === 'error').length === 0;
};

export const refreshSyncProps = (observation: App.ObservationRecord, plot: App.InspectionPlotWithRecord): void => {
    if (!hasProperty(observation, 'isSyncing')) observation.isSyncing = false;
    if (!hasProperty(observation, 'hasChanged')) observation.hasChanged = false;

    const errors = validate(observation, plot);

    observation.isValid = Object.values(errors).filter(error => error.type === 'error').length === 0;
};

export const useUpdateObservationObservationTypes = ():
    MutationResult<void, App.UpdateObservationObservationTypesRequest[]> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdateObservationObservationTypesRequest[]>(
        (request: App.UpdateObservationObservationTypesRequest[]) => {
            return observationRequests.updateObservationObservationTypes(year, request);
        },
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([year, OBSERVATION_KEY]);
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_KEY]);
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_DETAILS_KEY]);
            }
        }
    );

    return result;
};