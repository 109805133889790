import { isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Cicadelle Verte
 ** - symptoms: Symptômes
 **     Liste : ['0', 'm', 'M'], correspondant à 0,faible,fort
 **     Required
 ** - count: Si fort // /25 feuilles
 **     0 <= Value <= 25
 **     Required si symptoms === 'M'
 */

type CurrentObservation = App.Observation33;
type CurrentApiObservation = App.Api.Observation33;

export const SYMPTOM_VALUES = [
    '0',
    'm',
    'M'
];

const checkStrongSymptoms = (symptoms: string) => symptoms === 'M';

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count, symptoms } = observation;

    if (ignored === true) return validation;

    if (symptoms == null || !isString(symptoms)) {
        validation.symptoms = {
            field: 'symptoms',
            type: 'error',
            code: 'required'
        };
    }
    else if (SYMPTOM_VALUES.indexOf(symptoms) < 0) {
        validation.symptoms = {
            field: 'symptoms',
            type: 'error',
            code: 'unknown'
        };
    }
    // If symptoms are strong, the count value is required
    else if (checkStrongSymptoms(symptoms)) {
        // The value must be defined
        if (count == null || !isValidNumber(count)) {
            validation.count = {
                field: 'count',
                type: 'error',
                code: 'required'
            };
        }
        else if (count < 0 || count > 25) {
            validation.count = {
                field: 'count',
                type: 'error',
                code: 'format'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        symptoms,
        count
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.symptoms = parseObservationField(symptoms);
    parsedObs.count = parseObservationField(count);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        symptoms,
        count
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.symptoms = symptoms !== undefined ? new RecordValue(symptoms) : null;
    mappedObs.count = count !== undefined ? new RecordValue(count) : null;

    return mappedObs;
};