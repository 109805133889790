import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isDefined, isValidDate, ServiceError } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import Page from 'components/Page';
import {
    Card,
    Form,
    Button,
    Loader,
    ConfirmDialog
} from 'shared-ui';
import config from 'config';
import { CrossIcon, EditIcon, PlusIcon, TrashIcon } from 'components/Icons';
import { errorProvider } from 'components/errorHandler';
import { FooterDateInfo } from 'components/FooterDateInfo';
import {
    useDeactivateUser,
    useDeleteUser,
    useReactivateUser,
    useRoles,
    useUpdateUser,
    useUser
} from 'data/user';
import { UserNavigation } from 'screens/routes';
import { UpdatePasswordDialog } from './components';
import UserDetailsBreadcrumb from './UserDetailsBreadcrumb';
import styles from './UserDetailsScreen.module.scss';

const UserDetailsScreen: React.FC = () => {
    const { t } = useTranslation();
    const roles = useRoles();
    const { query: { userId: userIdParam }, push: navigate } = useNavigation();
    const { data: user, isLoading } = useUser(parseInt(userIdParam, 10));
    const { mutateAsync: updateUser } = useUpdateUser();
    const { mutateAsync: deactivateUser, isLoading: isDeactivating } = useDeactivateUser();
    const { mutateAsync: reactivateUser, isLoading: isReactivating } = useReactivateUser();
    const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();

    const userState = React.useRef(user);
    const [showUpdatePassword, setShowUpdatePassword] = React.useState(false);
    const [showDeletionConfirm, setShowDeletionConfirm] = React.useState(false);

    React.useEffect(() => {
        userState.current = user;
    }, [user]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: any) => {
        if (!isDefined(userState.current)) throw new Error('L\'utilisateur n\'a pas été trouvé.');

        try {
            const {
                id,
                firstName,
                lastName,
                roleId,
                phoneNumber,
                email,
                isTrustworthy
            } = userState.current;

            await updateUser({
                id,
                firstName,
                lastName,
                roleId,
                phoneNumber,
                email,
                isTrustworthy,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }

        return undefined;
    }, [userState, updateUser]);

    const handleUserActivationState = React.useCallback((activate: boolean) => async () => {
        if (!isDefined(userState.current)) throw new Error('L\'utilisateur n\'a pas été trouvé.');

        if (activate === true) {
            await reactivateUser(userState.current.id);
        }
        else {
            await deactivateUser(userState.current.id);
        }
    }, [userState, deactivateUser, reactivateUser]);

    const handleUserDeletion = React.useCallback(async (shouldDelete: boolean) => {
        if (shouldDelete === false) return setShowDeletionConfirm(false);
        if (!isDefined(userState.current)) throw new Error('L\'utilisateur n\'a pas été trouvé.');

        await deleteUser(userState.current.id);

        setShowDeletionConfirm(false);
        navigate(UserNavigation());
    }, [userState, deleteUser, setShowDeletionConfirm, navigate]);

    const fullName = React.useMemo(() => {
        if (user == null) return '';

        return `${user.firstName} ${user.lastName}`;
    }, [user]);

    return (
        <>
            <Helmet>
                <title>{t('user.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {fullName}
                    </Page.Title>
                    <Page.HeaderActions>
                        <UserDetailsBreadcrumb label={fullName} />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Informations de l'utilisateur
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <div className="row">
                                <Form.Group controlId="user-lastName" className="col col-4">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Editable.Input
                                        value={user?.lastName}
                                        onSave={handleSaveInput('lastName')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="user-firstName" className="col col-4">
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Editable.Input
                                        value={user?.firstName}
                                        onSave={handleSaveInput('firstName')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="user-username" className="col col-4">
                                    <Form.Label>Nom d'utilisateur</Form.Label>
                                    <Form.Input value={user?.username} readOnly />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <Form.Group controlId="user-role" className="col col-4">
                                    <Form.Label>Rôle</Form.Label>
                                    <Form.Editable.Select
                                        selected={user?.roleId}
                                        onSave={handleSaveInput('roleId')}
                                    >
                                        {roles.map(role => (
                                            <Form.Select.Option key={role.id} value={role.id}>
                                                {role.label}
                                            </Form.Select.Option>
                                        ))}
                                    </Form.Editable.Select>
                                </Form.Group>
                                <Form.Group controlId="user-email" className="col col-4">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Editable.Input
                                        value={user?.email ?? ''}
                                        onSave={handleSaveInput('email')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="user-phoneNumber" className="col col-4">
                                    <Form.Label>Téléphone</Form.Label>
                                    <Form.Editable.Input
                                        value={user?.phoneNumber ?? ''}
                                        onSave={handleSaveInput('phoneNumber')}
                                    />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <Form.Group controlId="user-trustworthy" className="form-inline">
                                    <Form.Checkbox
                                        checked={user?.isTrustworthy}
                                        onChange={handleSaveInput('isTrustworthy')}
                                    />
                                    <Form.Label>Utilisateur de confiance</Form.Label>
                                </Form.Group>
                            </div>
                        </Card.Body>
                        <Card.Footer className="row">
                            {user != null && (
                                <Card.Infos>
                                    <FooterDateInfo creationDate={user.creationDate} updateDate={user.updateDate} />
                                </Card.Infos>
                            )}
                            <Card.Actions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={(<EditIcon />)}
                                    onClick={() => setShowUpdatePassword(true)}
                                    disabled={isDeactivating || isReactivating || isDeleting}
                                >
                                    Modifier le mot de passe
                                </Button>
                                {isValidDate(user?.deactivationDate) && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        startIcon={(<PlusIcon />)}
                                        pending={isReactivating}
                                        disabled={isDeactivating || isReactivating || isDeleting}
                                        onClick={handleUserActivationState(true)}
                                    >
                                        Ré-activer l'utilisateur
                                    </Button>
                                )}
                                {!isValidDate(user?.deactivationDate) && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        startIcon={(<CrossIcon />)}
                                        pending={isDeactivating}
                                        disabled={isDeactivating || isReactivating || isDeleting}
                                        onClick={handleUserActivationState(false)}
                                    >
                                        Désactiver l'utilisateur
                                    </Button>
                                )}
                                <Button
                                    color="danger"
                                    variant="outlined"
                                    startIcon={(<TrashIcon />)}
                                    disabled={isDeactivating || isReactivating || isDeleting}
                                    pending={isDeleting}
                                    onClick={() => setShowDeletionConfirm(true)}
                                >
                                    Supprimer l'utilisateur
                                </Button>
                                {showUpdatePassword && user != null && (
                                    <UpdatePasswordDialog
                                        userId={user.id}
                                        onClose={() => setShowUpdatePassword(false)}
                                    />
                                )}
                                {showDeletionConfirm && (
                                    <ConfirmDialog
                                        title={`Supprimer l'utilisateur "${user?.firstName} ${user?.lastName}"`}
                                        message="Vous êtes sur le point de supprimer cet utilisateur. Êtes-vous sûr de vouloir continuer ?"
                                        ok="Supprimer"
                                        onClose={handleUserDeletion}
                                    />
                                )}
                            </Card.Actions>
                        </Card.Footer>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(UserDetailsScreen, 'USER_DETAILS');