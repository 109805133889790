import React from 'react';
import { useMutation, useQuery } from 'react-query';
import propTypes from 'prop-types';
import { computeOptions, isBoolean, isString, isValidDate, ServiceError } from '@dateam/ark';
import { propTypeNullable, usePick } from '@dateam/ark-react';
import { isRefLabelNumber, refLabelNumberPropTypes } from 'utils/propTypes';
import { useYearState } from 'utils/yearStore';
import { queryClient } from 'utils/queryClient';
import {
    PLOT_KEY,
    PLOT_DETAILS_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    defaultActionOptions,
    mutateResultKeys,
    OBSERVATION_TYPE_DETAILS_KEY,
    CUSTOMER_DETAILS_KEY,
    TRACK_DETAILS_KEY,
    PLOT_CITY_KEY
} from './constants';
import { plotRequests } from './requests';

export const usePlots = (options?: DataOptions): QueryResult<App.Plot[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const [year] = useYearState();
    const query = useQuery<App.Plot[], ServiceError>({
        ...config,
        queryKey: [year, PLOT_KEY],
        queryFn: () => plotRequests.getAll(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const usePlotDetails = (plotId: App.PlotDetails['id'], options?: DataOptions): QueryResult<App.PlotDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.PlotDetails, ServiceError>({
        ...config,
        queryKey: [year, PLOT_DETAILS_KEY, plotId],
        queryFn: () => plotRequests.get(year, plotId)
    });

    return usePick(query as any, queryResultKeys);
};

export const usePlotCities = (options?: DataOptions): QueryResult<string[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<string[], ServiceError>({
        ...config,
        queryKey: [year, PLOT_CITY_KEY],
        queryFn: () => plotRequests.getCities(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreatePlot = (): MutationResult<App.PlotKey, App.CreatePlotRequest> => {
    const [year] = useYearState();
    const result = useMutation<App.PlotKey, ServiceError, App.CreatePlotRequest>(
        (request: App.CreatePlotRequest) => plotRequests.create(year, { ...request }),
        {
            ...defaultActionOptions,
            onSuccess: (_, { customerId }) => {
                queryClient.invalidateQueries([year, PLOT_KEY]);
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY, customerId]);
            }
        }
    );

    return result;
};

export const useUpdatePlot = (): MutationResult<void, App.UpdatePlotRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdatePlotRequest>(
        (request: App.UpdatePlotRequest) => {
            const {
                id,
                ...props
            } = request;

            return plotRequests.update(year, id, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([year, PLOT_KEY]);
                queryClient.invalidateQueries([year, PLOT_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, PLOT_CITY_KEY]);
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY]);
                queryClient.invalidateQueries([year, TRACK_DETAILS_KEY]);
            }
        }
    );

    return result;
};

export const useUpdatePlotObservationTypes = (): MutationResult<void, App.UpdatePlotObservationTypesRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdatePlotObservationTypesRequest>(
        (request: App.UpdatePlotObservationTypesRequest) => {
            const {
                id,
                observationTypes
            } = request;

            return plotRequests.updateObservationTypes(year, id, observationTypes);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([year, PLOT_KEY]);
                queryClient.invalidateQueries([year, PLOT_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, OBSERVATION_TYPE_DETAILS_KEY]);
                queryClient.invalidateQueries([year, TRACK_DETAILS_KEY]);
            }
        }
    );

    return result;
};

export const useDeletePlot = (): MutationResult<void, App.PlotDetails['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.PlotDetails['id']>(
        id => plotRequests.deletePlot(year, id),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([year, PLOT_KEY]);
                queryClient.invalidateQueries([year, PLOT_CITY_KEY]);
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY]);
            }
        }
    );

    return result;
};

export const plotDetailsPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    year: propTypes.number.isRequired,
    activityId: propTypes.number.isRequired,
    publicId: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    position: propTypes.number.isRequired,
    varietal: propTypeNullable(isRefLabelNumber),
    customer: refLabelNumberPropTypes.isRequired,
    area: propTypeNullable(isRefLabelNumber),
    bio: refLabelNumberPropTypes.isRequired,
    city: propTypes.string.isRequired,
    confusion: propTypeNullable(isBoolean),
    observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    observations: propTypes.arrayOf(propTypes.any.isRequired).isRequired,
    observationComment: propTypeNullable(isString),
    validationDate: propTypeNullable(isValidDate),
    ignored: propTypes.bool.isRequired,
    ignoredReason: propTypeNullable(isString)
});