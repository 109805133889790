import React from 'react';
import propTypes from 'prop-types';
import { isFunction, noop } from '@dateam/ark';
import { concatClassName, useDebounce } from '@dateam/ark-react';
import { Form } from 'shared-ui';
import { useRoles } from 'data/user';

export type UserFilters = {
    userText: string;
    roles: string[];
    hideDeactivated: boolean;
};

type Props = {
    className?: string;
    onChange?: (filters: UserFilters) => void;
};

const FilterBar: React.FC<Props> = ({
    className,
    onChange
}) => {
    const roles = useRoles();
    const notifyChange = React.useRef(onChange ?? noop);
    const saveChanges = useDebounce((filter: UserFilters) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<UserFilters>({
        userText: '',
        roles: [],
        hideDeactivated: true
    });

    const handleUserTextChange = React.useCallback((newValue: string) => {
        const newFilter: UserFilters = {
            ...filter,
            userText: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleRoleChange = React.useCallback((selectedValues: string[]) => {
        const newFilter: UserFilters = {
            ...filter,
            roles: selectedValues
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleShowDeactivatedChange = React.useCallback((hideDeactivated: boolean) => {
        const newFilter: UserFilters = {
            ...filter,
            hideDeactivated
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    return (
        <div className={concatClassName('row', className)}>
            <Form.Group controlId="filter-name" className="col">
                <Form.Label>Nom / Prénom / Nom d'utilisateur</Form.Label>
                <Form.Input
                    value={filter.userText}
                    autocomplete="off"
                    onChange={handleUserTextChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-role" className="col">
                <Form.Label>Rôle</Form.Label>
                <Form.Multiselect
                    id="filter-role-select"
                    onChange={handleRoleChange}
                >
                    {roles.map((role, index) => (
                        <Form.Select.Option
                            key={index}
                            value={role.id}
                            selectedText={role.label}
                        >
                            {role.label}
                        </Form.Select.Option>
                    ))}
                </Form.Multiselect>
            </Form.Group>
            <Form.Group controlId="filter-deactivation">
                <Form.Label>Cacher les utilisateurs désactivés</Form.Label>
                <Form.Select selected={filter.hideDeactivated} onChange={handleShowDeactivatedChange}>
                    <Form.Select.Option value={true}>Oui</Form.Select.Option>
                    <Form.Select.Option value={false}>Non</Form.Select.Option>
                </Form.Select>
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    className: propTypes.string,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default FilterBar;
