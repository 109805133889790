import React from 'react';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import { CustomerDetailsNavigation } from 'screens/routes';
import { useYearState } from 'utils/yearStore';
import { customerRequests } from 'data/requests';
import { DownloadIcon, PlusIcon } from '../../../components/Icons';
import CreateCustomerModal from './CreateCustomerModal';
import styles from '../CustomerScreen.module.scss';

const HeaderAction: React.FC = () => {
    const { push: navigate } = useNavigation();
    const [year] = useYearState();
    const [showCreateCustomer, setShowCreateCustomer] = React.useState(false);

    const handleCustomerCreateCompletion = (customerId: App.CustomerIdentity['id']) => {
        navigate(CustomerDetailsNavigation(customerId));
    };

    const exportUrl = React.useMemo(() => customerRequests.getExportUrl(year), [year]);

    return (
        <div className={styles['headerActions']}>
            <Button
                color="primary"
                type="link"
                startIcon={(<DownloadIcon />)}
                className={styles['actionButton']}
                link={exportUrl}
                target="_blank"
                rel="noreferrer"
            >
                Exporter
            </Button>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={() => setShowCreateCustomer(true)}
                className={styles['actionButton']}
            >
                Ajouter un vigneron
            </Button>
            {showCreateCustomer && (
                <CreateCustomerModal
                    onClose={() => setShowCreateCustomer(false)}
                    onCompletion={handleCustomerCreateCompletion}
                />
            )}
        </div>
    );
};

export default HeaderAction;
