import React from 'react';
import { propTypeNullable } from '@dateam/ark-react';
import { isRefLabelNumber } from 'utils/propTypes';

type Props = {
    data: RefLabel<number> | null;
};

const ColumnVarietal: React.FC<Props> = ({ data }: Props) => (
    <div>
        {data?.label ?? '-'}
    </div>
);

ColumnVarietal.propTypes = {
    data: propTypeNullable(isRefLabelNumber)
};

ColumnVarietal.defaultProps = {
    data: undefined
};

export default ColumnVarietal;
