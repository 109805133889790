import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import * as ArkReact from '@dateam/ark-react';
import { useYearlyNavigation } from 'utils/yearStore';
import { isRefLabelNumber, refLabelNumberPropTypes } from 'utils/propTypes';
import { PlotDetailsNavigation } from 'screens/routes';
import { SidePanelListItemCol, SidePanelListItemLink } from 'components/SidePanelList';
import { InfiniteIcon } from 'components/Icons';

import styles from '../../../CustomerDetailsScreen.module.scss';

type Props = {
    value: App.Plot
};

const PlotItem: React.FC<Props> = ({ value }: Props) => {
    const plotNav = useYearlyNavigation(PlotDetailsNavigation, [value.id]);

    const tracks = value.tracks != null && value.tracks.length > 0 ? value.tracks.map(item => item.label).join(',') : null;

    const subText = tracks != null ? (
        <div className={styles['track-col']}>
            <InfiniteIcon className={styles['track-icon']} />
            {tracks}
        </div>
    ) : (<>-</>);

    return (
        <SidePanelListItemLink key={value.id} to={plotNav}>
            <SidePanelListItemCol className="col-5" text={value.label} subtext={value.publicId} />
            <SidePanelListItemCol className="col-4" text={value.city ?? '-'} subtext={subText} />
            <SidePanelListItemCol className="col-3" text={value.bio?.label ?? '-'} />
        </SidePanelListItemLink>
    );
};

PlotItem.propTypes = {
    value: propTypes.shape({
        id: propTypes.number.isRequired,
        year: propTypes.number.isRequired,
        publicId: propTypes.string.isRequired,
        activityId: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
        area: ArkReact.propTypeNullable(isRefLabelNumber),
        bio: refLabelNumberPropTypes.isRequired,
        varietal: ArkReact.propTypeNullable(isRefLabelNumber),
        customer: refLabelNumberPropTypes.isRequired,
        confusion: ArkReact.propTypeNullable(Ark.isBoolean),
        city: propTypes.string.isRequired,
        creationDate: propTypes.instanceOf(Date).isRequired,
        tracks: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired
    }).isRequired
};

PlotItem.defaultProps = {
};

export default PlotItem;
