import React from 'react';
import { useYearlyNavigation } from 'utils/yearStore';
import { SidePanelListItemCol, SidePanelListItemLink } from 'components/SidePanelList';
import { UserIcon } from 'components/Icons';
import { UserDetailsNavigation } from 'screens/routes';
import styles from '../DashboardScreen.module.scss';

type Props = {
    value: App.User;
};

const ObservatorItem: React.FC<Props> = ({ value }: Props) => {
    const campaignNav = useYearlyNavigation(UserDetailsNavigation, [value.id]);

    return (
        <SidePanelListItemLink key={value.id} to={campaignNav}>
            <SidePanelListItemCol className={`${styles['observator-desktop']} col-1`} icon={<UserIcon />} />
            <SidePanelListItemCol className={`${styles['observator-desktop']} col-5`} text={`${value.firstName} ${value.lastName}`} />
            <SidePanelListItemCol className={`${styles['observator-desktop']} col-6`} text={value.phoneNumber ?? '-'} />
            <SidePanelListItemCol
                className={styles['observator-mobile']}
                text={`${value.firstName} ${value.lastName}`}
                subtext={value.phoneNumber ?? '-'}
            />
        </SidePanelListItemLink>
    );
};

ObservatorItem.propTypes = {
};

ObservatorItem.defaultProps = {
};

export default ObservatorItem;
