import React from 'react';
import propTypes from 'prop-types';
import { propTypeNullable } from '@dateam/ark-react';
import * as Ark from '@dateam/ark';
import {
    Button,
    Card,
    ConfirmDialog,
    Form
} from 'shared-ui';
import { useNavigation, useRouterPath } from 'shared-utils';
import { useYearlyNavigation } from 'utils/yearStore';
import {
    isRefLabelNumber,
    refLabelNumberPropTypes
} from 'utils/propTypes';
import { TrashIcon } from 'components/Icons';
import { errorProvider } from 'components/errorHandler';
import { FooterDateInfo } from 'components/FooterDateInfo';
import { CustomerDetailsNavigation, PlotNavigation } from 'screens/routes';
import { useDeletePlot, usePlotCities, useUpdatePlot } from 'data/plot';
import { useBioTypes } from 'data/bio';
import { useAreas } from 'data/area';
import { useVarietals } from 'data/varietal';
import styles from '../PlotDetailsScreen.module.scss';

type Props = {
    plot: App.PlotDetails;
    displayActions?: boolean;
    className?: string;
};

const ConfigurationPanel: React.FC<Props> = ({
    plot,
    displayActions,
    className
}) => {
    const plotState = React.useRef(plot);
    const { data: bioTypes } = useBioTypes();
    const { data: areas } = useAreas();
    const { data: varietals } = useVarietals();
    const { data: cities } = usePlotCities();
    const { push: navigate } = useNavigation();
    const { mutateAsync: updatePlot, isLoading: isUpdating } = useUpdatePlot();
    const { mutateAsync: deletePlot, isLoading: isDeleting } = useDeletePlot();
    const customerNav = useYearlyNavigation(CustomerDetailsNavigation, [plot.customer.id]);
    const customerPath = useRouterPath(customerNav);
    const [showDeletionConfirm, setShowDeletionConfirm] = React.useState(false);

    const goToCustomer = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(CustomerDetailsNavigation(plot.customer.id));
    }, [plot, navigate]);

    React.useEffect(() => {
        plotState.current = plot;
    }, [plot]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: any) => {
        if (!Ark.isDefined(plotState.current)) throw new Error('La parcelle n\'a pas été trouvée.');

        try {
            const {
                id,
                year,
                publicId,
                label,
                area,
                bio,
                varietal,
                confusion,
                city,
                landReference,
                comment
            } = plotState.current;

            await updatePlot({
                id,
                year,
                publicId,
                label,
                areaId: area?.id ?? null,
                bioId: bio?.id ?? null,
                varietalId: varietal?.id ?? null,
                confusion,
                city,
                landReference,
                comment,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof Ark.ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }
    }, [plotState, updatePlot]);

    const handleCityGetOptions = React.useCallback(async (value: string) => {
        if (cities == null) return [];
        if (value.length === 0) return cities;

        return cities.filter(item => Ark.strIncludes(value, item, {
            caseInsensitive: true,
            ignoreDiacritics: true,
            startsWith: true
        }));
    }, [cities]);

    const handlePlotDeletion = React.useCallback(async (shouldDelete: boolean) => {
        if (shouldDelete === false) return setShowDeletionConfirm(false);
        if (!Ark.isDefined(plot)) throw new Error('La parcelle n\'a pas été trouvée.');

        await deletePlot(plot.id);

        setShowDeletionConfirm(false);
        navigate(PlotNavigation());
    }, [plot, deletePlot, navigate, setShowDeletionConfirm]);

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>
                    Informations de la parcelle
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <div className={styles['customerLabel']}>
                    <div className="formLabel">Vigneron</div>
                    <a
                        href={customerPath}
                        onClick={goToCustomer}
                        className={styles['customerLink']}
                    >
                        {plot.customer.label}
                    </a>
                </div>
                <div className={styles['configRow']}>
                    <Form.Group controlId="plot-id" className="col col-2">
                        <Form.Label>Code</Form.Label>
                        <Form.Editable.Input
                            value={plot.publicId}
                            onSave={handleSaveInput('publicId')}
                        />
                    </Form.Group>
                    <Form.Group controlId="plot-label" className="col col-6">
                        <Form.Label>Nom</Form.Label>
                        <Form.Editable.Input
                            value={plot.label}
                            onSave={handleSaveInput('label')}
                        />
                    </Form.Group>
                    <Form.Group controlId="plot-varietal" className="col col-4">
                        <Form.Label>Cépage</Form.Label>
                        <Form.Editable.Select
                            selected={plot.varietal?.id}
                            onSave={handleSaveInput('varietalId')}
                        >
                            <Form.Select.Option value={null} />
                            {varietals?.map(varietal => (
                                <Form.Select.Option key={`varietal-${varietal.id}`} value={varietal.id}>
                                    {varietal.label}
                                </Form.Select.Option>
                            ))}
                        </Form.Editable.Select>
                    </Form.Group>
                </div>
                <div className={styles['configRow']}>
                    <Form.Group controlId="plot-city" className="col col-6">
                        <Form.Label>Ville</Form.Label>
                        <Form.Editable.Autocomplete
                            value={plot.city ?? ''}
                            maxlength={50}
                            onSave={handleSaveInput('city')}
                            onGetOptions={handleCityGetOptions}
                        />
                    </Form.Group>
                    <Form.Group controlId="plot-area" className="col col-6">
                        <Form.Label>Secteur géographique</Form.Label>
                        <Form.Editable.Select
                            selected={plot.area?.id}
                            onSave={handleSaveInput('areaId')}
                        >
                            <Form.Select.Option value={null} />
                            {areas?.map(area => (
                                <Form.Select.Option key={`area-${area.id}`} value={area.id}>
                                    {area.label}
                                </Form.Select.Option>
                            ))}
                        </Form.Editable.Select>
                    </Form.Group>
                </div>
                <div className={styles['configRow']}>
                    <Form.Group controlId="plot-bio" className="col col-4">
                        <Form.Label>Type de protection</Form.Label>
                        <Form.Editable.Select
                            selected={plot.bio?.id}
                            onSave={handleSaveInput('bioId')}
                        >
                            <Form.Select.Option value={null} />
                            {bioTypes?.map(bio => (
                                <Form.Select.Option key={`bio-${bio.id}`} value={bio.id}>
                                    {bio.label}
                                </Form.Select.Option>
                            ))}
                        </Form.Editable.Select>
                    </Form.Group>
                    <Form.Group controlId="plot-confusion" className="col col-4">
                        <Form.Label>Confusion</Form.Label>
                        <Form.Editable.Select
                            selected={plot.confusion}
                            onSave={handleSaveInput('confusion')}
                        >
                            <Form.Select.Option value={null} />
                            <Form.Select.Option value={true}>Oui</Form.Select.Option>
                            <Form.Select.Option value={false}>Non</Form.Select.Option>
                        </Form.Editable.Select>
                    </Form.Group>
                    <Form.Group controlId="plot-landReference" className="col col-4">
                        <Form.Label>Référence cadastre</Form.Label>
                        <Form.Editable.Input
                            value={plot.landReference ?? ''}
                            maxlength={20}
                            onSave={handleSaveInput('landReference')}
                        />
                    </Form.Group>
                </div>
                <Form.Group controlId="plot-comment">
                    <Form.Label>Commentaire</Form.Label>
                    <Form.Editable.Input
                        multiline
                        rows={5}
                        maxlength={1000}
                        value={plot.comment ?? ''}
                        onSave={handleSaveInput('comment')}
                    />
                </Form.Group>
            </Card.Body>
            <Card.Footer className="row">
                <Card.Infos>
                    <FooterDateInfo creationDate={plot.creationDate} updateDate={plot.updateDate} />
                </Card.Infos>
                {displayActions && (
                    <Card.Actions>
                        <Button
                            color="danger"
                            variant="outlined"
                            startIcon={(<TrashIcon />)}
                            disabled={isUpdating || isDeleting}
                            pending={isDeleting}
                            onClick={() => setShowDeletionConfirm(true)}
                        >
                            Supprimer la parcelle
                        </Button>
                        {showDeletionConfirm && (
                            <ConfirmDialog
                                title={`Supprimer la parcelle "${plot.label}"`}
                                message="Vous êtes sur le point de supprimer cette parcelle. Êtes-vous sûr de vouloir continuer ?"
                                ok="Supprimer"
                                onClose={handlePlotDeletion}
                            />
                        )}
                    </Card.Actions>
                )}
            </Card.Footer>
        </Card>
    );
};

ConfigurationPanel.propTypes = {
    plot: propTypes.shape({
        id: propTypes.number.isRequired,
        year: propTypes.number.isRequired,
        publicId: propTypes.string.isRequired,
        activityId: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        customer: refLabelNumberPropTypes.isRequired,
        area: propTypeNullable(isRefLabelNumber),
        bio: propTypeNullable(isRefLabelNumber),
        varietal: propTypeNullable(isRefLabelNumber),
        observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
        city: propTypeNullable(Ark.isString),
        confusion: propTypeNullable(Ark.isBoolean),
        landReference: propTypeNullable(Ark.isString),
        comment: propTypeNullable(Ark.isString),
        creationDate: propTypes.instanceOf(Date).isRequired,
        updateDate: propTypeNullable(Ark.isValidDate),
        tracks: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired
    }).isRequired,
    displayActions: propTypes.bool,
    className: propTypes.string
};

ConfigurationPanel.defaultProps = {
    displayActions: undefined,
    className: undefined
};

export default ConfigurationPanel;
