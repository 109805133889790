import React from 'react';
import propTypes from 'prop-types';
import { hasProperty, isValidNumber } from '@dateam/ark';
import {
    useNavigation,
    useNavigationActive
} from 'shared-utils';
import { Form, Menu } from 'shared-ui';
import logger from 'utils/logger';
import { useYearlyRootNavigation, useYearState } from 'utils/yearStore';
import { useUserState } from 'utils/userStore';
import {
    BurgerMenuIcon,
    CompassIcon,
    DocumentIcon,
    ExportIcon,
    EyeIcon,
    HomeIcon,
    InfiniteIcon,
    ListIcon,
    PinIcon,
    SettingsIcon,
    StatsIcon,
    UserIcon,
    UsersIcon
} from 'components/Icons';
import Sidebar, {
    SidebarFooter,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuLink,
    SidebarProfileItem
} from 'components/Sidebar';
import Topbar from 'components/topbar';
import { useLogOut } from 'data/user';
import {
    LogOutNavigation,
    DashboardNavigation,
    InspectionNavigation,
    CampaignNavigation,
    UserNavigation,
    CustomerNavigation,
    PlotNavigation,
    ExportNavigation,
    TrackNavigation,
    ObservationTypeNavigation,
    ObservationNavigation,
    ReportNavigation,
    VarietalTypeNavigation,
    BioTypeNavigation
} from 'screens/routes';
import { useYears } from 'data/year';
import { AdminNavigation } from 'screens/administration';
import LogoImg from '../../../public/logo.svg';
import { CreateNewYearModal } from './components/createNewYearModal';
import styles from './MainLayout.module.scss';

const MainLayout = ({ children }: React.PropsWithChildren) => {
    const { push: navigate, query } = useNavigation();
    const isActive = useNavigationActive();
    const [yearState, setYearState] = useYearState();
    const { data: years } = useYears();
    const [user] = useUserState();
    const { mutateAsync: logOut } = useLogOut();
    const userMenuRef = React.useRef<HTMLDivElement>(null);
    const burgerMenuRef = React.useRef<HTMLDivElement>(null);
    const [showMenuUser, setShowMenuUser] = React.useState(false);
    const [showMenuBurger, setShowMenuBurger] = React.useState(false);
    const [selectedYear, setSelectedYear] = React.useState<number | null>(yearState);
    const [showNewYearModal, setShowNewYearModal] = React.useState(false);

    const isSupervisor = user?.roleId === 'administrator' || user?.roleId === 'supervisor';

    React.useEffect(() => {
        setSelectedYear(yearState);
    }, [yearState]);

    React.useEffect(() => {
        if (hasProperty(query, 'year')) {
            const currentYear = new Date().getFullYear();
            let parsedYear = parseInt(query.year, 10);

            if (!isValidNumber(parsedYear)) {
                parsedYear = currentYear;
            }

            setTimeout(() => setYearState(selectedYear => {
                if (selectedYear !== parsedYear) {
                    return parsedYear;
                }

                return selectedYear;
            }), 0);
        }
    }, [query, setYearState]);

    const nextYear = React.useMemo(() => {
        if (years == null) return null;

        const lastYear = Math.max(...years);

        return lastYear + 1;
    }, [years]);

    const handleUserMenuClick = React.useCallback(() => {
        if (showMenuUser === true) return;
        setShowMenuUser(true);
    }, [showMenuUser, setShowMenuUser]);

    const handleUserMenuClose = React.useCallback(() => {
        setShowMenuUser(false);
    }, [setShowMenuUser]);

    const handleCreateModalCancel = React.useCallback(() => {
        setSelectedYear(yearState);
        setShowNewYearModal(false);
    }, [setShowNewYearModal, setSelectedYear, yearState]);

    const handleMenuLogoutClick = React.useCallback(async () => {
        logger.debug('logOut');
        try {
            await logOut();
            navigate(LogOutNavigation());
        }
        catch {
            // Ignore
        }
    }, [navigate, logOut]);

    const [rootNavigations] = React.useState({
        dashboardNav: DashboardNavigation,
        campaignNav: CampaignNavigation,
        inspectionNav: InspectionNavigation,
        exportNav: ExportNavigation,
        customerNav: CustomerNavigation,
        plotNav: PlotNavigation,
        trackNav: TrackNavigation,
        observationNav: ObservationNavigation,
        observationTypeNav: ObservationTypeNavigation,
        userNav: UserNavigation,
        reportNav: ReportNavigation,
        adminNav: AdminNavigation,
        varietalTypeNav: VarietalTypeNavigation,
        bioTypeNav: BioTypeNavigation
    });

    const {
        dashboardNav,
        campaignNav,
        inspectionNav,
        exportNav,
        customerNav,
        plotNav,
        trackNav,
        observationNav,
        observationTypeNav,
        userNav,
        reportNav,
        adminNav,
        varietalTypeNav,
        bioTypeNav
    } = useYearlyRootNavigation(rootNavigations);

    const onYearChange = React.useCallback((value: number | string) => {
        if (isValidNumber(value)) setYearState(value);
        else {
            setShowNewYearModal(true);
            setSelectedYear(null);
        }
    }, [setYearState]);

    return (
        <>
            <Sidebar className={styles['sidebar']}>
                <SidebarHeader className={styles['header']}>
                    <LogoImg />
                    <h1 className={styles['headerTitle']}>Viticoncept</h1>
                    <div className={styles['yearSelect']}>
                        <Form.Select
                            selected={selectedYear}
                            onChange={onYearChange}
                        >
                            {years?.map(year => (
                                <Form.Select.Option key={year} value={year} selectedText={year.toString()}>
                                    {year}
                                </Form.Select.Option>
                            ))}
                            {nextYear != null && isSupervisor && (
                                <Form.Select.Option value={null}>
                                    {showNewYearModal === false ? `Créer ${nextYear}` : yearState}
                                </Form.Select.Option>
                            )}
                        </Form.Select>
                    </div>
                </SidebarHeader>
                <SidebarMenu>
                    <SidebarMenuLink
                        to={dashboardNav}
                        icon={(<HomeIcon />)}
                        active={isActive(dashboardNav)}
                    >
                        Tableau de bord
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        to={campaignNav}
                        icon={(<ListIcon />)}
                        active={isActive(campaignNav)}
                    >
                        Tournées
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        to={inspectionNav}
                        icon={(<PinIcon />)}
                        active={isActive(inspectionNav)}
                    >
                        Visites
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        to={reportNav}
                        icon={(<DocumentIcon />)}
                        active={isActive(reportNav)}
                    >
                        Rapports
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        to={exportNav}
                        icon={(<ExportIcon />)}
                        active={isActive(exportNav)}
                    >
                        Exports
                    </SidebarMenuLink>
                    <div className={styles['sidebarSeparator']} />
                    <SidebarMenuLink
                        to={customerNav}
                        icon={(<UsersIcon />)}
                        active={isActive(customerNav)}
                    >
                        Vignerons
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        to={plotNav}
                        icon={(<CompassIcon />)}
                        active={isActive(plotNav)}
                    >
                        Parcelles
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        to={trackNav}
                        icon={(<InfiniteIcon />)}
                        active={isActive(trackNav)}
                    >
                        Circuits
                    </SidebarMenuLink>
                    {isSupervisor && (
                        <>
                            <SidebarMenuLink
                                to={observationNav}
                                icon={(<EyeIcon />)}
                                active={isActive(observationNav)}
                            >
                                Observations
                            </SidebarMenuLink>
                            <SidebarMenuLink
                                to={observationTypeNav}
                                icon={(<StatsIcon />)}
                                active={isActive(observationTypeNav)}
                            >
                                Types de suivi
                            </SidebarMenuLink>
                            <SidebarMenuLink
                                to={varietalTypeNav}
                                icon={(<StatsIcon />)}
                                active={isActive(varietalTypeNav)}
                            >
                                Cépages
                            </SidebarMenuLink>
                            <SidebarMenuLink
                                to={bioTypeNav}
                                icon={(<StatsIcon />)}
                                active={isActive(bioTypeNav)}
                            >
                                Type de protection
                            </SidebarMenuLink>
                        </>
                    )}
                    {user?.roleId === 'administrator' && (
                        <>
                            <SidebarMenuLink
                                to={userNav}
                                icon={(<UserIcon />)}
                                active={isActive(userNav)}
                            >
                                Utilisateurs
                            </SidebarMenuLink>
                            <SidebarMenuLink
                                to={adminNav}
                                icon={(<SettingsIcon />)}
                                active={isActive(adminNav)}
                            >
                                Administration
                            </SidebarMenuLink>
                        </>
                    )}
                </SidebarMenu>
                <SidebarFooter className={styles['sidebar-footer']}>
                    <SidebarProfileItem
                        ref={userMenuRef}
                        onClick={handleUserMenuClick}
                    />
                </SidebarFooter>
            </Sidebar>
            <Topbar className={styles['topbar']}>
                <LogoImg />
                <div
                    role="button"
                    tabIndex={0}
                    className={styles['burgerButton']}
                    ref={burgerMenuRef}
                    onClick={() => setShowMenuBurger(true)}
                >
                    <BurgerMenuIcon />
                </div>
            </Topbar>
            <main role="main" className={styles['pageContainer']}>
                {children}
            </main>
            {showMenuUser && (
                <Menu
                    anchorEl={userMenuRef}
                    position="right-bottom"
                    onClose={handleUserMenuClose}
                >
                    <Menu.Item onClick={handleMenuLogoutClick}>Se déconnecter</Menu.Item>
                </Menu>
            )}
            {showMenuBurger && (
                <Menu
                    anchorEl={burgerMenuRef}
                    position="bottom-right"
                    onClose={() => setShowMenuBurger(false)}
                    className={styles['burgerMenu']}
                >
                    <Menu.Item onClick={() => navigate(dashboardNav)}>Tableau de bord</Menu.Item>
                    <Menu.Item onClick={() => navigate(campaignNav)}>Tournées</Menu.Item>
                    <Menu.Item onClick={() => navigate(inspectionNav)}>Visites</Menu.Item>
                    <Menu.Item onClick={() => navigate(reportNav)}>Rapports</Menu.Item>
                    <Menu.Item onClick={() => navigate(exportNav)}>Exports</Menu.Item>
                    <Menu.Item onClick={() => navigate(customerNav)}>Vignerons</Menu.Item>
                    <Menu.Item onClick={() => navigate(plotNav)}>Parcelles</Menu.Item>
                    <Menu.Item onClick={() => navigate(trackNav)}>Circuits</Menu.Item>
                    {isSupervisor && (
                        <>
                            <Menu.Item onClick={() => navigate(observationNav)}>Observations</Menu.Item>
                            <Menu.Item onClick={() => navigate(observationTypeNav)}>Types de suivi</Menu.Item>
                        </>
                    )}
                    {user?.roleId === 'administrator' && (
                        <Menu.Item onClick={() => navigate(userNav)}>Utilisateurs</Menu.Item>
                    )}
                    <Menu.Item onClick={handleMenuLogoutClick}>Se déconnecter</Menu.Item>
                </Menu>
            )}
            {showNewYearModal && nextYear != null && (
                <CreateNewYearModal
                    newYear={nextYear}
                    className={styles['createYearModal']}
                    onCancel={handleCreateModalCancel}
                    onClose={() => setShowNewYearModal(false)}
                />
            )}
        </>
    );
};

MainLayout.propTypes = {
    children: propTypes.node
};

MainLayout.defaultProps = {
    children: undefined
};

export default MainLayout;
