import React from 'react';
import { Button } from 'shared-ui';
import { useYearState } from 'utils/yearStore';
import { plotRequests } from 'data/requests';
import { DownloadIcon } from '../../../components/Icons';
import styles from '../PlotScreen.module.scss';

const HeaderAction: React.FC = () => {
    const [year] = useYearState();

    const exportUrl = React.useMemo(() => plotRequests.getExportUrl(year), [year]);

    return (
        <div className={styles['headerActions']}>
            <Button
                color="primary"
                type="link"
                startIcon={(<DownloadIcon />)}
                className={styles['actionButton']}
                link={exportUrl}
                target="_blank"
                rel="noreferrer"
            >
                Exporter
            </Button>
        </div>
    );
};

export default HeaderAction;
