import { isString } from '@dateam/ark';

// Removed because moronic browsers do not support lookbehind conditions
// const OUTLOOK_REGEX = /(?:(?:[^<]*(?<=[^<]*)<([^>]*)>)|([^;]*));(.*)/i; // eslint-disable-line max-len
// const OUTLOOK_LEFTOVER_REGEX = /(?:[^<;]*(?<=[^<;]*)<([^>]*)>)/i; // eslint-disable-line max-len
// const CLEAR_SEPARATORS_REGEX = /((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))[\s\n\r]+((?<=[\s\n\r]*?)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))((?:[\s\n\r]*?.*?)*?)+/i; // eslint-disable-line max-len
const OUTLOOK_REGEX = /(?:(?:[^<]*<([^>]*)>)|([^;]*));(.*)/i; // eslint-disable-line max-len
const OUTLOOK_LEFTOVER_REGEX = /(?:[^<;]*<([^>]*)>)/i; // eslint-disable-line max-len
const CLEAR_SEPARATORS_REGEX = /((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))[\s\n\r]+((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))((?:[\s\n\r]*?.*?)*?)+/i; // eslint-disable-line max-len
const EMAIL_REGEX = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/i; // eslint-disable-line max-len

const isValidEmailEntry = (str: string) => str != null && str.trim().length > 0;

export const parseEmailFormat = (value: string): string[] => {
    if (!isString(value) || value.length === 0) throw new Error('Unable to parse invalid email value');

    let arr = value.toLowerCase().split('\n');
    arr = parseOutlookFormat(arr);
    arr = parseOutlookLeftovers(arr);
    arr = parseSeparatorsFormat(arr);
    return arr
        .map(item => item.trim())
        .filter(item => item.match(EMAIL_REGEX));
};

const parseOutlookFormat = (arr: string[]) => arr.reduce((acc: string[], item) => {
    let match;
    while (match = item.match(OUTLOOK_REGEX)) { // eslint-disable-line no-cond-assign
        const [, email, unhandledFormat, extraStr] = match;

        if (isValidEmailEntry(email)) acc.push(email);
        else if (isString(unhandledFormat)) acc.push(unhandledFormat);

        item = extraStr;
    }

    if (isValidEmailEntry(item)) acc.push(item);

    return acc;
}, []);

const parseOutlookLeftovers = (arr: string[]) => arr.map(item => {
    const match = item.match(OUTLOOK_LEFTOVER_REGEX);

    if (match == null) return item;

    const [, email] = match;

    return isValidEmailEntry(email) ? email : item;
}, []);

const parseSeparatorsFormat = (arr: string[]) => arr.reduce((acc: string[], item) => {
    let match;
    while (match = item.match(CLEAR_SEPARATORS_REGEX)) { // eslint-disable-line no-cond-assign
        const [, email, extraStr] = match;

        if (isValidEmailEntry(email)) acc.push(email);
        item = extraStr;
    }

    if (isValidEmailEntry(item)) acc.push(item);

    return acc;
}, []);
(globalThis as any).parseEmailFormat = parseEmailFormat;
(globalThis as any).parseOutlookFormat = parseOutlookFormat;
(globalThis as any).parseOutlookLeftovers = parseOutlookLeftovers;
(globalThis as any).parseSeparatorsFormat = parseSeparatorsFormat;