import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import config from 'config';
import { inspectionDetailsPropTypes } from 'data/inspection';
import Page from 'components/Page';
import { ConfigurationPanel, TimelinePanel } from './components';
import styles from '../../InspectionEditScreen.module.scss';

type Props = {
    inspection: Omit<App.InspectionDetails, 'observations' | 'plots'>;
    displayActions?: boolean;
};

const ConfigurationSection: React.FC<Props> = ({
    inspection,
    displayActions
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('inspection.details.config.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page.Content className="row">
                <ConfigurationPanel
                    className="col col-8"
                    inspection={inspection}
                    displayActions={displayActions}
                />
                <TimelinePanel className={`${styles['timelinePanel']} col col-4`} data={inspection} />
            </Page.Content>
        </>
    );
};

ConfigurationSection.propTypes = {
    inspection: inspectionDetailsPropTypes.isRequired,
    displayActions: propTypes.bool
};

ConfigurationSection.defaultProps = {
    displayActions: undefined
};

export default ConfigurationSection;
