import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import AdminScreen from './AdminScreen';

export const AdminNavigation = (): NavigationRoute =>
    new NavigationRoute(
        AdminScreen.key,
        [getYearRouteParam()]
    );

const router = new Router();

router
    .add(new RouteData({
        key: AdminScreen.key,
        path: '/',
        component: AdminScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;