import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isDefined, isValidDate, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { plotDetailsPropTypes } from 'data/plot';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps } from './types';
import { ObservationErrors } from './components';
import ObservationField from './ObservationField';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation43';

type Props = ObservationProps<App.Observation43>;

const Observation43: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.count1 === undefined &&
            data.count2 === undefined &&
            data.count3 === undefined) return;

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const count1 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.count1 ?? undefined;
    }, [data]);

    const count2 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.count2 ?? undefined;
    }, [data]);

    const count3 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.count3 ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>Série 1: {internalData.current.previous.count1?.value ?? '-'}</div>
                    <div className={styles['previous']}>Série 2: {internalData.current.previous.count2?.value ?? '-'}</div>
                    <div className={styles['previous']}>Série 3: {internalData.current.previous.count3?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation43 = {
            ...internalData.current,
            count1: null,
            count2: null,
            count3: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCount1Change = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation43 = {
            ...internalData.current,
            count1: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCount2Change = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation43 = {
            ...internalData.current,
            count2: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCount3Change = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation43 = {
            ...internalData.current,
            count3: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="Comptages grappes"
        >
            <div className="row">
                <ObservationField
                    id={`${observationKey}-count1`}
                    label="Série 1"
                    offLabel="/10 ceps"
                    className={concatClassName(styles[`${observationKey}-count1`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={10}
                        value={count1}
                        onChange={handleCount1Change}
                        state={validationErrors['count1']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
                <ObservationField
                    id={`${observationKey}-count2`}
                    label="Série 2"
                    offLabel="/10 ceps"
                    className={concatClassName(styles[`${observationKey}-count2`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={10}
                        value={count2}
                        onChange={handleCount2Change}
                        state={validationErrors['count2']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
                <ObservationField
                    id={`${observationKey}-count3`}
                    label="Série 3"
                    offLabel="/10 ceps"
                    className={concatClassName(styles[`${observationKey}-count3`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={10}
                        value={count3}
                        onChange={handleCount3Change}
                        state={validationErrors['count3']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation43.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotDetailsPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation43.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation43;
