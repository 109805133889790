import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import tokenStore from 'utils/tokenStore';
import config from 'config';

export const getAll = async (year: number): Promise<App.Customer[]> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer', config.apiUrl, year);

        response = await httpService.get<App.Customer[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${getAll.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_GET_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_GET_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const get = async (year: number, customerId: App.CustomerDetails['id']): Promise<App.CustomerDetails> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}', config.apiUrl, year, customerId);

        response = await httpService.get<App.CustomerDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${get.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_GET_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_GET_DETAILS_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_GET_DETAILS_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_GET_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isPlainObject(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_GET_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getEmails = async (year: number, customerId: App.CustomerDetails['id']): Promise<App.CustomerEmail[]> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}/email', config.apiUrl, year, customerId);

        response = await httpService.get<App.CustomerEmail[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${getEmails.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_GET_EMAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_GET_EMAILS_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_GET_EMAILS_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_GET_EMAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_GET_EMAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

type CreateCustomerRequest = Pick<
    App.CustomerDetails,
    | 'publicId'
    | 'fullName'
>;

export const create = async (year: number, request: CreateCustomerRequest): Promise<App.CustomerKey> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer', config.apiUrl, year);
        const {
            publicId,
            fullName
        } = request;
        const requestData = {
            publicId,
            fullName
        };

        response = await httpService.post<App.CustomerKey>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_CREATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isPlainObject(response.body), 'invalid customer key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_CREATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

type UpdateCustomerRequest = {
    publicId: string;
    fullName: string;
    header: string | null;
    contactInfo: string | null;
    comment: string | null;
    technicalUser: number | null;
};

export const update = async (year: number, customerId: App.CustomerDetails['id'], request: UpdateCustomerRequest): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}', config.apiUrl, year, customerId);
        const {
            publicId,
            fullName,
            header,
            contactInfo,
            comment,
            technicalUser
        } = request;
        const requestData = {
            publicId,
            fullName,
            header,
            contactInfo,
            comment,
            technicalUser
        };

        response = await httpService.post<App.CustomerKey>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid customer key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const updateEmails = async (year: number, customerId: App.CustomerDetails['id'], emails: App.CustomerEmail[]): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');
    Ark.assertIsArray(emails, 'emails parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}/email', config.apiUrl, year, customerId);
        const requestData = emails;

        response = await httpService.post<App.CustomerKey>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${updateEmails.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_EMAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_EMAILS_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_EMAILS_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_UPDATE_EMAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid customer key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_UPDATE_EMAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deactivate = async (year: number, customerId: App.CustomerDetails['id']): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}/deactivate', config.apiUrl, year, customerId);

        response = await httpService.post<App.CustomerKey>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${deactivate.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_DEACTIVATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_DEACTIVATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_DEACTIVATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_DEACTIVATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid customer key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_DEACTIVATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const reactivate = async (year: number, customerId: App.CustomerDetails['id']): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}/reactivate', config.apiUrl, year, customerId);

        response = await httpService.post<App.CustomerKey>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling customer type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${reactivate.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_REACTIVATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_REACTIVATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_REACTIVATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_REACTIVATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid customer key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_REACTIVATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteCustomer = async (year: number, customerId: App.CustomerDetails['id']): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsValidNumber(customerId, 'customerId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/customer/{2}', config.apiUrl, year, customerId);

        response = await httpService.delete<App.CustomerKey>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete customer API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send customer request (${deleteCustomer.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('CUSTOMER_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('CUSTOMER_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('CUSTOMER_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'CUSTOMER_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid customer key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('CUSTOMER_DELETE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const getExportUrl = (year: number): string => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getExportUrl: unable to retrieve token.');

    return Ark.stringFormat('{0}/{1}/customer/export?access-token={2}', config.apiUrl, year, token);
};