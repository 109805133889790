import React from 'react';
import propTypes from 'prop-types';
import { concatClassName, propTypeNullable } from '@dateam/ark-react';
import { isBoolean } from '@dateam/ark';
import { Button, Card } from 'shared-ui';
import { useNavigation } from 'shared-utils';
import {
    refLabelNumberPropTypes,
    isRefLabelNumber
} from 'utils/propTypes';
import { customerDetailsPropTypes } from 'data/customer';
import { PlotDetailsNavigation } from 'screens/routes';
import { PlusIcon } from 'components/Icons';
import { CreatePlotModal } from 'components/CreatePlotModal';
import { SidePanelList } from 'components/SidePanelList';
import styles from '../../../CustomerDetailsScreen.module.scss';
import PlotItem from './PlotItem';

type Props = {
    customer: App.CustomerDetails;
    plots: App.Plot[];
    displayActions?: boolean;
    className?: string;
};

const PlotsPanel: React.FC<Props> = ({
    customer,
    plots,
    displayActions,
    className
}) => {
    const { push: navigate } = useNavigation();
    const [showCreatePlot, setShowCreatePlot] = React.useState(false);

    const handlePlotCreateCompletion = (plotId: App.PlotDetails['id']) => {
        navigate(PlotDetailsNavigation(plotId));
    };

    return (
        <Card className={className}>
            <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                <Card.Title>
                    Liste des parcelles
                </Card.Title>
                {displayActions && (
                    <Button
                        color="primary"
                        startIcon={(<PlusIcon />)}
                        onClick={() => setShowCreatePlot(true)}
                    >
                        Ajouter une parcelle
                    </Button>
                )}
                {showCreatePlot && (
                    <CreatePlotModal
                        customer={customer}
                        onClose={() => setShowCreatePlot(false)}
                        className={styles['modal']}
                        onCompletion={handlePlotCreateCompletion}
                    />
                )}
            </Card.Header>
            <Card.Body className={styles['cardContent']}>
                <SidePanelList>
                    {plots.map(plot => (<PlotItem key={plot.id} value={plot} />))}
                </SidePanelList>
            </Card.Body>
        </Card>
    );
};

PlotsPanel.propTypes = {
    customer: customerDetailsPropTypes.isRequired,
    plots: propTypes.arrayOf(propTypes.shape({
        id: propTypes.number.isRequired,
        year: propTypes.number.isRequired,
        publicId: propTypes.string.isRequired,
        activityId: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
        area: propTypeNullable(isRefLabelNumber),
        bio: refLabelNumberPropTypes.isRequired,
        varietal: propTypeNullable(isRefLabelNumber),
        customer: refLabelNumberPropTypes.isRequired,
        confusion: propTypeNullable(isBoolean),
        city: propTypes.string.isRequired,
        creationDate: propTypes.instanceOf(Date).isRequired,
        tracks: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired
    }).isRequired).isRequired,
    displayActions: propTypes.bool,
    className: propTypes.string
};

PlotsPanel.defaultProps = {
    displayActions: undefined,
    className: undefined
};

export default PlotsPanel;
