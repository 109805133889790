import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

type UpdateObservationObservationTypesRequest = {
    id: number;
    observationTypes: number[];
};

export const get = async (year: number): Promise<App.Observation[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observation', config.apiUrl, year);

        response = await httpService.get<App.Api.Observation[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling observation API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send observation request (get)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_GET_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_GET_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('OBSERVATION_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body.map(data => mapObservation(data));
};

export const updateObservationObservationTypes = async (
    year: number, 
    request: UpdateObservationObservationTypesRequest[]
):Promise<void> => {
    Ark.assertIsArray(request, 'updateObservationObservationTypes: request is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/observation/observationType', config.apiUrl, year);

        response = await httpService.post<number | null>(requestUrl, request);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling observation type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send observation request (${updateObservationObservationTypes.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('OBSERVATION_UPDATE_OBSERVATION_TYPES_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('OBSERVATION_UPDATE_OBSERVATION_TYPES_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('OBSERVATION_UPDATE_OBSERVATION_TYPES_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'OBSERVATION_UPDATE_OBSERVATION_TYPES_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assertIsArray(response.body, 'invalid observations id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('OBSERVATION_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

const mapObservation = ({
    id,
    label,
    types
}: App.Api.Observation): App.Observation => ({
    id,
    label,
    types
});
