import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import styles from '../PlotDetailsScreen.module.scss';

type Props = {
    types: App.InspectionPlot['observationTypes'];
    className?: string;
};

const SelectedObservationTypes: React.FC<Props> = ({
    types,
    className
}: Props) => {
    return (
        <ul className={concatClassName(styles['typeList'], className)}>
            {types?.map(type => (
                <li key={type.id} className={styles['typeItem']}>
                    {type.label}
                </li>
            ))}
        </ul>
    );
};

SelectedObservationTypes.propTypes = {
    types: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    className: propTypes.string
};

SelectedObservationTypes.defaultProps = {
    className: undefined
};

export default SelectedObservationTypes;
