import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import TrackScreen from './TrackScreen';

export const TrackNavigation = (): NavigationRoute => new NavigationRoute(
    TrackScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: TrackScreen.key,
        path: '',
        component: TrackScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;