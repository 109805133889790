import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { queryClient } from 'utils/queryClient';
import {
    ADMIN_PARAMETER_KEY,
    defaultActionOptions,
    defaultDataOptions,
    mutateResultKeys,
    MutationResult,
    QueryResult,
    queryResultKeys
} from './constants';
import { adminRequests } from './requests';

export const useAdminParameters = (year: number, options?: DataOptions): QueryResult<App.AdminParameters> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.AdminParameters, ServiceError>({
        ...config,
        queryKey: [ADMIN_PARAMETER_KEY, year],
        queryFn: () => adminRequests.getParameters(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useUpdateAdminParameters = (): MutationResult<void, App.UpdateAdminParametersRequest> => {
    const result = useMutation<void, ServiceError, App.UpdateAdminParametersRequest>(
        ({ ...request }: App.UpdateAdminParametersRequest) => {
            const { year, ...props } = request;

            return adminRequests.updateParameters(year, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: () => queryClient.invalidateQueries([ADMIN_PARAMETER_KEY])
        }
    );

    return result;
};

export const useUploadTemporaryReportTemplate = ():
    MutationResult<string | null, App.UploadTemporaryReportTemplateParametersRequest> => {
    const result = useMutation<string | null, ServiceError, App.UploadTemporaryReportTemplateParametersRequest>(
        ({ ...request }: App.UploadTemporaryReportTemplateParametersRequest) => {
            const { year, ...props } = request;

            return adminRequests.uploadTemporaryReportTemplate(year, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: () => queryClient.invalidateQueries([ADMIN_PARAMETER_KEY])
        }
    );

    return result;
};

export const useValidateTemporaryReportTemplate = ():
    MutationResult<void, App.ValidateTemporaryReportTemplate> => {
    const result = useMutation<void, ServiceError, App.ValidateTemporaryReportTemplate>(
        ({ ...request }: App.ValidateTemporaryReportTemplate) => {
            const { year } = request;

            return adminRequests.validateTemporaryReportTemplate(year);
        },
        {
            ...defaultActionOptions,
            onSuccess: () => queryClient.invalidateQueries([ADMIN_PARAMETER_KEY])
        }
    );

    return result;
};
