import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from '../SidePanelList.module.scss';

type Props = {
    children?: React.ReactNode;
    className?: string;
};

const SidePanelListItem: React.FC<Props> = ({ children, className }: Props) => (
    <div className={concatClassName(className, styles['listItem'])}>
        {children}
    </div>
);

SidePanelListItem.propTypes = {
    children: propTypes.node,
    className: propTypes.string
};

SidePanelListItem.defaultProps = {
    children: undefined,
    className: undefined
};

export default SidePanelListItem;
