import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Modal
} from 'shared-ui';
import { useYearState } from 'utils/yearStore';
import { useCreateYear } from 'data/year';
import styles from '../../MainLayout.module.scss';

type Props = {
    newYear: number;
    className?: string;
    onClose?: () => void;
    onCancel?: () => void;
    onCompletion?: (year: number) => void;
};

const CreateNewYearModal: React.FC<Props> = ({
    newYear,
    className,
    onCompletion,
    onClose,
    onCancel
}: Props) => {
    const { mutateAsync: createYear, isLoading: isCreating } = useCreateYear();
    const [, setYearState] = useYearState();

    const handleCancelClick = React.useCallback(() => {
        onCancel?.();
        onClose?.();
    }, [onCancel, onClose]);

    const handleCreateClick = React.useCallback(async () => {
        const year = await createYear();
        setYearState(year);

        onCompletion?.(year);

        onClose?.();
    }, [createYear, setYearState, onClose, onCompletion]);

    return (
        <Modal
            className={concatClassName(className, 'modal-flex')}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Démarrer l'année {newYear}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['newYearText']}>
                    Une copie des données (clients, parcelles, circuits et types de suivi) va être associée
                    à la nouvelle année afin que les futures modifications n'impactent pas les années précédentes.
                    <br />
                    Tout changement sera restreint à l'année associée.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleCancelClick}
                    disabled={isCreating}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={handleCreateClick}
                    pending={isCreating}
                >
                    Démarrer l'année {newYear}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreateNewYearModal.propTypes = {
    newYear: propTypes.number.isRequired,
    className: propTypes.string,
    onClose: propTypes.func,
    onCancel: propTypes.func,
    onCompletion: propTypes.func
};

CreateNewYearModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCancel: undefined,
    onCompletion: undefined
};

export default CreateNewYearModal;
