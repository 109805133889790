import React from 'react';
import propTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isArray, isDefined, noop, orderBy } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import {
    useNavigation,
    useNavigationEvent,
    useRouterRegistration,
    NavigationRoute
} from 'shared-utils';
import { Button, Card, Loader } from 'shared-ui';
import config from 'config';
import { useInspectionRecordsState } from 'utils/inspectionRecordsStore';
import { InspectionEntriesPlotNavigation, InspectionTabNavigation } from 'screens/routes';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import { SaveIcon } from 'components/Icons';
import {
    useInspectionRecord,
    computeInspectionRecords,
    useSaveInspectionRecord,
    reconcileSync
} from 'data/inspection';
import {
    EntrySectionHeader,
    PlotInfos,
    PlotList,
    PlotObservations
} from './components';
import styles from '../../InspectionEditScreen.module.scss';

export const TAB_KEY = 'entry';

const PENDING_CHANGES_MESSAGE = 'Il reste des modifications en attente d\'enregistrement, êtes-vous sûr de vouloir quitter la page ?';
const pendingChangesHandler = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    ev.returnValue = PENDING_CHANGES_MESSAGE;
    return PENDING_CHANGES_MESSAGE;
};

type Props = {
    inspectionId: number;
};

const EntrySection: React.FC<Props> = ({ inspectionId }: Props) => {
    const { t } = useTranslation();
    const [router] = useRouterRegistration();
    const {
        query: { plotId: plotIdParam },
        push: navigate,
        location
    } = useNavigation();
    const [inspectionRecordsState, setInspectionRecordsState] = useInspectionRecordsState();
    const { data: inspection, isLoading } = useInspectionRecord(inspectionId);
    const { mutateAsync: save, isLoading: isSaving } = useSaveInspectionRecord();
    const [selected, setSelected] = React.useState<App.InspectionPlotWithRecord | null>(null);

    // Clears inspection record state on leaving the component
    React.useEffect(() => () => setInspectionRecordsState(null), [setInspectionRecordsState]);

    // Bind pending changes handlers to prevent navigation out
    React.useEffect(() => {
        if (inspectionRecordsState?.hasChanged === true) {
            globalThis.addEventListener('beforeunload', pendingChangesHandler);
        }

        return () => globalThis.removeEventListener('beforeunload', pendingChangesHandler);
    }, [inspectionRecordsState]);

    React.useEffect(() => {
        if (!isDefined(inspection)) return noop;

        return setInspectionRecordsState(prevState => {
            const reconciledInspection = reconcileSync(inspection, prevState);

            return reconciledInspection;
        });
    }, [inspection, setInspectionRecordsState]);

    // inspectionRecordsState?.hasChanged

    React.useEffect(() => {
        if (!isDefined(inspectionRecordsState) ||
            isDefined(plotIdParam) ||
            inspectionId !== inspectionRecordsState.id) {
            return;
        }

        if (isArray(inspectionRecordsState.plots) && inspectionRecordsState.plots.length > 0) {
            const plotList = [...inspectionRecordsState.plots];

            orderBy(plotList, 'position');
            const [firstPlot] = plotList;

            navigate(InspectionEntriesPlotNavigation(inspectionId, firstPlot.id));
        }
    }, [inspectionId, inspectionRecordsState, plotIdParam, navigate]);

    React.useEffect(() => {
        const plots = inspectionRecordsState?.plots;

        if (isArray(plots) && plotIdParam != null) {
            const plotId = parseInt(plotIdParam, 10);
            const plotFound = plots.find(plot => plot.id === plotId);

            if (plotFound != null) return setSelected(plotFound);

            navigate(InspectionTabNavigation(inspectionId, TAB_KEY));
            return;
        }

        return setSelected(null);
    }, [inspectionId, inspectionRecordsState, plotIdParam, setSelected, navigate]);

    useNavigationEvent(async (route, resolve, reject) => {
        if (inspectionRecordsState?.hasChanged === true && route instanceof NavigationRoute) {
            const currentEntryRouteNavigation = InspectionEntriesPlotNavigation(
                inspectionId,
                parseInt(plotIdParam, 10)
            );

            // If the route being navigated to is not an entry route (matching with route key)
            // prompt the user to warn about pending changes
            // eslint-disable-next-line no-alert
            if (currentEntryRouteNavigation.key !== route.key && !window.confirm(PENDING_CHANGES_MESSAGE)) {
                reject();
                return;
            }
        }

        resolve();
    }, [inspectionRecordsState, location.pathname, inspectionId, plotIdParam, router]);

    const handleOnSelect = React.useCallback((plot: App.InspectionPlotWithRecord) => {
        navigate(InspectionEntriesPlotNavigation(inspectionId, plot.id));
    }, [inspectionId, navigate]);

    const handleSaveInspectionRecords = React.useCallback(async () => {
        if (!isDefined(inspection)) return;

        try {
            await save();
        }
        catch {
            // Ignore
        }
    }, [save, inspection]);

    const handleCancelChanges = React.useCallback(() => {
        if (!isDefined(inspection)) return;

        const computedInspection = computeInspectionRecords(inspection);

        setInspectionRecordsState(computedInspection);
    }, [setInspectionRecordsState, inspection]);

    const saveDisabled = React.useMemo(() => {
        if (inspectionRecordsState == null) return true;
        if (isSaving === true) return true;

        return inspectionRecordsState.hasChanged === false || inspectionRecordsState.isValid === false;
    }, [inspectionRecordsState, isSaving]);

    const cancelDisabled = React.useMemo(() => {
        if (inspectionRecordsState == null) return true;
        if (isSaving === true) return true;

        return inspectionRecordsState.hasChanged === false;
    }, [inspectionRecordsState, isSaving]);

    return (
        <>
            {isLoading && (
                <Loader text="chargement" />
            )}
            <Helmet>
                <title>{t('inspection.details.entry.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page.Content className="row">
                <Card className="col">
                    <Card.Header className={concatClassName('withAction')}>
                        <Card.Title>
                            Saisies {inspectionRecordsState?.label}
                        </Card.Title>
                        <EntrySectionHeader inspection={inspectionRecordsState} />
                    </Card.Header>
                    <Card.Body className="row">
                        <ListSection className="col col-3">
                            <ListSection.Filter>
                                <div className={styles['colHeader']}>Parcelles</div>
                            </ListSection.Filter>
                            <ListSection.Content>
                                {inspectionRecordsState != null && inspectionRecordsState.plots.length > 0 && (
                                    <PlotList
                                        data={inspectionRecordsState?.plots}
                                        selected={selected}
                                        onSelectPlot={handleOnSelect}
                                    />
                                )}
                            </ListSection.Content>
                        </ListSection>
                        <ListSection className="col">
                            <ListSection.Filter>
                                <div className={styles['colHeader']}>Observations saisies</div>
                            </ListSection.Filter>
                            <ListSection.Content>
                                {selected?.ignored === true && (
                                    <div className="alert alert-warning">
                                        Parcelle non observable : {selected.ignoredReason}
                                    </div>
                                )}
                                {inspectionRecordsState != null && (
                                    <PlotObservations
                                        inspectionId={inspectionId}
                                        data={selected}
                                    />
                                )}
                            </ListSection.Content>
                        </ListSection>
                        <ListSection className="col col-4">
                            <ListSection.Filter>
                                <div className={styles['colHeader']}>Informations sur la parcelle</div>
                            </ListSection.Filter>
                            <ListSection.Content>
                                {inspectionRecordsState != null && selected != null && (
                                    <PlotInfos data={selected} />
                                )}
                            </ListSection.Content>
                        </ListSection>
                    </Card.Body>
                    <Card.Footer>
                        <Card.Actions>
                            <Button
                                color="secondary"
                                onClick={handleCancelChanges}
                                disabled={cancelDisabled}
                            >
                                Annuler
                            </Button>
                            <Button
                                startIcon={<SaveIcon />}
                                color="primary"
                                onClick={handleSaveInspectionRecords}
                                disabled={saveDisabled}
                                pending={isSaving}
                            >
                                Enregistrer les modifications
                            </Button>
                        </Card.Actions>
                    </Card.Footer>
                </Card>
            </Page.Content>
        </>
    );
};

EntrySection.propTypes = {
    inspectionId: propTypes.number.isRequired
};

EntrySection.defaultProps = {
};

export default EntrySection;
