import React from 'react';
import propTypes from 'prop-types';
import { isString, quickClone, removeFrom } from '@dateam/ark';
import {
    Button,
    Form,
    Modal,
    Table
} from 'shared-ui';
import { reportObservationsPropTypes } from 'data/inspection';
import styles from '../ReportSection.module.scss';

type Props = {
    data: App.Report.ReportObservation;
    display: string;
    className?: string;
    onSave?: (value: App.Report.ReportObservation) => void;
    onClose?: () => void;
};

const OverWrittenTableModal: React.FC<Props> = ({
    data,
    display,
    className,
    onSave,
    onClose
}: Props) => {
    const [observationState, setObservationState] = React.useState<App.Report.ReportObservation | null>(null);

    React.useEffect(() => {
        const newObservation = quickClone(data);

        if (observationState == null) setObservationState(newObservation);
    }, [observationState, data, setObservationState]);

    React.useEffect(() => {
        if (display !== 'values' && display !== 'previous') return;

        const handler = (event: any) => {
            if (event.target?.nodeName?.toLowerCase() === 'input') return;

            event.preventDefault();

            const pastedData = event.clipboardData?.getData('text');

            if (!isString(pastedData) || pastedData.trim().length === 0) return;

            console.log(pastedData.split('\n').map(item => item.split('\t')));
            const cellData = pastedData.split('\n').map(item => item.split('\t'));
            if (cellData.length === 0 || cellData[0].length === 0) return;

            setObservationState(previousState => {
                if (previousState == null) return previousState;

                let newPlots = [...previousState.records.plots];

                const columnCount = previousState.records.headers.length;
                if (columnCount < cellData[0].length) return previousState;

                const rowCount = previousState.records.plots.length;

                newPlots = newPlots.map((plot, idx) => {
                    const row = cellData[idx];
                    if (row == null) return plot;

                    // Fill cells starting upper right of table
                    if (columnCount > row.length) {
                        const entries = [...plot[display]];
                        console.log('ENTRIES');

                        removeFrom(entries, entries.length - row.length);
                        entries.push(...row);
                        console.log(entries);

                        return {
                            ...plot,
                            [display]: entries
                        };
                    }

                    return plot;
                });

                const newData = {
                    ...previousState,
                    manualEdit: true,
                    records: {
                        ...previousState.records,
                        plots: newPlots
                    }
                };

                return newData;
            });
        };

        globalThis.addEventListener('paste', handler);

        return () => {
            globalThis.removeEventListener('paste', handler);
        };
    }, [display]);

    const handleChange = React.useCallback((plotId: string, idx: number) => (value: string) => {
        if (observationState == null) return;

        const plotIndex = observationState.records.plots.findIndex(item => item.id === plotId);

        if (plotIndex < 0) return;

        const plot = observationState.records.plots[plotIndex];

        if (!Object.keys(plot).includes(display)) return;

        const newPlots = [...observationState.records.plots];
        const values = [...plot[display as keyof typeof plot]];

        values[idx] = value;

        newPlots[plotIndex] = {
            ...plot,
            [display]: values
        };

        const newData = {
            ...observationState,
            manualEdit: true,
            records: {
                ...observationState.records,
                plots: newPlots
            }
        };

        setObservationState(newData);
    }, [observationState, display]);

    const handleClose = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleSave = React.useCallback(() => {
        if (observationState == null) return;

        onSave?.(observationState);
        onClose?.();
    }, [onSave, observationState, onClose]);

    if (observationState === null) return null;

    return (
        <Modal
            className={`${styles['modal']} ${className} modal-flex`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Modifier les valeurs
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table<App.Report.ReportPlot> data={observationState.records.plots}>
                    <Table.Column className={styles['plotCell']}>
                        <Table.Column.Header></Table.Column.Header>
                        <Table.Column.Cell>
                            {(props: any) => (<div>{props.data.label}</div>)}
                        </Table.Column.Cell>
                    </Table.Column>
                    {observationState.records.headers.map((item, idx) => (
                        <Table.Column key={`${item}-${display}`}>
                            <Table.Column.Header>{item}</Table.Column.Header>
                            <Table.Column.Cell>
                                {(props: any) => (
                                    <Form.Input
                                        value={props.data[display][idx]}
                                        onChange={handleChange(props.data.id, idx)}
                                    />
                                )}
                            </Table.Column.Cell>
                        </Table.Column>
                    ))}
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={handleSave}
                >
                    Modifier
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

OverWrittenTableModal.propTypes = {
    data: reportObservationsPropTypes.isRequired,
    display: propTypes.string.isRequired,
    className: propTypes.string,
    onSave: propTypes.func,
    onClose: propTypes.func
};

OverWrittenTableModal.defaultProps = {
    className: undefined,
    onSave: undefined,
    onClose: undefined
};

export default OverWrittenTableModal;
