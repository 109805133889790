import { Router, RouteData, NavigationRoute } from 'shared-utils';
import UserScreen from './UserScreen';

export const UserNavigation = (): NavigationRoute => new NavigationRoute(UserScreen.key);

const router = new Router();

router
    .add(new RouteData({
        key: UserScreen.key,
        path: '',
        component: UserScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator']
        }
    }));

export default router;