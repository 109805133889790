import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import ObservationScreen from './ObservationScreen';

export const ObservationNavigation = (): NavigationRoute => new NavigationRoute(
    ObservationScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: ObservationScreen.key,
        path: '',
        component: ObservationScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;