import * as Ark from '@dateam/ark';
import { assert, assertIsArray, HttpStatusCode, isDefined, ServiceError, stringFormat } from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';
import tokenStore from 'utils/tokenStore';

export const getParameters = async (year: number): Promise<App.AdminParameters> => {
    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/admin', config.apiUrl, year);

        response = await httpService.get<App.Api.AdminParameters>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling admin type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send admin request (getParameters)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('ADMIN_GET_TYPE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('ADMIN_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'ADMIN_GET_TYPE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsDefined(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('ADMIN_GET_TYPE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return mapAdminParameters(response.body);
};

export const updateParameters = async (year: number, request: App.Api.UpdateAdminParametersRequest): Promise<void> => {
    Ark.assertIsDefined(year, 'year is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/admin', config.apiUrl, year);
        const { reportNextInspection, notifInspectionDelay, deviceSpadFactor, deviceYaraFactor } = request;
        const requestData = { reportNextInspection, notifInspectionDelay, deviceSpadFactor, deviceYaraFactor };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling admin type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send admin type request (${updateParameters.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'ADMIN_TYPE_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
    }
    catch (err) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const uploadTemporaryReportTemplate = async (
    year: number,
    request: App.Api.UploadTemporaryReportTemplateParametersRequest
): Promise<string | null> => {
    Ark.assertIsDefined(year, 'year is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/admin/reportTemplate', config.apiUrl, year);

        const requestData = new FormData();
        if (request.header != null) {
            requestData.append('files', request.header, 'report-header.docx');
        }
        if (request.footer != null) {
            requestData.append('files', request.footer, 'report-footer.docx');
        }

        response = await httpService.post<string>(
            requestUrl,
            requestData,
            {
                headers: { 'Content-Type': undefined }
            }
        );
    }
    catch (err) {
        console.log(err);
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling admin type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send admin upload template request (${updateParameters.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'ADMIN_TYPE_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    const body = await (response.body as Response).text();

    return Ark.isString(body) ? body : null;
};

export const validateTemporaryReportTemplate = async (year: number): Promise<void> => {
    Ark.assertIsDefined(year, 'year is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/admin/reportTemplate/validate', config.apiUrl, year);

        response = await httpService.post(requestUrl, null);
    }
    catch (err) {
        console.log(err);
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling admin type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send admin validate report template request (${updateParameters.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('ADMIN_TYPE_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'ADMIN_TYPE_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const getReportPreviewDownloadUrl = (year: number): string => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getReportPreviewDownloadUrl: unable to retrieve token.');

    return stringFormat('{0}/{1}/admin/reportTemplate/preview?access-token={2}', config.apiUrl, year, token);
};

export const getReportHeaderTemplateDownloadUrl = (year: number): string => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getReportHeaderTemplateDownloadUrl: unable to retrieve token.');

    return stringFormat('{0}/{1}/admin/reportTemplate/downloadHeader?access-token={2}', config.apiUrl, year, token);
};

export const getReportFooterTemplateDownloadUrl = (year: number): string => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');

    const token = tokenStore.get();
    if (token == null) throw new Error('getReportFooterTemplateDownloadUrl: unable to retrieve token.');

    return stringFormat('{0}/{1}/admin/reportTemplate/downloadFooter?access-token={2}', config.apiUrl, year, token);
};

const mapAdminParameters = ({
    reportNextInspection,
    notifInspectionDelay,
    deviceSpadFactor,
    deviceYaraFactor
}: App.Api.AdminParameters): App.AdminParameters => ({
    reportNextInspection,
    notifInspectionDelay,
    deviceSpadFactor,
    deviceYaraFactor
});
