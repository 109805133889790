import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as Ark from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { Button, Card, ConfirmDialog, Form, Loader } from 'shared-ui';
import Page from 'components/Page';
import { SidePanelList, SidePanelListItem, SidePanelListItemCol } from 'components/SidePanelList';
import { BioTypeNavigation } from 'screens/routes';
import { TrashIcon } from 'components/Icons';
import { errorProvider } from 'components/errorHandler';
import { FooterDateInfo } from 'components/FooterDateInfo';
import { useBioType, useDeleteBioType, useUpdateBioType } from 'data/bio';
import config from 'config';
import BioTypeDetailsBreadcrumb from './BioTypeDetailsBreadcrumb';
import styles from './BioTypeDetailsScreen.module.scss';

const BioTypeDetailsScreen: React.FC = () => {
    const { t } = useTranslation();
    const {
        push: navigate,
        query: { bioTypeId: bioTypeIdParam },
        replace: replaceNavigation
    } = useNavigation();
    const bioTypeId = React.useMemo(() => parseInt(bioTypeIdParam, 10), [bioTypeIdParam]);
    const { data: type, isLoading, isFetched: hasLoaded } = useBioType(bioTypeId);
    const { mutateAsync: updateBioType, isLoading: isUpdating } = useUpdateBioType();
    const { mutateAsync: deleteBioType, isLoading: isDeleting } = useDeleteBioType();
    const [showDeletionConfirm, setShowDeletionConfirm] = React.useState(false);

    const typeState = React.useRef(type);

    React.useEffect(() => {
        typeState.current = type;
    }, [type]);

    React.useEffect(() => {
        if (type == null && hasLoaded) {
            replaceNavigation(BioTypeNavigation());
        }
    }, [type, hasLoaded, replaceNavigation]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: any) => {
        if (!Ark.isDefined(typeState.current)) throw new Error('Le type de protection n\'a pas été trouvé.');

        try {
            const {
                id,
                label
            } = typeState.current;

            await updateBioType({
                id,
                label,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof Ark.ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }
    }, [typeState, updateBioType]);

    const handleBioTypeDeletion = React.useCallback(async (shouldDelete: boolean) => {
        if (shouldDelete === false) return setShowDeletionConfirm(false);
        if (!Ark.isDefined(typeState.current)) throw new Error('Le type de protection n\'a pas été trouvé.');

        await deleteBioType(typeState.current.id);

        setShowDeletionConfirm(false);
        navigate(BioTypeNavigation());
    }, [typeState, deleteBioType, navigate, setShowDeletionConfirm]);

    if (!Ark.isDefined(type)) return null;

    return (
        <>
            <Helmet>
                <title>{t('bioType.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {type?.label}
                    </Page.Title>
                    <Page.HeaderActions>
                        <BioTypeDetailsBreadcrumb label={type?.label ?? ''} />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Informations du type de protection
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <Form.Group controlId="bioType-label" className="col col-6">
                                <Form.Label>Nom</Form.Label>
                                <Form.Editable.Input
                                    value={type?.label}
                                    onSave={handleSaveInput('label')}
                                />
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer className="row">
                            {Ark.isDefined(type) && (
                                <Card.Infos>
                                    <FooterDateInfo creationDate={type?.creationDate} />
                                </Card.Infos>
                            )}
                            <Card.Actions>
                                <Button
                                    color="danger"
                                    variant="outlined"
                                    startIcon={(<TrashIcon />)}
                                    pending={isDeleting}
                                    disabled={isDeleting || isUpdating}
                                    // can't be deleted if readOnly obsType
                                    onClick={() => setShowDeletionConfirm(true)}
                                >
                                    Supprimer le type de protection
                                </Button>
                                {showDeletionConfirm && (
                                    <ConfirmDialog
                                        title={`Supprimer le type de protection "${type?.label}"`}
                                        message="Vous êtes sur le point de supprimer le type de protection. Êtes-vous sûr de vouloir continuer ?"
                                        ok="Supprimer"
                                        onClose={handleBioTypeDeletion}
                                    />
                                )}
                            </Card.Actions>
                        </Card.Footer>
                    </Card>
                    <Card className="col col-4">
                        <Card.Header>
                            <Card.Title>
                                Utilisation
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className={styles['cardContent']}>
                            <SidePanelList>
                                <SidePanelListItem>
                                    <SidePanelListItemCol className="col-8" text="Nombre de parcelles associées" />
                                    <SidePanelListItemCol className="col-4" text={Ark.isNumber(type?.plotCount) ? type?.plotCount.toString() : '0'} />
                                </SidePanelListItem>
                            </SidePanelList>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(BioTypeDetailsScreen, 'BIOTYPE_DETAILS');