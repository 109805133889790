import React from 'react';
import propTypes from 'prop-types';
import {
    dateToFormat,
    isString,
    isValidDate
} from '@dateam/ark';
import { concatClassName, propTypeAllowUndefined } from '@dateam/ark-react';
import styles from '../../../DashboardScreen.module.scss';

type Props = {
    step: {
        index: number;
        key: string;
        label: string;
        date?: Date | null;
        subtext?: string;
    };
    stepCount: number;
    activeStepIndex: number;
    className?: string;
};

const Step: React.FC<Props> = ({
    step,
    stepCount,
    activeStepIndex,
    className
}) => {
    const isFistStep = step.index === 0;
    const isLastStep = step.index === stepCount - 1;
    const isActive = step.index <= activeStepIndex;
    const isNextStepDisabled = activeStepIndex - step.index === 0;
    const isPlanned = isFistStep && isNextStepDisabled;
    const isValidated = activeStepIndex === 3;

    const date = React.useMemo(() => {
        if (!isValidDate(step.date)) return '';

        return dateToFormat(step.date, 'dd/MM HHhmm');
    }, [step]);

    return (
        <div
            className={concatClassName(
                styles['step'],
                isFistStep ? styles['firstStep'] : undefined,
                isLastStep ? styles['lastStep'] : undefined,
                isActive ? styles['step-active'] : undefined,
                isNextStepDisabled ? styles['nextStepDisabled'] : undefined,
                isPlanned ? styles['plannedStep'] : undefined,
                isValidated ? styles['validatedStep'] : undefined,
                className
            )}
        >
            <div className={styles['stepDate']}>{date}</div>
            <div className={concatClassName(styles['stepCircle'])} />
            <div className={concatClassName(styles['stepStatus'])}>{step.label}</div>
            <div className={styles['stepProgress']}>
                {isString(step.subtext) ? step.subtext : ''}
            </div>
        </div>
    );
};

Step.propTypes = {
    step: propTypes.shape({
        index: propTypes.number.isRequired,
        key: propTypes.string.isRequired,
        label: propTypes.string.isRequired,
        date: propTypeAllowUndefined(isValidDate),
        subtext: propTypeAllowUndefined(isString)
    }).isRequired,
    stepCount: propTypes.number.isRequired,
    activeStepIndex: propTypes.number.isRequired,
    className: propTypes.string
};

Step.defaultProps = {
    className: undefined
};

export default Step;
