import React from 'react';
import { isArray, overrideDateJsonAsUTC, toJSON } from '@dateam/ark';
import { appInsights, initAppInsights, SeverityLevel, SeverityLevelType } from 'utils/appInsights';
import logger, { LoggerLevel, parseLoggerLevel } from 'utils/logger';
import storageProvider from 'utils/storageProvider';
import httpService from 'utils/httpService';
import tokenStore from 'utils/tokenStore';
import { getInitStarted, setInitStarted } from 'utils/app';
import config from 'config';
import { useRenewAuth } from 'data/user';

const useAppInitialize = (): boolean => {
    const { mutateAsync: renewAuth } = useRenewAuth();
    const [isReady, setIsReady] = React.useState(false);

    React.useEffect(() => {
        if (getInitStarted() === true) return;

        setInitStarted(true);

        // Initializing Providers ...
        tokenStore.setStore(storageProvider);
        logger.setLevel(parseLoggerLevel(config.log.level) ?? LoggerLevel.DEBUG);
        logger.setHandler([], log => {
            if (!isArray(log.args) || log.args.length === 0) return;
            let severityLevel: SeverityLevelType;

            switch (log.level) {
                case LoggerLevel.DEBUG:
                    severityLevel = SeverityLevel.Information;
                    break;
                case LoggerLevel.LOG:
                    severityLevel = SeverityLevel.Information;
                    break;
                case LoggerLevel.WARN:
                    severityLevel = SeverityLevel.Warning;
                    break;
                case LoggerLevel.ERROR:
                    severityLevel = SeverityLevel.Error;
                    break;
                default:
                    severityLevel = SeverityLevel.Information;
                    break;
            }

            appInsights?.trackTrace({
                message: log.args[0]?.toString() ?? toJSON(log.args[0]),
                severityLevel
            }, { args: log.args });
        });
        httpService
            .setTokenProvider(tokenStore)
            .setLogger(logger);
        overrideDateJsonAsUTC();
        initAppInsights();

        // Initializing App ...
        const authToken = tokenStore.get();
        if (authToken != null) {
            renewAuth().finally(() => setIsReady(true));
        }
        else {
            setIsReady(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return React.useMemo(() => isReady, [isReady]);
};

export default useAppInitialize;