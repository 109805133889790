import React from 'react';
import propTypes from 'prop-types';
import { isFunction } from '@dateam/ark';
import { Button } from 'shared-ui';
import { PlusIcon } from 'components/Icons';
import PlotSelector from 'components/PlotSelector';

type Props = {
    onPlotAdded?: (plotIds: number[]) => unknown;
};

const TrackDetailsHeader: React.FC<Props> = ({ onPlotAdded }: Props) => {
    const [showPlotSelector, setShowPlotSelector] = React.useState(false);

    const addPlot = React.useCallback((plotIds: number[]) => {
        setShowPlotSelector(false);

        if (isFunction(onPlotAdded)) onPlotAdded(plotIds);
    }, [onPlotAdded, setShowPlotSelector]);

    return (
        <div>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={() => setShowPlotSelector(true)}
            >
                Ajouter des parcelles
            </Button>

            {showPlotSelector && (
                <PlotSelector
                    onClose={() => setShowPlotSelector(false)}
                    onAdd={addPlot}
                />
            )}
        </div>
    );
};

TrackDetailsHeader.propTypes = {
    onPlotAdded: propTypes.func
};

TrackDetailsHeader.defaultProps = {
    onPlotAdded: undefined
};

export default TrackDetailsHeader;
