import React from 'react';
import * as Ark from '@dateam/ark';
import * as ArkReact from '@dateam/ark-react';
import { NavigationRoute, useNavigation } from 'shared-utils';

const yearGlobal = ArkReact.createGlobalStateHook<number>(new Date().getFullYear());

export const useYearState = yearGlobal.hook;
export const setYearState = yearGlobal.setState;
export const getYearState = yearGlobal.getState;

export const getYearRouteParam = (): number | undefined => {
    const selectedYear = getYearState();
    const currentYear = new Date().getFullYear();

    if (selectedYear === currentYear) return undefined;

    return selectedYear;
};

export const useYearlyNavigation =
    (route: (...args: any[]) => NavigationRoute, args: any[]): NavigationRoute => {
        const [year] = useYearState();

        return React.useMemo(() => route(...args), [year, ...args]); // eslint-disable-line react-hooks/exhaustive-deps
    };

export const useYearlyRootNavigation =
    (routes: Record<string, (year: number) => NavigationRoute>): Record<string, NavigationRoute> => {
        const [year] = useYearState();

        return React.useMemo(() => Ark.mapEachProperty(
            routes,
            (navigation: (year: number) => NavigationRoute) => navigation(year)
        ), [
            routes,
            year
        ]);
    };

export const useBindYearNavigation =
    (route: (...args: any[]) => NavigationRoute, args: any[]): void => {
        const { push: navigate } = useNavigation();
        const navParams = React.useRef<any[]>([]);
        const [year] = useYearState();
        const prevYear = ArkReact.usePrevious(year);

        React.useEffect(() => {
            navParams.current = args ?? [];
        }, [...args]); // eslint-disable-line react-hooks/exhaustive-deps

        React.useEffect(() => {
            if (prevYear === year) return;

            const navigationRoute = route(...navParams.current);

            navigate(navigationRoute);
        }, [year, navParams, prevYear]); // eslint-disable-line react-hooks/exhaustive-deps
    };