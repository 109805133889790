import React from 'react';
import propTypes from 'prop-types';
import { propTypeNullable } from '@dateam/ark-react';
import { isString, isValidDate, isNumber, isDefined } from '@dateam/ark';
import { Button, ConfirmDialog } from 'shared-ui';
import { getYearState } from 'utils/yearStore';
import { CheckIcon, DownloadIcon } from 'components/Icons';
import { useValidateInspection } from 'data/inspection';
import { inspectionRequests } from 'data/requests';
import { plotPropType } from './types';
import styles from '../../../InspectionEditScreen.module.scss';

type Props = {
    inspection?: App.InspectionWithRecord | null;
};

const EntrySectionHeader: React.FC<Props> = ({ inspection }: Props) => {
    const { mutateAsync: validate, isLoading: isValidating } = useValidateInspection();
    const [showValidationDialog, setShowValidationDialog] = React.useState(false);

    const validateInspection = React.useCallback(() => {
        if (inspection != null) validate(inspection.id);
    }, [inspection, validate]);

    const openValidationDialog = React.useCallback(() => {
        if (isValidating) return;

        setShowValidationDialog(true);
    }, [isValidating, setShowValidationDialog]);

    const closeValidationDialog = React.useCallback(async (confirmed: boolean) => {
        setShowValidationDialog(false);

        if (confirmed !== true || isValidating) return;

        validateInspection();
    }, [validateInspection, isValidating]);

    const exportUrl = React.useMemo(() => {
        if (inspection == null) return '#';
        const year = getYearState();

        return inspectionRequests.getExportUrl(year, inspection.id);
    }, [inspection]);

    return (
        <>
            <div className={styles['headerActions']}>
                <Button
                    color="primary"
                    type="link"
                    startIcon={(<DownloadIcon />)}
                    className={styles['actionButton']}
                    link={exportUrl}
                    target="_blank"
                    rel="noreferrer"
                    disabled={!isDefined(inspection) || isValidating}
                >
                    Exporter
                </Button>
                <Button
                    color="primary"
                    startIcon={(<CheckIcon />)}
                    onClick={openValidationDialog}
                    disabled={!isDefined(inspection) || isValidDate(inspection.validationDate)}
                    pending={isValidating}
                    className={styles['actionButton']}
                >
                    Valider la visite
                </Button>
            </div>
            {showValidationDialog && (
                <ConfirmDialog
                    title="Valider la visite"
                    message="La validation de la visite va être forcée. Voulez-vous continuer ?"
                    ok="Valider"
                    onClose={closeValidationDialog}
                />
            )}
        </>
    );
};

EntrySectionHeader.propTypes = {
    inspection: propTypes.shape({
        id: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        instruction: propTypeNullable(isString),
        campaignInstruction: propTypeNullable(isString),
        startDate: propTypeNullable(isValidDate),
        overlapDays: propTypeNullable(isNumber),
        plots: propTypes.arrayOf(plotPropType.isRequired).isRequired,
        validationDate: propTypeNullable(isValidDate)
    })
};

EntrySectionHeader.defaultProps = {
    inspection: undefined
};

export default EntrySectionHeader;
