import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import BioTypeDetailsScreen from './BioTypeDetailsScreen';

export const BioTypeDetailsNavigation = (bioTypeId: number): NavigationRoute =>
    new NavigationRoute(
        BioTypeDetailsScreen.key,
        [getYearRouteParam(), bioTypeId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: BioTypeDetailsScreen.key,
        path: '/<:bioTypeId>', // Should have matched the pattern <:bioTypeId([\\d]+)> if react-router V6 supported it
        component: BioTypeDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;