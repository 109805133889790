import React from 'react';
import { useMutation, useQuery } from 'react-query';
import propTypes from 'prop-types';
import { computeOptions, isBoolean, isString, isValidDate, ServiceError } from '@dateam/ark';
import { propTypeNullable, usePick } from '@dateam/ark-react';
import {
    isRefLabelNumber,
    refLabelNumberPropTypes,
    refLabelStringPropTypes
} from 'utils/propTypes';
import { useYearState } from 'utils/yearStore';
import { queryClient } from 'utils/queryClient';
import {
    CUSTOMER_DETAILS_KEY,
    CUSTOMER_EMAIL_KEY,
    CUSTOMER_KEY,
    defaultActionOptions,
    defaultDataOptions,
    mutateResultKeys,
    MutationResult,
    PLOT_KEY,
    QueryResult,
    queryResultKeys
} from './constants';
import { customerRequests } from './requests';

export const useCustomers = (options?: DataOptions): QueryResult<App.Customer[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Customer[], ServiceError>({
        ...config,
        queryKey: [year, CUSTOMER_KEY],
        queryFn: () => customerRequests.getAll(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCustomerDetails = (customerId: App.CustomerDetails['id'], options?: DataOptions): QueryResult<App.CustomerDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.CustomerDetails, ServiceError>({
        ...config,
        queryKey: [year, CUSTOMER_DETAILS_KEY, customerId],
        queryFn: () => customerRequests.get(year, customerId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCustomerEmails = (customerId: App.CustomerDetails['id'], options?: DataOptions): QueryResult<App.CustomerEmail[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.CustomerEmail[], ServiceError>({
        ...config,
        queryKey: [year, CUSTOMER_DETAILS_KEY, customerId, CUSTOMER_EMAIL_KEY],
        queryFn: () => customerRequests.getEmails(year, customerId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateCustomer = (): MutationResult<App.CustomerKey, App.CreateCustomerRequest> => {
    const [year] = useYearState();
    const result = useMutation<App.CustomerKey, ServiceError, App.CreateCustomerRequest>(
        (request: App.CreateCustomerRequest) => customerRequests.create(year, { ...request }),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([year, CUSTOMER_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateCustomer = (): MutationResult<void, App.UpdateCustomerRequest> => {
    const result = useMutation<void, ServiceError, App.UpdateCustomerRequest>(
        (request: App.UpdateCustomerRequest) => {
            const {
                id,
                year,
                ...props
            } = request;

            return customerRequests.update(year, id, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id, year }) => {
                queryClient.invalidateQueries([year, CUSTOMER_KEY]);
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};

export const useUpdateCustomerEmails = (): MutationResult<void, App.UpdateCustomerEmailsRequest> => {
    const result = useMutation<void, ServiceError, App.UpdateCustomerEmailsRequest>(
        (request: App.UpdateCustomerEmailsRequest) => {
            const {
                id,
                year,
                emails
            } = request;

            return customerRequests.updateEmails(year, id, emails);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id, year }) => {
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY, id, CUSTOMER_EMAIL_KEY]);
            }
        }
    );

    return result;
};

export const useDeactivateCustomer = (): MutationResult<void, App.CustomerDetails['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.CustomerDetails['id']>(
        id => customerRequests.deactivate(year, id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, CUSTOMER_KEY]);
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, PLOT_KEY]);
            }
        }
    );

    return result;
};

export const useReactivateCustomer = (): MutationResult<void, App.CustomerDetails['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.CustomerDetails['id']>(
        id => customerRequests.reactivate(year, id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, CUSTOMER_KEY]);
                queryClient.invalidateQueries([year, CUSTOMER_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, PLOT_KEY]);
            }
        }
    );

    return result;
};

export const useDeleteCustomer = (): MutationResult<void, App.CustomerDetails['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.CustomerDetails['id']>(
        id => customerRequests.deleteCustomer(year, id),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([year, CUSTOMER_KEY]);
            }
        }
    );

    return result;
};

export const customerPropTypes = propTypes.shape({
    id: propTypes.string.isRequired,
    year: propTypes.number.isRequired,
    fullName: propTypes.string.isRequired,
    plotCount: propTypes.number.isRequired,
    creationDate: propTypes.instanceOf(Date).isRequired,
    deactivationDate: propTypeNullable(isValidDate)
});

export const customerDetailsPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    year: propTypes.number.isRequired,
    publicId: propTypes.string.isRequired,
    fullName: propTypes.string.isRequired,
    header: propTypeNullable(isString),
    contactInfo: propTypeNullable(isString),
    technicalUser: propTypeNullable(isRefLabelNumber),
    plotCount: propTypes.number.isRequired,
    plots: propTypes.arrayOf(propTypes.shape({
        id: propTypes.number.isRequired,
        year: propTypes.number.isRequired,
        publicId: propTypes.string.isRequired,
        activityId: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
        area: propTypeNullable(isRefLabelNumber),
        bio: refLabelNumberPropTypes.isRequired,
        varietal: propTypeNullable(isRefLabelNumber),
        customer: refLabelNumberPropTypes.isRequired,
        confusion: propTypeNullable(isBoolean),
        city: propTypes.string.isRequired,
        creationDate: propTypes.instanceOf(Date).isRequired,
        tracks: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired
    }).isRequired).isRequired,
    comment: propTypeNullable(isString),
    deactivationDate: propTypeNullable(isValidDate),
    creationDate: propTypes.instanceOf(Date).isRequired,
    updateDate: propTypeNullable(isValidDate)
});
