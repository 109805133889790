import {
    assert,
    assertIsArray,
    assertIsDefined,
    assertIsValidDate,
    assertIsValidNumber,
    checkType,
    HttpStatusCode,
    isArray,
    isBoolean,
    isDefined,
    isNumber,
    isString,
    isValidNumber,
    ServiceError,
    stringFormat,
    Type
} from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

type CreateCampaignRequest = {
    label: string;
};

type UpdateCampaignRequest = {
    label: string;
    instruction: string | null;
    includeNextInspection: boolean;
    observations: number[];
};

export const getCampaigns = async (year: number): Promise<App.Campaign[]> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign', config.apiUrl, year);

        response = await httpService.get<App.Api.Campaign[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send campaign request (getCampaigns)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_GET_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body.map(data => mapCampaign(data));
};

export const getCampaignDetails = async (year: number, campaignId: number): Promise<App.CampaignDetails> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}', config.apiUrl, year, campaignId);

        response = await httpService.get<App.Api.CampaignDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send campaign request (getCampaignDetails)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_GET_DETAILS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('CAMPAIGN_GET_DETAILS_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_GET_DETAILS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_GET_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsDefined(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_GET_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return mapCampaignDetails(response.body);
};

export const getReportObservations = async (year: number): Promise<App.ReportObservation[]> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/reportObs', config.apiUrl, year);

        response = await httpService.get<App.ReportObservation[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling campaign report API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send campaign report request (${getReportObservations.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_OBSERVATIONS_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_OBSERVATIONS_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_GET_REPORT_OBSERVATIONS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_OBSERVATIONS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getCampaignReport = async (year: number, campaignId: number): Promise<App.CampaignReport> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}/report', config.apiUrl, year, campaignId);

        response = await httpService.get<App.CampaignReport>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send campaign request (${getCampaignReport.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_GET_REPORT_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsDefined(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_GET_REPORT_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const createCampaign = async (year: number, request: CreateCampaignRequest): Promise<number> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');
    if (!isDefined(request)) throw new Error('request parameter is not valid');

    const { label } = request;
    if (!isString(label) || label.trim().length === 0) throw new ServiceError('CAMPAIGN_CREATE_INVALID_REQUEST', 'request label is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign', config.apiUrl, year);
        const requestData = { label };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling create campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send campaign request (createCampaign)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_CREATE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_CREATE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_CREATE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isNumber(response.body), 'invalid campaign id from the response');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const updateCampaign = async (year: number, id: number, request: UpdateCampaignRequest): Promise<void> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!isDefined(request)) throw new Error('request parameter is not valid');

    const {
        label,
        instruction,
        includeNextInspection,
        observations
    } = request;

    if (!isString(label) || label.trim().length === 0) throw new ServiceError('CAMPAIGN_CREATE_INVALID_REQUEST', 'request label is not valid');
    if (!checkType(instruction, Type.Null | Type.String)) throw new ServiceError('CAMPAIGN_CREATE_INVALID_REQUEST', 'request instruction is not valid');
    if (!isArray(observations) || !observations.every(isValidNumber)) throw new ServiceError('CAMPAIGN_CREATE_INVALID_REQUEST', 'request observations are not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}', config.apiUrl, year, id);
        const requestData = {
            label,
            instruction,
            includeNextInspection,
            observations
        };

        response = await httpService.post(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send campaign request (updateCampaign)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_UPDATE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('CAMPAIGN_UPDATE_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_UPDATE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }
};

type UpdateCampaignReportRequest = {
    reportHeadline: string;
    reportNextInspection: string;
    observations: App.CampaignReportObservation[];
};

export const updateCampaignReport = async (
    year: number,
    campaignId: number,
    request: UpdateCampaignReportRequest
): Promise<void> => {
    assertIsValidNumber(year, 'year parameter is not valid');
    assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}/report', config.apiUrl, year, campaignId);
        const {
            reportHeadline,
            reportNextInspection,
            observations
        } = request;

        const requestData = {
            reportHeadline,
            reportNextInspection,
            observations
        };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send campaign request (${getCampaignReport.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_UPDATE_REPORT_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('CAMPAIGN_UPDATE_REPORT_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_UPDATE_REPORT_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_UPDATE_REPORT_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsDefined(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_UPDATE_REPORT_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteCampaign = async (year: number, id: number): Promise<void> => {
    if (!isNumber(year)) throw new Error('year parameter is not valid');
    if (!isValidNumber(id)) throw new Error('id parameter is not valid');

    let response;
    try {
        const requestUrl = stringFormat('{0}/{1}/campaign/{2}', config.apiUrl, year, id);

        response = await httpService.delete<boolean>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete campaign API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send campaign request (${deleteCampaign.name})`);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('CAMPAIGN_DELETE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.NotFound) {
        throw new ServiceError('CAMPAIGN_DELETE_NOT_FOUND');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('CAMPAIGN_DELETE_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'CAMPAIGN_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(isBoolean(response.body), 'invalid campaign result from the response');
    }
    catch (err) {
        throw new ServiceError('CAMPAIGN_DELETE_FAILED', (err instanceof Error && err?.message) || '');
    }
};

const mapCampaign = ({
    id,
    label,
    inspectionCount,
    inspectionValidatedCount,
    creationDate,
    firstPlannedDate,
    lastPlannedDate
}: App.Api.Campaign): App.Campaign => {
    assertIsValidDate(creationDate);

    return {
        id,
        label,
        inspectionCount,
        inspectionValidatedCount,
        creationDate,
        firstPlannedDate,
        lastPlannedDate
    };
};

const mapCampaignDetails = ({
    id,
    label,
    instruction,
    reportHeadline,
    includeNextInspection,
    observations,
    creationDate,
    updateDate
}: App.Api.CampaignDetails): App.CampaignDetails => {
    assertIsValidDate(creationDate);

    return {
        id,
        label,
        instruction,
        reportHeadline,
        includeNextInspection,
        observations,
        creationDate,
        updateDate
    };
};