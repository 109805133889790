import { ValidationError } from './types';
import { validate as validateObservation1 } from './observation1';
import { validate as validateObservation2 } from './observation2';
import { validate as validateObservation3 } from './observation3';
import { validate as validateObservation4 } from './observation4';
import { validate as validateObservation5 } from './observation5';
import { validate as validateObservation6 } from './observation6';
import { validate as validateObservation7 } from './observation7';
import { validate as validateObservation8 } from './observation8';
import { validate as validateObservation9 } from './observation9';
import { validate as validateObservation10 } from './observation10';
import { validate as validateObservation11 } from './observation11';
import { validate as validateObservation12 } from './observation12';
import { validate as validateObservation13 } from './observation13';
import { validate as validateObservation14 } from './observation14';
import { validate as validateObservation15 } from './observation15';
import { validate as validateObservation16 } from './observation16';
import { validate as validateObservation17 } from './observation17';
import { validate as validateObservation18 } from './observation18';
import { validate as validateObservation19 } from './observation19';
import { validate as validateObservation20 } from './observation20';
import { validate as validateObservation21 } from './observation21';
import { validate as validateObservation22 } from './observation22';
import { validate as validateObservation23 } from './observation23';
import { validate as validateObservation24 } from './observation24';
import { validate as validateObservation25 } from './observation25';
import { validate as validateObservation26 } from './observation26';
import { validate as validateObservation27 } from './observation27';
import { validate as validateObservation28 } from './observation28';
import { validate as validateObservation29 } from './observation29';
import { validate as validateObservation30 } from './observation30';
import { validate as validateObservation31 } from './observation31';
import { validate as validateObservation32 } from './observation32';
import { validate as validateObservation33 } from './observation33';
import { validate as validateObservation34 } from './observation34';
import { validate as validateObservation35 } from './observation35';
import { validate as validateObservation36 } from './observation36';
import { validate as validateObservation37 } from './observation37';
import { validate as validateObservation38 } from './observation38';
import { validate as validateObservation39 } from './observation39';
import { validate as validateObservation40 } from './observation40';
import { validate as validateObservation41 } from './observation41';
import { validate as validateObservation42 } from './observation42';
import { validate as validateObservation43 } from './observation43';
import { validate as validateObservation44 } from './observation44';
import { validate as validateObservation45 } from './observation45';
import { validate as validateObservation46 } from './observation46';
import { validate as validateObservation47 } from './observation47';

export * from './types';

const validate = (
    observation: App.ObservationRecord,
    plot: App.InspectionPlotWithRecord
): Record<string, ValidationError> => {
    switch (observation.id) {
        case 1: return validateObservation1(observation, plot);
        case 2: return validateObservation2(observation, plot);
        case 3: return validateObservation3(observation, plot);
        case 4: return validateObservation4(observation, plot);
        case 5: return validateObservation5(observation, plot);
        case 6: return validateObservation6(observation, plot);
        case 7: return validateObservation7(observation, plot);
        case 8: return validateObservation8(observation, plot);
        case 9: return validateObservation9(observation, plot);
        case 10: return validateObservation10(observation, plot);
        case 11: return validateObservation11(observation, plot);
        case 12: return validateObservation12(observation, plot);
        case 13: return validateObservation13(observation, plot);
        case 14: return validateObservation14(observation, plot);
        case 15: return validateObservation15(observation, plot);
        case 16: return validateObservation16(observation, plot);
        case 17: return validateObservation17(observation, plot);
        case 18: return validateObservation18(observation, plot);
        case 19: return validateObservation19(observation, plot);
        case 20: return validateObservation20(observation, plot);
        case 21: return validateObservation21(observation, plot);
        case 22: return validateObservation22(observation, plot);
        case 23: return validateObservation23(observation, plot);
        case 24: return validateObservation24(observation, plot);
        case 25: return validateObservation25(observation, plot);
        case 26: return validateObservation26(observation, plot);
        case 27: return validateObservation27(observation, plot);
        case 28: return validateObservation28(observation, plot);
        case 29: return validateObservation29(observation, plot);
        case 30: return validateObservation30(observation, plot);
        case 31: return validateObservation31(observation, plot);
        case 32: return validateObservation32(observation, plot);
        case 33: return validateObservation33(observation, plot);
        case 34: return validateObservation34(observation, plot);
        case 35: return validateObservation35(observation, plot);
        case 36: return validateObservation36(observation, plot);
        case 37: return validateObservation37(observation, plot);
        case 38: return validateObservation38(observation, plot);
        case 39: return validateObservation39(observation, plot);
        case 40: return validateObservation40(observation, plot);
        case 41: return validateObservation41(observation, plot);
        case 42: return validateObservation42(observation, plot);
        case 43: return validateObservation43(observation, plot);
        case 44: return validateObservation44(observation, plot);
        case 45: return validateObservation45(observation, plot);
        case 46: return validateObservation46(observation, plot);
        case 47: return validateObservation47(observation, plot);
    }

    throw new Error('validate: unhandled observation');
};

export default validate;