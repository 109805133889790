import React from 'react';
import { NavigationRoute, useNavigation, useRouterPath } from 'shared-utils';
import Breadcrumb from 'components/Breadcrumb';
import { DashboardNavigation, ObservationTypeNavigation } from 'screens/routes';

type Props = {
    label: string;
};

const ObservationTypeBreadcrumb: React.FC<Props> = ({ label }: Props) => {
    const homePath = useRouterPath(DashboardNavigation());
    const observationTypePath = useRouterPath(ObservationTypeNavigation());
    const { push: navigate } = useNavigation();

    const triggerNav = React.useCallback((route: NavigationRoute) => (event: React.MouseEvent<any>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(route);
    }, [navigate]);

    return (
        <Breadcrumb>
            <Breadcrumb.Home
                key="bc-home"
                to={homePath ?? undefined}
                onClick={triggerNav(DashboardNavigation())}
            />
            <Breadcrumb.Item
                key="bc-observationType"
                to={observationTypePath ?? undefined}
                onClick={triggerNav(ObservationTypeNavigation())}
            >
                Types de suivi
            </Breadcrumb.Item>
            <Breadcrumb.Item key="bc-observationType-details">{label}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default ObservationTypeBreadcrumb;
