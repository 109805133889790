import React from 'react';
import propTypes from 'prop-types';
import { Card } from 'shared-ui';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import { SidePanelList } from 'components/SidePanelList';
import styles from '../PlotDetailsScreen.module.scss';
import TrackItem from './TrackItem';

type Props = {
    tracks: App.PlotDetails['tracks'];
    className?: string;
};

const TracksPanel: React.FC<Props> = ({
    tracks,
    className
}) => (
    <Card className={className}>
        <Card.Header>
            <Card.Title>
                Circuits
            </Card.Title>
        </Card.Header>
        <Card.Body className={styles['cardContent']}>
            <SidePanelList>
                {tracks.map(track => (
                    <TrackItem key={track.id} value={track} />
                ))}
            </SidePanelList>
        </Card.Body>
    </Card>
);

TracksPanel.propTypes = {
    tracks: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    className: propTypes.string
};

TracksPanel.defaultProps = {
    className: undefined
};

export default TracksPanel;
