import React from 'react';
import { NavigationRoute, useNavigation, useRouterPath } from 'shared-utils';
import { useYearlyRootNavigation } from 'utils/yearStore';
import Breadcrumb from 'components/Breadcrumb';
import { DashboardNavigation, CustomerNavigation } from 'screens/routes';

type Props = {
    label: string;
};

const UserDetailsBreadcrumb: React.FC<Props> = ({ label }: Props) => {
    const homePath = useRouterPath(DashboardNavigation());
    const [rootNavigations] = React.useState({ customerNav: CustomerNavigation });
    const { customerNav } = useYearlyRootNavigation(rootNavigations);
    const customerPath = useRouterPath(customerNav);
    const { push: navigate } = useNavigation();

    const triggerNav = React.useCallback((route: NavigationRoute) => (event: React.MouseEvent<any>) => {
        event.preventDefault();
        navigate(route);
    }, [navigate]);

    return (
        <Breadcrumb>
            <Breadcrumb.Home
                key="bc-home"
                to={homePath ?? undefined}
                onClick={triggerNav(DashboardNavigation())}
            />
            <Breadcrumb.Item
                key="bc-customer"
                to={customerPath ?? undefined}
                onClick={triggerNav(customerNav)}
            >
                Vignerons
            </Breadcrumb.Item>
            <Breadcrumb.Item key="bc-customer-details">{label}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default UserDetailsBreadcrumb;
