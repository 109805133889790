import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isDefined, isString } from '@dateam/ark';
import styles from '../SidePanelList.module.scss';

type Props = {
    text?: React.ReactNode;
    subtext?: React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
    subTextClassName?: string;
};

const SidePanelListItemCol: React.FC<Props> = ({ text, subtext, icon, className, subTextClassName }: Props) => (
    <div className={concatClassName(className, 'col')}>
        {isDefined(icon) && (<div className={styles['icon']}>{icon}</div>)}
        {isDefined(text) && (<div className={styles['text']}>{text}</div>)}
        {isDefined(subtext) && (<div className={concatClassName(styles['subtext'], subTextClassName)}>{subtext}</div>)}
    </div>
);

SidePanelListItemCol.propTypes = {
    text: propTypes.node,
    subtext: propTypes.node,
    icon: propTypes.node,
    className: propTypes.string,
    subTextClassName: propTypes.string
};

SidePanelListItemCol.defaultProps = {
    text: undefined,
    subtext: undefined,
    icon: undefined,
    className: undefined,
    subTextClassName: undefined
};

export default SidePanelListItemCol;
