import React from 'react';
import propTypes from 'prop-types';
import { isValidDate, isString } from '@dateam/ark';
import { concatClassName, propTypeAllowUndefined } from '@dateam/ark-react';
import styles from '../../../DashboardScreen.module.scss';
import Step from './Step';

type Props = {
    history: {
        key: string;
        label: string;
        date?: Date | null;
        subtext?: string;
        isActive: boolean;
    }[];
    className?: string;
};

const Timeline: React.FC<Props> = ({ history, className }) => {
    return (
        <div className={concatClassName(styles['timeline'], className)}>
            {history.map((step, idx) => (
                <Step
                    key={idx}
                    stepCount={history.length}
                    activeStepIndex={history.findIndex(step => step.isActive)}
                    step={{ ...step, index: idx }}
                />
            ))}
        </div>
    );
};

Timeline.propTypes = {
    history: propTypes.arrayOf(propTypes.shape({
        key: propTypes.string.isRequired,
        label: propTypes.string.isRequired,
        date: propTypeAllowUndefined(isValidDate),
        subtext: propTypeAllowUndefined(isString),
        isActive: propTypes.bool.isRequired
    }).isRequired).isRequired,
    className: propTypes.string
};

Timeline.defaultProps = {
    className: undefined
};

export default Timeline;
