import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { unique } from '@dateam/ark';
import { withRouter } from 'shared-utils';
import logger from 'utils/logger';
import { useYearState } from 'utils/yearStore';
import config from 'config';
import Page from 'components/Page';
import { useActivitySummary } from 'data/activity';
import { SummaryPanel, ObservatorPanel, WriterInspectionPanel } from './components';
import styles from './DashboardScreen.module.scss';

const DashboardScreen: React.FC = () => {
    const { t } = useTranslation();
    const [currentYear] = React.useState(new Date().getFullYear());
    const [year] = useYearState();
    const isActualYear = React.useMemo(() => currentYear === year, [currentYear, year]);
    const { data: activitySummary } = useActivitySummary({ enabled: isActualYear });

    const assignees = activitySummary?.map(item => item.assigneeUser.id);
    const observatorsIds = unique(assignees ?? []);

    return (
        <>
            <Helmet>
                <title>{t('dashboard.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title className={styles['title']}>
                        Tableau de bord
                    </Page.Title>
                </Page.Header>
                <Page.Content className={concatClassName(styles['content'], 'row')}>
                    <SummaryPanel data={activitySummary} isActualYear={isActualYear} className="col col-7" />
                    <div className="col col-flex col-5">
                        <WriterInspectionPanel data={observatorsIds} className="row" />
                        <ObservatorPanel data={observatorsIds} className="row row-grow" />
                    </div>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(DashboardScreen, 'DASHBOARD');