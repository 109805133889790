import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const CheckIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
);

CheckIcon.propTypes = {
    className: propTypes.string
};

CheckIcon.defaultProps = {
    className: undefined
};

export default CheckIcon;
