import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { CheckIcon, WarnIcon } from 'components/Icons';
import styles from '../../../InspectionEditScreen.module.scss';
import { plotPropType } from './types';

type Props = {
    data: App.InspectionPlotWithRecord[];
    selected?: App.InspectionPlotWithRecord | null;
    className?: string;
    onSelectPlot?: (plot: App.InspectionPlotWithRecord) => void;
};

const PlotList: React.FC<Props> = ({ data, selected, className, onSelectPlot }: Props) => {
    const plots = React.useMemo(() => {
        if (!Ark.isArray(data)) return [];

        const plotList = [...data];

        Ark.orderBy(plotList, 'position');

        return plotList;
    }, [data]);

    const selectPlot = React.useCallback((plot: App.InspectionPlotWithRecord) => {
        onSelectPlot?.(plot);
    }, [onSelectPlot]);

    return (
        <ul className={concatClassName(styles['plotList'], className)}>
            {plots.map(plot => (
                <li
                    key={plot.id}
                    className={concatClassName(
                        styles['plotListItem'],
                        selected?.id === plot.id ? styles['plotListItem-selected'] : null
                    )}
                    onClick={() => selectPlot(plot)}
                >
                    <div className={styles['plotIconContainer']}>
                        {(plot.ignored === true && (
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            <div className={styles['plotIcon']}>
                                <WarnIcon className={styles['plotIgnoredIcon']} />
                            </div>
                        )) || (plot.validationDate !== null && (
                            <div className={styles['plotIcon']}>
                                <CheckIcon className={styles['plotValidIcon']} />
                            </div>
                        ))}
                    </div>
                    <div className={styles['plotLabel']}>
                        {plot.label}
                    </div>
                </li>
            ))}
        </ul>
    );
};

PlotList.propTypes = {
    data: propTypes.arrayOf(plotPropType.isRequired).isRequired,
    selected: plotPropType,
    className: propTypes.string,
    onSelectPlot: propTypes.func
};

PlotList.defaultProps = {
    selected: undefined,
    className: undefined,
    onSelectPlot: undefined
};

export default PlotList;
