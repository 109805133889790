import { map as mapObservation1, parse as parseObservation1 } from './observation1';
import { map as mapObservation2, parse as parseObservation2 } from './observation2';
import { map as mapObservation3, parse as parseObservation3 } from './observation3';
import { map as mapObservation4, parse as parseObservation4 } from './observation4';
import { map as mapObservation5, parse as parseObservation5 } from './observation5';
import { map as mapObservation6, parse as parseObservation6 } from './observation6';
import { map as mapObservation7, parse as parseObservation7 } from './observation7';
import { map as mapObservation8, parse as parseObservation8 } from './observation8';
import { map as mapObservation9, parse as parseObservation9 } from './observation9';
import { map as mapObservation10, parse as parseObservation10 } from './observation10';
import { map as mapObservation11, parse as parseObservation11 } from './observation11';
import { map as mapObservation12, parse as parseObservation12 } from './observation12';
import { map as mapObservation13, parse as parseObservation13 } from './observation13';
import { map as mapObservation14, parse as parseObservation14 } from './observation14';
import { map as mapObservation15, parse as parseObservation15 } from './observation15';
import { map as mapObservation16, parse as parseObservation16 } from './observation16';
import { map as mapObservation17, parse as parseObservation17 } from './observation17';
import { map as mapObservation18, parse as parseObservation18 } from './observation18';
import { map as mapObservation19, parse as parseObservation19 } from './observation19';
import { map as mapObservation20, parse as parseObservation20 } from './observation20';
import { map as mapObservation21, parse as parseObservation21 } from './observation21';
import { map as mapObservation22, parse as parseObservation22 } from './observation22';
import { map as mapObservation23, parse as parseObservation23 } from './observation23';
import { map as mapObservation24, parse as parseObservation24 } from './observation24';
import { map as mapObservation25, parse as parseObservation25 } from './observation25';
import { map as mapObservation26, parse as parseObservation26 } from './observation26';
import { map as mapObservation27, parse as parseObservation27 } from './observation27';
import { map as mapObservation28, parse as parseObservation28 } from './observation28';
import { map as mapObservation29, parse as parseObservation29 } from './observation29';
import { map as mapObservation30, parse as parseObservation30 } from './observation30';
import { map as mapObservation31, parse as parseObservation31 } from './observation31';
import { map as mapObservation32, parse as parseObservation32 } from './observation32';
import { map as mapObservation33, parse as parseObservation33 } from './observation33';
import { map as mapObservation34, parse as parseObservation34 } from './observation34';
import { map as mapObservation35, parse as parseObservation35 } from './observation35';
import { map as mapObservation36, parse as parseObservation36 } from './observation36';
import { map as mapObservation37, parse as parseObservation37 } from './observation37';
import { map as mapObservation38, parse as parseObservation38 } from './observation38';
import { map as mapObservation39, parse as parseObservation39 } from './observation39';
import { map as mapObservation40, parse as parseObservation40 } from './observation40';
import { map as mapObservation41, parse as parseObservation41 } from './observation41';
import { map as mapObservation42, parse as parseObservation42 } from './observation42';
import { map as mapObservation43, parse as parseObservation43 } from './observation43';
import { map as mapObservation44, parse as parseObservation44 } from './observation44';
import { map as mapObservation45, parse as parseObservation45 } from './observation45';
import { map as mapObservation46, parse as parseObservation46 } from './observation46';
import { map as mapObservation47, parse as parseObservation47 } from './observation47';

export const map = (observation: App.ObservationRecord): App.Api.ObservationRecord => {
    switch (observation.id) {
        case 1: return mapObservation1(observation);
        case 2: return mapObservation2(observation);
        case 3: return mapObservation3(observation);
        case 4: return mapObservation4(observation);
        case 5: return mapObservation5(observation);
        case 6: return mapObservation6(observation);
        case 7: return mapObservation7(observation);
        case 8: return mapObservation8(observation);
        case 9: return mapObservation9(observation);
        case 10: return mapObservation10(observation);
        case 11: return mapObservation11(observation);
        case 12: return mapObservation12(observation);
        case 13: return mapObservation13(observation);
        case 14: return mapObservation14(observation);
        case 15: return mapObservation15(observation);
        case 16: return mapObservation16(observation);
        case 17: return mapObservation17(observation);
        case 18: return mapObservation18(observation);
        case 19: return mapObservation19(observation);
        case 20: return mapObservation20(observation);
        case 21: return mapObservation21(observation);
        case 22: return mapObservation22(observation);
        case 23: return mapObservation23(observation);
        case 24: return mapObservation24(observation);
        case 25: return mapObservation25(observation);
        case 26: return mapObservation26(observation);
        case 27: return mapObservation27(observation);
        case 28: return mapObservation28(observation);
        case 29: return mapObservation29(observation);
        case 30: return mapObservation30(observation);
        case 31: return mapObservation31(observation);
        case 32: return mapObservation32(observation);
        case 33: return mapObservation33(observation);
        case 34: return mapObservation34(observation);
        case 35: return mapObservation35(observation);
        case 36: return mapObservation36(observation);
        case 37: return mapObservation37(observation);
        case 38: return mapObservation38(observation);
        case 39: return mapObservation39(observation);
        case 40: return mapObservation40(observation);
        case 41: return mapObservation41(observation);
        case 42: return mapObservation42(observation);
        case 43: return mapObservation43(observation);
        case 44: return mapObservation44(observation);
        case 45: return mapObservation45(observation);
        case 46: return mapObservation46(observation);
        case 47: return mapObservation47(observation);
    }

    throw new Error('map: unhandled observation');
};

export const parse = (observation: App.Api.ObservationRecord): App.ObservationRecord => {
    switch (observation.id) {
        case 1: return parseObservation1(observation);
        case 2: return parseObservation2(observation);
        case 3: return parseObservation3(observation);
        case 4: return parseObservation4(observation);
        case 5: return parseObservation5(observation);
        case 6: return parseObservation6(observation);
        case 7: return parseObservation7(observation);
        case 8: return parseObservation8(observation);
        case 9: return parseObservation9(observation);
        case 10: return parseObservation10(observation);
        case 11: return parseObservation11(observation);
        case 12: return parseObservation12(observation);
        case 13: return parseObservation13(observation);
        case 14: return parseObservation14(observation);
        case 15: return parseObservation15(observation);
        case 16: return parseObservation16(observation);
        case 17: return parseObservation17(observation);
        case 18: return parseObservation18(observation);
        case 19: return parseObservation19(observation);
        case 20: return parseObservation20(observation);
        case 21: return parseObservation21(observation);
        case 22: return parseObservation22(observation);
        case 23: return parseObservation23(observation);
        case 24: return parseObservation24(observation);
        case 25: return parseObservation25(observation);
        case 26: return parseObservation26(observation);
        case 27: return parseObservation27(observation);
        case 28: return parseObservation28(observation);
        case 29: return parseObservation29(observation);
        case 30: return parseObservation30(observation);
        case 31: return parseObservation31(observation);
        case 32: return parseObservation32(observation);
        case 33: return parseObservation33(observation);
        case 34: return parseObservation34(observation);
        case 35: return parseObservation35(observation);
        case 36: return parseObservation36(observation);
        case 37: return parseObservation37(observation);
        case 38: return parseObservation38(observation);
        case 39: return parseObservation39(observation);
        case 40: return parseObservation40(observation);
        case 41: return parseObservation41(observation);
        case 42: return parseObservation42(observation);
        case 43: return parseObservation43(observation);
        case 44: return parseObservation44(observation);
        case 45: return parseObservation45(observation);
        case 46: return parseObservation46(observation);
        case 47: return parseObservation47(observation);
    }

    throw new Error('parse: unhandled observation');
};
