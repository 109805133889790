import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { useNavigation, useRouterPath } from 'shared-utils';
import { useYearlyNavigation } from 'utils/yearStore';
import { PlotDetailsNavigation } from 'screens/routes';
import { Plot, plotPropTypes } from '../types';
import styles from '../PlotList.module.scss';

type Props = {
    data: Plot;
    className?: string;
};

const PlotInfos: React.FC<Props> = ({ data, className }: Props) => {
    const { push: navigate } = useNavigation();
    const plotNav = useYearlyNavigation(PlotDetailsNavigation, [data.id]);
    const plotPath = useRouterPath(plotNav);

    const onClick = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(PlotDetailsNavigation(data.id));
    }, [data, navigate]);

    return (
        <div className={concatClassName(className, 'row')}>
            <a className={concatClassName(styles['plotInfosLink'], 'col', 'col-7')} href={plotPath} onClick={onClick}>
                <div className={styles['plotId']}>{data.publicId}</div>
                <div className={styles['plotLabel']}>{data.label}</div>
            </a>
            <div className="col">
                <div className={styles['title']}>Localisation</div>
                <div className={styles['plotCity']}>{data.city}</div>
                <div className={styles['plotArea']}>{data.area?.label}</div>
            </div>
        </div>
    );
};

PlotInfos.propTypes = {
    data: plotPropTypes.isRequired,
    className: propTypes.string
};

PlotInfos.defaultProps = {
    className: undefined
};

export default PlotInfos;
