import React from 'react';
import * as Ark from '@dateam/ark';
import { useUsers } from 'data/user';
import {
    QueryResult,
    queryResultKeys
} from '../constants';

export type UserSearchFilter = {
    userText: string | undefined;
    roles: string[];
    hideDeactivated: boolean;
};

export const useUserSearch = (filter: UserSearchFilter, options?: DataOptions): QueryResult<App.User[]> => {
    const { data: users, ...queryResult } = useUsers();

    return React.useMemo(() => {
        let filteredData: App.User[] = [];

        if (Ark.isArray(users)) {
            filteredData = [...users];

            const {
                userText: userFilter,
                roles: rolesFilter,
                hideDeactivated
            } = filter;

            const filterPredicates: ((dataEntry: App.User) => boolean)[] = [];

            if (hideDeactivated === true) {
                filterPredicates.push((dataEntry: App.User) => dataEntry.deactivationDate == null);
            }

            if (Ark.isArray(rolesFilter) && rolesFilter.length > 0) {
                filterPredicates.push((dataEntry: App.User) => rolesFilter.indexOf(dataEntry.roleId) >= 0);
            }

            if (Ark.isString(userFilter) && userFilter.trim().length > 0) {
                const filter = userFilter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.User) =>
                    dataEntry.username.toUpperCase().indexOf(filter) >= 0 ||
                    dataEntry.firstName.toUpperCase().indexOf(filter) >= 0 ||
                    dataEntry.lastName.toUpperCase().indexOf(filter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        Ark.orderBy(filteredData, 'lastName');
        Ark.orderBy(filteredData, 'firstName');

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [users, queryResult, filter]);
};
