import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { useNavigation, useRouterPath } from 'shared-utils';
import { useYearlyNavigation } from 'utils/yearStore';
import { CustomerDetailsNavigation } from 'screens/routes';
import { Plot, plotPropTypes } from '../types';
import styles from '../PlotList.module.scss';

type Props = {
    data: Plot;
    className?: string;
};

const CustomerInfos: React.FC<Props> = ({ data, className }: Props) => {
    const { push: navigate } = useNavigation();
    const customerNav = useYearlyNavigation(CustomerDetailsNavigation, [data.customer.id]);
    const customerPath = useRouterPath(customerNav);

    const onClick = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(CustomerDetailsNavigation(data.customer.id));
    }, [data, navigate]);

    return (
        <a className={concatClassName(styles['plotCustomerLink'], className)} href={customerPath} onClick={onClick}>
            <div className={styles['title']}>Vigneron</div>
            <div className={styles['customerLabel']}>{data.customer?.label}</div>
        </a>
    );
};

CustomerInfos.propTypes = {
    data: plotPropTypes.isRequired,
    className: propTypes.string
};

CustomerInfos.defaultProps = {
    className: undefined
};

export default CustomerInfos;
