import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { Popover } from 'shared-ui';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import styles from './ObservationTypesTableItem.module.scss';

type Props = {
    types: RefLabel[];
    className?: string;
};

const ObservationTypesTableItem: React.FC<Props> = ({
    types,
    className
}: Props) => {
    const { t } = useTranslation();
    const displayedTypes = Ark.take(types, 2);
    const hiddedTypes = Ark.take(types, types.length, 2);
    const anchorEl = React.createRef<HTMLDivElement>();
    const [showHiddedTypes, setshowHiddedTypes] = React.useState(false);

    const handleHiddedTypesClick = React.useCallback(() => {
        if (showHiddedTypes === true) return;
        setshowHiddedTypes(true);
    }, [showHiddedTypes, setshowHiddedTypes]);

    const handleHiddedTypesClose = React.useCallback(() => {
        setshowHiddedTypes(false);
    }, [setshowHiddedTypes]);
    return (
        <div className={concatClassName(styles['obsItem'], className)}>
            {displayedTypes.map((type, idx) => (
                <div key={idx} className={concatClassName(styles['type'], styles['detailLabel'])}>{type.label}</div>
            ))}
            {hiddedTypes.length > 0 && (
                <div
                    ref={anchorEl}
                    role="button"
                    onClick={handleHiddedTypesClick}
                    tabIndex={0}
                    className={concatClassName(styles['type'], styles['detailLabel'], styles['detailLabel-button'])}
                >
                    {t('inspection.other', { count: hiddedTypes.length })}
                </div>
            )}
            {showHiddedTypes && (
                <Popover
                    position="bottom-center"
                    onClickOutside={handleHiddedTypesClose}
                    anchorEl={anchorEl}
                >
                    {types.map((type, idx) => (
                        <div key={idx} className={styles['popoverType']}>{type.label}</div>
                    ))}
                </Popover>
            )}
        </div>
    );
};

ObservationTypesTableItem.propTypes = {
    types: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    className: propTypes.string
};

ObservationTypesTableItem.defaultProps = {
    className: undefined
};

export default ObservationTypesTableItem;
