import React from 'react';
import propTypes from 'prop-types';
import { isArray, isValidNumber, orderBy } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { useNavigation } from 'shared-utils';
import {
    Button,
    Card
} from 'shared-ui';
import CreateInspectionDialog from 'components/CreateInspectionDialog';
import { useCampaignInspections } from 'data/inspection';
import { InspectionEditNavigation } from 'screens/routes';
import { InspectionItem } from './components';
import { PlusIcon } from '../../components/Icons';
import styles from './CampaignDetailsScreen.module.scss';

type Props = {
    displayActions?: boolean;
    className?: string;
    campaignId?: number;
};

const PlannedInspection: React.FC<Props> = ({
    displayActions,
    className,
    campaignId
}) => {
    const { push: navigate } = useNavigation();
    const { data: inspections } = useCampaignInspections(campaignId ?? 0, { enabled: isValidNumber(campaignId) });
    const [showCreateInspection, setShowCreateInspection] = React.useState(false);

    const inspectionList = React.useMemo(() => {
        if (!isArray(inspections)) return [];

        const data = [...inspections];

        orderBy(data, 'startDate', { ascending: false, nullFirst: true });

        return data;
    }, [inspections]);

    const handleAddInspection = React.useCallback(() => {
        setShowCreateInspection(true);
    }, []);

    const handleInspectionCreateCompletion = React.useCallback((inspectionId: number) => {
        navigate(InspectionEditNavigation(inspectionId));
    }, [navigate]);

    return (
        <>
            <Card className={concatClassName(className)}>
                <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                    <Card.Title>
                        Visites
                    </Card.Title>
                    {campaignId != null && displayActions && (
                        <Button
                            color="primary"
                            startIcon={(<PlusIcon />)}
                            onClick={handleAddInspection}
                        >
                            Ajouter une visite
                        </Button>
                    )}
                </Card.Header>
                <Card.Body className={concatClassName(styles['cardContent'])}>
                    <ul className={styles['inspection-list']}>
                        {inspectionList.map(inspection => (<InspectionItem key={inspection.id} value={inspection} />))}
                    </ul>
                </Card.Body>
            </Card>
            {showCreateInspection && campaignId != null && (
                <CreateInspectionDialog
                    campaignId={campaignId}
                    onClose={() => setShowCreateInspection(false)}
                    onCompletion={handleInspectionCreateCompletion}
                />
            )}
        </>
    );
};

PlannedInspection.propTypes = {
    displayActions: propTypes.bool,
    className: propTypes.string,
    campaignId: propTypes.number
};

PlannedInspection.defaultProps = {
    displayActions: undefined,
    className: undefined,
    campaignId: undefined
};

export default PlannedInspection;