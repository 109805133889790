import React from 'react';
import propTypes from 'prop-types';
import {
    isArray,
    orderBy,
    pureObjectAssign,
    removeFromCollection
} from '@dateam/ark';
import {
    Card,
    Form,
    Table,
    TableHeaderSelectionChangeEvent,
    TableSelectionChangeEvent,
    TableSelectionItem
} from 'shared-ui';
import Page from 'components/Page';
import { useCampaignDetails, useCampaignReport, useReportObservations, useUpdateCampaignReport } from 'data/campaign';
import { ObservationColumn, SelectedReportObservations } from './component';
import styles from './ReportModelSection.module.scss';

type ObservationTableItem = TableSelectionItem<{
    id: number;
    label: string;
    position: number;
    comment: string;
}>;

type Props = {
    campaignId?: App.CampaignDetails['id'];
};

const ReportModelSection: React.FC<Props> = ({ campaignId }) => {
    const { data: report } = useCampaignReport(
        campaignId ?? 0,
        { enabled: campaignId != null }
    );
    const { data: campaign } = useCampaignDetails(
        campaignId ?? 0,
        { enabled: campaignId != null }
    );
    const { data: observationData } = useReportObservations();
    const { mutateAsync: updateReport } = useUpdateCampaignReport();

    const observationDataTable = React.useMemo<ObservationTableItem[]>(() => {
        if (!isArray(observationData)) return [];

        const dataTable = observationData
            .filter(obs => campaign?.observations.find(campaignObs => campaignObs === obs.id) != null)
            .map<ObservationTableItem>(obs => {
                const reportMatch = report?.observations.find(reportObs => reportObs.id === obs.id);

                return pureObjectAssign(obs, {
                    selected: reportMatch != null,
                    comment: reportMatch?.comment ?? ''
                });
            });

        orderBy(dataTable, 'position');
        return dataTable;
    }, [observationData, campaign, report]);

    const selectObservation = React.useCallback(async (event: TableSelectionChangeEvent<ObservationTableItem>) => {
        if (report == null || campaign == null) return;

        const { id: obsId } = event.data;

        const newSelection = [...report.observations];

        if (event.selected === true) newSelection.push({ id: obsId, comment: '' });
        else removeFromCollection(newSelection, item => item.id === obsId);

        await updateReport({
            campaignId: campaign.id,
            reportHeadline: report.reportHeadline,
            reportNextInspection: report.reportNextInspection,
            observations: newSelection
        });
    }, [report, updateReport, campaign]);

    const removeObservation = React.useCallback(async (obsId: number) => {
        if (report == null || campaign == null) return;

        const newSelection = [...report.observations];

        removeFromCollection(newSelection, item => item.id === obsId);

        await updateReport({
            campaignId: campaign.id,
            reportHeadline: report.reportHeadline,
            reportNextInspection: report.reportNextInspection,
            observations: newSelection
        });
    }, [report, updateReport, campaign]);

    const handleHeaderSelectionChange =
        React.useCallback(async (event: TableHeaderSelectionChangeEvent<ObservationTableItem>) => {
            if (report == null || campaign == null) return;

            const newSelection = event.selected === true ? event.items.map(item => ({ id: item.data.id, comment: '' })) : [];

            await updateReport({
                campaignId: campaign.id,
                reportHeadline: report.reportHeadline,
                reportNextInspection: report.reportNextInspection,
                observations: newSelection
            });
        }, [report, updateReport, campaign]);

    const handleCommentChange = React.useCallback(async (id: number, comment: string) => {
        if (report == null || campaign == null) return;

        const newSelection = [...report.observations];

        try {
            await updateReport({
                campaignId: campaign.id,
                reportHeadline: report.reportHeadline,
                reportNextInspection: report.reportNextInspection,
                observations: newSelection
                    .map(item => {
                        if (item.id === id) return ({ id, comment });

                        return item;
                    })
            });

            return comment;
        }
        catch {
            // Ignore
        }

        return undefined;
    }, [report, updateReport, campaign]);

    const handleHeadlineChange = React.useCallback(async (headline: string) => {
        if (report == null || campaign == null) return;

        try {
            await updateReport({
                campaignId: campaign.id,
                reportHeadline: headline,
                reportNextInspection: report.reportNextInspection,
                observations: report.observations
            });

            return headline;
        }
        catch {
            // Ignore
        }

        return undefined;
    }, [report, updateReport, campaign]);

    const handleNextInspectionChange = React.useCallback(async (message: string) => {
        if (report == null || campaign == null) return;

        try {
            await updateReport({
                campaignId: campaign.id,
                reportHeadline: report.reportHeadline,
                reportNextInspection: message,
                observations: report.observations
            });

            return message;
        }
        catch {
            // Ignore
        }

        return undefined;
    }, [report, updateReport, campaign]);

    const observationTable = React.useMemo(() => {
        if (observationDataTable.length > 0) {
            return (
                <Table<ObservationTableItem>
                    data={observationDataTable}
                    selectedField="selected"
                    onSelectionChange={selectObservation}
                    onHeaderSelectionChange={handleHeaderSelectionChange}
                >
                    <Table.Column>
                        <Table.Column.Header>Nom</Table.Column.Header>
                        <Table.Column.Cell>
                            {(props: any) => (
                                <ObservationColumn
                                    data={props.data}
                                    selected={props.data.selected}
                                    onCommentChange={handleCommentChange}
                                />
                            )}
                        </Table.Column.Cell>
                    </Table.Column>
                </Table>
            );
        }

        return <>Aucune observation sélectionnée </>;
    }, [observationDataTable, selectObservation, handleHeaderSelectionChange, handleCommentChange]);

    const selectedObservationsList = report?.observations.map(obs => obs.id);

    return (
        <Page.Content className="row">
            <Card className="col">
                <Card.Header>
                    <Card.Title>
                        Modèle de rapports de la tournée
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form.Group controlId="report-summary">
                        <Form.Label>Récapitulatif de la tournée</Form.Label>
                        <Form.Editable.Input
                            value={report?.reportHeadline ?? ''}
                            multiline
                            rows={5}
                            maxlength={2000}
                            onSave={handleHeadlineChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="report-obs">
                        <Form.Label>Observations</Form.Label>
                        {observationTable}
                        {campaign?.includeNextInspection === false && (
                            <div className="line-entry-disable">
                                Message prochaine visite désactivé
                            </div>
                        )}
                    </Form.Group>
                    {campaign?.includeNextInspection === true && (
                        <Form.Group controlId="report-summary">
                            <Form.Label>Message prochaine visite</Form.Label>
                            <Form.Editable.Input
                                value={report?.reportNextInspection ?? ''}
                                multiline
                                rows={5}
                                maxlength={2000}
                                onSave={handleNextInspectionChange}
                            />
                        </Form.Group>
                    )}
                </Card.Body>
            </Card>
            <Card className="col col-4">
                <Card.Header>
                    <Card.Title>
                        Observations inclues dans les rapports
                    </Card.Title>
                </Card.Header>
                <Card.Body className={styles['cardContent']}>
                    <SelectedReportObservations
                        observations={selectedObservationsList}
                        onRemoveObservation={removeObservation}
                    />
                </Card.Body>
            </Card>
        </Page.Content>
    );
};

ReportModelSection.propTypes = {
    campaignId: propTypes.number
};

ReportModelSection.defaultProps = {
    campaignId: undefined
};

export default ReportModelSection;
