import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { orderBy, sortByProperty } from '@dateam/ark';
import * as Ark from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import Page from 'components/Page';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent,
    TableSelectionItem
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import { useInspectionSearch } from 'data/effects';
import { InspectionNavigation, InspectionEditNavigation } from 'screens/routes';
import ListSection from 'components/ListSection';
import { AssigneeColumn, DateColumn, FilterBar, InspectionFilter, StatusColumn } from './component';
import InspectionBreadcrumb from './InspectionBreadcrumb';
import styles from './InspectionScreen.module.scss';

const InspectionScreen: React.FC = () => {
    useBindYearNavigation(InspectionNavigation, []);
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const [filter, setFilter] = React.useState<InspectionFilter>({
        label: undefined,
        campaignIds: [],
        status: undefined,
        observator: undefined
    });
    const { data: inspections, isLoading, isFetched, error } = useInspectionSearch({
        label: filter.label,
        campaignIds: filter.campaignIds,
        status: filter.status,
        observator: filter.observator
    });
    const enableFilters = isFetched === true && error == null;

    const handleRowClick = (event: TableRowClickEvent<TableSelectionItem<App.InspectionSearchResult>>) => {
        navigate(InspectionEditNavigation(event.data.id));
    };

    const inspectionsRows = React.useMemo<TableSelectionItem<App.InspectionSearchResult>[]>(() => {
        if (!Ark.isArray(inspections)) return [];

        const rows = [...inspections];

        orderBy(rows, 'startDate', { ascending: false, nullFirst: true });
        // This particular implementation is used to sort such as :
        // if the dates are defined, it returns 0.
        // if they are not, it sorts them at the end
        // Allowing to keep the previous sort by startDate and
        // simply making 2 groups (validated and non-validated)
        sortByProperty(rows, 'validationDate', () => 0, { nullFirst: true });

        return rows;
    }, [inspections]);

    const handleFilterChanged = (filter: InspectionFilter) => {
        setFilter(filter);
    };

    return (
        <>
            <Helmet>
                <title>{t('inspection.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Visites
                    </Page.Title>
                    <Page.HeaderActions>
                        <InspectionBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Liste des visites
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar
                                        onChange={handleFilterChanged}
                                        enabled={enableFilters}
                                    />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<App.InspectionSearchResult>
                                        data={inspectionsRows}
                                        onRowClick={handleRowClick}
                                        className={styles['tableRow']}
                                    >
                                        <Table.Column accessor="label">
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column className={styles['colCampaign']}>
                                            <Table.Column.Header>Tournée</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {(props: any) => (<>{props.data.campaign.label}</>)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colState']}>
                                            <Table.Column.Header>État</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {(props: any) => (<StatusColumn status={props.data.status} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colStartDate']}>
                                            <Table.Column.Header>Planifiée au</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {(props: any) => (<DateColumn date={props.data.startDate} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colObservator']}>
                                            <Table.Column.Header>Observateur</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {(props: any) => (<AssigneeColumn data={props.data} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(InspectionScreen, 'INSPECTION');