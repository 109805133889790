import { QueryOptions, UseMutationResult, UseQueryResult } from 'react-query';
import { ServiceError, isNumber } from '@dateam/ark';
import logger from 'utils/logger';
import config from 'config';
import { unauthorizedProvider } from 'components/unauthorizedHandler';
import { errorProvider } from 'components/errorHandler';

export const ADMIN_PARAMETER_KEY = 'admin_parameters';
export const AUTH_KEY = 'auth';
export const USER_KEY = 'user';
export const USER_OBSERVATOR_KEY = 'observator';
export const USER_WRITER_KEY = 'writer';
export const USER_TECHNICAL_KEY = 'technicalUser';
export const PLOT_KEY = 'plots';
export const PLOT_DETAILS_KEY = 'plots_details';
export const PLOT_SEARCH_KEY = 'plot_search';
export const PLOT_CITY_KEY = 'plot_city';
export const OBSERVATION_KEY = 'observations';
export const REPORT_OBSERVATION_KEY = 'report_observations';
export const OBSERVATION_TYPE_KEY = 'observation_types';
export const OBSERVATION_TYPE_DETAILS_KEY = 'observation_types_details';
export const BIO_KEY = 'bio';
export const BIO_TYPE_DETAILS_KEY = 'bio_details';
export const VARIETAL_KEY = 'varietal';
export const VARIETAL_TYPE_DETAILS_KEY = 'varietal_details';
export const AREA_KEY = 'area';
export const TRACK_KEY = 'tracks';
export const TRACK_DETAILS_KEY = 'tracks_details';
export const CUSTOMER_KEY = 'customer';
export const CUSTOMER_DETAILS_KEY = 'customer_details';
export const CUSTOMER_EMAIL_KEY = 'email';
export const CAMPAIGN_KEY = 'campaigns';
export const CAMPAIGN_DETAILS_KEY = 'details';
export const CAMPAIGN_REPORT_KEY = 'report';
export const INSPECTION_KEY = 'inspections';
export const INSPECTION_DETAILS_KEY = 'inspections_details';
export const INSPECTION_RECORD_KEY = 'record';
export const INSPECTION_REPORT_KEY = 'report';
export const INSPECTION_ALL_REPORT_KEY = 'search_report';
export const SUMMARY_KEY = 'summary';
export const SUMMARY_REPORT_KEY = 'summary_report';
export const YEAR_KEY = 'years';

const _queryResultKeys = [
    'data',
    'error',
    'isError',
    'isFetched',
    'isFetching',
    'isIdle',
    'isLoading',
    'isPlaceholderData',
    'isStale',
    'isSuccess',
    'refetch',
    'remove'
] as const;

const _mutateResultKeys = [
    'data',
    'error',
    'isError',
    'isIdle',
    'isLoading',
    'isSuccess',
    'mutate',
    'mutateAsync'
] as const;

export const queryResultKeys: readonly ValuesOf<typeof _queryResultKeys>[] = _queryResultKeys;
export const mutateResultKeys: readonly ValuesOf<typeof _mutateResultKeys>[] = _mutateResultKeys;

export type QueryResult<TData = unknown> =
    Pick<UseQueryResult<TData, ServiceError>, ValuesOf<typeof queryResultKeys>>;

export type MutationResult<TData = unknown, TVariables = void, TContext = unknown> =
    Pick<UseMutationResult<TData, ServiceError, TVariables, TContext>, ValuesOf<typeof mutateResultKeys>>;

export const queryRetry: QueryOptions<unknown, ServiceError>['retry'] = (failureCount, error) => {
    if (error instanceof ServiceError && error.code.indexOf('_UNAUTHORIZED')) {
        return false;
    }

    if (isNumber(config.query.retry)) {
        return failureCount < (config.query.retry ?? 0);
    }

    return config.query.retry as boolean;
};

export const onServiceError = (error: ServiceError): void => {
    logger.warn(error);
    if (!(error instanceof ServiceError)) return;

    if (error.code.indexOf('_NOT_FOUND') >= 0) return;
    if (error.code.indexOf('_UNAUTHORIZED') >= 0) return unauthorizedProvider.notify();
    if (error.code.indexOf('_FORBIDDEN') >= 0) {
        return errorProvider.notify(new Error('Vous n\'êtes pas authorisé à effectuer cette action.'));
    }

    errorProvider.notify(error);
};

export const defaultDataOptions = {
    enabled: true,
    onError: onServiceError,
    retry: queryRetry
};

export const defaultActionOptions = {
    onError: onServiceError
};