import React from 'react';
import propTypes from 'prop-types';
import { isArray, orderBy } from '@dateam/ark';
import { Card, Table } from 'shared-ui';
import { useInspectionReports } from 'data/inspection';
import ListSection from 'components/ListSection';
import DateColumn from './DateColumn';
import ActionsColumn from './ActionsColumn';
import styles from '../ReportSection.module.scss';

type Props = {
    inspectionId: App.InspectionDetails['id'];
};

const ReportSectionList: React.FC<Props> = ({
    inspectionId
}) => {
    const { data: reports } = useInspectionReports(inspectionId);

    const tableData = React.useMemo(() => {
        if (!isArray(reports)) return [];

        const data = [...reports];

        orderBy(data, 'customer.label');

        return data;
    }, [reports]);

    return (
        <Card className="col">
            <Card.Header>
                <Card.Title>
                    Rapports
                </Card.Title>
            </Card.Header>
            <Card.Body className="row">
                <ListSection>
                    <ListSection.Content>
                        <Table<App.InspectionReport> data={tableData ?? []}>
                            <Table.Column accessor="customer.label">
                                <Table.Column.Header>Vigneron</Table.Column.Header>
                            </Table.Column>
                            <Table.Column accessor="plotCount" className={styles['colPlots']}>
                                <Table.Column.Header>Nombre de parcelles</Table.Column.Header>
                            </Table.Column>
                            <Table.Column className={styles['colGenerateDate']}>
                                <Table.Column.Header>Généré le</Table.Column.Header>
                                <Table.Column.Cell>
                                    {(props: any) => (
                                        <DateColumn date={(props.data as App.InspectionReport).generationDate} />
                                    )}
                                </Table.Column.Cell>
                            </Table.Column>
                            <Table.Column className={styles['colSendDate']}>
                                <Table.Column.Header>Envoyé le</Table.Column.Header>
                                <Table.Column.Cell>
                                    {(props: any) => (<DateColumn date={props.data.sendDate} />)}
                                </Table.Column.Cell>
                            </Table.Column>
                            <Table.Column className={styles['colActions']}>
                                <Table.Column.Header>Actions</Table.Column.Header>
                                <Table.Column.Cell>
                                    {(props: any) => (<ActionsColumn inspectionId={inspectionId} data={props.data} />)}
                                </Table.Column.Cell>
                            </Table.Column>
                        </Table>
                    </ListSection.Content>
                </ListSection>
            </Card.Body>
        </Card>
    );
};

ReportSectionList.propTypes = {
    inspectionId: propTypes.number.isRequired
};

ReportSectionList.defaultProps = {

};

export default ReportSectionList;
