import { GuardFunction, NavigationRoute } from 'shared-utils';
import { firstAuthPromise } from 'utils/auth';
import { getUserState } from 'utils/userStore';

const roleGuard = (fallback: NavigationRoute): GuardFunction => async (next, to) => {
    try {
        await firstAuthPromise;

        const user = getUserState();

        if (user == null) return next.redirect(fallback);

        if (to.metadata?.rolesAllowed == null || 
            to.metadata.rolesAllowed.indexOf(user?.roleId) >= 0) {
            return next();
        }

        return next.redirect(fallback);
    }
    catch {
        // Ignore
    }

    next.redirect(fallback);
};

export default roleGuard;