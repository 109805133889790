import React from 'react';
import propTypes from 'prop-types';
import { Card, Form } from 'shared-ui';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { SidePanelList } from 'components/SidePanelList';
import { useWriters } from 'data/user';
import { useActivityInspectionRemainingReports } from 'data/activity';
import { useUserState } from 'utils/userStore';
import styles from '../DashboardScreen.module.scss';
import WriterInspectionItem from './WriterInspectionItem';

type Props = {
    data: number[];
    className?: string;
};

const stripTimeFromDate = (date: Date | null) => {
    if (date == null) return null;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const WriterInspectionPanel: React.FC<Props> = ({
    data,
    className
}) => {
    const [user] = useUserState();
    const { data: inspections } = useActivityInspectionRemainingReports();
    const { data: writers } = useWriters();
    const [selectedWriter, setSelectedWriter] = React.useState<string | undefined>(user?.id.toString());

    const handleWriterChange = React.useCallback((newValue: string | undefined) => {
        setSelectedWriter(newValue);
    }, []);

    const inspectionList = React.useMemo(() => {
        if (!Ark.isArray(inspections) || !Ark.isArray(data)) return [];

        return inspections
            .sort((itemA, itemB) => {
                const dateA = stripTimeFromDate(itemA.startDate)?.getTime() ?? -Infinity;
                const dateB = stripTimeFromDate(itemB.startDate)?.getTime() ?? Infinity;

                if (dateA !== dateB) return dateB - dateA;

                return (itemA.label ?? '').localeCompare(itemB.label ?? '');
            });
    }, [inspections, data]);

    const assignedList = React.useMemo(() => {
        return inspectionList.filter(item => item.writerUser?.id.toString() === selectedWriter);
    }, [inspectionList, selectedWriter]);

    const unassignedList = React.useMemo(() => {
        return inspectionList.filter(item => item.writerUser == null);
    }, [inspectionList]);

    return (
        <Card
            className={concatClassName(
                styles['writerSelectorCard'],
                className
            )}
        >
            <Card.Header>
                <Card.Title>
                    Rapports à traiter
                </Card.Title>
            </Card.Header>
            <Card.Body className={styles['cardContent']}>
                <div className={styles['writerSelector']}>
                    <span className={styles['writerSelector-name']}>
                        Rédacteur
                    </span>
                    <Form.Select
                        selected={selectedWriter}
                        onChange={handleWriterChange}
                    >
                        {writers && writers.map(option => (
                            <Form.Select.Option key={option.id.toString()} value={option.id.toString()} selectedText={`${option.firstName} ${option.lastName}`}>
                                {option.firstName} {option.lastName}
                            </Form.Select.Option>
                        ))}
                    </Form.Select>
                </div>
                <SidePanelList>
                    {assignedList.map(inspection => <WriterInspectionItem key={inspection.id} value={inspection} />)}
                    {unassignedList.length > 0 && (
                        <>
                            <div className={`${styles['unassigned-text']} ${styles['middleLine']}`}>non assigné</div>
                            {unassignedList.map(inspection =>
                                <WriterInspectionItem key={inspection.id} value={inspection} />)}
                        </>
                    )}
                </SidePanelList>
            </Card.Body>
        </Card>
    );
};

WriterInspectionPanel.propTypes = {
    data: propTypes.arrayOf(propTypes.number.isRequired).isRequired,
    className: propTypes.string
};

WriterInspectionPanel.defaultProps = {
    className: undefined
};

export default WriterInspectionPanel;
