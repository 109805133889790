import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import ReportScreen from './ReportScreen';

export const ReportNavigation = (): NavigationRoute =>
    new NavigationRoute(
        ReportScreen.key,
        [getYearRouteParam()]
    );

const router = new Router();

router
    .add(new RouteData({
        key: ReportScreen.key,
        path: '/',
        component: ReportScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;