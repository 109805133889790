import React from 'react';
import propTypes from 'prop-types';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useCreateCustomer } from 'data/customer';
import styles from '../CustomerScreen.module.scss';

type CreateCustomer = {
    publicId: string | null;
    fullName: string | null;
};

type Props = {
    className?: string;
    onClose?: () => void;
    onCompletion?: (customerId: App.CustomerIdentity['id']) => void;
};

const CreateCustomerModal: React.FC<Props> = ({
    className,
    onClose,
    onCompletion
}: Props) => {
    const { mutateAsync: createCustomer, isLoading: isCreating } = useCreateCustomer();
    const [customerState, setCustomerState] = React.useState<CreateCustomer>({
        publicId: null,
        fullName: null
    });

    const formIsValid = React.useMemo(() => customerState.publicId != null &&
        customerState.publicId.trim().length > 0 &&
        customerState.fullName != null &&
        customerState.fullName.trim().length > 0, [customerState]);

    const handleInputChange = React.useCallback((key: string) => async (newValue: any) => {
        setCustomerState(prevState => {
            const newState = {
                ...prevState,
                [key]: newValue
            };

            return newState;
        });
    }, [setCustomerState]);

    const handleCloseClick = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleCreateClick = React.useCallback(async () => {
        if (customerState.publicId == null ||
            customerState.fullName == null) return;

        const customerKey = await createCustomer({
            publicId: customerState.publicId,
            fullName: customerState.fullName
        });

        onCompletion?.(customerKey.id);
    }, [createCustomer, onCompletion, customerState]);

    return (
        <Modal
            className={`${styles['modal']} ${className} modal-flex`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Créer un nouveau vigneron
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group controlId="customer-publicId" className="col col-4">
                        <Form.Label>Code</Form.Label>
                        <Form.Input
                            value={customerState?.publicId ?? undefined}
                            maxlength={20}
                            autocomplete="off"
                            readOnly={isCreating}
                            onChange={handleInputChange('publicId')}
                        />
                    </Form.Group>
                    <Form.Group controlId="customer-name" className="col col-12">
                        <Form.Label>Nom</Form.Label>
                        <Form.Input
                            value={customerState?.fullName ?? undefined}
                            maxlength={100}
                            autocomplete="off"
                            readOnly={isCreating}
                            onChange={handleInputChange('fullName')}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isCreating}
                    onClick={handleCloseClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer le vigneron
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreateCustomerModal.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

CreateCustomerModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default CreateCustomerModal;
