import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isArray, orderBy } from '@dateam/ark';
import { Button } from 'shared-ui';
import { useReportObservations } from 'data/campaign';
import { CrossIcon } from 'components/Icons';
import styles from './SelectedReportObservations.module.scss';

type Props = {
    observations?: number[];
    className?: string;
    onRemoveObservation?: (id: number) => void;
};

const SelectedReportObservations: React.FC<Props> = ({
    observations,
    className,
    onRemoveObservation
}: Props) => {
    const { data: observationData } = useReportObservations();

    const selectedObservations = React.useMemo<RefLabel[]>(() => {
        if (!isArray(observations) || !isArray(observationData)) return [];

        const data = observationData.filter(obs => observations.includes(obs.id));

        orderBy(data, 'position');
        return data;
    }, [observationData, observations]);

    const removeObservation = React.useCallback((id: number) => {
        onRemoveObservation?.(id);
    }, [onRemoveObservation]);

    return (
        <ul className={concatClassName(styles['list'], className)}>
            {selectedObservations?.map(observation => (
                <li key={observation.id} className={styles['item']}>
                    {observation.label}
                    <Button
                        onClick={() => removeObservation(observation.id)}
                        className={styles['delete']}
                        endIcon={<CrossIcon />}
                        size="xs"
                    >
                    </Button>
                </li>
            ))}
        </ul>
    );
};

SelectedReportObservations.propTypes = {
    observations: propTypes.arrayOf(propTypes.number.isRequired),
    className: propTypes.string,
    onRemoveObservation: propTypes.func
};

SelectedReportObservations.defaultProps = {
    observations: undefined,
    className: undefined,
    onRemoveObservation: undefined
};

export default SelectedReportObservations;
