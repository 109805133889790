import React from 'react';
import { plotPropTypes } from './types';

type Props = {
    data: App.Plot;
};

const ColumnArea: React.FC<Props> = ({ data }: Props) => (
    <>
        <div className="table-text-secondary">
            {data.city ?? '-'}
        </div>
        <div>
            {data.area?.label ?? '-'}
        </div>
    </>
);

ColumnArea.propTypes = {
    data: plotPropTypes.isRequired
};

export default ColumnArea;
