import React from 'react';
import * as Ark from '@dateam/ark';
import { useObservationTypes } from 'data/observationType';
import {
    QueryResult,
    queryResultKeys
} from '../constants';

export type ObservationTypeSearchFilter = {
    label: string | undefined;
};

export const useObservationTypeSearch = (
    filter: ObservationTypeSearchFilter,
    options?: DataOptions
): QueryResult<App.ObservationType[]> => {
    const { data: observationTypes, ...queryResult } = useObservationTypes();

    return React.useMemo(() => {
        let filteredData: App.ObservationType[] = [];

        if (Ark.isArray(observationTypes)) {
            filteredData = [...observationTypes];

            const {
                label: userFilter
            } = filter;

            const filterPredicates: ((dataEntry: App.ObservationType) => boolean)[] = [];

            if (Ark.isString(userFilter) && userFilter.trim().length > 0) {
                const filter = userFilter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.ObservationType) =>
                    dataEntry.label.toUpperCase().indexOf(filter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        Ark.orderBy(filteredData, 'label');

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [observationTypes, queryResult, filter]);
};
