import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as Ark from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { Button, Card, ConfirmDialog, Form, Loader } from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import { useDeleteObservationType, useObservationType, useUpdateObservationType } from 'data/observationType';
import Page from 'components/Page';
import { SidePanelList, SidePanelListItem, SidePanelListItemCol } from 'components/SidePanelList';
import { ObservationTypeNavigation, ObservationTypeDetailsNavigation } from 'screens/routes';
import { TrashIcon } from 'components/Icons';
import { errorProvider } from 'components/errorHandler';
import { FooterDateInfo } from 'components/FooterDateInfo';
import config from 'config';
import ObservationTypeDetailsBreadcrumb from './ObservationTypeDetailsBreadcrumb';
import styles from './ObservationTypeDetailsScreen.module.scss';

const ObservationTypeDetailsScreen: React.FC = () => {
    const { t } = useTranslation();
    const {
        push: navigate,
        query: { observationTypeId: observationTypeIdParam },
        replace: replaceNavigation
    } = useNavigation();
    const observationTypeId = React.useMemo(() => parseInt(observationTypeIdParam, 10), [observationTypeIdParam]);
    useBindYearNavigation(ObservationTypeDetailsNavigation, [observationTypeId]);
    const { data: type, isLoading, isFetched: hasLoaded } = useObservationType(observationTypeId);
    const { mutateAsync: updateObservationType, isLoading: isUpdating } = useUpdateObservationType();
    const { mutateAsync: deleteObservationType, isLoading: isDeleting } = useDeleteObservationType();
    const [showDeletionConfirm, setShowDeletionConfirm] = React.useState(false);

    const typeState = React.useRef(type);

    React.useEffect(() => {
        typeState.current = type;
    }, [type]);

    React.useEffect(() => {
        if (type == null && hasLoaded) {
            replaceNavigation(ObservationTypeNavigation());
        }
    }, [type, hasLoaded, replaceNavigation]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: any) => {
        if (!Ark.isDefined(typeState.current)) throw new Error('Le type de suivi n\'a pas été trouvé.');

        try {
            const {
                id,
                label,
                comment,
                year
            } = typeState.current;

            await updateObservationType({
                id,
                label,
                comment,
                year,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof Ark.ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }
    }, [typeState, updateObservationType]);

    const handleObservationTypeDeletion = React.useCallback(async (shouldDelete: boolean) => {
        if (shouldDelete === false) return setShowDeletionConfirm(false);
        if (!Ark.isDefined(typeState.current)) throw new Error('Le type de suivi n\'a pas été trouvé.');

        await deleteObservationType(typeState.current.id);

        setShowDeletionConfirm(false);
        navigate(ObservationTypeNavigation());
    }, [typeState, deleteObservationType, navigate, setShowDeletionConfirm]);

    if (!Ark.isDefined(type)) return null;

    return (
        <>
            <Helmet>
                <title>{t('observationType.details.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        {type?.label}
                    </Page.Title>
                    <Page.HeaderActions>
                        <ObservationTypeDetailsBreadcrumb label={type?.label ?? ''} />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Informations du type de suivi
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <Form.Group controlId="observationType-label" className="col col-6">
                                <Form.Label>Nom</Form.Label>
                                <Form.Editable.Input
                                    value={type?.label}
                                    onSave={handleSaveInput('label')}
                                    readOnly={type?.isReadonly}
                                />
                            </Form.Group>
                            <Form.Group controlId="observationType-comment" className="col col-12">
                                <Form.Label>Commentaire</Form.Label>
                                <Form.Editable.Input
                                    multiline
                                    rows={5}
                                    value={type?.comment ?? undefined}
                                    onSave={handleSaveInput('comment')}
                                />
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer className="row">
                            {Ark.isDefined(type) && (
                                <Card.Infos>
                                    <FooterDateInfo creationDate={type?.creationDate} />
                                </Card.Infos>
                            )}
                            <Card.Actions>
                                <Button
                                    color="danger"
                                    variant="outlined"
                                    startIcon={(<TrashIcon />)}
                                    pending={isDeleting}
                                    disabled={type?.isReadonly === true || isDeleting || isUpdating}
                                    // can't be deleted if readOnly obsType
                                    onClick={() => setShowDeletionConfirm(true)}
                                >
                                    Supprimer le type de suivi
                                </Button>
                                {showDeletionConfirm && (
                                    <ConfirmDialog
                                        title={`Supprimer le type de suivi "${type?.label}"`}
                                        message="Vous êtes sur le point de supprimer ce type de suivi. Êtes-vous sûr de vouloir continuer ?"
                                        ok="Supprimer"
                                        onClose={handleObservationTypeDeletion}
                                    />
                                )}
                            </Card.Actions>
                        </Card.Footer>
                    </Card>
                    <Card className="col col-4">
                        <Card.Header>
                            <Card.Title>
                                Utilisation
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className={styles['cardContent']}>
                            <SidePanelList>
                                <SidePanelListItem>
                                    <SidePanelListItemCol className="col-8" text="Nombre de parcelles associées" />
                                    <SidePanelListItemCol className="col-4" text={Ark.isNumber(type?.plotCount) ? type?.plotCount.toString() : '0'} />
                                </SidePanelListItem>
                                <SidePanelListItem>
                                    <SidePanelListItemCol className="col-8" text="Nombre d'observations associées" />
                                    <SidePanelListItemCol className="col-4" text={Ark.isNumber(type?.observationCount) ? type?.observationCount.toString() : '0'} />
                                </SidePanelListItem>
                            </SidePanelList>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(ObservationTypeDetailsScreen, 'OBSTYPE_DETAILS');