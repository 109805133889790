import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const StatsIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
        <polyline points="17 6 23 6 23 12"></polyline>
    </svg>
);

StatsIcon.propTypes = {
    className: propTypes.string
};

StatsIcon.defaultProps = {
    className: undefined
};

export default StatsIcon;
