import { isArray, isString, isValidNumber, ObjectAccessor, fixedLenInteger } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** N-Tester
 ** - field1: Valeur
 **     3 chiffres (formaté en string: 85 -> 085, il faut toujours avoir 3 caracteres)
 **     Aleter avec un warning si la mesure precedente est superieure a celle saisie
 **     Required
 */

type CurrentObservation = App.Observation2;
type CurrentApiObservation = App.Api.Observation2;

export const FORMAT_REGEXP = /^\d{3}$/;

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, field1, fieldType } = observation;

    if (ignored === true) return validation;

    // The type must be defined
    if (fieldType == null) {
        validation.fieldType = {
            field: 'fieldType',
            type: 'error',
            code: 'required'
        };
    }

    // The value must be defined
    if (field1 == null || !isString(field1) || field1.trim().length === 0) {
        validation.field1 = {
            field: 'field1',
            type: 'error',
            code: 'required'
        };
    }
    else {
        const matchGroups = field1.match(FORMAT_REGEXP);

        // If the value doesn't match the RegExp, then the value doesn't respect the expected format
        if (!isArray(matchGroups)) {
            validation.field1 = {
                field: 'field1',
                type: 'error',
                code: 'format'
            };
        }
        else {
            const parsedValue = parseInt(field1, 10);

            // If the value cannot be parsed into a valid number, the formated value is not valid
            if (!isValidNumber(parsedValue)) {
                validation.field1 = {
                    field: 'field1',
                    type: 'error',
                    code: 'format'
                };
            }
            // Checking previous observation to ensure new value is higher than the previous one
            else if (observation.previous != null) {
                const { field1: previousValue } = observation.previous as CurrentObservation;

                if (isValidNumber(previousValue) && previousValue > parsedValue) {
                    validation.field1 = {
                        field: 'field1',
                        type: 'warning',
                        code: 'missmatchPrevious'
                    };
                }
            }
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        field1,
        fieldType
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    const parsedField1 = parseObservationField(field1);
    parsedObs.field1 = isValidNumber(parsedField1) ? fixedLenInteger(parsedField1, 3) : parsedField1;
    parsedObs.fieldType = fieldType?.value;

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        field1,
        fieldType
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.field1 = field1 !== undefined ? new RecordValue(field1 !== null ? parseInt(field1, 10) : null) : null;
    mappedObs.fieldType = fieldType !== undefined ? new RecordValue(fieldType) : null;

    return mappedObs;
};
