import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { parseEmailFormat } from 'utils/email';
import styles from '../CustomerDetailsScreen.module.scss';

type Props = {
    className?: string;
    defaultType: string | null;
    onClose?: () => void;
    onCompletion?: (contacts: App.CustomerEmail[]) => void;
};

const AddContactModal: React.FC<Props> = ({
    className,
    defaultType,
    onClose,
    onCompletion
}: Props) => {
    const [contactsState, setContactsState] = React.useState<string>('');
    const [mailTypeState, setMailTypeState] = React.useState<string | null>(defaultType);

    const updateContactList = React.useCallback(() => {
        try {
            const contacts = parseEmailFormat(contactsState);

            const newCustomerEmail = contacts.map<App.CustomerEmail>(item => ({
                email: item,
                type: mailTypeState
            }));

            onCompletion?.(newCustomerEmail);
            onClose?.();
        }
        catch {
            // TODO: Handle error
        }
    }, [contactsState, mailTypeState, onCompletion, onClose]);

    const handleClose = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleMailTypeChange = React.useCallback((newValue: string | null) => {
        setMailTypeState(newValue);
    }, []);

    return (
        <Modal
            className={concatClassName(styles['modal'], 'modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Ajouter des destinataires
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="customer-contact" className="col col-12">
                    <Form.Label>Type de contact</Form.Label>
                    <Form.Select
                        selected={mailTypeState}
                        onChange={handleMailTypeChange}
                    >
                        <Form.Select.Option key="contact" value={null}>
                            Contact
                        </Form.Select.Option>
                        <Form.Select.Option key="dre" value="DRE">
                            Contact DRE
                        </Form.Select.Option>
                    </Form.Select>
                </Form.Group>
                <Form.Group controlId="customer-contact" className="col col-12">
                    <Form.Label>Nouveau(x) destinataire(s)</Form.Label>
                    <Form.Input
                        multiline
                        value={contactsState}
                        autocomplete="off"
                        rows={7}
                        onChange={setContactsState}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={contactsState.length === 0}
                    onClick={updateContactList}
                >
                    Ajouter
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddContactModal.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

AddContactModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default AddContactModal;
