import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    NavigationRoute,
    useNavigation,
    useRouterPath
} from 'shared-utils';
import styles from '../SidePanelList.module.scss';

type Props = {
    to: NavigationRoute;
    children?: React.ReactNode;
    className?: string;
};

const SidePanelListItem: React.FC<Props> = ({ to, children, className }: Props) => {
    const path = useRouterPath(to);
    const { push: navigate } = useNavigation();

    const onClick = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        if (to instanceof NavigationRoute) navigate(to);
    }, [navigate, to]);

    return (
        <a href={path} className={concatClassName(className, styles['listItem'])} onClick={onClick}>
            {children}
        </a>
    );
};

SidePanelListItem.propTypes = {
    to: propTypes.instanceOf(NavigationRoute).isRequired,
    children: propTypes.node,
    className: propTypes.string
};

SidePanelListItem.defaultProps = {
    children: undefined,
    className: undefined
};

export default SidePanelListItem;
