import React from 'react';
import propTypes from 'prop-types';
import { isArray, isDefined, isFunction, strIncludes } from '@dateam/ark';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useTracks } from 'data/track';
import { inspectionDetailsPropTypes, useCreateInspection, useUpdateInspection } from 'data/inspection';
import styles from './DuplicateInspectionDialog.module.scss';

type Props = {
    inspection: Omit<App.InspectionDetails, 'observations' | 'plots'>;
    className?: string;
    onClose?: () => void;
    onCompletion?: (campainId: number) => void;
};

type TrackOption = {
    id: number;
    label: string;
};

type TrackState = {
    track?: TrackOption;
    includeTrack: boolean;
};

type DuplicationFields = {
    observator: boolean;
    writer: boolean;
    date: boolean;
    instruction: boolean;
};

const DuplicateInspectionDialog: React.FC<Props> = ({
    inspection,
    className,
    onClose,
    onCompletion
}: Props) => {
    const { data: tracks } = useTracks();
    const { mutateAsync: createInspection } = useCreateInspection();
    const { mutateAsync: updateInspection } = useUpdateInspection();
    const [isCreating, setIsCreating] = React.useState<boolean>(false);
    const [labelState, setLabelState] = React.useState<App.CreateInspectionRequest['label']>('');
    const [trackState, setTrackState] = React.useState<TrackState>({ includeTrack: false });
    const [duplicationFieldsState, setDuplicationFieldsState] = React.useState<DuplicationFields>({
        observator: true,
        writer: true,
        date: true,
        instruction: true
    });

    const formIsValid = React.useMemo(() => labelState.trim().length > 0, [labelState]);

    const handleLabelChange = React.useCallback((value: string) => {
        setLabelState(value);
    }, [setLabelState]);

    const handleCloseClick = () => {
        onClose?.();
    };

    const handleCreateClick = async () => {
        if (!formIsValid) return;

        setIsCreating(true);

        try {
            const newInspectionId = await createInspection({
                campaignId: inspection.campaign.id,
                label: labelState,
                includeTrackId: (trackState.includeTrack && trackState.track?.id) || null // eslint-disable-line @typescript-eslint/strict-boolean-expressions
            });

            const newInspection: App.UpdateInspectionRequest = {
                id: newInspectionId,
                label: labelState,
                instruction: duplicationFieldsState.instruction === true ? inspection.instruction : null,
                startDate: duplicationFieldsState.date === true ? inspection.startDate : null,
                assigneeUser: duplicationFieldsState.observator === true && isDefined(inspection.assigneeUser) ?
                    inspection.assigneeUser?.id : null,
                overlapDays: 1,
                writer: duplicationFieldsState.writer === true && isDefined(inspection.writerUser) ?
                    inspection.writerUser?.id : null
            };

            await updateInspection(newInspection);

            onCompletion?.(newInspectionId);
            onClose?.();
        }
        catch {
            // ignore
        }

        setIsCreating(false);
    };

    const handleGetLabelOptions = React.useCallback(async (value: string): Promise<TrackOption[]> => {
        if (!isArray(tracks)) return [];

        return tracks.filter(dataItem => strIncludes(value, dataItem.label, { caseInsensitive: true }));
    }, [tracks]);

    const handleOptionSelected = React.useCallback((option: TrackOption) => {
        setLabelState(option.label);
        setTrackState({
            track: { ...option },
            includeTrack: true
        });
    }, []);

    const handleIncludeTrackChange = React.useCallback((value: boolean) => {
        setTrackState(prevState => ({
            ...prevState,
            includeTrack: value
        }));
    }, []);

    const handleDuplicationFieldsChange = React.useCallback((key: string) => (value: boolean) => {
        setDuplicationFieldsState(prevState => ({
            ...prevState,
            [key]: value
        }));
    }, [setDuplicationFieldsState]);

    return (
        <Modal
            className={`${styles['modal']} modal-flex ${className}`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Dupliquer la visite {inspection.label}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group controlId="dialog-inspection-name" className="col">
                        <Form.Label>Nom de la visite</Form.Label>
                        <Form.Autocomplete<TrackOption>
                            maxlength={50}
                            onGetOptions={handleGetLabelOptions}
                            onChange={handleLabelChange}
                            onOptionSelected={handleOptionSelected}
                            onGetLabel={value => value.label}
                        />
                    </Form.Group>
                    {trackState?.track != null && (
                        <Form.Group controlId="include-track" className="form-inline">
                            <Form.Checkbox checked={trackState.includeTrack} onChange={handleIncludeTrackChange} />
                            <Form.Label>Inclure les parcelles du circuit {trackState.track.label}</Form.Label>
                        </Form.Group>
                    )}
                    <div className="formLabel">Informations à dupliquer</div>
                    <Form.Group controlId="include-observator" className="form-inline">
                        <Form.Checkbox checked={duplicationFieldsState.observator} onChange={handleDuplicationFieldsChange('observator')} />
                        <Form.Label>Observateur</Form.Label>
                    </Form.Group>
                    <Form.Group controlId="include-writer" className="form-inline">
                        <Form.Checkbox checked={duplicationFieldsState.writer} onChange={handleDuplicationFieldsChange('writer')} />
                        <Form.Label>Rédacteur</Form.Label>
                    </Form.Group>
                    <Form.Group controlId="include-date" className="form-inline">
                        <Form.Checkbox checked={duplicationFieldsState.date} onChange={handleDuplicationFieldsChange('date')} />
                        <Form.Label>Date de la visite</Form.Label>
                    </Form.Group>
                    <Form.Group controlId="include-instruction" className="form-inline">
                        <Form.Checkbox checked={duplicationFieldsState.instruction} onChange={handleDuplicationFieldsChange('instruction')} />
                        <Form.Label>Consigne</Form.Label>
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isCreating}
                    onClick={handleCloseClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer la visite
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

DuplicateInspectionDialog.propTypes = {
    inspection: inspectionDetailsPropTypes.isRequired,
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

DuplicateInspectionDialog.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default DuplicateInspectionDialog;
