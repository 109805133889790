import React from 'react';
import { useMutation, useQuery } from 'react-query';
import * as Ark from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { useYearState } from 'utils/yearStore';
import { queryClient } from 'utils/queryClient';
import {
    TRACK_KEY,
    TRACK_DETAILS_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    defaultActionOptions,
    mutateResultKeys,
    PLOT_DETAILS_KEY
} from './constants';
import { trackRequests } from './requests';

export const useTracks = (options?: DataOptions): QueryResult<App.Track[]> => {
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const [year] = useYearState();
    const query = useQuery<App.Track[], Ark.ServiceError>({
        ...config,
        queryKey: [year, TRACK_KEY],
        queryFn: () => trackRequests.getTracks(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useTrackDetails = (trackId: App.TrackDetails['id'], options?: DataOptions): QueryResult<App.TrackDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.TrackDetails, Ark.ServiceError>({
        ...config,
        queryKey: [year, TRACK_DETAILS_KEY, trackId],
        queryFn: () => trackRequests.get(year, trackId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateTrack = (): MutationResult<App.TrackKey, App.CreateTrackRequest> => {
    const [year] = useYearState();
    const result = useMutation<App.TrackKey, Ark.ServiceError, App.CreateTrackRequest>(
        ({ ...requestData }: App.CreateTrackRequest) => trackRequests.createTrack(
            year,
            requestData.label
        ),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([year, TRACK_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateTrack = (): MutationResult<void, App.UpdateTrackRequest> => {
    const result = useMutation<void, Ark.ServiceError, App.UpdateTrackRequest>(
        ({ id, year, ...requestData }: App.UpdateTrackRequest) => trackRequests.updateTrack(
            year,
            id,
            { ...requestData }
        ),
        {
            ...defaultActionOptions,
            onSuccess: (_, { id, year }) => {
                queryClient.invalidateQueries([year, TRACK_KEY]);
                queryClient.invalidateQueries([year, TRACK_DETAILS_KEY, id]);
                queryClient.invalidateQueries([year, PLOT_DETAILS_KEY]);
            }
        }
    );

    return result;
};

export const useDeleteTrack = (): MutationResult<void, App.TrackDetails['id']> => {
    const [year] = useYearState();
    const result = useMutation<void, Ark.ServiceError, App.TrackDetails['id']>(
        id => trackRequests.deleteTrack(year, id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, TRACK_KEY]);
                queryClient.invalidateQueries([year, TRACK_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};
