import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import TrackDetailsScreen from './TrackDetailsScreen';

export const TrackDetailsNavigation = (trackId: App.Track['id']): NavigationRoute =>
    new NavigationRoute(
        TrackDetailsScreen.key,
        [getYearRouteParam(), trackId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: TrackDetailsScreen.key,
        path: '/<:trackId>', // Should have matched the pattern <:trackId(\\d+)> if react-router V6 supported it
        component: TrackDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;