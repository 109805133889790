import React from 'react';

type Props = {
    data: App.Report.Report;
};

const ColumnInspection: React.FC<Props> = ({ data }: Props) => (
    <div>
        {data.inspectionLabel}
    </div>
);

export default ColumnInspection;
