import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    isDefined,
    isValidDate,
    orderBy
} from '@dateam/ark';
import { propTypeNullable } from '@dateam/ark-react';
import propTypes from 'prop-types';
import { getInspectionStatus } from 'data/inspection/helpers';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import {
    Card
} from 'shared-ui';
import { SummaryItem } from './components';
import styles from '../../DashboardScreen.module.scss';

type Props = {
    data?: App.ActivitySummary[];
    isActualYear: boolean;
    className?: string;
};

const stepList = [
    { key: 'planned', label: 'Planifiée' },
    { key: 'downloaded', label: 'Récupérée' },
    { key: 'inprogress', label: 'En cours' },
    { key: 'validated', label: 'Validée' }
];

const SummaryPanel: React.FC<Props> = ({ data, isActualYear, className }) => {
    const computedActivitySummary = React.useMemo(() => {
        if (!isDefined(data)) return [];

        const activitySummary = [...data];

        orderBy(activitySummary, 'syncDate', { ascending: false, nullFirst: true });
        orderBy(activitySummary, 'validationDate', { ascending: false, nullFirst: true });

        return activitySummary.map(summary => {
            const { creationDate, syncDate, firstSyncDate, validationDate } = summary;

            const inspectionStatus = getInspectionStatus(summary);

            const statusList: {
                key: string,
                date: Date | null
            }[] = [{
                key: 'planned',
                date: creationDate
            }];

            if (inspectionStatus === 'validated' || inspectionStatus === 'inprogress' || inspectionStatus === 'downloaded') {
                statusList.push({
                    key: 'downloaded',
                    date: firstSyncDate
                });
            }
            if (inspectionStatus === 'validated' || inspectionStatus === 'inprogress') {
                statusList.push({
                    key: 'inprogress',
                    date: syncDate
                });
            }
            if (inspectionStatus === 'validated') {
                statusList.push({
                    key: 'validated',
                    date: validationDate
                });
            }
            
            let activeStep: string | null = null;

            const history = stepList.map(step => {
                const matchStep = statusList.find(status => status.key === step.key);
                const isActive = stepList[statusList.length - 1].key === step.key;

                if (isActive && activeStep == null) activeStep = step.key;

                return {
                    key: step.key,
                    label: step.label,
                    date: matchStep?.date,
                    subtext: step.key === 'inprogress' ? `${summary.validatedPlotCount}/${summary.plotCount}` : undefined,
                    isActive
                };
            });

            return {
                id: summary.id,
                label: summary.label,
                plotCount: summary.plotCount,
                overlapDays: summary.overlapDays,
                assigneeUser: summary.assigneeUser.label,
                activeStep,
                history
            };
        });
    }, [data]);

    const renderContent = () => {
        if (isActualYear === false) {
            return (
                <div className={styles['noData']}>
                    Le voyage temporel n'est pas supporté par cette application.
                    <br />
                    Nous vous conseillons de retourner dans votre époque pour visualiser le tableau de bord.
                </div>
            );
        }

        if (computedActivitySummary.length > 0) {
            return (
                <ul className={styles['inspectionList']}>
                    {computedActivitySummary.map(summary => (
                        <SummaryItem key={summary.id} data={summary} className={styles['inspectionItem']} />
                    ))}
                </ul>
            );
        }

        return (<div className={styles['noData']}>Aucune visite planifiée aujourd'hui</div>);
    };

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>
                    Visites du jour
                </Card.Title>
            </Card.Header>
            <Card.Body className={styles['inspectionContainer']}>
                {renderContent()}
            </Card.Body>
        </Card>
    );
};

SummaryPanel.propTypes = {
    data: propTypes.arrayOf(propTypes.shape({
        id: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        creationDate: propTypes.instanceOf(Date).isRequired,
        startDate: propTypes.instanceOf(Date).isRequired,
        assigneeUser: refLabelNumberPropTypes.isRequired,
        overlapDays: propTypes.number.isRequired,
        syncDate: propTypeNullable(isValidDate),
        firstSyncDate: propTypeNullable(isValidDate),
        validationDate: propTypeNullable(isValidDate),
        plotCount: propTypes.number.isRequired,
        validatedPlotCount: propTypes.number.isRequired
    }).isRequired),
    isActualYear: propTypes.bool.isRequired,
    className: propTypes.string
};

SummaryPanel.defaultProps = {
    data: undefined,
    className: undefined
};

export default SummaryPanel;
