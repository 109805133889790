import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { isArray, orderBy } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import config from 'config';
import { CrossIcon } from 'components/Icons';
import { useUserState } from 'utils/userStore';
import { CustomerNavigation, CustomerDetailsNavigation } from 'screens/routes';
import { useCustomerSearch } from 'data/effects';
import { FilterBar, HeaderAction } from './components';
import CustomerBreadcrumb from './CustomerBreadcrumb';
import styles from './CustomerScreen.module.scss';

const CustomerScreen: React.FC = () => {
    useBindYearNavigation(CustomerNavigation, []);
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState<string>('');
    const { push: navigate } = useNavigation();
    const { data: customers, isLoading } = useCustomerSearch(filter);
    const [user] = useUserState();
    const displayActions = user?.roleId === 'administrator' || user?.roleId === 'supervisor';

    const tableData = React.useMemo(() => {
        if (!isArray(customers)) return [];

        const data = [...customers];

        orderBy(customers, 'fullName');

        return data;
    }, [customers]);

    const handleRowClick = React.useCallback((event: TableRowClickEvent<App.Customer>) => {
        navigate(CustomerDetailsNavigation(event.data.id));
    }, [navigate]);

    const handleFilterChange = React.useCallback((filter: string) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('customer.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Vignerons
                    </Page.Title>
                    <Page.HeaderActions>
                        <CustomerBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                            <Card.Title>
                                Liste des vignerons
                            </Card.Title>
                            {displayActions && (<HeaderAction />)}
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar onChange={handleFilterChange} />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<App.Customer>
                                        data={tableData}
                                        onRowClick={handleRowClick}
                                        className={styles['tableRow']}
                                    >
                                        <Table.Column accessor="fullName" className={styles['tableCol-name']}>
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column accessor="plotCount" className={styles['tableCol-plot']}>
                                            <Table.Column.Header>Nombre de parcelles</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column accessor="deactivationDate" className={styles['tableCol-customer']}>
                                            <Table.Column.Header>Désactivé</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {(props: any) => (
                                                    <>
                                                        {props.data.deactivationDate != null && (
                                                            <CrossIcon />
                                                        )}
                                                    </>
                                                )}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(CustomerScreen, 'CUSTOMER');