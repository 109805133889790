import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import ObservationTypeScreen from './ObservationTypeScreen';

export const ObservationTypeNavigation = (): NavigationRoute => new NavigationRoute(
    ObservationTypeScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: ObservationTypeScreen.key,
        path: '',
        component: ObservationTypeScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;