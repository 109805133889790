import { isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** % surface enherbée
 ** - row1: Général ou 1er rang // %
 **     0 <= Value <= 100
 **     Required
 ** - row2: 2ème rang // %
 **     0 <= Value <= 100
 ** - processedRow: Rang de traitement // %
 **     0 <= Value <= 100
 ** - comment: Commentaire
 */

type CurrentObservation = App.Observation4;
type CurrentApiObservation = App.Api.Observation4;

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, row1, row2, processedRow, comment } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (row1 == null || !isValidNumber(row1)) {
        validation.row1 = {
            field: 'row1',
            type: 'error',
            code: 'required'
        };
    }
    else if (row1 < 0 || row1 > 100) {
        validation.row1 = {
            field: 'row1',
            type: 'error',
            code: 'format'
        };
    }

    if (row2 != null) {
        if ((!isValidNumber(row2) || row2 < 0 || row2 > 100)) {
            validation.row2 = {
                field: 'row2',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (processedRow != null) {
        if ((!isValidNumber(processedRow) || processedRow < 0 || processedRow > 100)) {
            validation.processedRow = {
                field: 'processedRow',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (comment != null && !isString(comment)) {
        validation.comment = {
            field: 'comment',
            type: 'error',
            code: 'format'
        };
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        row1,
        row2,
        processedRow,
        comment
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.row1 = parseObservationField(row1);
    parsedObs.row2 = parseObservationField(row2);
    parsedObs.processedRow = parseObservationField(processedRow);
    parsedObs.comment = parseObservationField(comment);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        row1,
        row2,
        processedRow,
        comment
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.row1 = row1 !== undefined ? new RecordValue(row1) : null;
    mappedObs.row2 = row2 !== undefined ? new RecordValue(row2) : null;
    mappedObs.processedRow = processedRow !== undefined ? new RecordValue(processedRow) : null;
    mappedObs.comment = comment !== undefined ? new RecordValue(comment) : null;

    return mappedObs;
};
