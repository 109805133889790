import React from 'react';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import { useYearlyNavigation } from 'utils/yearStore';
import { SidePanelListItemCol, SidePanelListItemLink } from 'components/SidePanelList';
import { TrackDetailsNavigation } from 'screens/routes';

type Props = {
    value: RefLabel;
};

const TrackItem: React.FC<Props> = ({ value }: Props) => {
    const trackNav = useYearlyNavigation(TrackDetailsNavigation, [value.id]);

    return (
        <SidePanelListItemLink key={`track-${value.id}`} to={trackNav}>
            <SidePanelListItemCol className="col-12" text={value.label} />
        </SidePanelListItemLink>
    );
};

TrackItem.propTypes = {
    value: refLabelNumberPropTypes.isRequired
};

export default TrackItem;
