import * as Ark from '@dateam/ark';
import { assert, assertIsArray, HttpStatusCode, isDefined, ServiceError, stringFormat } from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const get = async (): Promise<App.BioType[]> => {
    let response;
    try {
        const requestUrl = stringFormat('{0}/bio', config.apiUrl);

        response = await httpService.get<App.Api.BioType[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling bio type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send bio request (getTypes)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('BIO_GET_TYPE_UNAUTHORIZED');
    }
    if (response.status === HttpStatusCode.Forbidden) {
        throw new ServiceError('BIO_GET_FORBIDDEN');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'BIO_GET_TYPE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('BIO_GET_TYPE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body.map(data => mapBioType(data));
};

export const getTypeDetails = async (id: App.BioTypeDetails['id']): Promise<App.BioTypeDetails> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/bio/{1}', config.apiUrl, id);
        console.log(requestUrl);
        response = await httpService.get<App.Api.BioTypeDetails>(requestUrl, {});
    }

    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling bio type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send bio request (getTypeDetails)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('BIO_GET_TYPE_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('BIO_GET_TYPE_DETAILS_FORBIDDEN');
    }

    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('BIO_GET_TYPE_DETAILS_NOT_FOUND');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'BIO_GET_TYPE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isDefined(response.body) && Ark.isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('BIO_GET_TYPE_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return mapBioTypeDetails(response.body);
};

export const createBioType = async (request: App.Api.CreateBioTypeRequest): Promise<number> => {
    if (!Ark.isDefined(request)) throw new Error('request parameter is not valid');

    const { label } = request;
    if (!Ark.isString(label) || label.trim().length === 0) throw new Ark.ServiceError('BIO_TYPE_CREATE_INVALID_REQUEST', 'request label is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/bio', config.apiUrl);
        const requestData = { label };

        response = await httpService.post<number>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling create bio type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send user request (${createBioType.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('BIO_TYPE_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('BIO_TYPE_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('BIO_TYPE_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'BIO_TYPE_CREATE_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isValidNumber(response.body), 'invalid bio type id from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('BIO_TYPE_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const update = async (
    id: App.BioTypeDetails['id'],
    request: App.Api.UpdateBioTypeRequest
): Promise<void> => {
    Ark.assertIsValidNumber(id, 'id parameter is not valid');
    Ark.assertIsDefined(request, 'request parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/bio/{1}', config.apiUrl, id);
        const { label } = request;
        const requestData = { label };

        response = await httpService.post<number | null>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling bio type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send bio type request (${update.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('BIO_TYPE_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('BIO_TYPE_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('BIO_TYPE_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'BIO_TYPE_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
    }
    catch (err) {
        throw new Ark.ServiceError('BIO_TYPE_UPDATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteBioType = async (bioTypeId: App.BioTypeDetails['id']): Promise<void> => {
    Ark.assertIsNumber(bioTypeId, 'bioTypeId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/bio/{1}', config.apiUrl, bioTypeId);

        response = await httpService.delete(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete bio type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send bio type request (${deleteBioType.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('BIO_TYPE_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('BIO_TYPE_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('BIO_TYPE_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'BIO_TYPE_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }
};

const mapBioType = ({
    id,
    label
}: App.Api.BioType): App.BioType => ({
    id,
    label
});

const mapBioTypeDetails = ({
    id,
    label,
    plotCount,
    creationDate,
    updatedDate
}: App.Api.BioTypeDetails): App.BioTypeDetails => ({
    id,
    label,
    plotCount,
    creationDate,
    updatedDate
});