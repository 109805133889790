import React from 'react';
import { propTypeNullable } from '@dateam/ark-react';
import { isRefLabelNumber } from 'utils/propTypes';

type Props = {
    data: RefLabel<number> | null;
};

const ColumnBio: React.FC<Props> = ({ data }: Props) => (
    <div>
        {data?.label ?? '-'}
    </div>
);

ColumnBio.propTypes = {
    data: propTypeNullable(isRefLabelNumber)
};

ColumnBio.defaultProps = {
    data: undefined
};

export default ColumnBio;
