import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import VarietalTypeDetailsScreen from './VarietalTypeDetailsScreen';

export const VarietalTypeDetailsNavigation = (varietalTypeId: number): NavigationRoute =>
    new NavigationRoute(
        VarietalTypeDetailsScreen.key,
        [getYearRouteParam(), varietalTypeId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: VarietalTypeDetailsScreen.key,
        path: '/<:varietalTypeId>', // Should have matched the pattern <:varietalTypeId([\\d]+)> if react-router V6 supported it
        component: VarietalTypeDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;