import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { useNavigation, withRouter } from 'shared-utils';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent
} from 'shared-ui';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import { useVarietalTypeSearch } from 'data/effects';
import { VarietalTypeDetailsNavigation, VarietalTypeNavigation } from 'screens/routes';
import { FilterBar, HeaderAction, VarietalTypeFilters } from './components';
import VarietalTypeBreadcrumb from './VarietalTypeBreadcrumb';
import styles from './VarietalTypeScreen.module.scss';

const VarietalTypeScreen: React.FC = () => {
    useBindYearNavigation(VarietalTypeNavigation, []);
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const [filter, setFilter] = React.useState<VarietalTypeFilters>({ label: '', code: '' });
    const { data: varietalTypes, isLoading } = useVarietalTypeSearch({ label: filter.label, code: filter.code });

    const handleRowClick = React.useCallback((event: TableRowClickEvent<App.VarietalType>) => {
        navigate(VarietalTypeDetailsNavigation(event.data.id));
    }, [navigate]);

    const handleFilterChange = React.useCallback((filter: VarietalTypeFilters) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('varietalType.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Cépages
                    </Page.Title>
                    <Page.HeaderActions>
                        <VarietalTypeBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName('withAction')}>
                            <Card.Title>
                                Liste des cépages
                            </Card.Title>
                            <HeaderAction />
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar onChange={handleFilterChange} />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<App.VarietalType>
                                        data={varietalTypes ?? []}
                                        onRowClick={handleRowClick}
                                        className={styles['tableRow']}
                                    >
                                        <Table.Column accessor="code">
                                            <Table.Column.Header>Code</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column accessor="label">
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(VarietalTypeScreen, 'VARIETALTYPE');