import React from 'react';
import propTypes from 'prop-types';
import { customerDetailsPropTypes } from 'data/customer';
import { ConfigurationPanel } from './components';
import PlotsPanel from './components/PlotsPanel';

type Props = {
    customer: App.CustomerDetails;
    displayActions?: boolean;
};

const ConfigurationSection: React.FC<Props> = ({
    customer,
    displayActions
}: Props) => {
    return (
        <div className="row">
            <ConfigurationPanel
                className="col col-7"
                customer={customer}
                displayActions={displayActions}
            />
            <PlotsPanel
                plots={customer.plots}
                customer={customer}
                displayActions={displayActions}
                className="col col-5"
            />
        </div>
    );
};

ConfigurationSection.propTypes = {
    customer: customerDetailsPropTypes.isRequired,
    displayActions: propTypes.bool
};

ConfigurationSection.defaultProps = {
    displayActions: undefined
};

export default ConfigurationSection;
