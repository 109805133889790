import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import propTypes from 'prop-types';
import { isDefined, orderBy, quickClone } from '@dateam/ark';
import { useNavigationEvent } from 'shared-utils';
import { Button, Card, Loader } from 'shared-ui';
import { useRefreshReportRecords, useReport, useSaveInspectionReport } from 'data/inspection';
import ListSection from 'components/ListSection';
import { RefreshIcon, SaveIcon } from 'components/Icons';
import HeaderAction from './HeaderAction';
import ObservationList from './ObservationList';
import ReportSummary from './ReportSummary';
import ReportEntry from './ReportEntry';
import ReportPreview from './ReportPreview';
import styles from '../ReportSection.module.scss';
import ReportEndSection from './ReportEndSection';

const PENDING_CHANGES_MESSAGE = 'Il reste des modifications en attente d\'enregistrement, êtes-vous sûr de vouloir quitter la page ?';
const pendingChangesHandler = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    ev.returnValue = PENDING_CHANGES_MESSAGE;
    return PENDING_CHANGES_MESSAGE;
};

type Props = {
    inspectionId: App.InspectionDetails['id'];
    reportId: App.InspectionReport['id'];
};

const ReportSectionDetails: React.FC<Props> = ({
    inspectionId,
    reportId
}) => {
    const { t } = useTranslation();
    const { data: report, isLoading } = useReport(inspectionId, reportId);
    const { mutateAsync: save, isLoading: isSaving } = useSaveInspectionReport();
    const { mutateAsync: refreshRecords, isLoading: isRefreshingRecords } = useRefreshReportRecords();
    const [reportState, setReportState] = React.useState<App.Report.ReportDetails | null>();
    const [selectedId, setSelectedId] = React.useState<number>(0);
    const [reportChanged, setReportChanged] = React.useState(false);

    // Bind pending changes handlers to prevent navigation out
    React.useEffect(() => {
        if (reportChanged === true) {
            globalThis.addEventListener('beforeunload', pendingChangesHandler);
        }

        return () => globalThis.removeEventListener('beforeunload', pendingChangesHandler);
    }, [reportChanged]);

    React.useEffect(() => {
        if (!isDefined(report) || reportChanged) return;

        const newReport = quickClone(report);
        if (newReport == null) return;

        orderBy(newReport.observations, 'position');
        setReportState(newReport);
    }, [report, reportChanged, setReportState]);

    useNavigationEvent(async (route, resolve, reject) => {
        if (reportChanged === true && isDefined(route)) {
            // eslint-disable-next-line no-alert
            if (!window.confirm(PENDING_CHANGES_MESSAGE)) {
                reject();
                return;
            }
        }

        resolve();
    }, [reportChanged]);

    const selectedObs = React.useMemo(
        () => reportState?.observations.find(observation => observation.id === selectedId),
        [
            reportState,
            selectedId
        ]
    );

    const handleOnSelect = React.useCallback((id: number) => {
        setSelectedId(id);
    }, [setSelectedId]);

    const handleReportChange = React.useCallback((value: App.Report.ReportDetails) => {
        setReportState(value);
        setReportChanged(true);
    }, [setReportState]);

    const handleObservationChange = React.useCallback((value: App.Report.ReportObservation) => {
        setReportState(prevState => {
            if (prevState == null) return prevState;

            const index = prevState.observations.findIndex(item => item.id === selectedId);
            if (index < 0) return prevState;

            const newObservations = [...prevState.observations];
            newObservations[index] = value;

            setReportChanged(true);
            return {
                ...prevState,
                observations: newObservations
            };
        });
    }, [selectedId, setReportState, setReportChanged]);

    const handleSaveReport = React.useCallback(async () => {
        if (reportState == null || reportChanged === false) return;

        await save({
            inspectionId,
            report: reportState
        });
        setReportChanged(false);
    }, [inspectionId, reportState, reportChanged, save]);

    const handleCancelChanges = React.useCallback(() => {
        setReportState(quickClone(report));
        setReportChanged(false);
    }, [setReportState, report]);

    const handleReportRegeneration = React.useCallback(async () => {
        if (reportChanged === true) return;

        await refreshRecords({
            inspectionId,
            reportId
        });
    }, [inspectionId, reportId, reportChanged, refreshRecords]);

    if (reportState == null) return null;

    return (
        <Card className="col">
            <Card.Header className="withAction">
                <Card.Title>
                    Rapport {reportState.customer.label} ({t('report.plot', { count: reportState.plotCount })})
                </Card.Title>
                <HeaderAction />
            </Card.Header>
            <Card.Body className="row">
                {isLoading && (
                    <Loader text="chargement" />
                )}
                <ListSection className="col col-3">
                    <ListSection.Filter>
                        <div className={styles['colHeader']}>Sections</div>
                    </ListSection.Filter>
                    <ListSection.Content>
                        <ObservationList
                            data={reportState.observations}
                            selected={selectedId}
                            includeNextInspection={reportState.includeNextInspection === true}
                            onSelectPlot={handleOnSelect}
                        />
                    </ListSection.Content>
                </ListSection>
                <ListSection className="col">
                    <ListSection.Filter>
                        <div className={styles['colHeader']}>Saisie</div>
                    </ListSection.Filter>
                    <ListSection.Content>
                        {selectedId === 0 && (
                            <ReportSummary
                                data={reportState}
                                onChange={handleReportChange}
                            />
                        )}
                        {selectedId === 9999 && (
                            <ReportEndSection
                                data={reportState}
                                onChange={handleReportChange}
                            />
                        )}
                        {selectedId > 0 && selectedObs != null && (
                            <ReportEntry
                                data={selectedObs}
                                onChange={handleObservationChange}
                            />
                        )}
                    </ListSection.Content>
                </ListSection>
                <ListSection className={`${styles['reportPreviewCol']} col`}>
                    <ListSection.Filter>
                        <div className={styles['colHeader']}>Aperçu</div>
                    </ListSection.Filter>
                    <ListSection.Content>
                        <ReportPreview data={reportState} selectedId={selectedId} />
                    </ListSection.Content>
                </ListSection>
            </Card.Body>
            <Card.Footer>
                <Card.Actions>
                    <Button
                        startIcon={<RefreshIcon />}
                        color="primary"
                        variant="outlined"
                        onClick={handleReportRegeneration}
                        pending={isRefreshingRecords}
                        disabled={reportChanged}
                    >
                        Regénérer les relevés
                    </Button>
                    <Button
                        color="secondary"
                        onClick={handleCancelChanges}
                        disabled={!reportChanged}
                    >
                        Annuler
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        color="primary"
                        onClick={handleSaveReport}
                        pending={isSaving}
                        disabled={!reportChanged}
                    >
                        Enregistrer les modifications
                    </Button>
                </Card.Actions>
            </Card.Footer>
        </Card>
    );
};

ReportSectionDetails.propTypes = {
    inspectionId: propTypes.number.isRequired,
    reportId: propTypes.number.isRequired
};

ReportSectionDetails.defaultProps = {};

export default ReportSectionDetails;