import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { useObservators, useWriters } from 'data/user';
import { reportDetailsPropTypes } from 'data/inspection';
import styles from '../ReportSection.module.scss';

type Props = {
    data: App.Report.ReportDetails;
    onChange?: (value: App.Report.ReportDetails) => void;
};

const ReportEndSection: React.FC<Props> = ({
    data,
    onChange
}) => {
    const handleChange = React.useCallback((key: keyof typeof data) => (value: any) => {
        onChange?.({ ...data, [key]: value });
    }, [onChange, data]);

    return (
        <div className={styles['endsectionContainer']}>
            <div className={styles['observationLabel']}>Fin de rapport</div>
            <Form.Group controlId="report-endsection-endmessage">
                <Form.Label>Message prochaine visite</Form.Label>
                <Form.Input
                    value={data?.modelNextInspection ?? undefined}
                    multiline
                    rows={5}
                    maxlength={2000}
                    onChange={handleChange('modelNextInspection')}
                />
            </Form.Group>
        </div>
    );
};

ReportEndSection.propTypes = {
    data: reportDetailsPropTypes.isRequired,
    onChange: propTypes.func
};

ReportEndSection.defaultProps = {
    onChange: undefined
};

export default ReportEndSection;
