import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isValidDate, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { plotDetailsPropTypes } from 'data/plot';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps } from './types';
import { AfflictedObservationField, ObservationErrors } from './components';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation16';

type Props = ObservationProps<App.Observation16>

const Observation16: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.plot === undefined &&
            data.area === undefined &&
            data.isolatedRoot === undefined) {
            return;
        }

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const plotValue = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.plot ?? undefined;
    }, [data]);

    const area = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.area ?? undefined;
    }, [data]);

    const isolatedRoot = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.isolatedRoot ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>Parcelle: {internalData.current.previous.plot?.value ?? '-'}</div>
                    <div className={styles['previous']}>Zones: {internalData.current.previous.area?.value ?? '-'}</div>
                    <div className={styles['previous']}>Ceps isolés: {internalData.current.previous.isolatedRoot?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation16 = {
            ...internalData.current,
            plot: null,
            area: null,
            isolatedRoot: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleOptionChange = React.useCallback((label: string, newValue: number | null) => {
        const newData: App.Observation16 = {
            ...internalData.current,
            [label]: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handlePlotChange = React.useCallback((newValue: number | null) => {
        handleOptionChange('plot', newValue);
    }, [handleOptionChange]);

    const handleAreaChange = React.useCallback((newValue: number | null) => {
        handleOptionChange('area', newValue);
    }, [handleOptionChange]);

    const handleIsolatedRootChange = React.useCallback((newValue: number | null) => {
        handleOptionChange('isolatedRoot', newValue);
    }, [handleOptionChange]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="Court Noué"
        >
            <div className="row">
                <AfflictedObservationField
                    id={`${observationKey}-plot`}
                    label="Parcelle"
                    value={plotValue}
                    onChange={handlePlotChange}
                    className={concatClassName(styles[`${observationKey}-plot`], 'col-4')}
                    state={validationErrors['plot']?.type}
                    readOnly={data.ignored}
                />
                <AfflictedObservationField
                    id={`${observationKey}-area`}
                    label="Zones"
                    value={area}
                    onChange={handleAreaChange}
                    className={concatClassName(styles[`${observationKey}-area`], 'col-4')}
                    state={validationErrors['area']?.type}
                    readOnly={data.ignored}
                />
                <AfflictedObservationField
                    id={`${observationKey}-isolatedRoot`}
                    label="Ceps isolés"
                    value={isolatedRoot}
                    onChange={handleIsolatedRootChange}
                    className={concatClassName(styles[`${observationKey}-isolatedRoot`], 'col-4')}
                    state={validationErrors['isolatedRoot']?.type}
                    readOnly={data.ignored}
                />
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation16.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotDetailsPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation16.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation16;
