import React from 'react';
import propTypes from 'prop-types';
import { isFunction, noop } from '@dateam/ark';
import { concatClassName, useDebounce } from '@dateam/ark-react';
import { Form } from 'shared-ui';

export type VarietalTypeFilters = {
    code: string;
    label: string;
};

type Props = {
    className?: string;
    onChange?: (filters: VarietalTypeFilters) => void;
};

const FilterBar: React.FC<Props> = ({
    className,
    onChange
}) => {
    const notifyChange = React.useRef(onChange ?? noop);
    const saveChanges = useDebounce((filter: VarietalTypeFilters) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<VarietalTypeFilters>({ label: '', code: '' });

    const handleVarietalTypeLabelChange = React.useCallback((newValue: string) => {
        const newFilter: VarietalTypeFilters = {
            ...filter,
            label: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleVarietalTypeCodeChange = React.useCallback((newValue: string) => {
        const newFilter: VarietalTypeFilters = {
            ...filter,
            code: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    return (
        <div className={concatClassName('row', className)}>
            <Form.Group controlId="filter-varietal-code" className="col">
                <Form.Label>Code</Form.Label>
                <Form.Input
                    value={filter.code}
                    autocomplete="off"
                    onChange={handleVarietalTypeCodeChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-varietal-type" className="col">
                <Form.Label>Nom</Form.Label>
                <Form.Input
                    value={filter.label}
                    autocomplete="off"
                    onChange={handleVarietalTypeLabelChange}
                />
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    className: propTypes.string,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default FilterBar;
