import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isDefined } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import {
    Card,
    Button,
    Form
} from 'shared-ui';
import logger from 'utils/logger';
import { useUserState } from 'utils/userStore';
import config from 'config';
import { UnlockIcon } from 'components/Icons';
import { userRequests } from 'data/requests';
import { useLogIn } from 'data/user';
import { DefaultNavigation } from 'screens/routes';
import styles from './LoginScreen.module.scss';

const LoginScreen: React.FC = () => {
    const { t } = useTranslation();
    const [user] = useUserState();
    const {
        mutateAsync: logIn, 
        error, 
        isLoading: isLogingIn
    } = useLogIn();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorState, setErrorState] = React.useState<string | null>(null);
    const { push: navigate } = useNavigation();

    React.useEffect(() => {
        if (!isDefined(user) || user.roleId === 'observator') return;

        navigate(DefaultNavigation());
    }, [user, navigate]);

    React.useEffect(() => {
        if (error == null) return;

        if (error.code === userRequests.LOGIN_UNAUTHORIZED) {
            return setErrorState('Identifiants non valides');
        }

        setErrorState('Une erreur est survenue.');
    }, [error]);

    const onUsernameChange = React.useCallback((value: string): void => {
        setErrorState(prevState => {
            if (prevState != null) return null;
            return prevState;
        });

        setUsername(value);
    }, []);

    const onPasswordChange = React.useCallback((value: string): void => {
        setErrorState(prevState => {
            if (prevState != null) return null;
            return prevState;
        });

        setPassword(value);
    }, []);

    const formIsValid = React.useMemo(() => username.trim().length > 0 &&
        password.trim().length > 0, [username, password]);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setErrorState(prevState => {
            if (prevState != null) return null;
            return prevState;
        });

        try {
            const user = await logIn({ username, password });

            if (user?.roleId === 'observator') {
                setErrorState('Vous n\'êtes pas autorisé.');
                return;
            }
            
            navigate(DefaultNavigation());
        }
        catch (err) {
            logger.debug('login failed', err);
            return;
        }

        logger.debug('login complete');
    }, [username, password, logIn, navigate]);

    return (
        <>
            <Helmet>
                <title>{t('login.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <div className={styles['wrapper']}>
                <div className={styles['content']}>
                    <Card>
                        <Card.Body>
                            <>
                                <UnlockIcon className={styles['loginIcon']} />
                                <h1 className={styles['title']}>{t('login.title')}</h1>
                                <form onSubmit={onSubmit}>
                                    <Form.Group controlId="username">
                                        <Form.Input
                                            autocomplete="off"
                                            spellcheck={false}
                                            autocapitalize="none"
                                            placeholder={t('user.username')}
                                            value={username}
                                            className={styles['username']}
                                            onChange={onUsernameChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="password">
                                        <Form.Input
                                            type="password"
                                            autocomplete="off"
                                            spellcheck={false}
                                            autocapitalize="none"
                                            placeholder={t('user.password')}
                                            value={password}
                                            className={styles['password']}
                                            onChange={onPasswordChange}
                                        />
                                    </Form.Group>
                                    {errorState != null && (
                                        <div className={styles['errorMessage']}>{errorState}</div>
                                    )}
                                    <Button
                                        type="submit"
                                        color="primary"
                                        value={t('login.action')}
                                        className={styles['submitBtn']}
                                        pending={isLogingIn}
                                        disabled={!formIsValid}
                                    >
                                    </Button>
                                </form>
                            </>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default withRouter(LoginScreen, 'LOGIN');