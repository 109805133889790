import React from 'react';
import { useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import {
    AREA_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys
} from './constants';
import { areaRequests } from './requests';

export const useAreas = (options?: DataOptions): QueryResult<App.Area[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Area[], ServiceError>({
        ...config,
        queryKey: AREA_KEY,
        queryFn: areaRequests.get
    });

    return usePick(query as any, queryResultKeys);
};