import React from 'react';
import { useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { useYearState } from 'utils/yearStore';
import {
    SUMMARY_KEY,
    SUMMARY_REPORT_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys
} from './constants';
import { activityRequests } from './requests';

export const useActivitySummary = (options?: DataOptions): QueryResult<App.ActivitySummary[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const [year] = useYearState();
    const query = useQuery<App.ActivitySummary[], ServiceError>({
        ...config,
        queryKey: [year, SUMMARY_KEY],
        queryFn: activityRequests.getSummary
    });

    return usePick(query as any, queryResultKeys);
};

export const useActivityInspectionRemainingReports =
    (options?: DataOptions): QueryResult<App.InspectionRemainingReport[]> => {
        const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
        const [year] = useYearState();
        const query = useQuery<App.InspectionRemainingReport[], ServiceError>({
            ...config,
            queryKey: [year, SUMMARY_REPORT_KEY],
            queryFn: () => activityRequests.getInspectionRemainingReports(year),
            staleTime: 0
        });

        return usePick(query as any, queryResultKeys);
    };