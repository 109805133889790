import React from 'react';
import propTypes from 'prop-types';
import { Table } from 'shared-ui';
import { reportPlotPropTypes } from 'data/inspection';
import styles from '../ReportSection.module.scss';

type Props = {
    headers: string[];
    plots: App.Report.ReportPlot[];
    display: 'values' | 'previous';
};

const ReportTable: React.FC<Props> = ({
    headers,
    plots,
    display
}) => (
    <div>
        <Table<App.Report.ReportPlot> data={plots} className={styles['reportTable']}>
            <Table.Column className={styles['plotCol']}>
                <Table.Column.Header>Parcelles</Table.Column.Header>
                <Table.Column.Cell>
                    {(props: any) => (<div>{props.data.label}</div>)}
                </Table.Column.Cell>
            </Table.Column>
            {headers.map((item, idx) => (
                <Table.Column key={`${item}-${display}`}>
                    <Table.Column.Header>{item}</Table.Column.Header>
                    <Table.Column.Cell>
                        {(props: any) => (<div>{props.data[display][idx]}</div>)}
                    </Table.Column.Cell>
                </Table.Column>
            ))}
        </Table>
    </div>
);

ReportTable.propTypes = {
    headers: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
    plots: propTypes.arrayOf(reportPlotPropTypes.isRequired).isRequired,
    display: propTypes.oneOf(['values', 'previous'] as const).isRequired
};

ReportTable.defaultProps = {

};

export default ReportTable;
