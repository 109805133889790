import React from 'react';
import propTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { NavigationRoute, TokenStore, useNavigation, useRouterRegistration } from 'shared-utils';
import { useUserState } from 'utils/userStore';
import { useLogOut } from 'data/user';
import unauthorizedProvider from './unauthorizedProvider';
import UnauthorizedDialog from './UnauthorizedDialog';
import styles from './UnauthorizedHandler.module.scss';

type Props = {
    route: NavigationRoute;
    tokenStore: TokenStore;
};

const UnauthorizedProvider: React.FC<Props> = ({
    route,
    tokenStore
}: Props) => {
    const { push: navigate, location } = useNavigation();
    const { mutateAsync: logOut } = useLogOut();
    const [router] = useRouterRegistration();
    const [, setUser] = useUserState();
    const [isUnauthorized, setIsUnauthorized] = React.useState(false);

    React.useEffect(() => {
        const unsubscribe = unauthorizedProvider.subscribe(async (silent: boolean) => {
            unauthorizedProvider.forget();

            if (route instanceof NavigationRoute && router != null) {
                if (matchPath(location.pathname, route.toPath(router) ?? '')) {
                    return;
                }
            }
            await logOut();

            if (silent === false) {
                setIsUnauthorized(true);
            }
            else {
                navigate(route);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [route, router, location, tokenStore, setUser, setIsUnauthorized, navigate, logOut]);

    const close = React.useCallback(() => {
        setIsUnauthorized(false);
        navigate(route);
    }, [route, setIsUnauthorized, navigate]);

    if (!isUnauthorized) return null;

    return (
        <UnauthorizedDialog
            title="Vous allez être redirigé sur la page de connexion"
            message={'Une requête n\'a pas réussi à vous identifier.\nPar précaution, nous vous demandons de bien vouloir vous reconnecter.'}
            onClose={close}
            className={styles['unauthorizedDialog']}
        />
    );
};

UnauthorizedProvider.propTypes = {
    route: propTypes.any.isRequired,
    tokenStore: propTypes.any.isRequired
};

UnauthorizedProvider.defaultProps = {};

export default UnauthorizedProvider;