import { Router } from 'shared-utils';
import { MainLayout } from 'layout';
import { authGuard, roleGuard } from './guards';
import { LoginNavigation, loginRouter } from './login';
import { inspectionRouter } from './inspection';
import { inspectionEditRouter } from './inspectionEdit';
import { dashboardRouter, DashboardNavigation } from './dashboard';
import { campaignRouter } from './campaign';
import { campaignDetailsRouter } from './campaignDetails';
import { trackRouter } from './track';
import { trackDetailsRouter } from './trackDetails';
import { userRouter } from './user';
import { userDetailsRouter } from './userDetails';
import { customerRouter } from './customer';
import { customerDetailsRouter } from './customerDetails';
import { plotRouter } from './plot';
import { plotDetailsRouter } from './plotDetails';
import { exportRouter } from './export';
import { observationRouter } from './observation';
import { observationTypeRouter } from './observationType';
import { observationTypeDetailsRouter } from './observationTypeDetails';
import { varietalTypeRouter } from './varietalType';
import { reportRouter } from './report';
import { adminRouter } from './administration';
import { bioTypeRouter } from './bioType';
import { bioTypeDetailsRouter } from './bioTypeDetails';
import { varietalTypeDetailsRouter } from './varietalTypeDetails';

export * from './login';
export * from './dashboard';
export * from './inspection';
export * from './inspectionEdit';
export * from './campaign';
export * from './campaignDetails';
export * from './user';
export * from './userDetails';
export * from './customer';
export * from './customerDetails';
export * from './plot';
export * from './plotDetails';
export * from './observationType';
export * from './track';
export * from './trackDetails';
export * from './export';
export * from './observationTypeDetails';
export * from './observation';
export * from './report';
export * from './varietalType';
export * from './bioType';
export * from './bioTypeDetails';
export * from './varietalTypeDetails';
export * from './administration';
export const DefaultNavigation = DashboardNavigation;
export const LogOutNavigation = LoginNavigation;

const yearRouter = new Router();
yearRouter
    .use('/inspection', inspectionRouter)
    .use('/inspection', inspectionEditRouter)
    .use('/campaign', campaignDetailsRouter)
    .use('/campaign', campaignRouter)
    .use('/track', trackRouter)
    .use('/track', trackDetailsRouter)
    .use('/customer', customerRouter)
    .use('/customer', customerDetailsRouter)
    .use('/plot', plotRouter)
    .use('/plot', plotDetailsRouter)
    .use('/export', exportRouter)
    .use('/observation', observationRouter)
    .use('/observationType', observationTypeRouter)
    .use('/observationType', observationTypeDetailsRouter)
    .use('/report', reportRouter)
    .use('/admin', adminRouter)
    .use('/varietalType', varietalTypeRouter)
    .use('/varietalType', varietalTypeDetailsRouter)
    .use('/bioType', bioTypeRouter)
    .use('/bioType', bioTypeDetailsRouter);

const authRouter = new Router();
authRouter
    .setLayout(MainLayout)
    .setGuards([
        authGuard(LoginNavigation()),
        roleGuard(DefaultNavigation())
    ])
    .use('/dashboard', dashboardRouter)
    .use('/user', userRouter)
    .use('/user', userDetailsRouter)
    .use('/<:year?>', yearRouter); // Should have matched the pattern <:year(\\d+)?> if react-router V6 supported it

const router = new Router();

// Apply screen routers
router
    .use('/login', loginRouter)
    .use('', authRouter)
    .otherwise('/dashboard');

export default router;
