import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isValidDate, ServiceError } from '@dateam/ark';
import { useNavigation } from 'shared-utils';
import { Button, Card, ConfirmDialog, Form } from 'shared-ui';
import {
    customerDetailsPropTypes,
    useDeactivateCustomer,
    useDeleteCustomer,
    useReactivateCustomer,
    useUpdateCustomer
} from 'data/customer';
import { errorProvider } from 'components/errorHandler';
import { FooterDateInfo } from 'components/FooterDateInfo';
import { CrossIcon, PlusIcon, TrashIcon } from 'components/Icons';
import { CustomerNavigation } from 'screens/routes';
import { useTechnicalUsers } from 'data/user';

type Props = {
    customer: App.CustomerDetails;
    displayActions?: boolean;
    className?: string;
};

const ConfigurationPanel: React.FC<Props> = ({
    customer,
    displayActions,
    className
}) => {
    const { push: navigate } = useNavigation();
    const customerState = React.useRef(customer);
    const { mutateAsync: updateCustomer, isLoading: isUpdating } = useUpdateCustomer();
    const { mutateAsync: deleteCustomer, isLoading: isDeleting } = useDeleteCustomer();
    const { mutateAsync: deactivateCustomer, isLoading: isDeactivating } = useDeactivateCustomer();
    const { mutateAsync: reactivateCustomer, isLoading: isReactivating } = useReactivateCustomer();
    const [showDeletionConfirm, setShowDeletionConfirm] = React.useState(false);
    const { data: technicalUsers } = useTechnicalUsers();

    React.useEffect(() => {
        customerState.current = customer;
    }, [customer]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: any) => {
        if (!isDefined(customerState.current)) throw new Error('Le vigneron n\'a pas été trouvé.');

        try {
            const {
                id,
                year,
                publicId,
                fullName,
                header,
                contactInfo,
                comment,
                technicalUser
            } = customerState.current;

            await updateCustomer({
                id,
                year,
                publicId,
                fullName,
                header,
                contactInfo,
                comment,
                technicalUser: technicalUser?.id ?? null,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }
    }, [customerState, updateCustomer]);

    const handleCustomerDeletion = React.useCallback(async (shouldDelete: boolean) => {
        if (shouldDelete === false) return setShowDeletionConfirm(false);
        if (!isDefined(customerState.current)) throw new Error('Le vigneron n\'a pas été trouvé.');

        await deleteCustomer(customerState.current.id);

        setShowDeletionConfirm(false);
        navigate(CustomerNavigation());
    }, [customerState, deleteCustomer, navigate, setShowDeletionConfirm]);

    const handleUserActivationState = React.useCallback((activate: boolean) => async () => {
        if (!isDefined(customerState.current)) throw new Error('Le vigneron n\'a pas été trouvé.');

        if (activate === true) {
            await reactivateCustomer(customerState.current.id);
        }
        else {
            await deactivateCustomer(customerState.current.id);
        }
    }, [customerState, deactivateCustomer, reactivateCustomer]);

    const lockActions = React.useMemo(
        () => isUpdating || isDeactivating || isReactivating || isDeleting,
        [isUpdating, isDeactivating, isReactivating, isDeleting]
    );

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>
                    Informations du vigneron
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <Form.Group controlId="customer-code" className="col col-4">
                        <Form.Label>Code</Form.Label>
                        <Form.Editable.Input
                            value={customer.publicId}
                            onSave={handleSaveInput('publicId')}
                        />
                    </Form.Group>
                    <Form.Group controlId="customer-fullName" className="col col-8">
                        <Form.Label>Nom</Form.Label>
                        <Form.Editable.Input
                            value={customer.fullName}
                            onSave={handleSaveInput('fullName')}
                        />
                    </Form.Group>
                </div>
                <Form.Group controlId="customer-header">
                    <Form.Label>Entête</Form.Label>
                    <Form.Editable.Input
                        multiline
                        rows={4}
                        value={customer.header ?? ''}
                        onSave={handleSaveInput('header')}
                    />
                </Form.Group>
                <Form.Group controlId="customer-contactInfo">
                    <Form.Label>Informations de contact</Form.Label>
                    <Form.Editable.Input
                        multiline
                        rows={4}
                        value={customer.contactInfo ?? ''}
                        onSave={handleSaveInput('contactInfo')}
                    />
                </Form.Group>
                <Form.Group controlId="customer-technicalUser">
                    <Form.Label>Référent technique</Form.Label>
                    <Form.Editable.Select
                        selected={customer.technicalUser?.id}
                        onSave={handleSaveInput('technicalUser')}
                    >
                        <Form.Select.Option value={null} />
                        {technicalUsers?.map(user => (
                            <Form.Select.Option
                                key={`technicalUser-${user.id}`}
                                value={user.id}
                                selectedText={`${user.firstName} ${user.lastName}`}
                            >
                                {user.firstName} {user.lastName}
                            </Form.Select.Option>
                        ))}
                    </Form.Editable.Select>
                </Form.Group>
                <Form.Group controlId="customer-comment">
                    <Form.Label>Commentaire</Form.Label>
                    <Form.Editable.Input
                        multiline
                        rows={4}
                        value={customer.comment ?? ''}
                        onSave={handleSaveInput('comment')}
                    />
                </Form.Group>
            </Card.Body>
            <Card.Footer className="row">
                <Card.Infos>
                    <FooterDateInfo creationDate={customer.creationDate} updateDate={customer.updateDate} />
                </Card.Infos>
                {displayActions && (
                    <Card.Actions>
                        {isValidDate(customer.deactivationDate) && (
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={(<PlusIcon />)}
                                pending={isReactivating}
                                disabled={lockActions}
                                onClick={handleUserActivationState(true)}
                            >
                                Ré-activer le vigneron
                            </Button>
                        )}
                        {!isValidDate(customer.deactivationDate) && (
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={(<CrossIcon />)}
                                pending={isDeactivating}
                                disabled={lockActions}
                                onClick={handleUserActivationState(false)}
                            >
                                Désactiver le vigneron
                            </Button>
                        )}
                        <Button
                            color="danger"
                            variant="outlined"
                            startIcon={(<TrashIcon />)}
                            disabled={lockActions}
                            pending={isDeleting}
                            onClick={() => setShowDeletionConfirm(true)}
                        >
                            Supprimer le vigneron
                        </Button>
                        {showDeletionConfirm && (
                            <ConfirmDialog
                                title={`Supprimer le vigneron "${customer.fullName}"`}
                                message="Vous êtes sur le point de supprimer ce vigneron. Êtes-vous sûr de vouloir continuer ?"
                                ok="Supprimer"
                                onClose={handleCustomerDeletion}
                            />
                        )}
                    </Card.Actions>
                )}
            </Card.Footer>
        </Card>
    );
};

ConfigurationPanel.propTypes = {
    customer: customerDetailsPropTypes.isRequired,
    displayActions: propTypes.bool,
    className: propTypes.string
};

ConfigurationPanel.defaultProps = {
    className: undefined,
    displayActions: undefined
};

export default ConfigurationPanel;
