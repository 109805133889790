import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { queryClient } from 'utils/queryClient';
import {
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    mutateResultKeys,
    defaultActionOptions,
    YEAR_KEY
} from './constants';
import { yearRequests } from './requests';

export const useYears = (options?: DataOptions): QueryResult<number[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<number[], ServiceError>({
        ...config,
        queryKey: [YEAR_KEY],
        queryFn: () => yearRequests.get()
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateYear = (): MutationResult<number, void> => {
    const result = useMutation<number, ServiceError, void>(
        () => yearRequests.create(),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([YEAR_KEY]);
            }
        }
    );

    return result;
};
