import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const RefreshIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <polyline points="1 4 1 10 7 10"></polyline>
        <polyline points="23 20 23 14 17 14"></polyline>
        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
    </svg>
);

RefreshIcon.propTypes = {
    className: propTypes.string
};

RefreshIcon.defaultProps = {
    className: undefined
};

export default RefreshIcon;
