import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isArray, orderBy } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { useBindYearNavigation } from 'utils/yearStore';
import config from 'config';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent
} from 'shared-ui';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import { ObservationTypesTableItem } from 'components/observationTypesTableItem';
import { usePlotSearch } from 'data/effects';
import { PlotNavigation, PlotDetailsNavigation } from 'screens/routes';
import {
    ColumnArea,
    ColumnBio,
    ColumnConfusion,
    ColumnCustomer,
    ColumnName,
    ColumnVarietal,
    FilterBar,
    HeaderAction,
    PlotFilters
} from './components';
import UserBreadcrumb from './PlotBreadcrumb';
import styles from './PlotScreen.module.scss';

type DataTablePlot = { data: App.Plot; };

const PlotScreen: React.FC = () => {
    useBindYearNavigation(PlotNavigation, []);
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const [filter, setFilter] = React.useState<PlotFilters>({
        plot: '',
        track: '',
        varietals: [],
        areas: [],
        customer: '',
        bio: []
    });

    const { data: plots, isLoading, isFetched, error } = usePlotSearch({
        plot: filter.plot,
        track: filter.track,
        varietalIds: filter.varietals,
        areaIds: filter.areas,
        customer: filter.customer,
        bio: filter.bio
    });

    const enableFilters = isFetched === true && error == null;

    const tableData = React.useMemo(() => {
        if (!isArray(plots)) return [];

        const data = [...plots];

        orderBy(data, 'id');

        return data;
    }, [plots]);

    const handleRowClick = React.useCallback((event: TableRowClickEvent<App.Plot>) => {
        navigate(PlotDetailsNavigation(event.data.id));
    }, [navigate]);

    const handleFilterChange = React.useCallback((filter: PlotFilters) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('plot.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Parcelles
                    </Page.Title>
                    <Page.HeaderActions>
                        <UserBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Liste des parcelles
                            </Card.Title>
                            <HeaderAction />
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar
                                        onChange={handleFilterChange}
                                        enabled={enableFilters}
                                    />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<App.Plot>
                                        data={tableData}
                                        onRowClick={handleRowClick}
                                        className={styles['tableRow']}
                                    >
                                        <Table.Column className={styles['colName']}>
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: DataTablePlot) => (<ColumnName data={data} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colCustomer']}>
                                            <Table.Column.Header>Vigneron</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: DataTablePlot) => (<ColumnCustomer data={data.customer} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colArea']}>
                                            <Table.Column.Header>Localisation</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: DataTablePlot) => (<ColumnArea data={data} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colVarietal']}>
                                            <Table.Column.Header>Cépage</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: DataTablePlot) => (
                                                    <ColumnVarietal data={data.varietal} />
                                                )}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colBio']}>
                                            <Table.Column.Header>Type de protection</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: DataTablePlot) => (<ColumnBio data={data.bio} />)}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colConfusion']}>
                                            <Table.Column.Header>Confusion</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data }: DataTablePlot) => (
                                                    <ColumnConfusion data={data.confusion} />
                                                )}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                        <Table.Column className={styles['colTypes']}>
                                            <Table.Column.Header>Suivi</Table.Column.Header>
                                            <Table.Column.Cell>
                                                {({ data: { observationTypes } }: DataTablePlot) => (
                                                    <ObservationTypesTableItem types={observationTypes} />
                                                )}
                                            </Table.Column.Cell>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(PlotScreen, 'PLOT');