import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const get = async (): Promise<number[]> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/year', config.apiUrl);

        response = await httpService.get<number[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling year API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send year request (get)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('YEAR_GET_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('YEAR_GET_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'YEAR_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('YEAR_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const create = async (): Promise<number> => {
    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/year/create', config.apiUrl);

        response = await httpService.post<number>(requestUrl, {});
    }
    catch (err) {
        const errMessage = err instanceof Error && err?.message || 'no further detail';
        throw new Error(`an error occurred while calling year API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send year request (${create.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('YEAR_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('YEAR_CREATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('YEAR_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'YEAR_CREATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isValidNumber(response.body), 'invalid year from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('YEAR_CREATE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};