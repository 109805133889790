import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useCreateUser, useRoles } from 'data/user';
import styles from './CreateUserDialog.module.scss';

type UserState = {
    username: string | null;
    password: string | null;
    roleId: string | null;
    firstName: string | null;
    lastName: string | null;
};

type Props = {
    className?: string;
    onClose?: () => void;
    onCompletion?: (userId: number) => void;
};

const CreateUserDialog: React.FC<Props> = ({
    className,
    onClose,
    onCompletion
}: Props) => {
    const roles = useRoles();
    const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser();
    const [userState, setUserState] = React.useState<UserState>({
        username: null,
        password: null,
        roleId: null,
        firstName: null,
        lastName: null
    });
    const formIsValid = React.useMemo(() => {
        if (userState == null) return false;

        const {
            username,
            password,
            roleId,
            firstName,
            lastName
        } = userState;

        if (!isString(username) || username.trim().length === 0) return false;
        if (!isString(password) || password.trim().length === 0) return false;
        if (!isString(firstName) || firstName.trim().length === 0) return false;
        if (!isString(lastName) || lastName.trim().length === 0) return false;
        if (roles.find(role => role.id === roleId) == null) return false;

        return true;
    }, [userState, roles]);

    const changeInput = React.useCallback((field: string) => (value: string) => {
        setUserState(prevState => ({
            ...prevState,
            [field]: value
        }));
    }, [setUserState]);

    const handleCreateClick = React.useCallback(async () => {
        if (!formIsValid) return;

        const {
            username,
            password,
            roleId,
            firstName,
            lastName
        } = userState;

        if (!isDefined(username)) return;
        if (!isDefined(password)) return;
        if (!isDefined(roleId)) return;
        if (!isDefined(firstName)) return;
        if (!isDefined(lastName)) return;

        const newUserId = await createUser({
            username,
            password,
            roleId,
            firstName,
            lastName
        });

        onCompletion?.(newUserId);
        onClose?.();
    }, [userState, formIsValid, createUser, onCompletion, onClose]);

    return (
        <Modal
            className={`${styles['modal']} modal-flex`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Créer un nouvel utilisateur
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={concatClassName('row', styles['formRow'])}>
                    <Form.Group controlId="user-username" className="col col-12">
                        <Form.Label>Nom d'utilisateur</Form.Label>
                        <Form.Input
                            value={userState.username ?? ''}
                            maxlength={100}
                            autocomplete="off"
                            autocapitalize="none"
                            spellcheck={false}
                            onChange={changeInput('username')}
                        />
                    </Form.Group>
                </div>
                <div className={concatClassName('row', styles['formRow'])}>
                    <Form.Group controlId="user-password" className="col col-6">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Input
                            value={userState.password ?? ''}
                            maxlength={50}
                            autocomplete="off"
                            autocapitalize="none"
                            spellcheck={false}
                            onChange={changeInput('password')}
                        />
                    </Form.Group>
                    <Form.Group controlId="user-role" className="col col-6">
                        <Form.Label>Rôle</Form.Label>
                        <Form.Select
                            selected={userState.roleId}
                            onChange={changeInput('roleId')}
                        >
                            {roles.map(role => (
                                <Form.Select.Option key={role.id} value={role.id}>
                                    {role.label}
                                </Form.Select.Option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className={concatClassName('row', styles['formRow'])}>
                    <Form.Group controlId="user-lastName" className="col col-6">
                        <Form.Label>Nom</Form.Label>
                        <Form.Input
                            value={userState.lastName ?? ''}
                            maxlength={40}
                            autocomplete="off"
                            autocapitalize="none"
                            onChange={changeInput('lastName')}
                        />
                    </Form.Group>
                    <Form.Group controlId="user-firstName" className="col col-6">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Input
                            value={userState.firstName ?? ''}
                            maxlength={40}
                            autocomplete="off"
                            autocapitalize="none"
                            onChange={changeInput('firstName')}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                    disabled={isCreating}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer l'utilisateur
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreateUserDialog.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

CreateUserDialog.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default CreateUserDialog;
