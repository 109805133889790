import { assertIsArray, dateToFormat, formatDate, hasProperty, isValidDate, isValidNumber, quickClone } from '@dateam/ark';
import { refreshSyncProps } from 'data/observation';

const _inspectionStatus = [
    'notplanned',
    'planned',
    'downloaded',
    'inprogress',
    'validated'
] as const;

type SyncCount<T> = T & {
    readyForSync: number;
};

type Changes = {
    countChanged: number;
    isValid: boolean;
};

export type InspectionStatus = ValuesOf<typeof _inspectionStatus>;

export const inspectionStatus: readonly ValuesOf<typeof _inspectionStatus>[] = _inspectionStatus;

export const computeInspectionRecords =
    (inspectionRecords: App.InspectionWithRecord | App.InspectionWithRecordSync): App.InspectionWithRecordSync => {
        const inspectionSync = quickClone(inspectionRecords) as App.InspectionWithRecordSync;

        assertIsArray(inspectionSync.plots, 'inspectionRecords doesn\'t match expected format (`inspections.plots`).');
        if (!hasProperty(inspectionSync, 'hasChanged')) inspectionSync.hasChanged = false;

        const changes = inspectionSync.plots.reduce((plotAcc: SyncCount<Changes>, plot) => {
            assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');
            if (!hasProperty(plot, 'hasChanged')) plot.hasChanged = false;

            const changes = plot.observations.reduce((obsAcc: Changes, observation) => {
                refreshSyncProps(observation, plot);

                obsAcc.countChanged += +(observation.hasChanged);
                obsAcc.isValid = obsAcc.isValid && observation.isValid;

                return obsAcc;
            }, { countChanged: 0, isValid: true });

            plot.isValid = changes.isValid;
            plot.hasChanged = plot.hasChanged || changes.countChanged > 0;
            plot.readyForSync = plot.isValid && plot.hasChanged;

            plotAcc.countChanged += +(plot.hasChanged);
            plotAcc.isValid = plotAcc.isValid && changes.isValid;
            plotAcc.readyForSync += +(plot.readyForSync);

            return plotAcc;
        }, { countChanged: 0, isValid: true, readyForSync: 0 });

        changes.countChanged += +(inspectionSync.hasChanged);
        changes.readyForSync += +(inspectionSync.hasChanged);
        inspectionSync.isValid = changes.isValid;
        inspectionSync.hasChanged = changes.countChanged > 0;

        const { readyForSync, countChanged } = changes;

        inspectionSync.syncState = countChanged === 0 ? 'none' : (readyForSync === countChanged ? 'full' : 'partial');

        return inspectionSync;
    };

export const resetInspectionRecords =
    (inspectionRecords: App.InspectionWithRecord | App.InspectionWithRecordSync): App.InspectionWithRecordSync => {
        const inspectionSync = quickClone(inspectionRecords) as App.InspectionWithRecordSync;

        assertIsArray(inspectionSync.plots, 'inspectionRecords doesn\'t match expected format (`inspections.plots`).');
        inspectionSync.hasChanged = false;

        inspectionSync.plots.forEach(plot => {
            assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');

            plot.observations.forEach(observation => {
                observation.isSyncing = false;
                observation.hasChanged = false;
                observation.isValid = true;
            });

            plot.hasChanged = false;
            plot.isValid = true;
            plot.readyForSync = false;
        });

        inspectionSync.isValid = true;
        inspectionSync.hasChanged = false;

        inspectionSync.syncState = 'none';

        return inspectionSync;
    };

export const reconcileSync = (
    freshInspection: App.InspectionWithRecord,
    inspectionSync: App.InspectionWithRecordSync | null
): App.InspectionWithRecordSync => {
    if (inspectionSync == null || ['none'].indexOf(inspectionSync.syncState) >= 0) {
        return computeInspectionRecords(freshInspection);
    }

    assertIsArray(inspectionSync.plots, 'inspection record doesn\'t match expected format (`inspection.plots`).');

    const plots = inspectionSync.plots.reduce((plotAcc: App.InspectionPlotWithRecord[], plot) => {
        const plotMatch = freshInspection.plots.find(freshPlot => freshPlot.id === plot.id);

        if (plotMatch != null) {
            if (plot.hasChanged === false) {
                plotAcc.push(plotMatch);
            }
            else {
                assertIsArray(plot.observations, 'inspection record doesn\'t match expected format (`inspection.plots[].observations`).');
                const syncObservationIds = plotMatch.observations.map(({ id }) => id);

                const observations = plot.observations.reduce((obsAcc: App.ObservationRecord[], observation) => {
                    if (syncObservationIds.includes(observation.id) || observation.manuallyAdded === true) {
                        obsAcc.push(observation);
                    }

                    return obsAcc;
                }, []);

                plotMatch.observations.reduce((obsAcc: App.ObservationRecord[], observation) => {
                    if (!obsAcc.find(item => item.id === observation.id)) obsAcc.push(observation);

                    return obsAcc;
                }, observations);

                plotAcc.push({
                    ...plotMatch,
                    validationDate: plot.validationDate,
                    observations
                });
            }
        }

        return plotAcc;
    }, []);

    plots.reduce((plotAcc: App.InspectionPlotWithRecord[], plot) => {
        if (!plotAcc.find(item => item.id === plot.id)) plotAcc.push(plot);

        return plotAcc;
    }, plots);

    const reconciledInspection = {
        ...freshInspection,
        plots
    };

    return computeInspectionRecords(reconciledInspection);
};

export const getInspectionStatus = (inspection: App.Inspection |
{
    validationDate: Date | null,
    validatedPlotCount: number,
    firstSyncDate: Date | null,
    startDate: Date | null
}): InspectionStatus => {
    if (isValidDate(inspection.validationDate)) return 'validated';
    if (inspection.validatedPlotCount > 0) return 'inprogress';
    if (isValidDate(inspection.firstSyncDate)) return 'downloaded';
    if (isValidDate(inspection.startDate)) return 'planned';

    return 'notplanned';
};
