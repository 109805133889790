import React from 'react';
import propTypes from 'prop-types';
import { isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useUpdateUserPassword } from 'data/user';
import styles from '../UserDetailsScreen.module.scss';

type Props = {
    userId: App.UserDetails['id'];
    className?: string;
    onClose?: () => void;
    onCompletion?: () => void;
};

const UpdatePasswordDialog: React.FC<Props> = ({
    userId,
    className,
    onClose,
    onCompletion
}: Props) => {
    const { mutateAsync: updatePassword, isLoading: isUpdatingPassword } = useUpdateUserPassword();
    const [passwordState, setPasswordState] = React.useState<string>('');
    const formIsValid = React.useMemo(
        () => isString(passwordState) && passwordState.trim().length > 0,
        [passwordState]
    );

    const handlePasswordChange = React.useCallback(async () => {
        if (!formIsValid) return;

        await updatePassword({
            id: userId,
            password: passwordState
        });

        onCompletion?.();
        onClose?.();
    }, [userId, passwordState, formIsValid, updatePassword, onCompletion, onClose]);

    return (
        <Modal
            className={concatClassName(styles['modal'], 'modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Modifier le mot de passe
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="user-password" className="col col-12">
                    <Form.Label>Nouveau mot de passe</Form.Label>
                    <Form.Input
                        value={passwordState}
                        autocomplete="off"
                        onChange={setPasswordState}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isUpdatingPassword}
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isUpdatingPassword}
                    onClick={handlePasswordChange}
                >
                    Modifier
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

UpdatePasswordDialog.propTypes = {
    userId: propTypes.number.isRequired,
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

UpdatePasswordDialog.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default UpdatePasswordDialog;
