import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import {
    insertAt,
    isBoolean,
    isDefined,
    isString,
    isValidDate,
    orderBy,
    removeAt,
    removeFromCollection
} from '@dateam/ark';
import { concatClassName, propTypeNullable } from '@dateam/ark-react';
import { Card } from 'shared-ui';
import config from 'config';
import {
    isRefLabelNumber,
    refLabelNumberPropTypes
} from 'utils/propTypes';
import Page from 'components/Page';
import { PlotList } from 'components/PlotList';
import { useUpdateInspectionPlot } from 'data/inspection';
import { PlotSectionHeader } from './components';

type Props = {
    inspection?: Pick<App.InspectionDetails, 'id' | 'plots'>;
    displayActions?: boolean;
};

const PlotSection: React.FC<Props> = ({
    inspection,
    displayActions
}: Props) => {
    const { t } = useTranslation();
    const { mutateAsync: updatePlots } = useUpdateInspectionPlot();
    const inspectionId = React.useRef(inspection?.id);

    const [plots, setPlots] = React.useState<App.InspectionDetails['plots']>([]);

    React.useEffect(() => {
        inspectionId.current = inspection?.id;
    }, [inspection]);

    React.useEffect(() => {
        if (isDefined(inspection)) {
            const plots = [...inspection.plots];
            orderBy(plots, 'position');
            setPlots(plots);
        }
        else setPlots([]);
    }, [inspection, setPlots]);

    const saveChanges = React.useCallback((...args: Parameters<typeof updatePlots>): ReturnType<typeof updatePlots> => {
        return updatePlots(...args);
    }, [updatePlots]);

    const addPlot = React.useCallback((plotIds: number[]) => {
        if (!isDefined(inspectionId.current)) return;

        const id = inspectionId.current;

        const updatedPlots: App.PlotOrder[] = [...plots.map(({ id, position }) => ({ id, position }))];
        const lastPlot = updatedPlots[updatedPlots.length - 1];
        const lastPosition = (lastPlot?.position ?? 0) + 1;

        const currentPlotIds = updatedPlots.map(plot => plot.id);
        const newPlots = plotIds
            .filter(id => currentPlotIds.indexOf(id) < 0)
            .map((id, idx) => ({ id, position: lastPosition + idx }));

        updatedPlots.push(...newPlots);

        saveChanges({ id, plots: updatedPlots });
    }, [inspectionId, plots, saveChanges]);

    const removePlot = React.useCallback((plot: App.InspectionPlot) => {
        if (!isDefined(inspectionId.current)) return;

        const id = inspectionId.current;
        const { position: plotPosition } = plot;

        const updatedPlots = [...plots];
        removeAt(updatedPlots, plotPosition - 1);

        updatedPlots.forEach((plot, idx) => {
            plot.position = idx + 1;
        });

        saveChanges({
            id,
            plots: updatedPlots.map(({ id, position }) => ({
                id,
                position
            }))
        });
    }, [inspectionId, plots, saveChanges]);

    const handlePositionChange = React.useCallback((plot: ArrayType<App.InspectionDetails['plots']>, position: number): void => {
        if (!isDefined(inspectionId.current)) return;

        const id = inspectionId.current;

        setPlots(plots => {
            let data = [...plots];

            removeFromCollection(data, item => item.id === plot.id);
            insertAt(data, position - 1, plot);

            data = data.map((plot, idx) => {
                plot.position = idx + 1;

                return plot;
            });

            orderBy(data, 'position');

            saveChanges({
                id,
                plots: data.map(({ id, position }) => ({
                    id,
                    position
                }))
            });

            return data;
        });
    }, [inspectionId, setPlots, saveChanges]);

    return (
        <>
            <Helmet>
                <title>{t('inspection.details.plot.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page.Content className="row">
                <Card className="col">
                    <Card.Header className={concatClassName(displayActions ? 'withAction' : null)}>
                        <Card.Title>
                            Liste des parcelles
                        </Card.Title>
                        {displayActions && (<PlotSectionHeader onPlotAdded={addPlot} />)}
                    </Card.Header>
                    <Card.Body>
                        <PlotList
                            data={plots}
                            onRemovePlot={removePlot}
                            onPositionChange={handlePositionChange}
                        />
                    </Card.Body>
                </Card>
            </Page.Content>
        </>
    );
};

PlotSection.propTypes = {
    inspection: propTypes.shape({
        id: propTypes.number.isRequired,
        plots: propTypes.arrayOf(propTypes.shape({
            id: propTypes.number.isRequired,
            year: propTypes.number.isRequired,
            activityId: propTypes.number.isRequired,
            publicId: propTypes.string.isRequired,
            label: propTypes.string.isRequired,
            position: propTypes.number.isRequired,
            varietal: propTypeNullable(isRefLabelNumber),
            customer: refLabelNumberPropTypes.isRequired,
            area: propTypeNullable(isRefLabelNumber),
            bio: propTypeNullable(isRefLabelNumber),
            observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
            city: propTypeNullable(isString),
            confusion: propTypeNullable(isBoolean),
            validationDate: propTypeNullable(isValidDate),
            ignored: propTypes.bool.isRequired,
            ignoredReason: propTypeNullable(isString)
        }).isRequired).isRequired
    }),
    displayActions: propTypes.bool
};

PlotSection.defaultProps = {
    inspection: undefined,
    displayActions: undefined
};

export default PlotSection;
