import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from './Sidebar.module.scss';

type Props = {
    // icon?: React.ReactNode;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => unknown;
};

const SidebarMenuItem: React.FC<Props> = ({
    children,
    // icon,
    className,
    onClick
}: Props) => {
    return (
        <li
            className={concatClassName(
                styles['menuItem'],
                className
            )}
        >
            <button type="button" className={styles['menuItemBase']} onClick={onClick}>
                {children}
            </button>
        </li>
    );
};

SidebarMenuItem.propTypes = {
    children: propTypes.node,
    // icon: propTypes.node,
    className: propTypes.string,
    onClick: propTypes.func
};

SidebarMenuItem.defaultProps = {
    children: undefined,
    // icon: undefined,
    className: undefined,
    onClick: undefined
};

export default SidebarMenuItem;
