import React from 'react';
import { useNavigation } from 'shared-utils';
import { Button } from 'shared-ui';
import { TrackDetailsNavigation } from 'screens/routes';
import { PlusIcon } from '../../../components/Icons';
import CreateTrackModal from './CreateTrackModal';
import styles from '../TrackScreen.module.scss';

const HeaderAction: React.FC = () => {
    const { push: navigate } = useNavigation();
    const [showCreateTrack, setShowCreateTrack] = React.useState(false);

    const handleTrackCreateCompletion = (trackId: number) => {
        navigate(TrackDetailsNavigation(trackId));
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={() => setShowCreateTrack(true)}
            >
                Ajouter un circuit
            </Button>
            {showCreateTrack && (
                <CreateTrackModal
                    onClose={() => setShowCreateTrack(false)}
                    onCompletion={handleTrackCreateCompletion}
                    className={styles['modal']}
                />
            )}
        </>
    );
};

export default HeaderAction;
