import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const exportFile = async (year: number, request: App.GlobalExportFilters): Promise<App.FileData> => {
    if (!Ark.isNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/export', config.apiUrl, year);
        const {
            startDate,
            endDate,
            campaigns,
            tracks,
            observations,
            customers,
            varietals,
            areas,
            bio,
            confusion
        } = request;
        const requestData = {
            startDate: startDate != null ? Ark.formatDate(startDate) : null,
            endDate: endDate != null ? Ark.formatDate(endDate) : null,
            campaigns,
            tracks,
            observations,
            customers,
            varietals,
            areas,
            bio,
            confusion
        };

        response = await httpService.post(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling export API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send export request (exportFile)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('EXPORT_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('EXPORT_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'EXPORT_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === false, 'excepted file data');
    }
    catch (err) {
        throw new Ark.ServiceError('EXPORT_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    const file = await httpService.getFileFromResponse(response.body);
    if (file == null) throw new Ark.ServiceError('EXPORT_ASSERTION_FAILED', 'Unable to access file data');

    return {
        filename: file.filename,
        contentType: file.contentType,
        blob: file.blob
    };
};
