import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isDefined, isValidDate, isValidNumber } from '@dateam/ark';
import { useNavigation } from 'shared-utils';
import config from 'config';
import { inspectionDetailsPropTypes } from 'data/inspection';
import { InspectionEditNavigation } from 'screens/routes';
import Page from 'components/Page';
import { ReportSectionList, ReportSectionDetails } from './component';
// import styles from './ReportSection.module.scss';

type Props = {
    inspection: Omit<App.InspectionDetails, 'observations' | 'plots'>;
};

const ConfigurationSection: React.FC<Props> = ({ inspection }) => {
    const { t } = useTranslation();
    const { query: { reportId: reportIdParam }, push: navigate } = useNavigation();

    React.useEffect(() => {
        if (!isValidDate(inspection.validationDate)) {
            navigate(InspectionEditNavigation(inspection.id));
        }
    }, [inspection, navigate]);

    const renderContent = () => {
        if (isDefined(reportIdParam)) {
            const parsedReportId = parseInt(reportIdParam, 10);

            if (isValidNumber(parsedReportId)) {
                return (<ReportSectionDetails inspectionId={inspection.id} reportId={parsedReportId} />);
            }
        }

        return (<ReportSectionList inspectionId={inspection.id} />);
    };

    return (
        <>
            <Helmet>
                <title>{t('inspection.details.report.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page.Content className="row">
                {renderContent()}
            </Page.Content>
        </>
    );
};

ConfigurationSection.propTypes = {
    inspection: inspectionDetailsPropTypes.isRequired
};

ConfigurationSection.defaultProps = {

};

export default ConfigurationSection;
