import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import { queryClient } from 'utils/queryClient';
import {
    BIO_KEY,
    BIO_TYPE_DETAILS_KEY,
    defaultDataOptions,
    MutationResult,
    QueryResult,
    queryResultKeys,
    mutateResultKeys,
    defaultActionOptions
} from './constants';
import { bioRequests } from './requests';

export const useBioTypes = (options?: DataOptions): QueryResult<App.BioType[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.BioType[], ServiceError>({
        ...config,
        queryKey: BIO_KEY,
        queryFn: bioRequests.get
    });

    return usePick(query as any, queryResultKeys);
};

export const useBioType = (id: App.BioTypeDetails['id'], options?: DataOptions): QueryResult<App.BioTypeDetails> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.BioTypeDetails, ServiceError>({
        ...config,
        queryKey: [BIO_TYPE_DETAILS_KEY, id],
        queryFn: () => bioRequests.getTypeDetails(id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateBioType = (): MutationResult<number, App.CreateBioTypeRequest> => {
    const result = useMutation<number, ServiceError, App.CreateObservationTypeRequest>(
        (requestData: App.CreateBioTypeRequest) =>
            bioRequests.createBioType({ ...requestData }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([BIO_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateBioType = (): MutationResult<void, App.UpdateBioTypeRequest> => {
    const result = useMutation<void, ServiceError, App.UpdateBioTypeRequest>(
        ({ ...request }: App.UpdateBioTypeRequest) => {
            const {
                id,
                ...props
            } = request;

            return bioRequests.update(id, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([BIO_KEY]);
                queryClient.invalidateQueries([BIO_TYPE_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};

export const useDeleteBioType = (): MutationResult<void, App.BioTypeDetails['id']> => {
    const result = useMutation<void, ServiceError, App.BioTypeDetails['id']>(
        id => bioRequests.deleteBioType(id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([BIO_KEY]);
                queryClient.invalidateQueries([BIO_TYPE_DETAILS_KEY, id]);
            }
        }
    );

    return result;
};