import React from 'react';
import { isDefined, isString } from '@dateam/ark';
import { refLabelNumberPropTypes } from 'utils/propTypes';

type Props = {
    data?: RefLabel;
};

const ColumnCustomer: React.FC<Props> = ({
    data
}: Props) => {
    if (!isDefined(data)) return null;
    if (isString(data.label)) return (<>{data.label}</>);

    return (<>{data.id}</>);
};

ColumnCustomer.propTypes = {
    data: refLabelNumberPropTypes
};

ColumnCustomer.defaultProps = {
    data: undefined
};

export default ColumnCustomer;
