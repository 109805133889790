import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { concatClassName } from '@dateam/ark-react';
import { isArray } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import config from 'config';
import {
    Card,
    Loader,
    Table,
    TableRowClickEvent
} from 'shared-ui';
import Page from 'components/Page';
import ListSection from 'components/ListSection';
import { useRoles } from 'data/user';
import { useUserSearch } from 'data/effects';
import { UserDetailsNavigation } from 'screens/routes';
import { FilterBar, HeaderAction, UserFilters } from './components';
import UserBreadcrumb from './UserBreadcrumb';
import styles from './UserScreen.module.scss';

type UserTableItem = App.User & { roleLabel: string; };

const UserScreen: React.FC = () => {
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const roles = useRoles();
    const [filter, setFilter] = React.useState<UserFilters>({
        userText: '',
        roles: [],
        hideDeactivated: true
    });
    const { data: users, isLoading } = useUserSearch({
        userText: filter.userText,
        roles: filter.roles,
        hideDeactivated: filter.hideDeactivated
    });

    const tableData = React.useMemo(() => {
        if (!isArray(users)) return [];

        const data: UserTableItem[] = users.map(user => ({
            ...user,
            roleLabel: roles.find(role => role.id === user.roleId)?.label ?? '-'
        }));

        return data;
    }, [users, roles]);

    const handleRowClick = React.useCallback((event: TableRowClickEvent<App.User>) => {
        navigate(UserDetailsNavigation(event.data.id));
    }, [navigate]);

    const handleFilterChange = React.useCallback((filter: UserFilters) => {
        setFilter(filter);
    }, [setFilter]);

    return (
        <>
            <Helmet>
                <title>{t('user.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page>
                <Page.Header>
                    <Page.Title>
                        Utilisateurs
                    </Page.Title>
                    <Page.HeaderActions>
                        <UserBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header className={concatClassName('withAction')}>
                            <Card.Title>
                                Liste des utilisateurs
                            </Card.Title>
                            <HeaderAction />
                        </Card.Header>
                        <Card.Body className="row">
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <ListSection>
                                <ListSection.Filter>
                                    <FilterBar onChange={handleFilterChange} />
                                </ListSection.Filter>
                                <ListSection.Content>
                                    <Table<App.User>
                                        data={tableData}
                                        onRowClick={handleRowClick}
                                        className={styles['tableRow']}
                                    >
                                        <Table.Column accessor="firstName">
                                            <Table.Column.Header>Prénom</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column accessor="lastName">
                                            <Table.Column.Header>Nom</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column accessor="username">
                                            <Table.Column.Header>Nom d'utilisateur</Table.Column.Header>
                                        </Table.Column>
                                        <Table.Column accessor="roleLabel">
                                            <Table.Column.Header>Rôle</Table.Column.Header>
                                        </Table.Column>
                                    </Table>
                                </ListSection.Content>
                            </ListSection>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(UserScreen, 'USER');