import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isDefined, isValidDate, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { plotDetailsPropTypes } from 'data/plot';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps } from './types';
import { ObservationErrors } from './components';
import ObservationField from './ObservationField';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation11';

type Props = ObservationProps<App.Observation11>;

const Observation11: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.count === undefined &&
            data.intensity === undefined) return;

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const count = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.count ?? undefined;
    }, [data]);

    const intensity = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.intensity ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>Comptage: {internalData.current.previous.count?.value ?? '-'}</div>
                    <div className={styles['previous']}>Intensité: {internalData.current.previous.intensity?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation11 = {
            ...internalData.current,
            count: null,
            intensity: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCountChange = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation11 = {
            ...internalData.current,
            count: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleIntensityChange = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation11 = {
            ...internalData.current,
            intensity: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="Accident climatique - échaudage"
        >
            <div className="row">
                <ObservationField
                    id={`${observationKey}-count`}
                    label="Comptage"
                    offLabel="/50 grappes"
                    className={concatClassName(styles[`${observationKey}-count`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={50}
                        value={count}
                        onChange={handleCountChange}
                        state={validationErrors['count']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
                <ObservationField
                    id={`${observationKey}-intensity`}
                    label="Intensité"
                    offLabel="%"
                    className={concatClassName(styles[`${observationKey}-intensity`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={100}
                        value={intensity}
                        onChange={handleIntensityChange}
                        state={validationErrors['intensity']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation11.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotDetailsPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation11.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation11;
