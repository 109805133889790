import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import PlotDetailsScreen from './PlotDetailsScreen';

export const PlotDetailsNavigation = (plotId: App.PlotIdentity['id']): NavigationRoute =>
    new NavigationRoute(
        PlotDetailsScreen.key,
        [getYearRouteParam(), plotId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: PlotDetailsScreen.key,
        path: '/<:plotId>', // Should have matched the pattern <:plotId([a-zA-Z0-9]+)> if react-router V6 supported it
        component: PlotDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor', 'writer']
        }
    }));

export default router;