import { isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Comptages grappes
 ** - count1: Série 1 // /10 ceps
 **     0 <= Value <= 10
 ** - count2: Série 2 // /10 ceps
 **     0 <= Value <= 10
 ** - count3: Série 3 // /10 ceps
 **     0 <= Value <= 10
 */

type CurrentObservation = App.Observation43;
type CurrentApiObservation = App.Api.Observation43;

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count1, count2, count3 } = observation;

    if (ignored === true) return validation;

    if (count1 != null) {
        if (!isValidNumber(count1) || count1 < 0) {
            validation.count1 = {
                field: 'count1',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (count2 != null) {
        if (!isValidNumber(count2) || count2 < 0) {
            validation.count2 = {
                field: 'count2',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (count3 != null) {
        if (!isValidNumber(count3) || count3 < 0) {
            validation.count3 = {
                field: 'count3',
                type: 'error',
                code: 'format'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        count1,
        count2,
        count3
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.count1 = parseObservationField(count1);
    parsedObs.count2 = parseObservationField(count2);
    parsedObs.count3 = parseObservationField(count3);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        count1,
        count2,
        count3
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.count1 = count1 !== undefined ? new RecordValue(count1) : null;
    mappedObs.count2 = count2 !== undefined ? new RecordValue(count2) : null;
    mappedObs.count3 = count3 !== undefined ? new RecordValue(count3) : null;

    return mappedObs;
};