import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { ObservationTypesTableItem } from 'components/observationTypesTableItem';
import { Plot, plotPropTypes } from '../types';
import styles from '../PlotList.module.scss';

type Props = {
    data: Plot;
    className?: string;
};

const PlotExtraInfos: React.FC<Props> = ({ data, className }: Props) => (
    <div className={concatClassName(className, 'row')}>
        {Ark.isString(data.bio?.label) && <div className={styles['bio']}>{data.bio?.label}</div>}
        <div className={styles['varietal']}>
            <div className={styles['title']}>Cépage</div>
            <div className={styles['detailLabel']}>{data.varietal?.label ?? '-'}</div>
        </div>
        <div className={styles['confusion']}>
            <div className={styles['title']}>Confusion</div>
            <div className={styles['detailLabel']}>
                {Ark.isBoolean(data.confusion) ? (data.confusion === true ? 'Oui' : 'Non') : '-'}
            </div>
        </div>
        <div>
            <div className={styles['title']}>Types de suivi</div>
            <ObservationTypesTableItem types={data.observationTypes} />
        </div>
    </div>
);

PlotExtraInfos.propTypes = {
    data: plotPropTypes.isRequired,
    className: propTypes.string
};

PlotExtraInfos.defaultProps = {
    className: undefined
};

export default PlotExtraInfos;
