import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isFunction } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { useUserState } from 'utils/userStore';
import {
    ChevronDownIcon,
    UserIcon
} from 'components/Icons';
import styles from './Sidebar.module.scss';

type Props = {
    className?: string;
    onClick?: () => unknown;
};

const SidebarProfileItem = React.forwardRef<HTMLDivElement, Props>(({
    className,
    onClick
}: Props, ref) => {
    const rootRef = React.useRef<HTMLDivElement>(null);
    const [user] = useUserState();

    React.useEffect(() => {
        if (!isDefined(ref)) return;
        (ref as any).current = rootRef.current;
    }, [ref]);

    const username = React.useMemo(() => {
        if (!isDefined(user)) return '';

        return `${user.firstName} ${user.lastName}`;
    }, [user]);

    const handleClick = React.useCallback(() => {
        if (isFunction(onClick)) {
            onClick();
        }
    }, [onClick]);

    return (
        <div
            role="button"
            tabIndex={0}
            ref={rootRef}
            className={concatClassName(
                styles['profileItem'],
                styles['menuItemBase'],
                className
            )}
            onClick={handleClick}
        >
            <span
                className={concatClassName(
                    styles['profileItemIcon'],
                    styles['menuItemIcon']
                )}
            >
                <UserIcon />
            </span>
            <span className={styles['menuItemText']}>{username}</span>
            <span><ChevronDownIcon className={styles['profileItemChevron']} /></span>
        </div>
    );
});

SidebarProfileItem.propTypes = {
    className: propTypes.string,
    onClick: propTypes.func
};

SidebarProfileItem.defaultProps = {
    className: undefined,
    onClick: undefined
};

export default SidebarProfileItem;
