import React from 'react';
import { useMutation, useQuery } from 'react-query';
import * as Ark from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { queryClient } from 'utils/queryClient';
import tokenStore from 'utils/tokenStore';
import { resolveFirstAuth } from 'utils/auth';
import { useUserState } from 'utils/userStore';
import appConfig from 'config';
import { unauthorizedProvider } from 'components/unauthorizedHandler';
import {
    QueryResult,
    defaultDataOptions,
    queryResultKeys,
    mutateResultKeys,
    MutationResult,
    AUTH_KEY,
    USER_KEY,
    USER_OBSERVATOR_KEY,
    USER_WRITER_KEY,
    USER_TECHNICAL_KEY,
    defaultActionOptions
} from './constants';
import { userRequests } from './requests';

const roles: RefLabel<string>[] = [
    {
        id: 'administrator',
        label: 'Administrateur'
    },
    {
        id: 'supervisor',
        label: 'Planificateur'
    },
    {
        id: 'writer',
        label: 'Rédacteur'
    },
    {
        id: 'observator',
        label: 'Observateur'
    }
];

export const useUsers = (options?: DataOptions): QueryResult<App.User[]> => {
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.User[], Ark.ServiceError>({
        ...config,
        queryKey: [USER_KEY],
        queryFn: async () => {
            const result = await userRequests.getUsers();

            if (Ark.isArray(result)) {
                Ark.orderBy(result, 'lastName');
                Ark.orderBy(result, 'firstName');
            }

            return result;
        }
    });

    return usePick(query as any, queryResultKeys);
};

export const useUser = (id: number, options?: DataOptions): QueryResult<App.UserDetails> => {
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.UserDetails, Ark.ServiceError>({
        ...config,
        queryKey: [USER_KEY, id],
        queryFn: () => userRequests.getUser(id)
    });

    return usePick(query as any, queryResultKeys);
};

export const useObservators = (options?: DataOptions): QueryResult<App.User[]> => {
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.User[], Ark.ServiceError>({
        ...config,
        queryKey: [USER_KEY, USER_OBSERVATOR_KEY],
        queryFn: async () => {
            const result = await userRequests.getObservators();

            if (Ark.isArray(result)) {
                Ark.orderBy(result, 'lastName');
                Ark.orderBy(result, 'firstName');
            }

            return result;
        }
    });

    return usePick(query as any, queryResultKeys);
};

export const useWriters = (options?: DataOptions): QueryResult<App.User[]> => {
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.User[], Ark.ServiceError>({
        ...config,
        queryKey: [USER_KEY, USER_WRITER_KEY],
        queryFn: async () => {
            const result = await userRequests.getWriters();

            if (Ark.isArray(result)) {
                Ark.orderBy(result, 'lastName');
                Ark.orderBy(result, 'firstName');
            }

            return result;
        }
    });

    return usePick(query as any, queryResultKeys);
};

export const useTechnicalUsers = (options?: DataOptions): QueryResult<App.User[]> => {
    const config = React.useMemo(() => Ark.computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.User[], Ark.ServiceError>({
        ...config,
        queryKey: [USER_KEY, USER_TECHNICAL_KEY],
        queryFn: async () => {
            const result = await userRequests.getTechnicalUsers();

            if (Ark.isArray(result)) {
                Ark.orderBy(result, 'lastName');
                Ark.orderBy(result, 'firstName');
            }

            return result;
        }
    });

    return usePick(query as any, queryResultKeys);
};

export const useRoles = (): RefLabel<string>[] => {
    return roles;
};

export const useLogIn = (): MutationResult<App.UserInfo, App.AuthCredentials> => {
    const [, setUser] = useUserState();

    const result = useMutation<App.UserInfo, Ark.ServiceError, App.AuthCredentials>(
        ({ username, password }: App.AuthCredentials) => {
            tokenStore.clear();

            return userRequests.login({ username, password });
        },
        {
            ...defaultActionOptions,
            onSuccess: async user => {
                try {
                    setUser(user);
                    tokenStore.set(user.token);
                }
                catch (err) {
                    logger.warn('useLogIn: unable to store user data', err);
                }
                resolveFirstAuth(true);
            },
            onError: () => {
                resolveFirstAuth(false);
            }
        }
    );

    return result;
};

export const useRenewAuth = (): MutationResult<App.UserInfo, void> => {
    const [, setUser] = useUserState();

    const result = useMutation<App.UserInfo, Ark.ServiceError>(
        () => userRequests.renewAuth(),
        {
            ...defaultActionOptions,
            onSuccess: async user => {
                try {
                    setUser(user);
                    tokenStore.set(user.token);
                }
                catch (err) {
                    logger.warn('useLogIn: unable to store user data', err);
                }
                resolveFirstAuth(true);
            },
            onError: err => {
                defaultActionOptions.onError(err);
                resolveFirstAuth(false);
            }
        }
    );
    return result;
};

export const useLogOut = (): MutationResult<void> => {
    const [, setUser] = useUserState();

    const result = useMutation<void, Ark.ServiceError, void>(
        async () => {
            setUser(null);
            tokenStore.clear();
        },
        {
            onSuccess: () => {
                queryClient.clear();
            }
        }
    );

    return result;
};

export const useCreateUser = (): MutationResult<number, App.CreateUserRequest> => {
    const result = useMutation<number, Ark.ServiceError, App.CreateUserRequest>(
        (requestData: App.CreateUserRequest) => userRequests.createUser({ ...requestData }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([USER_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateUser = (): MutationResult<number, App.UpdateUserRequest> => {
    const result = useMutation<number, Ark.ServiceError, App.UpdateUserRequest>(
        ({ id, ...requestData }: App.UpdateUserRequest) => userRequests.updateUser(id, { ...requestData }),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([USER_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateUserPassword = (): MutationResult<number, App.UpdateUserPasswordRequest> => {
    const result = useMutation<number, Ark.ServiceError, App.UpdateUserPasswordRequest>(
        ({ id, password }: App.UpdateUserPasswordRequest) => userRequests.updateUserPassword(id, password),
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const useDeactivateUser = (): MutationResult<number, number> => {
    const result = useMutation<number, Ark.ServiceError, number>(
        id => userRequests.deactivateUser(id),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([USER_KEY]);
            }
        }
    );

    return result;
};

export const useReactivateUser = (): MutationResult<number, number> => {
    const result = useMutation<number, Ark.ServiceError, number>(
        id => userRequests.reactivateUser(id),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([USER_KEY]);
            }
        }
    );

    return result;
};

export const useDeleteUser = (): MutationResult<number, number> => {
    const result = useMutation<number, Ark.ServiceError, number>(
        id => userRequests.deleteUser(id),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([USER_KEY]);
            }
        }
    );

    return result;
};
