import React from 'react';
import { NavigationRoute, useNavigation, useRouterPath } from 'shared-utils';
import Breadcrumb from 'components/Breadcrumb';
import { DashboardNavigation, VarietalTypeNavigation } from 'screens/routes';

type Props = {
    label: string;
};

const VarietalTypeDetailsBreadcrumb: React.FC<Props> = ({ label }: Props) => {
    const homePath = useRouterPath(DashboardNavigation());
    const varietalTypePath = useRouterPath(VarietalTypeNavigation());
    const { push: navigate } = useNavigation();

    const triggerNav = React.useCallback((route: NavigationRoute) => (event: React.MouseEvent<any>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(route);
    }, [navigate]);

    return (
        <Breadcrumb>
            <Breadcrumb.Home
                key="bc-home"
                to={homePath ?? undefined}
                onClick={triggerNav(DashboardNavigation())}
            />
            <Breadcrumb.Item
                key="bc-varietalType"
                to={varietalTypePath ?? undefined}
                onClick={triggerNav(VarietalTypeNavigation())}
            >
                Cépages
            </Breadcrumb.Item>
            <Breadcrumb.Item key="bc-varietalType-details">{label}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default VarietalTypeDetailsBreadcrumb;
