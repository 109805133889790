import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import InspectionEditScreen from './InspectionEditScreen';

const inspectionTabKey = `${InspectionEditScreen.key}_TAB`;
const InspectionEntriesPlotKey = `${InspectionEditScreen.key}_ENTRIES_PLOT`;
const InspectionReportDetailsKey = `${InspectionEditScreen.key}_REPORT_DETAILS`;
const roles = ['administrator', 'supervisor', 'writer'];

export const InspectionEditNavigation = (inspectionId: App.Inspection['id']): NavigationRoute =>
    new NavigationRoute(
        InspectionEditScreen.key,
        [getYearRouteParam(), inspectionId]
    );

export const InspectionTabNavigation = (inspectionId: App.Inspection['id'], tab: string): NavigationRoute =>
    new NavigationRoute(
        inspectionTabKey,
        [getYearRouteParam(), inspectionId, tab]
    );

export const InspectionEntriesPlotNavigation = (inspectionId: App.Inspection['id'], plotId: App.PlotIdentity['id']): NavigationRoute =>
    new NavigationRoute(
        InspectionEntriesPlotKey,
        [getYearRouteParam(), inspectionId, plotId]
    );

export const InspectionReportDetailsNavigation = (
    inspectionId: number,
    reportId: number
): NavigationRoute =>
    new NavigationRoute(
        InspectionReportDetailsKey,
        [getYearRouteParam(), inspectionId, reportId]
    );

const router = new Router();

router
    .add(new RouteData({
        key: InspectionEditScreen.key,
        path: '/<:inspectionId>', // Should have matched the pattern <:inspectionId(\\d+)> if react-router V6 supported it
        component: InspectionEditScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }))
    .add(new RouteData({
        key: inspectionTabKey,
        path: '/<:inspectionId>/<:tab>', // Should have matched the pattern <:inspectionId(\\d+)>/<:tab([a-zA-Z]+)> if react-router V6 supported it
        component: InspectionEditScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }))
    .add(new RouteData({
        key: InspectionEntriesPlotKey,
        path: '/<:inspectionId>/entry/<:plotId>', // Should have matched the pattern <:inspectionId(\\d+)>/<:tab(entry)>/<:plotId(\\d+)> if react-router V6 supported it
        component: InspectionEditScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }))
    .add(new RouteData({
        key: InspectionReportDetailsKey,
        path: '/<:inspectionId>/report/<:reportId>', // Should have matched the pattern <:inspectionId(\\d+)>/<:tab(report)>/<:reportId(\\d+)> if react-router V6 supported it
        component: InspectionEditScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }));

export default router;