import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isFunction, isArray, noop, strIncludes } from '@dateam/ark';
import { useDebounce } from '@dateam/ark-react';
import { useCustomers } from 'data/customer';

export type ReportFilters = {
    customer?: number;
};

type Props = {
    enabled?: boolean;
    onChange?: (filters: ReportFilters) => void;
};

const FilterBar: React.FC<Props> = ({ enabled, onChange }) => {
    const { data: customers } = useCustomers({ enabled });

    const notifyChange = React.useRef(onChange ?? noop);
    const saveChanges = useDebounce((filter: ReportFilters) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<ReportFilters>({});

    const handleCustomerGetOptions = React.useCallback(async (value: string) => {
        if (!isArray(customers)) return [];
        if (value.trim().length === 0) return customers.map(customer => customer.fullName);

        return customers.filter(item => strIncludes(value, item.fullName, { caseInsensitive: true }))
            .map(customer => customer.fullName);
    }, [customers]);

    const handleCustomerChange = React.useCallback((newValue: string) => {
        const newCustomer = customers?.find(item => item.fullName.toUpperCase() === newValue.toUpperCase());

        const newFilter: ReportFilters = {
            ...filter,
            customer: newCustomer?.id
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter, customers]);

    const selectedCustomer = filter.customer != null ? customers?.find(item => item.id === filter.customer) : undefined;

    return (
        <div className="row">
            <Form.Group controlId="filter-customer" className="col">
                <Form.Label>Vigneron</Form.Label>
                <Form.Autocomplete<string>
                    value={selectedCustomer?.fullName.toString() ?? undefined}
                    selectOnFocus
                    onGetOptions={handleCustomerGetOptions}
                    onChange={handleCustomerChange}
                />
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    enabled: propTypes.bool,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    enabled: true,
    onChange: undefined
};

export default FilterBar;
