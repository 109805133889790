import React from 'react';
import * as Ark from '@dateam/ark';
import { useObservations } from 'data/observation';
import {
    QueryResult,
    queryResultKeys
} from '../constants';

export type ObservationSearchFilter = {
    label: string | undefined;
    types: number[];
};

export const useObservationSearch = (
    filter: ObservationSearchFilter,
    options?: DataOptions
): QueryResult<App.Observation[]> => {
    const { data: observations, ...queryResult } = useObservations();

    return React.useMemo(() => {
        let filteredData: App.Observation[] = [];

        if (Ark.isArray(observations)) {
            filteredData = [...observations];

            const {
                label: labelFilter,
                types: typesFilter
            } = filter;

            const filterPredicates: ((dataEntry: App.Observation) => boolean)[] = [];

            if (Ark.isArray(typesFilter) && typesFilter.length > 0) {
                filterPredicates.push((dataEntry: App.Observation) =>
                    typesFilter.every(typeFilter => dataEntry.types.includes(typeFilter)));
            }

            if (Ark.isString(labelFilter) && labelFilter.trim().length > 0) {
                const filter = labelFilter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.Observation) =>
                    dataEntry.label.toUpperCase().indexOf(filter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        Ark.orderBy(filteredData, 'label');

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [observations, queryResult, filter]);
};
