import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import ListContent from './ListContent';
import ListFilter from './ListFilter';
import styles from './ListSection.module.scss';

type Props = {
    className?: string;
    children?: React.ReactNode;
};

const ListSection: React.FC<Props> = ({
    className,
    children
}: Props) => (
    <div className={concatClassName(styles['section'], className)}>
        {children}
    </div>
);

ListSection.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

ListSection.defaultProps = {
    className: undefined,
    children: undefined
};

export default Object.assign(ListSection, {
    Content: ListContent,
    Filter: ListFilter
});
