import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const InfiniteIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="currentColor" className={concatClassName('icon', className)}>
        <path d="M22.19,8.13a5.47,5.47,0,0,0-7.73,0L8.13,14.46a3.57,3.57,0,0,1-4.91,0A3.48,3.48,0,0,1,8.13,9.54l1.4,1.4a1,1,0,0,0,1.41,0,1,1,0,0,0,.3-.7,1,1,0,0,0-.3-.71l-1.4-1.4a5.47,5.47,0,0,0-7.73,0,5.49,5.49,0,0,0,0,7.74,5.47,5.47,0,0,0,7.73,0l6.33-6.33a3.48,3.48,0,1,1,0,4.92l-1.4-1.4a1,1,0,0,0-1.71.7,1,1,0,0,0,.29.71l1.41,1.4a5.47,5.47,0,0,0,7.73,0A5.49,5.49,0,0,0,22.19,8.13Z" />
    </svg>
);

InfiniteIcon.propTypes = {
    className: propTypes.string
};

InfiniteIcon.defaultProps = {
    className: undefined
};

export default InfiniteIcon;
