import propTypes from 'prop-types';
import { isObjectLike, isString, isValidNumber } from '@dateam/ark';

export const isRefLabelNumber = (value: unknown): value is RefLabel => {
    if (!isObjectLike(value)) return false;

    return isValidNumber(value.id) && isString(value.label);
};

export const refLabelNumberPropTypes = propTypes.shape({
    id: propTypes.number.isRequired,
    label: propTypes.string.isRequired
});

export const isRefLabelString = (value: unknown): value is RefLabel<string> => {
    if (!isObjectLike(value)) return false;

    return isString(value.id) && isString(value.label);
};

export const refLabelStringPropTypes = propTypes.shape({
    id: propTypes.string.isRequired,
    label: propTypes.string.isRequired
});