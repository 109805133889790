import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const getTracks = async (year: number): Promise<App.Track[]> => {
    if (!Ark.isNumber(year)) throw new Error('year parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/track', config.apiUrl, year);

        response = await httpService.get<App.Api.Track[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling track type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send track request (getTracks)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('TRACK_GET_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('TRACK_GET_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'TRACK_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('TRACK_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body.map(data => mapTrack(data));
};

export const get = async (year: number, trackId: App.TrackDetails['id']): Promise<App.TrackDetails> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsNumber(trackId, 'trackId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/track/{2}', config.apiUrl, year, trackId);

        response = await httpService.get<App.TrackDetails>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling track type API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send track request (${get.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('TRACK_GET_DETAILS_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('TRACK_GET_DETAILS_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('TRACK_GET_DETAILS_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'TRACK_GET_DETAILS_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isPlainObject(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('TRACK_GET_DETAILS_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const createTrack = async (year: number, label: string): Promise<App.TrackKey> => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!Ark.isString(label)) throw new Error('label parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/track', config.apiUrl, year);
        const requestData = { label };

        response = await httpService.post<App.TrackKey>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update track API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send track request (${updateTrack.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('TRACK_CREATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('TRACK_CREATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'TRACK_CREATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isPlainObject(response.body), 'invalid track key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('TRACK_CREATE_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

type UpdateTrackRequest = {
    label: string;
    plots: App.PlotOrder[];
};

export const updateTrack = async (year: number, id: App.TrackDetails['id'], request: UpdateTrackRequest): Promise<void> => {
    if (!Ark.isValidNumber(year)) throw new Error('year parameter is not valid');
    if (!Ark.isValidNumber(id)) throw new Error('id parameter is not valid');
    if (!Ark.isDefined(request)) throw new Error('request parameter is not valid');

    const {
        label,
        plots
    } = request;
    if (!Ark.isString(label) || label.trim().length === 0) throw new Ark.ServiceError('TRACK_UPDATE_INVALID_REQUEST', 'request label is not valid');
    if (!Ark.isArray(plots)) throw new Ark.ServiceError('TRACK_UPDATE_INVALID_REQUEST', 'request plots is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/track/{2}', config.apiUrl, year, id);
        const requestData = {
            label,
            plots
        };

        response = await httpService.post<App.TrackKey>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling update track API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send track request (${updateTrack.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('TRACK_UPDATE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('TRACK_UPDATE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('TRACK_UPDATE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'TRACK_UPDATE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid track key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('TRACK_UPDATE_FAILED', (err instanceof Error && err?.message) || '');
    }
};

export const deleteTrack = async (year: number, trackId: App.TrackDetails['id']): Promise<void> => {
    Ark.assertIsValidNumber(year, 'year parameter is not valid');
    Ark.assertIsNumber(trackId, 'trackId parameter is not valid');

    let response;
    try {
        const requestUrl = Ark.stringFormat('{0}/{1}/track/{2}', config.apiUrl, year, trackId);

        response = await httpService.delete(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling delete track API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error(`unable to send track request (${deleteTrack.name})`);
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('TRACK_DELETE_UNAUTHORIZED');
    }
    if (response.status === Ark.HttpStatusCode.NotFound) {
        throw new Ark.ServiceError('TRACK_DELETE_NOT_FOUND');
    }
    if (response.status === Ark.HttpStatusCode.Forbidden) {
        throw new Ark.ServiceError('TRACK_DELETE_FORBIDDEN');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'TRACK_DELETE_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(Ark.isPlainObject(response.body), 'invalid track key from the response');
    }
    catch (err) {
        throw new Ark.ServiceError('TRACK_DELETE_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }
};

const mapTrack = ({
    id,
    label,
    plotCount
}: App.Api.Track): App.Track => ({
    id,
    label,
    plotCount
});