import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Card,
    ConfirmDialog,
    Form
} from 'shared-ui';
import { useNavigation, useRouterPath } from 'shared-utils';
import { useYearlyNavigation } from 'utils/yearStore';
import { errorProvider } from 'components/errorHandler';
import { CopyIcon, TrashIcon } from 'components/Icons';
import DuplicateInspectionDialog from 'components/DuplicateInspectionDialog';
import { FooterDateInfo } from 'components/FooterDateInfo';
import { inspectionDetailsPropTypes, useDeleteInspection, useUpdateInspection } from 'data/inspection';
import { useObservators, useWriters } from 'data/user';
import { CampaignDetailsNavigation, InspectionEditNavigation, InspectionNavigation } from 'screens/routes';
import styles from '../../../InspectionEditScreen.module.scss';

type Props = {
    className?: string;
    inspection: Omit<App.InspectionDetails, 'observations' | 'plots'>;
    displayActions?: boolean;
};

const ConfigurationPanel: React.FC<Props> = ({
    className,
    inspection,
    displayActions
}) => {
    const { data: observators } = useObservators();
    const { data: writers } = useWriters();
    const { mutateAsync: updateInspection, isLoading: isUpdating } = useUpdateInspection();
    const { mutateAsync: deleteInspection, isLoading: isDeleting } = useDeleteInspection();
    const { push: navigate } = useNavigation();
    const [showDeletionInspection, setShowDeletionInspection] = React.useState(false);
    const [showDuplicationInspection, setShowDuplicationInspection] = React.useState(false);
    const campaignNav = useYearlyNavigation(CampaignDetailsNavigation, [inspection.campaign.id]);
    const campaignPath = useRouterPath(campaignNav);

    const inspectionState = React.useRef(inspection);

    React.useEffect(() => {
        inspectionState.current = inspection;
    }, [inspection]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: string) => {
        try {
            if (!Ark.isDefined(inspectionState.current)) throw new Error('La visite n\'a pas été trouvée.');

            const {
                id,
                label,
                instruction,
                startDate,
                overlapDays,
                assigneeUser,
                writerUser
            } = inspectionState.current;

            await updateInspection({
                id,
                label,
                instruction,
                startDate,
                overlapDays,
                assigneeUser: assigneeUser?.id ?? null,
                writer: writerUser?.id ?? null,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof Ark.ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }

        return undefined;
    }, [inspectionState, updateInspection]);

    const handleSaveAssignee = React.useCallback(async (assigneeValue: number) => {
        try {
            if (!Ark.isDefined(inspectionState.current)) throw new Error('La visite n\'a pas été trouvée.');

            if (!Ark.isValidNumber(assigneeValue)) throw new Error('La valeur sélectionnée n\'est pas valide.');

            const {
                id,
                label,
                instruction,
                startDate,
                overlapDays,
                writerUser
            } = inspectionState.current;

            await updateInspection({
                id,
                label,
                instruction,
                startDate,
                overlapDays,
                writer: writerUser?.id ?? null,
                assigneeUser: assigneeValue
            });

            return assigneeValue;
        }
        catch {
            errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
        }

        return undefined;
    }, [inspectionState, updateInspection]);

    const handleSaveWriter = React.useCallback(async (writerValue: number) => {
        try {
            if (!Ark.isDefined(inspectionState.current)) throw new Error('La visite n\'a pas été trouvée.');

            const {
                id,
                label,
                instruction,
                startDate,
                overlapDays,
                assigneeUser
            } = inspectionState.current;

            await updateInspection({
                id,
                label,
                instruction,
                startDate,
                overlapDays,
                assigneeUser: assigneeUser?.id ?? null,
                writer: writerValue
            });

            return writerValue;
        }
        catch {
            errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
        }

        return undefined;
    }, [inspectionState, updateInspection]);

    const handleStartDateSave = React.useCallback(async (newValue: Date | null) => {
        try {
            if (!Ark.isDefined(inspectionState.current)) throw new Error('La visite n\'a pas été trouvée.');

            const {
                id,
                label,
                instruction,
                overlapDays,
                writerUser,
                assigneeUser
            } = inspectionState.current;

            if (Ark.isValidDate(newValue) && Ark.dateOnly(newValue) < Ark.dateOnly(new Date())) {
                errorProvider.notify(new Error('La visite ne peut être planifiée dans le passé.'));
                return undefined;
            }

            await updateInspection({
                id,
                label,
                instruction,
                startDate: newValue,
                overlapDays,
                writer: writerUser?.id ?? null,
                assigneeUser: assigneeUser?.id ?? null
            });

            return newValue;
        }
        catch {
            errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
        }

        return undefined;
    }, [inspectionState, updateInspection]);

    const goToCampaign = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        navigate(CampaignDetailsNavigation(inspectionState.current.campaign.id));
    }, [inspectionState, navigate]);

    const handleDuplicateInspectionCompletion = (inspectionId: number) => {
        navigate(InspectionEditNavigation(inspectionId));
    };

    const handleInspectionDeletion = React.useCallback(async (shouldDelete: boolean) => {
        setShowDeletionInspection(false);
        if (shouldDelete !== true) return;

        if (!Ark.isDefined(inspectionState.current)) throw new Error('La visite n\'existe pas.');

        await deleteInspection(inspectionState.current.id);

        navigate(InspectionNavigation());
    }, [inspectionState, setShowDeletionInspection, navigate, deleteInspection]);

    return (
        <>
            <Card className={concatClassName(styles['section'], 'col')}>
                <Card.Header>
                    <Card.Title>
                        Configuration
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className={styles['campaignLabel']}>
                        <div className="formLabel">Tournée</div>
                        <a
                            href={campaignPath}
                            onClick={goToCampaign}
                            className={styles['campaignLink']}
                        >
                            {inspection.campaign.label}
                        </a>
                    </div>
                    <Form.Group controlId="inspection-name">
                        <Form.Label>Nom de la visite</Form.Label>
                        <Form.Editable.Input
                            value={inspection.label}
                            onSave={handleSaveInput('label')}
                            maxlength={50}
                        />
                    </Form.Group>
                    <Form.Group controlId="inspection-assignee">
                        <Form.Label>Observateur</Form.Label>
                        <Form.Editable.Select selected={inspection.assigneeUser?.id} onSave={handleSaveAssignee}>
                            {observators?.map(user => (
                                <Form.Select.Option
                                    key={user.id}
                                    value={user.id}
                                    selectedText={`${user.firstName} ${user.lastName}`}
                                >
                                    {user.firstName} {user.lastName}
                                </Form.Select.Option>
                            ))}
                        </Form.Editable.Select>
                    </Form.Group>
                    <Form.Group controlId="inspection-writer">
                        <Form.Label>Rédacteur</Form.Label>
                        <Form.Editable.Select selected={inspection.writerUser?.id} onSave={handleSaveWriter}>
                            <Form.Select.Option
                                key={null}
                                value={null}
                                selectedText=""
                            >
                            </Form.Select.Option>
                            {writers?.map(user => (
                                <Form.Select.Option
                                    key={user.id}
                                    value={user.id}
                                    selectedText={`${user.firstName} ${user.lastName}`}
                                >
                                    {user.firstName} {user.lastName}
                                </Form.Select.Option>
                            ))}
                        </Form.Editable.Select>
                    </Form.Group>
                    <div className="row">
                        <Form.Group controlId="inspection-start-date" className="col">
                            <Form.Label>Date</Form.Label>
                            <Form.Editable.Date
                                value={inspection.startDate ?? undefined}
                                onSave={handleStartDateSave}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles['warning-message']}>
                        {Ark.isValidDate(inspection.startDate) && inspection.plotCount === 0 && (
                            <div>Attention, la visite ne contient aucune parcelle</div>
                        )}
                        {Ark.isValidDate(inspection.startDate) && inspection.observationCount === 0 && (
                            <div>Attention, la visite ne contient aucune observation</div>
                        )}
                    </div>
                    <Form.Group controlId="inspection-instruction">
                        <Form.Label>Consigne</Form.Label>
                        <Form.Editable.Input
                            value={inspection.instruction ?? ''}
                            multiline
                            rows={9}
                            maxlength={1000}
                            onSave={handleSaveInput('instruction')}
                        />
                    </Form.Group>
                </Card.Body>
                <Card.Footer className="row">
                    <Card.Infos>
                        <FooterDateInfo creationDate={inspection.creationDate} updateDate={inspection.updateDate} />
                    </Card.Infos>
                    {displayActions && (
                        <Card.Actions>
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={(<CopyIcon />)}
                                disabled={isUpdating || isDeleting}
                                pending={isUpdating}
                                onClick={() => setShowDuplicationInspection(true)}
                            >
                                Dupliquer la visite
                            </Button>
                            <Button
                                color="danger"
                                variant="outlined"
                                startIcon={(<TrashIcon />)}
                                disabled={isUpdating || isDeleting}
                                pending={isDeleting}
                                onClick={() => setShowDeletionInspection(true)}
                            >
                                Supprimer la visite
                            </Button>
                        </Card.Actions>
                    )}
                </Card.Footer>
            </Card>
            {showDeletionInspection && (
                <ConfirmDialog
                    title={`Supprimer la visite "${inspection?.label}"`}
                    message="Vous êtes sur le point de supprimer cette visite. Êtes-vous sûr de vouloir continuer ?"
                    ok="Supprimer"
                    onClose={handleInspectionDeletion}
                />
            )}
            {showDuplicationInspection && (
                <DuplicateInspectionDialog
                    inspection={inspection}
                    onClose={() => setShowDuplicationInspection(false)}
                    onCompletion={handleDuplicateInspectionCompletion}
                />
            )}
        </>
    );
};

ConfigurationPanel.propTypes = {
    className: propTypes.string,
    inspection: inspectionDetailsPropTypes.isRequired,
    displayActions: propTypes.bool
};

ConfigurationPanel.defaultProps = {
    className: undefined,
    displayActions: undefined
};

export default ConfigurationPanel;
