import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import styles from './ListSection.module.scss';

type Props = {
    className?: string;
    children?: React.ReactNode;
};

const ListContent: React.FC<Props> = ({
    className,
    children
}: Props) => (
    <div className={concatClassName(styles['content'], className)}>
        {children}
    </div>
);

ListContent.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

ListContent.defaultProps = {
    className: undefined,
    children: undefined
};

export default ListContent;
