import React from 'react';
import * as Ark from '@dateam/ark';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'shared-utils';
import config from 'config';
import Page from 'components/Page';
import { errorProvider } from 'components/errorHandler';
import { useBindYearNavigation, useYearState } from 'utils/yearStore';
import { Card, Loader, Form, Button } from 'shared-ui';
import { useAdminParameters, useUpdateAdminParameters } from 'data/admin';
import { AdminNavigation } from 'screens/routes';
import AdminBreadcrumb from './AdminBreadcrumb';
import styles from './AdminScreen.module.scss';
import UpdateReportTemplateModal from './component/updateReportTemplateModal';

const AdminScreen: React.FC = () => {
    useBindYearNavigation(AdminNavigation, []);
    const { t } = useTranslation();
    const [year] = useYearState();
    const { isLoading, data: parameters } = useAdminParameters(year);
    const { mutateAsync: updateAdminParameters, isLoading: isUpdating } = useUpdateAdminParameters();

    const parametersState = React.useRef(parameters);
    const [showUpdateReportTemplate, setShowUpdateReportTemplate] = React.useState(false);

    React.useEffect(() => {
        parametersState.current = parameters;
    }, [parameters]);

    const handleSaveInput = React.useCallback((key: string) => async (newValue: any) => {
        if (!Ark.isDefined(parametersState.current)) throw new Error('Les éléments d\'administration n\'ont pu être récupérés');

        try {
            const {
                reportNextInspection,
                notifInspectionDelay,
                deviceSpadFactor,
                deviceYaraFactor
            } = parametersState.current;

            await updateAdminParameters({
                year,
                reportNextInspection,
                notifInspectionDelay,
                deviceSpadFactor,
                deviceYaraFactor,
                [key]: newValue
            });

            return newValue;
        }
        catch (error) {
            if (!(error instanceof Ark.ServiceError)) {
                errorProvider.notify(new Error('Une erreur est survenue lors de l\'enregistrement.'));
            }
        }
    }, [year, parametersState, updateAdminParameters]);

    return (
        <>
            <Helmet>
                <title>{t('administration.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page className="page-full">
                <Page.Header>
                    <Page.Title>
                        Administration
                    </Page.Title>
                    <Page.HeaderActions>
                        <AdminBreadcrumb />
                    </Page.HeaderActions>
                </Page.Header>
                <Page.Content className="row">
                    <Card className="col">
                        <Card.Header>
                            <Card.Title>
                                Administration
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {isLoading && (
                                <Loader text="chargement" />
                            )}
                            <h3>Rapport</h3>
                            <Form.Group controlId="customer-header">
                                <Form.Label>Message prochaine visite</Form.Label>
                                <Form.Editable.Input
                                    multiline
                                    rows={4}
                                    value={parameters?.reportNextInspection != null ? parameters.reportNextInspection : ''}
                                    onSave={handleSaveInput('reportNextInspection')}
                                />
                            </Form.Group>
                            <Form.Group controlId="customer-header">
                                <Form.Label>Modèle de rapport</Form.Label>
                                <Button
                                    color="primary"
                                    onClick={() => setShowUpdateReportTemplate(true)}
                                >
                                    Mettre à jour les fichiers de modèle
                                </Button>
                            </Form.Group>
                            <h3>Notification</h3>
                            <Form.Group controlId="customer-header">
                                <Form.Label>Délai DRE avant la visite (minute)</Form.Label>
                                <Form.Editable.Input
                                    value={parameters?.notifInspectionDelay?.toString() ?? ''}
                                    onSave={handleSaveInput('notifInspectionDelay')}
                                />
                            </Form.Group>
                            <h3>Appareil de mesure</h3>
                            {/* <Form.Group controlId="customer-header">
                                <Form.Label>Coefficiant multiplication YARA</Form.Label>
                                <Form.Editable.Input
                                    type="number"
                                    value={parameters?.deviceYaraFactor != null ? parameters.deviceYaraFactor.toString() : ''}
                                    onSave={handleSaveInput('deviceYaraFactor')}
                                />
                            </Form.Group> */}
                            <Form.Group controlId="customer-header">
                                <Form.Label>Coefficiant multiplication SPAD</Form.Label>
                                <Form.Editable.Input
                                    type="number"
                                    value={parameters?.deviceSpadFactor != null ? parameters.deviceSpadFactor.toString() : ''}
                                    onSave={handleSaveInput('deviceSpadFactor')}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Page.Content>
            </Page>
            {showUpdateReportTemplate && (
                <UpdateReportTemplateModal
                    onClose={() => setShowUpdateReportTemplate(false)}
                />
            )}
        </>
    );
};
export default withRouter(AdminScreen, 'ADMIN');
