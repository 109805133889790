import { isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Accident climatique - gel de printemps
 ** - damage: Dégâts // %
 **     0 <= Value <= 100
 */

type CurrentObservation = App.Observation9;
type CurrentApiObservation = App.Api.Observation9;

export const validate = (
    observation: CurrentObservation,
    _: App.InspectionPlotWithRecord
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, damage } = observation;

    if (ignored === true) return validation;

    if (damage != null) {
        if (!isValidNumber(damage) || damage < 0 || damage > 100) {
            validation.damage = {
                field: 'damage',
                type: 'error',
                code: 'format'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        damage
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.damage = parseObservationField(damage);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        damage
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.damage = damage !== undefined ? new RecordValue(damage) : null;

    return mappedObs;
};