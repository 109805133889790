import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { useObservators, useWriters } from 'data/user';
import { reportDetailsPropTypes } from 'data/inspection';
import styles from '../ReportSection.module.scss';

type Props = {
    data: App.Report.ReportDetails;
    onChange?: (value: App.Report.ReportDetails) => void;
};

const ReportSummary: React.FC<Props> = ({
    data,
    onChange
}) => {
    const { data: observators } = useObservators();
    const { data: writers } = useWriters();

    const handleChange = React.useCallback((key: keyof typeof data) => (value: any) => {
        onChange?.({ ...data, [key]: value });
    }, [onChange, data]);

    return (
        <div className={styles['summaryContainer']}>
            <div className={styles['observationLabel']}>Récapitulatif</div>
            <Form.Group controlId="report-summary-supervisor">
                <Form.Label>Message planificateur</Form.Label>
                <Form.Input
                    value={data?.modelHeadline ?? undefined}
                    multiline
                    rows={5}
                    maxlength={2000}
                    onChange={handleChange('modelHeadline')}
                />
            </Form.Group>
            <Form.Group controlId="report-summary-observator">
                <Form.Label>Message observateur</Form.Label>
                <Form.Input
                    value={data?.headline ?? undefined}
                    multiline
                    rows={5}
                    maxlength={2000}
                    onChange={handleChange('headline')}
                />
            </Form.Group>
            <Form.Group controlId="report-observator">
                <Form.Label>Observateur</Form.Label>
                <Form.Select
                    selected={data?.observatorUser}
                    onChange={handleChange('observatorUser')}
                >
                    {observators?.map(observator => (
                        <Form.Select.Option
                            key={observator.id}
                            value={observator.id}
                        >
                            {`${observator.firstName} ${observator.lastName}`}
                        </Form.Select.Option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="report-writer">
                <Form.Label>Rédacteur</Form.Label>
                <Form.Select
                    selected={data?.writerUser}
                    onChange={handleChange('writerUser')}
                >
                    {writers?.map(writer => (
                        <Form.Select.Option
                            key={writer.id}
                            value={writer.id}
                        >
                            {`${writer.firstName} ${writer.lastName}`}
                        </Form.Select.Option>
                    ))}
                </Form.Select>
            </Form.Group>
        </div>
    );
};

ReportSummary.propTypes = {
    data: reportDetailsPropTypes.isRequired,
    onChange: propTypes.func
};

ReportSummary.defaultProps = {
    onChange: undefined
};

export default ReportSummary;
