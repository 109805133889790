import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'shared-ui';
import { DownloadIcon } from 'components/Icons';
import { getYearState } from 'utils/yearStore';
import { inspectionRequests } from 'data/requests';
import { reportPropTypes } from 'data/inspection';

type Props = {
    customerId?: number;
    reports?: App.Report.Report[];
};

const HeaderAction: React.FC<Props> = ({
    customerId,
    reports
}) => {
    const year = getYearState();

    const enableDownload = customerId != null && Array.isArray(reports) && reports.length > 0;
    const downloadUrl = inspectionRequests.getCustomerReportDownloadUrl(year, customerId);

    return (
        <Button
            color="primary"
            type="link"
            startIcon={(<DownloadIcon />)}
            link={downloadUrl}
            target="_blank"
            rel="noreferrer"
            disabled={!enableDownload}
        >
            Télécharger les rapports
        </Button>
    );
};

HeaderAction.propTypes = {
    customerId: propTypes.number,
    reports: propTypes.arrayOf(reportPropTypes.isRequired)
};

HeaderAction.defaultProps = {
    customerId: undefined,
    reports: undefined
};

export default HeaderAction;
