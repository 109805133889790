import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import BioTypeScreen from './BioTypeScreen';

export const BioTypeNavigation = (): NavigationRoute => new NavigationRoute(
    BioTypeScreen.key,
    [getYearRouteParam()]
);

const router = new Router();

router
    .add(new RouteData({
        key: BioTypeScreen.key,
        path: '',
        component: BioTypeScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: ['administrator', 'supervisor']
        }
    }));

export default router;