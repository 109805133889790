import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import styles from '../ReportModelSection.module.scss';

type Props = {
    data: any;
    selected: boolean;
    onCommentChange?: (id: number, comment: string) => Promise<string | undefined>;
};

const ObservationColumn: React.FC<Props> = ({
    data,
    selected,
    onCommentChange
}: Props) => {
    const handleCommentChange = React.useCallback(async (comment: string) =>
        Promise.resolve(onCommentChange?.(data.id, comment)), [onCommentChange, data]);

    return (
        <>
            <div className={styles['observationLabel']}>{data.label}</div>
            {selected === true && (
                <Form.Group controlId="report-observation-comment">
                    <Form.Editable.Input
                        value={data.comment}
                        multiline
                        rows={5}
                        maxlength={2000}
                        onSave={handleCommentChange}
                    />
                </Form.Group>
            )}
        </>
    );
};

ObservationColumn.propTypes = {
    data: propTypes.any,
    selected: propTypes.bool.isRequired,
    onCommentChange: propTypes.func
};

ObservationColumn.defaultProps = {
    data: undefined,
    onCommentChange: undefined
};

export default ObservationColumn;
