import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { useYearState } from 'utils/yearStore';
import { queryClient } from 'utils/queryClient';
import {
    CAMPAIGN_KEY,
    CAMPAIGN_DETAILS_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    MutationResult,
    mutateResultKeys,
    defaultActionOptions,
    CAMPAIGN_REPORT_KEY,
    REPORT_OBSERVATION_KEY
} from './constants';
import { campaignRequests } from './requests';

export const useCampaigns = (options?: DataOptions): QueryResult<App.Campaign[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.Campaign[], ServiceError>({
        ...config,
        queryKey: [year, CAMPAIGN_KEY],
        queryFn: () => campaignRequests.getCampaigns(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCampaignDetails = (
    campaignId: number,
    options?: DataOptions
): QueryResult<App.CampaignDetails> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.CampaignDetails, ServiceError>({
        ...config,
        queryKey: [year, CAMPAIGN_KEY, CAMPAIGN_DETAILS_KEY, campaignId],
        queryFn: () => campaignRequests.getCampaignDetails(year, campaignId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useReportObservations = (options?: DataOptions): QueryResult<App.ReportObservation[]> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.ReportObservation[], ServiceError>({
        ...config,
        queryKey: [REPORT_OBSERVATION_KEY],
        queryFn: () => campaignRequests.getReportObservations(year)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCampaignReport = (
    campaignId: number,
    options?: DataOptions
): QueryResult<App.CampaignReport> => {
    const [year] = useYearState();
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.CampaignReport, ServiceError>({
        ...config,
        queryKey: [year, CAMPAIGN_KEY, CAMPAIGN_DETAILS_KEY, campaignId, CAMPAIGN_REPORT_KEY],
        queryFn: () => campaignRequests.getCampaignReport(year, campaignId)
    });

    return usePick(query as any, queryResultKeys);
};

export const useCreateCampaign = (): MutationResult<number, App.CreateCampaignRequest> => {
    const [year] = useYearState();
    const result = useMutation<number, ServiceError, App.CreateCampaignRequest>(
        (request: App.CreateCampaignRequest) =>
            campaignRequests.createCampaign(year, { ...request }),
        {
            ...defaultActionOptions,
            onSuccess: () => {
                queryClient.invalidateQueries([year, CAMPAIGN_KEY], { exact: true });
            }
        }
    );

    return result;
};

export const useUpdateCampaign = (): MutationResult<void, App.UpdateCampaignRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdateCampaignRequest>(
        (request: App.UpdateCampaignRequest) => {
            const { id, ...props } = request;

            return campaignRequests.updateCampaign(year, id, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { id }) => {
                queryClient.invalidateQueries([year, CAMPAIGN_KEY]);
            }
        }
    );

    return result;
};

export const useUpdateCampaignReport = (): MutationResult<void, App.UpdateCampaignReportRequest> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, App.UpdateCampaignReportRequest>(
        (request: App.UpdateCampaignReportRequest) => {
            const { campaignId, ...props } = request;

            return campaignRequests.updateCampaignReport(year, campaignId, props);
        },
        {
            ...defaultActionOptions,
            onSuccess: (_, { campaignId }) => {
                queryClient.invalidateQueries([
                    year,
                    CAMPAIGN_KEY,
                    CAMPAIGN_DETAILS_KEY,
                    campaignId,
                    CAMPAIGN_REPORT_KEY
                ]);
            }
        }
    );

    return result;
};

export const useDeleteCampaign = (): MutationResult<void, number> => {
    const [year] = useYearState();
    const result = useMutation<void, ServiceError, number>(
        id => campaignRequests.deleteCampaign(year, id),
        {
            ...defaultActionOptions,
            onSuccess: (_, id) => {
                queryClient.invalidateQueries([year, CAMPAIGN_KEY]);
            }
        }
    );

    return result;
};
