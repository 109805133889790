import React from 'react';
import { Button } from 'shared-ui';
import { PlusIcon } from '../../../components/Icons';
import AssociateObservationModal from './associateObservationModal';

const HeaderAction: React.FC = () => {
    const [showAssociateTypes, setShowAssociateTypes] = React.useState(false);

    return (
        <>
            <Button
                color="primary"
                startIcon={(<PlusIcon />)}
                onClick={() => setShowAssociateTypes(true)}
            >
                Associer des types de suivi
            </Button>
            {showAssociateTypes && (
                <AssociateObservationModal
                    onClose={() => setShowAssociateTypes(false)}
                    onCompletion={() => setShowAssociateTypes(false)}
                />
            )}
        </>
    );
};

export default HeaderAction;
