import React from 'react';
import propTypes from 'prop-types';
import * as Ark from '@dateam/ark';
import {
    Button,
    Modal,
    Table,
    TableSelectionChangeEvent,
    TableHeaderSelectionChangeEvent
} from 'shared-ui';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import { useUpdatePlotObservationTypes } from 'data/plot';
import { useObservationTypes } from 'data/observationType';
import styles from '../PlotDetailsScreen.module.scss';

type Props = {
    plotId: App.PlotDetails['id'];
    data: App.PlotDetails['observationTypes'];
    className?: string;
    onClose?: () => void;
    onCompletion?: () => void;
};

type TypeRow = {
    id: number;
    label: string;
    selected: boolean;
};

const TypeSelector: React.FC<Props> = ({
    plotId,
    data,
    className,
    onClose,
    onCompletion
}: Props) => {
    const [selection, setSelection] = React.useState<number[]>([]);
    const { data: observationTypes } = useObservationTypes();
    const { mutateAsync: updateObservationTypes } = useUpdatePlotObservationTypes();

    React.useEffect(() => {
        if (Ark.isArray(data)) {
            const newSelection = data.map(obs => obs.id);
            setSelection(newSelection);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const plotRows = React.useMemo<TypeRow[]>(() => {
        if (!Ark.isArray(observationTypes)) return [];

        return observationTypes.map(type => ({
            ...type,
            selected: selection.some(item => item === type.id)
        }));
    }, [observationTypes, selection]);

    const handleSelectionChange = (event: TableSelectionChangeEvent<TypeRow>) => {
        const newSelection = [...selection];
        const itemIndex = selection.findIndex(stateItem => stateItem === event.data.id);

        if (itemIndex < 0 && event.selected === true) {
            newSelection.push(event.data.id);
        }
        else if (itemIndex >= 0 && event.selected === false) {
            newSelection.splice(itemIndex, 1);
        }

        setSelection(newSelection);
    };

    const handleHeaderSelectionChange =
        React.useCallback((event: TableHeaderSelectionChangeEvent<TypeRow>) => {
            const newSelection = event.selected === true ? event.items.map(item => item.data.id) : [];
            setSelection(newSelection);
        }, [setSelection]);

    const onModalClose = () => {
        onClose?.();
    };

    const onSave = async () => {
        try {
            await updateObservationTypes({
                id: plotId,
                observationTypes: selection
            });

            onClose?.();
        }
        catch {
            // ignore
        }
    };

    return (
        <Modal
            className={`${styles['modal']} ${className} modal-flex`}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Ajouter/retirer des types de suivi
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table<TypeRow>
                    data={plotRows}
                    selectedField="selected"
                    onSelectionChange={handleSelectionChange}
                    onHeaderSelectionChange={handleHeaderSelectionChange}
                >
                    <Table.Column accessor="label" className={styles['modalTableCol']}>
                        <Table.Column.Header>Nom</Table.Column.Header>
                    </Table.Column>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onModalClose}>Annuler</Button>
                <Button
                    color="primary"
                    onClick={onSave}
                >
                    Mettre à jour les types de suivi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

TypeSelector.propTypes = {
    plotId: propTypes.number.isRequired,
    data: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

TypeSelector.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default TypeSelector;
