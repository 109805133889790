import React from 'react';
import propTypes from 'prop-types';
import { isFunction, noop } from '@dateam/ark';
import { concatClassName, useDebounce } from '@dateam/ark-react';
import { Form } from 'shared-ui';
import { useObservationTypes } from 'data/observationType';

export type ObservationFilters = {
    label: string;
    types: number[];
};

type Props = {
    enabled?: boolean;
    className?: string;
    onChange?: (filters: ObservationFilters) => void;
};

const FilterBar: React.FC<Props> = ({
    enabled,
    className,
    onChange
}) => {
    const { data: types } = useObservationTypes({ enabled });
    const notifyChange = React.useRef(onChange ?? noop);
    const saveChanges = useDebounce((filter: ObservationFilters) => {
        notifyChange.current?.(filter);
    }, 300);

    React.useEffect(() => {
        if (isFunction(onChange)) notifyChange.current = onChange;
    }, [onChange, notifyChange]);

    const [filter, setFilter] = React.useState<ObservationFilters>({
        label: '',
        types: []
    });

    const handleLabelChange = React.useCallback((newValue: string) => {
        const newFilter: ObservationFilters = {
            ...filter,
            label: newValue
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    const handleTypesChange = React.useCallback((selectedValues: number[]) => {
        const newFilter: ObservationFilters = {
            ...filter,
            types: selectedValues
        };

        setFilter(newFilter);
        saveChanges(newFilter);
    }, [saveChanges, filter]);

    return (
        <div className={concatClassName('row', className)}>
            <Form.Group controlId="filter-label" className="col">
                <Form.Label>Nom</Form.Label>
                <Form.Input
                    value={filter.label}
                    autocomplete="off"
                    onChange={handleLabelChange}
                />
            </Form.Group>
            <Form.Group controlId="filter-types" className="col">
                <Form.Label>Types de suivi</Form.Label>
                <Form.Multiselect
                    id="filter-types-select"
                    onChange={handleTypesChange}
                >
                    {types?.map((type, index) => (
                        <Form.Select.Option
                            key={index}
                            value={type.id}
                            selectedText={type.label}
                        >
                            {type.label}
                        </Form.Select.Option>
                    ))}
                </Form.Multiselect>
            </Form.Group>
        </div>
    );
};

FilterBar.propTypes = {
    enabled: propTypes.bool,
    className: propTypes.string,
    onChange: propTypes.func
};

FilterBar.defaultProps = {
    enabled: true,
    className: undefined,
    onChange: undefined
};

export default FilterBar;
