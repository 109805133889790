import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { useCreatePlot } from 'data/plot';
import { customerDetailsPropTypes } from 'data/customer';

type CreatePlot = {
    publicId: string | null;
    label: string | null;
};

type Props = {
    customer: App.CustomerDetails;
    className?: string;
    onClose?: () => void;
    onCompletion?: (plotId: number) => void;
};

const CreatePlotModal: React.FC<Props> = ({
    customer,
    className,
    onClose,
    onCompletion
}: Props) => {
    const { mutateAsync: createPlot, isLoading: isCreating } = useCreatePlot();
    const [plotState, setPlotState] = React.useState<CreatePlot>({
        publicId: customer.publicId,
        label: null
    });

    const formIsValid = React.useMemo(() => plotState.publicId != null &&
        plotState.publicId.trim().length > 0 &&
        plotState.label != null &&
        plotState.label.trim().length > 0, [plotState]);

    const handleInputChange = React.useCallback((key: string) => async (newValue: any) => {
        setPlotState(prevState => {
            const newState = {
                ...prevState,
                [key]: newValue
            };

            return newState;
        });
    }, [setPlotState]);

    const handleCloseClick = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleCreateClick = React.useCallback(async () => {
        if (plotState.publicId == null ||
            plotState.label == null) return;

        const plotKey = await createPlot({
            year: customer.year,
            customerId: customer.id,
            publicId: plotState.publicId,
            label: plotState.label
        });

        onCompletion?.(plotKey.id);
    }, [createPlot, onCompletion, plotState, customer]);

    return (
        <Modal
            className={concatClassName(className, 'modal-flex')}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Créer une nouvelle parcelle
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group controlId="plot-publicId" className="col col-4">
                        <Form.Label>Code</Form.Label>
                        <Form.Input
                            value={plotState.publicId ?? undefined}
                            maxlength={20}
                            autocomplete="off"
                            readOnly={isCreating}
                            onChange={handleInputChange('publicId')}
                        />
                    </Form.Group>
                    <Form.Group controlId="plot-name" className="col col-12">
                        <Form.Label>Nom</Form.Label>
                        <Form.Input
                            value={plotState.label ?? undefined}
                            maxlength={100}
                            autocomplete="off"
                            readOnly={isCreating}
                            onChange={handleInputChange('label')}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isCreating}
                    onClick={handleCloseClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!formIsValid}
                    pending={isCreating}
                    onClick={handleCreateClick}
                >
                    Créer la parcelle
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CreatePlotModal.propTypes = {
    customer: customerDetailsPropTypes.isRequired,
    className: propTypes.string,
    onClose: propTypes.func,
    onCompletion: propTypes.func
};

CreatePlotModal.defaultProps = {
    className: undefined,
    onClose: undefined,
    onCompletion: undefined
};

export default CreatePlotModal;
