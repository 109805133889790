import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isDefined, isValidDate, ObjectAccessor, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import Switch, { SwitchItem } from 'shared-ui/dist/switch';
import { plotDetailsPropTypes } from 'data/plot';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps } from './types';
import { ObservationErrors } from './components';
import ObservationField from './ObservationField';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation2';

const FIELD_TYPE_VALUES = [
    { value: 'yara', label: 'N-Tester' },
    { value: 'spad', label: 'Spad' }
];

type Props = ObservationProps<App.Observation2>;

const Observation2: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.field1 === undefined) return;

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const field1 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.field1 ?? undefined;
    }, [data]);

    const obsFieldType = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.fieldType ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>Appareil: {internalData.current.previous.fieldType?.value ?? '-'}</div>
                    <div className={styles['previous']}>Valeur: {internalData.current.previous.field1?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation2 = {
            ...internalData.current,
            field1: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleFieldChange = React.useCallback((newValue: string | undefined) => {
        const newData: App.Observation2 = {
            ...internalData.current,
            field1: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleTypeChange = React.useCallback((newValue: string | undefined) => {
        const newData: App.Observation2 = {
            ...internalData.current,
            fieldType: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleFocus = React.useCallback((field: ObjectAccessor<Omit<App.Observation2, 'previous'>>) => {
        if (internalData.current[field] !== undefined) return;

        const newData: App.Observation2 = {
            ...internalData.current,
            [field]: null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="N-Tester"
        >
            <div className="row">
                <ObservationField
                    id={`${observationKey}-fieldType`}
                    label="Appareil de mesure"
                    className={concatClassName(styles[`${observationKey}-fieldType`], 'col-4')}
                >
                    <Form.Switch
                        value={obsFieldType?.toLowerCase()}
                        required={!data.ignored}
                        readOnly={data.ignored}
                        onChange={handleTypeChange}
                    >
                        {FIELD_TYPE_VALUES.map(option => (
                            <SwitchItem
                                key={option.value}
                                value={option.value.toLowerCase()}
                            >
                                {option.label}
                            </SwitchItem>
                        ))}
                    </Form.Switch>
                </ObservationField>
                <ObservationField
                    id={`${observationKey}-field1`}
                    label="Valeur"
                    className={concatClassName(styles[`${observationKey}-field1`], 'col-4')}
                >
                    <Form.Input
                        value={field1}
                        onChange={handleFieldChange}
                        maxlength={3}
                        state={validationErrors['field1']?.type}
                        onFocus={() => handleFocus('field1')}
                        required={!data.ignored}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation2.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotDetailsPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation2.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation2;
