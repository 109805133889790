import React from 'react';
import { isDefined } from '@dateam/ark';
import { sortObservations } from 'data/observation';
import Observation from 'components/Observation';
import ObservationComment from './ObservationComment';
import { plotPropType } from './types';
import styles from '../../../InspectionEditScreen.module.scss';

type Props = {
    inspectionId: App.InspectionWithRecord['id'];
    data?: App.InspectionPlotWithRecord | null;
};

const PlotObservations: React.FC<Props> = ({
    inspectionId,
    data
}) => {
    const observations = React.useMemo(() => {
        if (!isDefined(data)) return [];

        return sortObservations(data.observations);
    }, [data]);

    if (!isDefined(data)) return (<div className={styles['noData']}>Aucune parcelle selectionnée</div>);

    return (
        <div className={styles['entry-observations']}>
            {observations.map(obs => (
                <Observation
                    key={`${data.id}-${obs.id}`}
                    data={obs}
                    inspectionId={inspectionId}
                    plot={data}
                />
            ))}
            <ObservationComment
                inspectionId={inspectionId}
                plot={data}
            />
        </div>
    );
};

PlotObservations.propTypes = {
    data: plotPropType
};

PlotObservations.defaultProps = {
    data: undefined
};

export default PlotObservations;
