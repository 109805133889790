import React from 'react';
import { isArray, isString, objectPick } from '@dateam/ark';
import { QueryResult, queryResultKeys } from 'data/constants';
import { usePlots } from 'data/plot';

export type PlotSearchFilter = {
    plot: string | undefined;
    track: string | undefined;
    varietalIds: number[];
    areaIds: number[];
    customer: string | undefined;
    bio: number[];
};

export const usePlotSearch = (filter: PlotSearchFilter, options?: DataOptions): QueryResult<App.Plot[]> => {
    const { data: plots, ...queryResult } = usePlots();

    return React.useMemo(() => {
        let filteredData: App.Plot[] = [];

        if (isArray(plots)) {
            filteredData = [...plots];

            const {
                plot: plotFilter,
                track: trackFilter,
                varietalIds: varietalFilter,
                areaIds: areaFilter,
                customer: customerFilter,
                bio: bioFilter
            } = filter;

            const filterPredicates: ((dataEntry: App.Plot) => boolean)[] = [];

            if (isArray(varietalFilter) && varietalFilter.length > 0) {
                filterPredicates.push((dataEntry: App.Plot) =>
                    dataEntry.varietal != null && varietalFilter.indexOf(dataEntry.varietal.id) >= 0);
            }

            if (isArray(bioFilter) && bioFilter.length > 0) {
                filterPredicates.push((dataEntry: App.Plot) =>
                    dataEntry.bio != null && bioFilter.indexOf(dataEntry.bio.id) >= 0);
            }

            if (isArray(areaFilter) && areaFilter.length > 0) {
                filterPredicates.push((dataEntry: App.Plot) =>
                    dataEntry.area != null && areaFilter.indexOf(dataEntry.area.id) >= 0);
            }

            if (isString(trackFilter) && trackFilter.trim().length > 0) {
                filterPredicates.push((dataEntry: App.Plot) =>
                    isArray(dataEntry.tracks) &&
                    dataEntry.tracks.find(track => track.label.indexOf(trackFilter) >= 0) != null);
            }

            if (isString(customerFilter) && customerFilter.trim().length > 0) {
                filterPredicates.push((dataEntry: App.Plot) =>
                    customerFilter === dataEntry.customer.label);
            }

            if (isString(plotFilter) && plotFilter.trim().length > 0) {
                const filter = plotFilter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.Plot) =>
                    dataEntry.publicId.toUpperCase().indexOf(filter) >= 0 ||
                    dataEntry.label.toUpperCase().indexOf(filter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        const result = {
            ...queryResult,
            data: filteredData
        };

        return objectPick(result as any, queryResultKeys);
    }, [plots, queryResult, filter]);
};
