import { Router, RouteData, NavigationRoute } from 'shared-utils';
import { getYearRouteParam } from 'utils/yearStore';
import CampaignDetailsScreen from './CampaignDetailsScreen';

const campaignTabKey = `${CampaignDetailsScreen.key}_TAB`;
const roles = ['administrator', 'supervisor', 'writer'];

export const CampaignDetailsNavigation = (campaignId: number): NavigationRoute =>
    new NavigationRoute(
        CampaignDetailsScreen.key,
        [getYearRouteParam(), campaignId]
    );

export const CampaignTabNavigation = (campaignId: number, tab: string): NavigationRoute =>
    new NavigationRoute(
        campaignTabKey,
        [getYearRouteParam(), campaignId, tab]
    );

const router = new Router();

router
    .add(new RouteData({
        key: CampaignDetailsScreen.key,
        path: '/<:campaignId>', // Should have matched the pattern <:campaignId([\\d]+)> if react-router V6 supported it
        component: CampaignDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }))
    .add(new RouteData({
        key: campaignTabKey,
        path: '/<:campaignId>/<:tab>', // Should have matched the pattern <:campaignId([\\d]+)>/<:tab([a-zA-Z]+)> if react-router V6 supported it
        component: CampaignDetailsScreen,
        exact: true,
        metadata: {
            authRequired: true,
            rolesAllowed: roles
        }
    }));

export default router;