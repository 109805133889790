import React from 'react';
import * as Ark from '@dateam/ark';
import { QueryResult, queryResultKeys } from 'data/constants';
import { getInspectionStatus, useInspections } from 'data/inspection';

export type InspectionSearchFilter = {
    label: string | undefined;
    campaignIds: number[];
    status: string | undefined;
    observator: number | null | undefined;
}

export const useInspectionSearch = (
    filter: InspectionSearchFilter,
    options?: DataOptions
): QueryResult<App.InspectionSearchResult[]> => {
    const { data: inspections, ...queryResult } = useInspections();

    return React.useMemo(() => {
        let filteredData: App.InspectionSearchResult[] = [];

        if (Array.isArray(inspections)) {
            filteredData = inspections.map(inspection => {
                // let status: App.InspectionSearchResult['status'] = 'planned';
                // if (Ark.isValidDate(inspection.validationDate)) status = 'validated';
                // if (!Ark.isValidDate(inspection.startDate)) status = 'notplanned';
                const status = getInspectionStatus(inspection);
                return { ...inspection, status };
            });

            const {
                label: labelFilter,
                campaignIds: campaignFilter,
                status: statusFilter,
                observator: observatorFilter
            } = filter;

            const filterPredicates: ((dataEntry: App.InspectionSearchResult) => boolean)[] = [];

            if (Ark.isArray(campaignFilter) && campaignFilter.length > 0) {
                filterPredicates.push((dataEntry: App.InspectionSearchResult) =>
                    campaignFilter.indexOf(dataEntry.campaign.id) >= 0);
            }

            if (Ark.isString(statusFilter) && statusFilter.trim().length > 0) {
                filterPredicates.push((dataEntry: App.InspectionSearchResult) =>
                    statusFilter === dataEntry.status);
            }

            if (Ark.isValidNumber(observatorFilter)) {
                filterPredicates.push((dataEntry: App.InspectionSearchResult) =>
                    dataEntry.assigneeUser != null && observatorFilter === dataEntry.assigneeUser?.id);
            }

            if (Ark.isString(labelFilter) && labelFilter.trim().length > 0) {
                const filter = labelFilter.trim().toUpperCase();
                filterPredicates.push((dataEntry: App.InspectionSearchResult) =>
                    dataEntry.label.toUpperCase().indexOf(filter) >= 0);
            }

            filteredData = filteredData.filter(dataEntry => filterPredicates.every(predicate => predicate(dataEntry)));
        }

        const result = {
            ...queryResult,
            data: filteredData
        };

        return Ark.objectPick(result as any, queryResultKeys);
    }, [inspections, queryResult, filter]);
};
