import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { plotPropType } from './types';
import styles from '../../../InspectionEditScreen.module.scss';

type Props = {
    data: App.InspectionPlotWithRecord;
    className?: string;
};

const PlotInfos: React.FC<Props> = ({ data, className }: Props) => {
    const types = data.observationTypes.map(type => type.label).join(',');

    return (
        <div className={concatClassName(styles['plotInfos'], className)}>
            <div className={styles['plotInfosItem']}>
                Code de la parcelle : {data.publicId}
            </div>
            <div className={styles['plotInfosItem']}>
                Vigneron : {data.customer.label}
            </div>
            <div className={styles['plotInfosItem']}>
                Cépage : {data.varietal?.label}
            </div>
            <div className={styles['plotInfosItem']}>
                Zone géographique : {data.area?.label}
            </div>
            <div className={styles['plotInfosItem']}>
                Ville : {data.city}
            </div>
            <div className={styles['plotInfosItem']}>
                Type de protection : {data.bio?.label}
            </div>
            <div className={styles['plotInfosItem']}>
                Confusion : {data.confusion === true ? 'Oui' : 'Non'}
            </div>
            <div className={styles['plotInfosItem']}>
                Type de suivi : {types}
            </div>
        </div>
    );
};

PlotInfos.propTypes = {
    data: plotPropType.isRequired,
    className: propTypes.string
};

PlotInfos.defaultProps = {
    className: undefined
};

export default PlotInfos;
