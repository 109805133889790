import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { NavigationRoute, useNavigation, useRouterPath } from 'shared-utils';
import styles from './Sidebar.module.scss';

type Props = {
    to: NavigationRoute;
    icon?: React.ReactNode;
    className?: string;
    active?: boolean;
    children?: React.ReactNode;
};

const SidebarMenuLink: React.FC<Props> = ({
    to,
    children,
    icon,
    className,
    active
}: Props) => {
    const path = useRouterPath(to);
    const { push: navigate } = useNavigation();

    const onClick = React.useCallback((event: React.MouseEvent<any, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();
        if (to instanceof NavigationRoute) navigate(to);
    }, [navigate, to]);

    return (
        <li
            className={concatClassName(
                styles['menuItem'],
                className,
                active ? styles['menuItem-active'] : null
            )}
        >
            <a href={path} className={styles['menuItemLink']} onClick={onClick}>
                <span className={styles['menuItemIcon']}>{icon}</span>
                <span className={styles['menuItemText']}>{children}</span>
            </a>
        </li>
    );
};

SidebarMenuLink.propTypes = {
    to: propTypes.instanceOf(NavigationRoute).isRequired,
    children: propTypes.node,
    icon: propTypes.node,
    className: propTypes.string,
    active: propTypes.bool
};

SidebarMenuLink.defaultProps = {
    children: undefined,
    icon: undefined,
    className: undefined,
    active: false
};

export default SidebarMenuLink;
