import React from 'react';
import { concatClassName } from '@dateam/ark-react';
import { dateToFormat, isValidDate } from '@dateam/ark';
import { useYearlyNavigation } from 'utils/yearStore';
import { SidePanelListItemCol, SidePanelListItemLink } from 'components/SidePanelList';
import { InspectionTabNavigation } from 'screens/inspectionEdit';
import { DocumentIcon } from 'components/Icons';
import styles from '../DashboardScreen.module.scss';

type Props = {
    value: App.InspectionRemainingReport;
};

const WriterInspectionItem: React.FC<Props> = ({ value }: Props) => {
    const inspectionNav = useYearlyNavigation(InspectionTabNavigation, [value.id, 'report']);

    const reportToWriteUp = value.reportCount - value.writtenReportCount;
    const reportToSend = value.reportCount - value.sendedReportCount;

    const startDate = isValidDate(value.startDate) ? dateToFormat(value.startDate) : '';

    return (
        <SidePanelListItemLink
            key={value.id}
            to={inspectionNav}
        >
            <SidePanelListItemCol className={`${styles['writerItemIndicator']} col-1`} icon={<DocumentIcon />} />
            <SidePanelListItemCol className={`${styles['']} col-5`} text={`${value.label}`} subtext={startDate} />
            <SidePanelListItemCol
                className={concatClassName(
                    'col-2',
                    styles['writerItemIndicator'],
                    reportToWriteUp > 0 ? styles['writerItemIndicator-error'] : null,
                    reportToWriteUp === 0 ? styles['writerItemIndicator-success'] : null
                )}
                subTextClassName={styles['writerItemIndicator-subText']}
                text={reportToWriteUp.toString()}
                subtext="à rédiger"
            />
            <SidePanelListItemCol
                className={`col-2 ${styles['writerItemIndicator']} ${styles['writerItemIndicator-warn']}`}
                subTextClassName={styles['writerItemIndicator-subText']}
                text={reportToSend.toString()}
                subtext="à envoyer"
            />
        </SidePanelListItemLink>
    );
};

WriterInspectionItem.propTypes = {
};

WriterInspectionItem.defaultProps = {
};

export default WriterInspectionItem;
