import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { useUploadTemporaryReportTemplate, useValidateTemporaryReportTemplate } from 'data/admin';
import {
    Button,
    Form,
    Modal
} from 'shared-ui';
import { DownloadIcon } from 'components/Icons';
import { adminRequests } from 'data/requests';
import { useYearState } from 'utils/yearStore';
import styles from './UpdateReportTemplateModal.module.scss';

type Props = {
    className?: string;
    onClose?: () => void;
};

const UpdateReportTemplateModal: React.FC<Props> = ({
    className,
    onClose
}: Props) => {
    const [year] = useYearState();
    const { mutateAsync: uploadTemporaryReportTemplate, isLoading: isUpdating } = useUploadTemporaryReportTemplate();
    const {
        mutateAsync: validateTemporaryReportTemplate,
        isLoading: isValidating
    } = useValidateTemporaryReportTemplate();

    const [files, setFiles] = React.useState<{ headerFile: File | null, footerFile: File | null }>({
        headerFile: null,
        footerFile: null
    });
    const [isPreview, setIsPreview] = React.useState(false);

    const handleHeaderFileChange = React.useCallback((file: File | null) => {
        setIsPreview(false);
        setFiles({
            ...files,
            headerFile: file
        });
    }, [files]);

    const handleFooterFileChange = React.useCallback((file: File | null) => {
        setIsPreview(false);
        setFiles({
            ...files,
            footerFile: file
        });
    }, [files]);

    const handlePreviewClick = React.useCallback(async () => {
        await uploadTemporaryReportTemplate({
            year,
            header: files.headerFile,
            footer: files.footerFile
        });

        const downloadUrl = adminRequests.getReportPreviewDownloadUrl(year);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsPreview(true);
    }, [files, uploadTemporaryReportTemplate, year]);

    const handleValidateClick = React.useCallback(async () => {
        try {
            await validateTemporaryReportTemplate({ year });
            if (onClose) {
                onClose();
            }
        }
        catch {
            console.log('error');
        }
    }, [onClose, validateTemporaryReportTemplate, year]);

    const downloadHeaderUrl = adminRequests.getReportHeaderTemplateDownloadUrl(year);
    const downloadFooterUrl = adminRequests.getReportFooterTemplateDownloadUrl(year);
    const haveTemplateFiles = files.footerFile != null || files.headerFile != null;

    return (
        <Modal
            className={concatClassName('modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Mise à jour du modèle de rapport
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles['modal']}>
                <div className={styles['modalContent']}>
                    <div className={styles['section-download']}>
                        <h3>Modèle d'entête du rapport de l'année {year}</h3>
                        <div className={styles['download-files']}>
                            <Button
                                color="primary"
                                type="link"
                                startIcon={(<DownloadIcon />)}
                                link={downloadHeaderUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Télécharger le modèle d'entête du rapport
                            </Button>
                            <Button
                                color="primary"
                                type="link"
                                startIcon={(<DownloadIcon />)}
                                link={downloadFooterUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Télécharger le modèle de fin du rapport
                            </Button>
                        </div>
                    </div>
                    <div className={styles['section-update']}>
                        <h3>Mettre à jour les fichiers de modèles</h3>
                        <Form.Group controlId="customer-header">
                            <Form.Label>Nouveau fichier d'entête</Form.Label>
                            <Form.File
                                onChange={handleHeaderFileChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="customer-header">
                            <Form.Label>Nouveau fichier de fin</Form.Label>
                            <Form.File
                                onChange={handleFooterFileChange}
                            />
                        </Form.Group>
                        <Button
                            color="primary"
                            startIcon={(<DownloadIcon />)}
                            onClick={handlePreviewClick}
                            disabled={!haveTemplateFiles}
                        >
                            Prévisualiser les modifications
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    disabled={!isPreview}
                    onClick={handleValidateClick}
                >
                    Valider les nouveaux fichiers
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

UpdateReportTemplateModal.propTypes = {
    className: propTypes.string,
    onClose: propTypes.func
};

UpdateReportTemplateModal.defaultProps = {
    className: undefined,
    onClose: undefined
};

export default UpdateReportTemplateModal;
